import { JsonPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { AssigneesSectionCommonProperties } from '@fieldos/models';
import {
  AsWorkOrderAssigneePipe,
  DataSourceEntityPipe,
} from '@fieldos/pipes';
import { DataSourceStore, StandardDataSourceId } from '@fieldos/store';
import { TranslocoModule } from '@ngneat/transloco';
import { NumberFieldComponent } from 'app/@components/fields/number-field';
import { filter, tap } from 'rxjs';
import { BoxConfirmDialogComponent } from '../../../../../../@components/box-confirm-dialog';
import {
  AutocompleteSelectComponent,
  DataSourceMultiSelectComponent,
  DatetimePickerComponent,
  DurationPickerComponent,
  HtmlEditorComponent,
} from '../../../../../../@components/fields';
import { SectionsFormAssigneeCategoryFormComponent } from '../sections-form-assignee-category-form/sections-form-assignee-category-form.component';
import { AssigneeWorkCategoryFormType } from '../sections-form-assignee-category-form/sections-form-assignee-category-form.form';
import { createWorkCategoryForm } from '../sections-form-assignees.form';
import { AssigneeFormType } from '../sections-form-assignees.form-models';

@Component({
  selector: 'app-sections-form-assignee-form',
  templateUrl: './sections-form-assignee-form.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCardModule,
    TranslocoModule,
    MatButtonModule,
    AutocompleteSelectComponent,
    DurationPickerComponent,
    DatetimePickerComponent,
    HtmlEditorComponent,
    MatDividerModule,
    MatIconModule,
    ReactiveFormsModule,
    NumberFieldComponent,
    DataSourceMultiSelectComponent,
    DataSourceMultiSelectComponent,
    DataSourceEntityPipe,
    NgClass,
    AsWorkOrderAssigneePipe,
    BoxConfirmDialogComponent,
    SectionsFormAssigneeCategoryFormComponent,
    JsonPipe,
  ],
})
export class SectionsFormAssigneeFormComponent {
  @Input({ required: true })
  public properties!: AssigneesSectionCommonProperties;

  @Input({ required: true })
  public form!: FormGroup<AssigneeFormType>;

  @Output() public readonly removeAssignee = new EventEmitter<void>();

  @ViewChild(BoxConfirmDialogComponent)
  private readonly _confirmDialog!: BoxConfirmDialogComponent;

  private readonly _dataSourceService = inject(DataSourceStore);

  protected get categoryFormGroups(): FormGroup<AssigneeWorkCategoryFormType>[] {
    return this.form.controls.categories
      .controls as FormGroup<AssigneeWorkCategoryFormType>[];
  }

  protected onAddWorkCategory(): void {
    const categories = this._dataSourceService.getDataSource(
      StandardDataSourceId.WorkCategory
    );
    const form = createWorkCategoryForm();
    form.patchValue({ id: categories[0].id });

    this.form.controls.categories.push(form);
  }

  protected onRemoveAssignee(): void {
    this._confirmDialog
      .open()
      .pipe(
        filter((canRemove) => canRemove),
        tap(() => this.removeAssignee.emit())
      )
      .subscribe();
  }

  protected onRemoveWorkCategory(index: number): void {
    this.form.controls.categories.removeAt(index);
  }
}
