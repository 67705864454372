@if (isMultipleSelect) {
  <app-multiple-select
    class="h-full"
    [formControl]="form"
    [loading]="loading()"
    [options]="items()" />
} @else {
  <app-autocomplete-select
    class="h-full"
    [formControl]="form"
    [loading]="loading()"
    [options]="items()"
    [searchProperties]="$any(searchProperties())"
    labelProperty="name"
    valueProperty="id" />
}
