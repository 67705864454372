import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';

export const pagesRoutes: Route[] = [
  {
    path: 'pages',
    component: LayoutComponent,
    children: [
      {
        path: 'safe-to-close-tab',
        loadComponent: () =>
          import('./safe-to-close-tab/safe-to-close-tab.component').then(
            (m) => m.SafeToCloseTabComponent
          ),
        data: {
          layout: 'empty',
        },
      },
    ],
  },
];
