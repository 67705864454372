import { DomainEntityBase } from './entity-base';

export interface Assignee extends DomainEntityBase<number> {
  type: 'employee' | 'contractor';
  contractor: string;
  email: string;
  surname: string;
  team: string | null;
  text: string;
}
