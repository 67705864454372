import { SectionProperties } from './section-properties';
import { SectionLabelInfoProperties } from './simple-section-properties';

export interface AssigneesSectionCommonProperties {
  type: boolean;
  team: boolean;
  workCategory: boolean;
  workDuration: boolean;
  cost: boolean;
  contract: boolean;
  notes: boolean;
  date: boolean;
  singleDataSource: boolean;
  multipleDataSources: boolean;
  singleDataSourceId?: number;
  multipledataSourcesId?: number;
}

export type AssigneesSectionProperties = SectionProperties<
  SectionLabelInfoProperties,
  AssigneesSectionCommonProperties
>;
