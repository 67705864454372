import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { TokenService } from '@fieldos/core';
import { AuthDataService } from '@fieldos/data-services';
import { RouterFacade } from '@fieldos/facades';
import {
  LoginCredentials,
  UserProfile,
  isLoginError,
  isTemporaryPasswordLoginResponse,
} from '@fieldos/models';
import { TranslocoService } from '@ngneat/transloco';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';

interface AuthState {
  loading: boolean;
  profile: UserProfile | null;
  errorType: 'credentials' | 'locked' | 'other' | null;
  error: string;
  hasError: boolean;
  isAuthenticated: boolean | null;
}

export const AuthStore = signalStore(
  { providedIn: 'root' },
  withState<AuthState>(() => ({
    loading: true,
    profile: null,
    errorType: null,
    hasError: false,
    isAuthenticated: null,
    error: '',
  })),
  withComputed((store) => ({
    roleId: computed(() => store.profile()?.roleId),
    userId: computed(() => store.profile()?.userId),
    language: computed(() => store.profile()?.lang),
  })),
  withMethods(
    (
      store,
      authService = inject(AuthDataService),
      transloco = inject(TranslocoService),
      router = inject(RouterFacade),
      tokenService = inject(TokenService)
    ) => ({
      async login(credentials: LoginCredentials): Promise<void> {
        try {
          const token = await authService.login(credentials);

          if (isTemporaryPasswordLoginResponse(token)) {
            router.navigateByUrl(`/reset-password/${token.token}?temporary=1`);
            return;
          }

          if (isLoginError(token)) {
            patchState(store, {
              errorType: 'credentials',
              hasError: true,
              loading: false,
            });

            return;
          }

          tokenService.accessToken = token;
          await this.fetchProfile();

          router.navigateAfterLogin();
        } catch (error) {
          const errorResponse = error as HttpErrorResponse;

          if (errorResponse.status === 400) {
            patchState(store, {
              errorType: 'other',
              hasError: true,
              error: errorResponse.error.error?.message || '',
            });

            return;
          }

          patchState(store, {
            errorType: errorResponse.status === 403 ? 'locked' : 'credentials',
            hasError: true,
            loading: false,
          });
        }
      },
      async loginWithProvider(code: string): Promise<void> {
        try {
          const token = await authService.loginWithProvider(code);

          tokenService.accessToken = token;
          await this.fetchProfile();
          router.navigateByUrl('/');
        } catch (error) {
          console.error(error);
        }
      },
      async fetchProfile(): Promise<void> {
        patchState(store, {
          loading: true,
        });
        try {
          const profile = await authService.getProfile();
          transloco.setActiveLang(profile.lang);

          patchState(store, {
            loading: false,
            hasError: false,
            errorType: null,
            isAuthenticated: true,
            profile,
          });
        } catch (error) {
          console.error(error);
        }
      },
      async setActiveLanguage(lang: string): Promise<void> {
        try {
          if (store.profile()?.lang !== lang) {
            authService.updateProfile({ lang });
            patchState(store, {
              profile: {
                ...(store.profile() as UserProfile),
                lang,
              },
            });
          }
          transloco.setActiveLang(lang);
        } catch (error) {
          console.error(error);
        }
      },
      logout(): void {
        if (!window.location.pathname.startsWith('/sign-in')) {
          window.location.href = '/sign-in';
        }
        tokenService.clearTokens();

        patchState(store, {
          loading: false,
          isAuthenticated: false,
        });
      },
      initialize(): void {
        const token = tokenService.accessToken;

        if (token) {
          this.fetchProfile();
          return;
        }

        if (
          location.pathname.startsWith('/auth/return') ||
          location.pathname.startsWith('/reset-password')
        ) {
          return;
        }

        this.logout();
      },
      resetError(): void {
        patchState(store, {
          hasError: false,
          errorType: null,
          error: '',
        });
      },
    })
  )
);
