import { FileModel } from '@fieldos/models';

export interface FileGalleryDialogData {
  files: FileModel[];
  selectedIndex: number;
  options: {
    removeDisabled: boolean;
  };
  onDelete: (index: number) => void;
}
