export interface LoginCredentials {
  username: string;
  password: string;
}

export interface AccessToken {
  accessToken: string;
  expiresIn: number;
  tokenType: string;
  scope: string;
}

export interface TemporaryPasswordLoginResponse {
  token: string;
  message: 'temporaryPassword';
}

export interface LoginError {
  error: {
    message: string;
  };
}

export type LoginResponse =
  | AccessToken
  | TemporaryPasswordLoginResponse
  | LoginError;

export const isTemporaryPasswordLoginResponse = (
  response: LoginResponse
): response is TemporaryPasswordLoginResponse => {
  if (
    (response as TemporaryPasswordLoginResponse).message === 'temporaryPassword'
  ) {
    return true;
  }

  return false;
};

export const isLoginError = (
  response: LoginResponse
): response is LoginError => {
  if ((response as LoginError).error) {
    return true;
  }

  return false;
};
