import {
  Section,
  SectionBaseEntityAction,
  SectionBasedEntityType,
} from '@fieldos/models';

export type SectionBasedEntityListModel = Record<
  string,
  unknown | Record<SectionBaseEntityAction, boolean>
> & {
  id: number;
};

export interface SectionBasedEntityListAGGridContext {
  sections: Record<string, Section>;
  entityType: SectionBasedEntityType;
}
