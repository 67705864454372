<mat-card>
  <mat-card-content>
    <app-label-with-info
      class="text-md block mb-1"
      [info]="info()"
      [label]="label()"
      [required]="rights()?.mandatory" />

    @if (component(); as component) {
      <ng-container
        [ngComponentOutlet]="component"
        [ngComponentOutletInputs]="{
          sectionId: sectionId()
        }" />
    }
  </mat-card-content>
</mat-card>
