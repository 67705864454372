import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  signal,
  ViewChild,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface ITextCellRendererParams
  extends ICellRendererParams<unknown, string | number, unknown> {
  styles?: Record<string, string>;
}

@Component({
  selector: 'app-text-cell-renderer',
  templateUrl: './text-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  imports: [MatTooltipModule],
})
export class TextCellRendererComponent
  implements ICellRendererAngularComp, AfterViewInit
{
  @ViewChild('cell')
  private readonly _cell!: ElementRef<HTMLDivElement>;

  protected readonly styles = signal<Record<string, string>>({});

  protected get tooltipDisabled(): boolean {
    if (!this._cell) {
      return false;
    }

    return (
      this._cell.nativeElement.scrollWidth <=
      this._cell.nativeElement.clientWidth
    );
  }

  protected value = '';

  private readonly _detector = inject(ChangeDetectorRef);

  agInit(params: ITextCellRendererParams): void {
    if (typeof params.value === 'string') {
      this.value = params.value || '';
    }

    if (typeof params.value === 'number') {
      this.value = (params.value || 0).toString();
    }

    if (params.styles) {
      this.styles.set(params.styles || {});
    }

    this._detector.detectChanges();
  }

  refresh(params: ITextCellRendererParams): boolean {
    if (typeof params.value === 'string') {
      this.value = params.value || '';
    }

    if (typeof params.value === 'number') {
      this.value = (params.value || 0).toString();
    }

    return true;
  }

  ngAfterViewInit(): void {
    const observer = new ResizeObserver(() => this._detector.detectChanges());
    observer.observe(this._cell.nativeElement);
  }
}
