@if (!store.entity()) {
  <div class="text-center text-base font-bold">
    {{ 'work_orders.sections.form.packagingloaded.no_data' | transloco }}
  </div>
} @else {
  <div class="flex flex-col gap-4">
    @for (formGroup of form.controls; track $index) {
      <div class="flex gap-4 w-full" [formGroup]="formGroup">
        <app-autocomplete-select
          formControlName="packagingId"
          class="flex-grow"
          [disabled]="!rights()?.canEdit"
          [options]="options()"
          label="{{
            'work_orders.sections.packagingloaded.packaging_id' | transloco
          }}"
          required />

        <app-number-field
          formControlName="quantity"
          class="w-32"
          [disabled]="!rights()?.canEdit"
          label="{{
            'work_orders.sections.packagingloaded.quantity' | transloco
          }}"
          required
          type="number" />

        <button
          mat-icon-button
          class="mt-6"
          [disabled]="form.controls.length === 1 || !rights()?.canEdit"
          (click)="removeRow($index)"
          color="warn">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    }

    @if (rights()?.canEdit) {
      <div class="flex justify-center">
        <button
          mat-flat-button
          [disabled]="!form.valid || !rights()?.canEdit"
          (click)="onAddClick()"
          color="primary">
          {{ 'common.add' | transloco }}
        </button>
      </div>

      <div class="flex justify-center">
        <button
          mat-flat-button
          [disabled]="!form.valid || !form.dirty || !rights()?.canEdit"
          (click)="onSave()"
          color="primary">
          {{ 'common.save' | transloco }}
        </button>
      </div>
    }
  </div>
}
