import { Injectable } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  constructor(_fuseConfigService: FuseConfigService) {
    _fuseConfigService.config$.subscribe(() => {
      const cssVariables = window.getComputedStyle(document.body);
      this._primaryColorSubject.next(cssVariables.getPropertyValue('--fuse-primary'));
      this._accentColorSubject.next(cssVariables.getPropertyValue('--fuse-accent'));
      this._warnColorSubject.next(cssVariables.getPropertyValue('--fuse-warn'));
    });
  }

  private _primaryColorSubject = new BehaviorSubject<string>('');
  private _accentColorSubject = new BehaviorSubject<string>('');
  private _warnColorSubject = new BehaviorSubject<string>('');

  public get primaryColor$(): Observable<string> {
    return this._primaryColorSubject.asObservable();
  }

  public get accentColor$(): Observable<string> {
    return this._accentColorSubject.asObservable();
  }

  public get warnColor$(): Observable<string> {
    return this._warnColorSubject.asObservable();
  }
}
