import { Injectable } from '@angular/core';
import { Customer, CustomerFieldSubType } from '@fieldos/models';
import { FieldBasedEntitiesServiceBase } from './field-based-entities.service';

@Injectable({
  providedIn: 'root',
})
export class CustomersDataService extends FieldBasedEntitiesServiceBase<Customer> {
  constructor() {
    super('customers', CustomerFieldSubType.CustomerType);
  }
}
