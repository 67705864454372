import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-label-with-info',
  templateUrl: './label-with-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule, MatTooltipModule],
})
export class LabelWithInfoComponent {
  public readonly label = input.required<string>();
  public readonly info = input<string>();

  public readonly required = input<boolean>();
  public readonly labelClass = input<string>();
}
