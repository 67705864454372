import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { distinctUntilChanged, filter, tap } from 'rxjs';

@Component({
  selector: 'app-number-field',
  templateUrl: './number-field.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatFormFieldModule, MatInputModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFieldComponent),
      multi: true,
    },
  ],
})
export class NumberFieldComponent implements ControlValueAccessor {
  constructor() {
    this.formCtrl.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap((value) => {
          if (value && !this.decimalRegex.test(value.toString() || '')) {
            const stringValue = value.toString();
            const newValue = stringValue.substring(
              0,
              stringValue.indexOf('.') + 3
            );
            this.formCtrl.setValue(Number(newValue));
          }
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    this.formCtrl.valueChanges
      .pipe(
        filter((value) => this.decimalRegex.test(value?.toString() || '')),
        tap((value) => this._onChange && this._onChange(value || 0))
      )
      .subscribe();
  }

  @Input({ required: true }) public label: string = '';

  protected get decimalRegex(): RegExp {
    const regex = /^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/;
    return new RegExp(regex);
  }

  protected formCtrl = new FormControl<number | null>(null);

  private _onChange!: (value: number) => void;
  private _onTouch!: () => void;

  writeValue(value: number): void {
    this.formCtrl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: number) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.formCtrl.enabled && isDisabled) {
      this.formCtrl.disable();
    }

    if (this.formCtrl.disabled && !isDisabled) {
      this.formCtrl.enable();
    }
  }
}
