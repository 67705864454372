export interface DomainEntityBase<TIdType extends number | string = number>
  extends DomainEntityId<TIdType> {
  name: string;
}

export interface DomainEntityId<TType extends number | string = number> {
  id: TType;
}

export enum DomainEntityType {
  Checklist = 'Checklist',
  Location = 'Location',
  Asset = 'Asset',
  Type = 'Type',
}
