<h1 mat-dialog-title>
  {{ data.title | transloco }}
</h1>
<mat-dialog-content class="flex flex-col">
  <app-chat-messages
    [canAddFiles]="data.canAddFiles"
    [chatMessages]="messages()"
    [loading]="false"
    (sendMessage)="onSendMessage($event)" />
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button [mat-dialog-close]="messages()" color="primary">
    {{ 'common.close' | transloco }}
  </button>
</mat-dialog-actions>
