<h2 mat-dialog-title>
  {{ data.title | transloco }}
</h2>
<mat-dialog-content>
  <mat-form-field class="fuse-mat-dense w-full" subscriptSizing="dynamic">
    <mat-label>{{ data.placeholder | transloco }}</mat-label>
    <input matInput [autocomplete]="'off'" [formControl]="formControl" />

    @if (formControl.invalid) {
      <mat-error>
        @switch (data.type) {
          @case ('link') {
            {{ 'common.invalid_url' | transloco }}
          }
        }
      </mat-error>
    }
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button [mat-dialog-close]="">
    {{ 'common.cancel' | transloco }}
  </button>

  <button
    mat-flat-button
    [disabled]="!formControl.value || formControl.invalid"
    [mat-dialog-close]="formControl.value"
    color="primary">
    {{ 'common.confirm' | transloco }}
  </button>
</mat-dialog-actions>
