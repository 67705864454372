import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
import { MouseCursorElementClickEvent } from './mouse-cursor-element.models';
import { MouseCursorElementService } from './mouse-cursor-element.service';

@Directive({
  selector: '[appMouseCursorElementDropzone]',
  standalone: true,
})
export class MouseCursorElementDropzoneDirective {
  constructor(
    _elementRef: ElementRef<HTMLElement>,
    private _service: MouseCursorElementService
  ) {
    this._service.registerDropzone(_elementRef.nativeElement);
  }

  @Output() public readonly appMouseCursorElementClick = new EventEmitter<
    MouseCursorElementClickEvent<any>
  >();

  @HostListener('click', ['$event'])
  _onClick(event: MouseEvent): void {
    if (this._service.isActive) {
      this.appMouseCursorElementClick.emit({
        data: this._service.data,
        event,
      });
      this._service.end();
    }
  }
}
