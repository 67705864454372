import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { HttpService } from '@fieldos/core';
import { DataSource, DomainEntityBase } from '@fieldos/models';
import {
  DataSourceWithOptions,
  StandardDataSourceId,
} from './data-source.models';

@Injectable({
  providedIn: 'root',
})
export class StandardDataSourceDataService {
  private readonly _http = inject(HttpService);

  fetchDataSources(): Promise<DataSource[]> {
    return this._http.get<DomainEntityBase[]>('/data-sources');
  }

  fetchDataSourcesWithData(): Promise<DataSource[]> {
    return this._http.get<DomainEntityBase[]>('/data-sources?withData=1');
  }

  fetchCustomDataSources(): Promise<DataSource[]> {
    return this._http.get<DomainEntityBase[]>('/data-sources-custom');
  }

  fetchCustomDataSourcesWithOptions(): Promise<DataSourceWithOptions[]> {
    return this._http.get<DataSourceWithOptions[]>(
      '/data-sources-custom?extraInfo=1'
    );
  }

  fetchCustom(
    dataSourceId: number,
    params?: Params
  ): Promise<DomainEntityBase[]> {
    return this._http.get<DomainEntityBase[]>(
      `/data-sources-custom/${dataSourceId}?`,
      'json',
      params
    );
  }

  fetch(
    dataSourceId: StandardDataSourceId,
    params?: Params
  ): Promise<DomainEntityBase[]> {
    return this._http.get<DomainEntityBase[]>(
      `/data-sources/${dataSourceId}`,
      'json',
      params
    );
  }
}
