import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FileModel } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { SignatureComponent } from '../../../../../../@components/fields/signature';

@Component({
  selector: 'app-sections-form-signature-dialog',
  templateUrl: './sections-form-signature-dialog.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    SignatureComponent,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    TranslocoModule,
  ],
})
export class SectionsFormSignatureDialogComponent {
  protected readonly signature?: FileModel = inject(MAT_DIALOG_DATA);

  protected readonly form = new FormControl<FileModel | undefined>(undefined, {
    nonNullable: true,
    validators: Validators.required,
  });

  protected readonly nameCtrl = new FormControl('', {
    validators: Validators.required,
    nonNullable: true,
  });

  private readonly _dialogRef = inject(MatDialogRef);

  protected onSignatureValueChange(signatureUrl?: string): void {
    if (!signatureUrl) {
      this.form.patchValue(undefined);
    } else {
      const file = this._converBase64toBlob(signatureUrl);
      this.form.patchValue({
        description: '',
        id: '',
        mimeType: 'image/png',
        name: `signature.png`,
        size: file.size,
        thumbnailUrl: signatureUrl,
        url: signatureUrl,
        file,
        value: this.nameCtrl.value,
      });
    }
  }

  protected onSave(): void {
    this._dialogRef.close({
      ...this.form.value,
      value: this.nameCtrl.value,
    });
  }

  private _converBase64toBlob(content: string) {
    const sliceSize = 512;
    const arr = content.split(',');
    const byteCharacters = window.atob(arr[1]);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: 'image/png',
    }); //statement which creates the blob
    return new File([blob], 'signature.png');
  }
}
