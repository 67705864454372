<mat-form-field class="fuse-mat-dense w-full" subscriptSizing="dynamic">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <input matInput [formControl]="formCtrl" step="0.01" type="number" />

  <div matSuffix>
    <ng-content select="[suffix]" />
  </div>
</mat-form-field>
