import { Pipe, PipeTransform } from '@angular/core';
import { Priority } from '@fieldos/models';

@Pipe({
  name: 'workOrderFormPriorityBtnClass',
  standalone: true,
})
export class WorkOrderFormPriorityButtonColorPipe implements PipeTransform {
  transform(value: Priority): string {
    return priorityColorMap[value];
  }
}

const priorityColorMap: Record<Priority, string> = {
  1: 'light-gray',
  2: 'gray',
  3: 'success',
  4: 'warn',
  5: 'error',
};
