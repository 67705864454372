import { InjectionToken } from '@angular/core';
import { SectionSubType } from '@fieldos/models';

export const HIDDEN_SECTION_COLUMNS = new InjectionToken<SectionSubType[]>(
  'HIDDEN_SECTION_COLUMNS',
  {
    factory: () => [
      SectionSubType.PackagingLeft,
      SectionSubType.PackagingLoaded,
      SectionSubType.PackagingUnloaded,
      SectionSubType.ConfirmEmtpy,
    ],
  }
);
