import { Injectable, inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import {
  Report,
  ReportColumnsResponse,
  ReportConfig,
  ReportType,
} from '@fieldos/models';
import { TranslocoService } from '@ngneat/transloco';
import { omit } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private readonly _http = inject(HttpService);
  private readonly _transloco = inject(TranslocoService);

  async exportReport(config: ReportConfig): Promise<void> {
    const blob = await this._http.postWithBlobResponse<Blob>(
      `/report/export`,
      config
    );

    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'Raport.xlsx';
    a.click();
  }

  async deleteReport(reportId: number): Promise<void> {
    await this._http.delete(`/administration-report-custom/${reportId}`);
  }

  async updateReport(report: Report): Promise<void> {
    await this._http.put<void>(`/administration-report-custom/${report.id}`, {
      properties: report.config,
      translatableProperties: {
        [this._transloco.getActiveLang()]: {
          name: report.name,
        },
      },
    });
  }

  async createReport(report: Report): Promise<void> {
    await this._http.post<Report>(`/administration-report-custom`, {
      properties: report.config,
      translatableProperties: {
        [this._transloco.getActiveLang()]: {
          name: report.name,
        },
      },
    });
  }

  fetchColumns(reportType: ReportType): Promise<ReportColumnsResponse> {
    return this._http.post<ReportColumnsResponse>(`/report-columns`, {
      reportType,
    });
  }

  fetchStandardReports(): Promise<Report[]> {
    return this._http.get<Report[]>('/report-standard');
  }

  fetchCustomReports(): Promise<Report[]> {
    return this._http.get<Report[]>('/report-custom');
  }

  fetchReportData(filter: ReportConfig): Promise<any> {
    return this._http.post(`/report/generate`, omit(filter, 'id', 'name'));
  }
}
