import { SectionProperties } from './section-properties';
import { SectionLabelInfoProperties } from './simple-section-properties';

export interface ShowInformationSectionCommonProperties {
  optionId: number;
}

export type ShowInformationSectionProperties = SectionProperties<
  SectionLabelInfoProperties,
  ShowInformationSectionCommonProperties
>;
