import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  input,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

import { ExceptionDialogsFacade } from '@fieldos/business/exceptions';
import { ExceptionsFacade } from '@fieldos/facades';
import { EntityException } from '@fieldos/models';
import { DataSourceEntityNamePipe } from '@fieldos/pipes';
import { TranslocoModule } from '@ngneat/transloco';
import { SectionsFormStore } from '../sections-form.store';

@Component({
  selector: 'app-sections-form-entity-locked-info-panel',
  templateUrl: './sections-form-entity-locked-info-panel.component.html',
  standalone: true,
  imports: [
    TranslocoModule,
    DataSourceEntityNamePipe,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionsFormEntityLockedInfoPanelComponent implements OnInit {
  public readonly workOrderId = input<number>();
  public readonly scopeId = input<number>();

  protected readonly exceptions = signal<EntityException[]>([]);

  private readonly _facade = inject(ExceptionsFacade);
  private readonly _exceptionDialogFacade = inject(ExceptionDialogsFacade);
  private readonly _store = inject(SectionsFormStore);

  async ngOnInit(): Promise<void> {
    try {
      const workOrderId = this.workOrderId();
      if (workOrderId) {
        const exceptions =
          await this._facade.fetchWorkOrderExcetions(workOrderId);

        this.exceptions.set(exceptions);
      }
    } catch (e) {
      console.error(e);
    }
  }

  protected async onInfoClick(exception: EntityException): Promise<void> {
    this._exceptionDialogFacade.openInfoDialog(exception);
  }

  protected async onActionClick(exception: EntityException): Promise<void> {
    try {
      const assignedException = await this._facade.fetchException(exception.id);

      const shouldRefresh = await this._exceptionDialogFacade.openDialog({
        targetId: assignedException.targetId,
        targetType: exception.targetType,
        parentId: exception.id,
        reasonCodeName: exception.label,
        scopeId: this.scopeId() as number,
      });

      if (shouldRefresh) {
        this._store.reloadEntity();
      }
    } catch (e) {
      console.error(e);
    }
  }
}
