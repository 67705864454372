import { Injectable, inject } from '@angular/core';
import { CategoryType, Field, FieldBasedEntity } from '@fieldos/models';
import { FIELD_BASED_ENTITIES_DATA_SERVICE_MAP } from './field-based-entities.provider';

@Injectable({
  providedIn: 'root',
})
export class FieldEntityDataService {
  private readonly _serviceMap = inject(FIELD_BASED_ENTITIES_DATA_SERVICE_MAP);

  fetchAll(
    categoryType: CategoryType,
    query?: Record<string, string> | undefined
  ): Promise<FieldBasedEntity[]> {
    return this._serviceMap[categoryType].fetchAll(query);
  }

  fetchOne(
    categoryType: CategoryType,
    entityId: number
  ): Promise<FieldBasedEntity> {
    return this._serviceMap[categoryType].fetchOne(entityId);
  }

  create(
    categoryType: CategoryType,
    fields: Field[]
  ): Promise<FieldBasedEntity> {
    return this._serviceMap[categoryType].create(fields);
  }

  update(
    categoryType: CategoryType,
    entityId: number,
    fields: Field[]
  ): Promise<FieldBasedEntity> {
    return this._serviceMap[categoryType].update(entityId, fields);
  }

  delete(categoryType: CategoryType, entityId: number): Promise<void> {
    return this._serviceMap[categoryType].delete(entityId);
  }
}
