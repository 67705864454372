import { createReducer, on } from '@ngrx/store';
import { AlertState } from './alert.state';

import * as actions from './alert.actions';

const initialState: AlertState = {
  alerts: [],
};

export const alertReducers = createReducer(
  initialState,
  on(actions.showAlert, (state, { alert }) => ({
    alerts: [...state.alerts, alert],
  })),
  on(actions.clearAlert, (state, { name }) => ({
    alerts: state.alerts.filter((e) => e.name !== name),
  }))
);
