import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthStore } from '@fieldos/store/auth';
import { filter, map } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
  const router: Router = inject(Router);

  const store = inject(AuthStore);

  // Check the authentication status
  return toObservable(store.isAuthenticated).pipe(
    filter((authenticated) => authenticated !== null),
    map((authenticated) => {
      // If the user is not authenticated...
      if (!authenticated) {
        // Redirect to the sign-in page with a redirectUrl param
        const redirectURL =
          state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
        const urlTree = router.parseUrl(`sign-in?${redirectURL}`);

        return urlTree;
      }

      // Allow the access
      return true;
    })
  );
};
