import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { FieldError } from '@fieldos/components';

@Pipe({
  standalone: true,
  name: 'fieldErrors',
})
export class FieldErrorsPipe implements PipeTransform {
  transform(errors: ValidationErrors | null): FieldError[] {
    if (!errors) {
      return [];
    }

    return Object.values(errors).map((error) => ({
      message: this._mapError(error),
    }));
  }

  private readonly _mapError = (error: string | object): string => {
    if (error === 'required') {
      return 'common.required';
    }

    return '';
  };
}
