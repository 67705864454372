import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  effect,
  inject,
  input,
  output,
  signal,
  Type,
  ViewEncapsulation,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import {
  AgGridModule,
  ICellEditorAngularComp,
  ICellRendererAngularComp,
  IFilterAngularComp,
} from 'ag-grid-angular';
import {
  CellRange,
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  IsRowSelectable,
  MenuItemDef,
  RangeSelectionChangedEvent,
  RowClickedEvent,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { TextFloatingFilterComponent } from '../ag-grid-renderers';
import { DATA_GRID_SELECTION_COLUMN } from './data-grid.providers';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./data-grid.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AgGridModule,
    TranslocoModule,
    MatProgressSpinnerModule,
  ],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class DataGridComponent<TData, KContext, TKey extends keyof TData> {
  constructor(private _translate: TranslocoService) {
    effect(
      () => {
        const columns = this.columns();
        const showSelectionColumn = this.showSelectionColumn();
        this._langChange();

        let cols = columns.map((column) => {
          if (
            column.floatingFilterComponent === TextFloatingFilterComponent &&
            column.suppressFloatingFilterButton === undefined
          ) {
            column.suppressFloatingFilterButton = true;
          }

          return column;
        });

        cols = cols
          .map((column) => ({
            ...column,
            headerName: this._translate.translate(column.headerName || ''),
          }))
          .concat(
            showSelectionColumn ? [this._selectionColumnDef] : ([] as any[])
          );

        this.columnDefs.set(cols);
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      this.selection();
      this._updateSelection();
    });
  }

  public readonly showSelectionColumn = input(false, {
    transform: booleanAttribute,
  });
  public readonly columns = input.required<ColDef[]>();

  public readonly defaultSizeToFit = input(false, {
    transform: booleanAttribute,
  });

  public readonly selectionMode = input<'edit' | 'add'>('edit');

  public readonly context = input<KContext | null>(null);
  public readonly idKey = input<TKey>('id' as TKey);
  public readonly selection = input<TKey[]>([]);
  public readonly gridClass = input<string>('');
  public readonly rowSelection = input<'single' | 'multiple' | undefined>(
    undefined
  );
  public readonly defaultColDef = input<ColDef | undefined>(undefined);
  public readonly contextMenuItems = input<MenuItemDef[] | undefined>(
    undefined
  );
  public readonly enableRangeSelection = input(false, {
    transform: booleanAttribute,
  });
  public readonly isRowSelectableFn = input<
    ((node: IRowNode) => boolean) | undefined
  >(undefined);

  public readonly components = input<
    Type<
      ICellRendererAngularComp | IFilterAngularComp | ICellEditorAngularComp
    >[]
  >([]);

  public readonly usePagination = input<boolean>(true);
  public readonly loading = input<boolean>();
  public readonly data = input.required<TData[]>();

  public readonly rowClick = output<RowClickedEvent<TData>>();
  public readonly selectionChange = output<TData[]>();
  public readonly rowDoubleClick = output<TData>();
  public readonly rangeSelectionChange = output<CellRange[] | null>();
  public readonly gridReady = output<GridApi>();
  public readonly cellValueChanged = output<CellValueChangedEvent>();

  protected readonly columnDefs = signal<ColDef[]>([]);

  protected readonly gridApi = signal<GridApi | null>(null);
  protected readonly gridOptions = signal<GridOptions>({});

  private readonly _langChange = toSignal(this._translate.langChanges$);

  private readonly _selectionColumnDef = inject(DATA_GRID_SELECTION_COLUMN);

  protected onGridReady(event: GridReadyEvent): void {
    this.gridApi.set(event.api);
    event.api.sizeColumnsToFit();
    this.gridReady.emit(event.api);
  }

  protected readonly getContextMenuItems = () =>
    this.contextMenuItems() || ([] as MenuItemDef[]);

  protected onRowDoubleClick(event: RowDoubleClickedEvent<TData>): void {
    this.rowDoubleClick.emit(event.data as TData);
  }

  protected onSelectionChange(event: SelectionChangedEvent<TData>): void {
    if (event.source === 'checkboxSelected') {
      this.selectionChange.emit(event.api.getSelectedRows());
    }
  }

  protected onRangeSelectionChange(
    event: RangeSelectionChangedEvent<TData>
  ): void {
    this.rangeSelectionChange.emit(event.api.getCellRanges());
  }

  protected onFirstDataRendered() {
    this._updateSelection();
  }

  protected onRowDataUpdated(): void {
    this._updateSelection();
  }

  protected readonly isRowSelectable: IsRowSelectable = (
    node: IRowNode
  ): boolean => {
    const fn = this.isRowSelectableFn();
    if (fn) {
      return fn(node);
    }
    if (this.selectionMode() === 'add') {
      return !this.selection().includes(node.data[this.idKey()]);
    }

    return true;
  };

  private _updateSelection(): void {
    this.gridApi()?.forEachNode((node) => {
      if (
        node.data &&
        this.selection().includes(node.data[this.idKey()] as TKey)
      ) {
        node.setSelected(true);
      } else {
        node.setSelected(false);
      }
    });
  }
}
