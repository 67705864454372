import { FieldBasedEntity } from '../fields';

export interface Asset extends FieldBasedEntity {
  id: number;
  typeId: number;
  parentAssetId: number | null;
  locationId: number;
  locationName: string;
  subLocationId: number;
  subLocationName: string;
}
