import { ChatMessage, FileModel } from '@fieldos/models';

export interface ChatMessageItem extends ChatMessage {
  isMine: boolean;
}

export interface SendChatMessageModel {
  message: string;
  files: FileModel[];
}
