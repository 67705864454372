<app-field-wrapper [errors]="errors()">
  <mat-form-field class="fuse-mat-dense w-full" subscriptSizing="dynamic">
    @if (label()) {
      <mat-label>{{ label() }}</mat-label>
    }

    @if (prefixIcon(); as prefixIcon) {
      <mat-icon matPrefix [svgIcon]="prefixIcon" />
    }

    <input
      #trigger
      matInput
      [autocomplete]="'off'"
      [formControl]="formCtrl"
      [matAutocomplete]="auto"
      [placeholder]="placeholder() || label() || ''"
      [required]="required()"
      type="text" />

    @if (loading()) {
      <mat-progress-spinner matSuffix color="primary" diameter="24" />
    }

    @if (formCtrl.value && !formCtrl.disabled) {
      <button
        mat-icon-button
        matSuffix
        class="-mr-3"
        (click)="clearValue()"
        appClickStopPropagation>
        <mat-icon svgIcon="mat_solid:clear" />
      </button>
    }

    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayWith"
      (optionSelected)="onOptionSelected($event)">
      @for (option of filteredOptions(); track option.id) {
        <mat-option [value]="option">
          {{ option.name }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</app-field-wrapper>
