import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { BpDragDropModule } from '@browsepedia/drag-and-drop';
import { TranslocoModule } from '@ngneat/transloco';
import { FIELDS_DESIGNER_STORE } from '../fields-designer.store';
import { FieldsDesignerContainerFieldComponent } from './fields-designer-container-field/fields-designer-container-field.component';
import { FieldsDesignerFieldComponent } from './fields-designer-field/fields-designer-field.component';

@Component({
  selector: 'app-fields-designer-enabled-fields',
  templateUrl: './fields-designer-enabled-fields.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoModule,
    FieldsDesignerContainerFieldComponent,
    BpDragDropModule,
    FieldsDesignerFieldComponent,
    MatDividerModule,
    MatIconModule,
  ],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class FieldsDesignerEnabledFieldsComponent {
  protected store = inject(FIELDS_DESIGNER_STORE);
}
