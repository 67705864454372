import { computed, inject } from '@angular/core';
import { NotificationsDataService } from '@fieldos/data-services';
import { NotificationStatusType, PushNotification } from '@fieldos/models';
import { callAndTakeOne } from '@fieldos/utils';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStoreFeature,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { map } from 'rxjs';

export interface NotificationsState {
  notifications: PushNotification[];
  loading: boolean;
  selectedNotification: PushNotification | undefined;
  canLoadMore: boolean;
  currentPage: number;
}

export const withNotificationsStore = (status?: NotificationStatusType) =>
  signalStoreFeature(
    withState<NotificationsState>({
      notifications: [],
      loading: false,
      selectedNotification: undefined,
      canLoadMore: true,
      currentPage: 1,
    }),
    withComputed((store) => ({
      sortedNotifications: computed(() =>
        store
          .notifications()
          .sort((a, b) => b.localDate.getTime() - a.localDate.getTime())
      ),
    })),
    withMethods((store, service = inject(NotificationsDataService)) => ({
      updateNotification: (notification: PushNotification) => {
        const index = store
          .notifications()
          .findIndex((e) => e.id === notification.id);

        if (index !== -1) {
          const updatedNotifications = store.notifications();
          updatedNotifications[index] = notification;
          patchState(store, {
            notifications: updatedNotifications,
          });
        }
      },
      updateAllAsRead: () =>
        patchState(store, {
          notifications: store.notifications().map((n) => ({
            ...n,
            read: true,
          })),
        }),
      addNotification: (notification: PushNotification) =>
        patchState(store, (state) => ({
          notifications: [notification, ...state.notifications],
        })),
      removeNotification: (notification: PushNotification) =>
        patchState(store, (state) => ({
          notifications: state.notifications.filter(
            (n) => n.id !== notification.id
          ),
        })),
      removeAll: () => patchState(store, { notifications: [] }),
      selectNotification: (message: PushNotification) =>
        patchState(store, { selectedNotification: message }),
      clearSelectedNotification: () =>
        patchState(store, { selectedNotification: undefined }),
      toggleBookmarked: (notification: PushNotification) =>
        service
          .patch(notification.id, { bookmarked: !notification.bookmarked })
          .pipe(
            map(() => ({
              ...notification,
              bookmarked: !notification.bookmarked,
            })),
            tapResponse(() => {
              const updatedNotifications = store
                .notifications()
                .map((n) =>
                  n.id === notification.id
                    ? { ...n, bookmarked: !n.bookmarked }
                    : n
                );

              patchState(store, {
                notifications: updatedNotifications,
              });
            }, console.error),
            callAndTakeOne()
          ),
      toggleRead: (notification: PushNotification) =>
        service.patch(notification.id, { read: !notification.read }).pipe(
          map(() => ({
            ...notification,
            read: !notification.read,
          })),
          tapResponse(() => {
            const updatedNotifications = store
              .notifications()
              .map((n) =>
                n.id === notification.id ? { ...n, read: !n.read } : n
              );

            patchState(store, {
              notifications: updatedNotifications,
            });
          }, console.error),
          callAndTakeOne()
        ),
      toggleArchived: (notification: PushNotification) => {
        const updates: Partial<PushNotification> = {
          archived: !notification.archived,
          bookmarked: false,
          read: true,
        };

        return service.patch(notification.id, updates).pipe(
          map(() => ({
            ...notification,
            ...updates,
          })),
          tapResponse(() => {
            const updatedNotifications = store
              .notifications()
              .map((n) =>
                n.id === notification.id
                  ? { ...n, archived: !n.archived, bookmarked: false }
                  : n
              );

            patchState(store, {
              notifications: updatedNotifications,
            });
          }, console.error),
          callAndTakeOne()
        );
      },
      fetchNotifications: () => {
        patchState(store, { loading: true });

        service
          .fetchWithStatus(status)
          .pipe(
            tapResponse(
              (notifications) =>
                patchState(store, {
                  notifications,
                  canLoadMore: notifications.length < 25,
                }),
              () => null,
              () => patchState(store, { loading: false })
            )
          )
          .subscribe();
      },
      loadMoreNotifications: () => {
        patchState(store, { loading: true });

        service
          .fetchWithStatus(status)
          .pipe(
            tapResponse(
              (notifications) =>
                patchState(store, {
                  notifications: [...store.notifications(), ...notifications],
                  canLoadMore:
                    notifications.length + store.notifications().length < 25,
                }),
              console.error,
              () => patchState(store, { loading: false })
            )
          )
          .subscribe();
      },
    })),
    withHooks({
      onInit: (store) => store.fetchNotifications(),
    })
  );
