import {
  ChangeDetectionStrategy,
  Component,
  WritableSignal,
  effect,
  inject,
  signal,
} from '@angular/core';
import { DomainEntityBase } from '@fieldos/models';
import { DataSourceStore } from '@fieldos/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DataSourceCellRendererParams } from './data-source-cell-renderer.models';

@Component({
  selector: 'app-data-source-cell-renderer',
  templateUrl: './data-source-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class DataSourceCellRendererComponent
  implements ICellRendererAngularComp
{
  constructor() {
    effect(
      () => {
        const options = this._options();
        const value = this._optionId();
        this.value.set(options[value]?.name || '');
      },
      { allowSignalWrites: true }
    );
  }

  protected readonly value = signal('');
  private _options = signal<Record<number, DomainEntityBase>>({});
  private readonly _optionId = signal<number>(0);

  private readonly _dataSourceStore = inject(DataSourceStore);

  agInit(params: DataSourceCellRendererParams): void {
    this._setValue(params);
  }

  refresh(params: DataSourceCellRendererParams): boolean {
    this._setValue(params);
    return true;
  }

  private _setValue(params: DataSourceCellRendererParams): void {
    const dataSourceId = params.dataSourceId;

    this._optionId.set(params.value || 0);

    this._options = this._dataSourceStore.selectNormalizedDataSource(
      dataSourceId
    ) as WritableSignal<Record<number, DomainEntityBase>>;
  }
}
