@if (form.controls.id.value | dataSourceEntity: 6; as entity) {
  @if (entity | asWorkOrderAssignee; as assignee) {
    <div class="border relative">
      <div class="flex p-4">
        <div class="flex flex-col flex-grow">
          <h5 class="text-lg font-semibold">
            {{ assignee.name }}
          </h5>
          @if (properties.type) {
            <span>
              {{ 'common.type' | transloco }}:
              {{
                'work_orders.sections.assignees.type.' + assignee.type
                  | transloco
              }}
            </span>
          }

          @if (properties.team) {
            <span>
              {{ 'common.team' | transloco }}:
              {{ assignee.team || '-' }}
            </span>
          }
        </div>

        <button
          mat-icon-button
          class="absolute right-4 top-4"
          (click)="onRemoveAssignee()"
          color="warn">
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      @if (properties.workCategory) {
        @for (categoryForm of categoryFormGroups; track $index) {
          <app-sections-form-assignee-category-form
            class="block"
            [canRemove]="categoryFormGroups.length > 1"
            [form]="categoryForm"
            [index]="$index"
            [isLast]="$last"
            [properties]="properties"
            (remove)="onRemoveWorkCategory($index)" />
        }

        <div class="w-full px-4 pb-4">
          <button
            mat-flat-button
            class="w-full"
            (click)="onAddWorkCategory()"
            color="primary">
            {{ 'work_orders.sections.assignees.work_category.add' | transloco }}
          </button>
        </div>
      }

      <app-box-confirm-dialog />
    </div>
  }
}
