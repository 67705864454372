<div
  class="absolute bottom-4 left-4 right-0 z-9999 max-w-120 flex flex-col gap-2">
  @for (toast of store.toasts(); track toast.name) {
    <fuse-alert
      [dismissible]="toast.showCloseButton"
      [type]="toast.type"
      appearance="border">
      <div class="flex flex-col gap-2">
        <span class="text-md font-medium">
          {{ toast.message | transloco }}
        </span>

        @if (toast.actions?.length) {
          <div class="flex gap-2">
            @for (action of toast.actions; track $index) {
              <a
                [class.text-primary]="
                  action.color === 'primary' || !action.color
                "
                [class.text-warn]="action.color === 'warn' || !action.color"
                class="anchor"
                (click)="action.onClick()">
                {{ action.label | transloco }}
              </a>
            }
          </div>
        }
      </div>
    </fuse-alert>
  }
</div>
