<div [style.width.px]="40 * actions.length" class="mx-2">
  @for (action of actions; track $index) {
    <button
      mat-icon-button
      (click)="onActionClick(action)"
      appClickStopPropagation
      color="{{
        action === 'edit' ? 'primary' : action === 'delete' ? 'warn' : 'accent'
      }}">
      @switch (action) {
        @case ('edit') {
          <mat-icon>edit</mat-icon>
        }
        @case ('delete') {
          <mat-icon>delete</mat-icon>
        }
        @case ('forward') {
          <mat-icon>arrow_forward</mat-icon>
        }
      }
    </button>
  }
</div>
