import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SectionBasedEntityType, StatusGraphNode } from '@fieldos/models';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatusGraphDataService {
  constructor(private _http: HttpClient) {}

  fetch(
    entityType: SectionBasedEntityType = 'workorders'
  ): Promise<StatusGraphNode[]> {
    return lastValueFrom(
      this._http.get<StatusGraphNode[]>(this._getUrlFromEntityType(entityType))
    );
  }

  fetchForScopeId(
    scopeId: number,
    entityType: SectionBasedEntityType = 'workorders'
  ): Promise<StatusGraphNode[]> {
    return lastValueFrom(
      this._http.get<StatusGraphNode[]>(
        this._getUrlFromEntityType(entityType),
        {
          headers: {
            'wo-scope-id': `${scopeId}`,
          },
        }
      )
    );
  }
  save(
    statusGraph: StatusGraphNode[],
    entityType: SectionBasedEntityType = 'workorders'
  ): Observable<void> {
    return this._http.put<void>(
      this._getUrlFromEntityType(entityType),
      statusGraph
    );
  }

  private _getUrlFromEntityType(entityType: SectionBasedEntityType): string {
    return entityType === 'workorders'
      ? '/administration-status-graph'
      : '/sr-status-graph';
  }
}
