import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { FilterChangedEvent, IFloatingFilterParams } from 'ag-grid-community';
import { debounceTime, tap } from 'rxjs';
import { FloatingFilterLayoutComponent } from '../../layouts';
import { TextFilterComponent } from '../text-filter/text-filter.component';

@Component({
  selector: 'app-text-floating-filter',
  templateUrl: './text-floating-filter.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslocoModule,
    FloatingFilterLayoutComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        margin-top: auto;
        margin-bottom: auto;
      }
    `,
  ],
})
export class TextFloatingFilterComponent implements IFloatingFilterAngularComp {
  constructor() {
    this.searchFormCtrl.valueChanges
      .pipe(
        debounceTime(200),
        tap(() => {
          if (!this.searchFormCtrl.value) {
            // Remove the filter
            this._params()?.parentFilterInstance((instance: unknown) => {
              const filterInstance = instance as TextFilterComponent;

              filterInstance.onFloatingFilterChanged(this.searchFormCtrl.value);
            });
            return;
          }

          this._params()?.parentFilterInstance((instance: unknown) => {
            const filterInstance = instance as TextFilterComponent;

            filterInstance.onFloatingFilterChanged(this.searchFormCtrl.value);
          });
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  protected readonly searchFormCtrl = new FormControl('', {
    nonNullable: true,
  });

  private readonly _params = signal<IFloatingFilterParams<
    never,
    string,
    never
  > | null>(null);

  agInit(params: IFloatingFilterParams<never, string, never>): void {
    this._params.set(params);
  }

  onParentModelChanged(
    parentModel: string,
    filterChangedEvent?: FilterChangedEvent | null
  ): void {
    this.searchFormCtrl.setValue(parentModel, { emitEvent: false });
  }
}
