import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { HashMap, LangDefinition, TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class TranslationFacade {
  constructor(private readonly _transloco: TranslocoService) { }

  public readonly language = toSignal(
    this._transloco.langChanges$
  ) as Signal<string>;

  translate(
    key: string,
    params?: HashMap | undefined,
    lang?: string | undefined
  ): string {
    return this._transloco.translate(key, params, lang);
  }

  getAvailableLangs() {
    return this._transloco.getAvailableLangs() as LangDefinition[];
  }
}


