@if (store.notifications().length) {
  <div
    class="w-full h-full aboslute top-0 left-0 bg-black bg-opacity-20 z-99999 pt-20 absolute flex justify-center">
    <div class="w-96 flex flex-col gap-4 absolute">
      @for (notification of store.notifications(); track notification.id) {
        <div class="p-4 bg-card mat-elevation-z2 border">
          <div class="text-base font-bold">
            {{ 'priority_notifications.title' | transloco }}
          </div>
          <div class="text-base">{{ notification.message }}</div>

          <div class="flex gap-2 justify-end">
            <button mat-flat-button (click)="store.hide(notification)">
              {{ 'common.close' | transloco }}
            </button>
            <button
              mat-flat-button
              (click)="onOpenNotification(notification)"
              color="primary">
              {{ 'common.open' | transloco }}
            </button>
          </div>
        </div>
      }
    </div>
  </div>
}
