import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { skip, tap } from 'rxjs';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, QuillModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtmlEditorComponent),
      multi: true,
    },
  ],
})
export class HtmlEditorComponent implements ControlValueAccessor {
  constructor() {
    this.formCtrl.valueChanges
      .pipe(
        skip(1),
        tap((value) => this._onChange && this._onChange(value)),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  public readonly label = input<string>('');

  protected value: string = '';
  protected formCtrl = new FormControl<string>('', {
    nonNullable: true,
  });

  private _onChange!: (value: string) => void;
  private _onTouch!: () => void;

  writeValue(value: string): void {
    if (value !== this.formCtrl.value) {
      this.formCtrl.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled && this.formCtrl.enabled) {
      this.formCtrl.disable();
    }

    if (!isDisabled && this.formCtrl.disabled) {
      this.formCtrl.enable();
    }
  }
}
