import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageTransformerComponent } from './image-transformer.component';

@NgModule({
  imports: [
    CommonModule,
    ImageCropperModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslocoModule,
  ],
  declarations: [ImageTransformerComponent],
  exports: [ImageTransformerComponent],
})
export class ImageTransformerModule {}
