import { DomainEntityBase } from '../entity-base';

export interface WorkOrderAssignee {
  familyName: string;
  givenName: string;
  id: number;
  totalRoutes: number;
  totalWorkOrders: number;
}

export type RouteWorkOrderStatusType = 'open' | 'completed';

export interface WorkOrderAssigneeLocation {
  userId: number;
  givenName: string;
  familyName: string;
  routeId: number;
  routeName: string | null;
  workOrders: {
    workorderId: number;
    workorderName: string;
    workorderLocationId: number;
    workorderSetOrder: number;
    workorderEndDate: string;
    workorderLocationAdress: string;
    workorderLocationGpsCoordinates: string;
    workorderLocationName: string;
    workorderStartingLocationName: string;
    workorderTypeName: string | null;
    workorderStatusType: RouteWorkOrderStatusType;
  }[];
}

export interface WorkOrderAssigneeRouteWorkOrder extends DomainEntityBase {
  locationName: string;
  dateTime: Date | null;
  startingLocationName: string;
  typeName: string;
  statusType: RouteWorkOrderStatusType;
}

export interface WorkOrderAssigneeRoute {
  id: number;
  name: string;
  workOrders: WorkOrderAssigneeRouteWorkOrder[];
}

export interface WorkOrderAssigneeWithRoutes {
  userId: number;
  name: string;
  routes: WorkOrderAssigneeRoute[];
}

export type WorkOrderRouteWorkOrdersShowType =
  | 'wo_with_route'
  | 'wo_no_route'
  | 'route_no_wo';
