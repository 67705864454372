export type OrderTypesModel = {
  id: number;
  translatableProperties: Record<
    string,
    {
      name: string;
      description: string;
    }
  >;
  properties: {
    code: string;
  };
  enabled: boolean;
};
