<div class="flex flex-col h-full">
  @if (store.permissions(); as permissions) {
    <app-label-with-info
      class="text-md block mb-1"
      [required]="permissions[fieldId()].mandatory"
      info="{{ info() | transloco }}"
      label="{{ label() | transloco }}" />

    @if (component(); as fieldComponent) {
      <div class="flex-grow">
        <ng-container *ngComponentOutlet="fieldComponent; inputs: inputs()" />
      </div>
    }
  }
</div>
