import { inject, Injectable } from '@angular/core';
import { SectionBasedEntityDataService } from '@fieldos/data-services';
import {
  Section,
  SectionBasedEntity,
  SectionBasedEntityType,
  WorkOrder,
} from '@fieldos/models';
import { ToastStore } from '../@store';

@Injectable({
  providedIn: 'root',
})
export class SectionBasedEntityFacade {
  private readonly _dataService = inject(SectionBasedEntityDataService);
  private readonly _toast = inject(ToastStore);

  fetch(
    workspaceScopeId: number,
    entityType: SectionBasedEntityType
  ): Promise<SectionBasedEntity[]> {
    return this._dataService.fetch(workspaceScopeId, entityType);
  }

  fetchById(
    id: number,
    entityType: SectionBasedEntityType
  ): Promise<SectionBasedEntity> {
    return this._dataService.fetchOne(id, entityType);
  }

  async update(
    entity: SectionBasedEntity,
    entityType: SectionBasedEntityType
  ): Promise<WorkOrder> {
    try {
      const updatedWorkOrder = await this._dataService.update(
        entity,
        entityType
      );
      this._toast.showSuccessToast('work_orders.saved');

      return updatedWorkOrder;
    } catch (e) {
      this._toast.showErrorToast('work_orders.save_failed');
      return Promise.reject(e);
    }
  }

  async updateSectionValue<TSection extends Section = Section>(
    entityId: number,
    sectionId: string,
    value: TSection['value'],
    entityType: SectionBasedEntityType
  ): Promise<void> {
    return this._dataService.updateSectionValue(
      entityId,
      {
        id: sectionId,
        value,
      },
      entityType
    );
  }
}
