import { AsyncPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { DomainEntityBase } from '@fieldos/models';
import { AutoUnsubscribe } from '@fieldos/utils';
import { Subscription, combineLatest, map, tap } from 'rxjs';
import { AutocompleteSelectComponent } from '../autocomplete-select';
import {
  EntityAutocompleteEntityType,
  EntityAutocompleteSelectStore,
} from './entity-autocomplete-select.store';

@Component({
  selector: 'app-entity-autocomplete-select',
  templateUrl: './entity-autocomplete-select.component.html',
  standalone: true,
  imports: [AsyncPipe, AutocompleteSelectComponent, ReactiveFormsModule],
  providers: [
    EntityAutocompleteSelectStore,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EntityAutocompleteSelectComponent),
      multi: true,
    },
  ],
})
@AutoUnsubscribe()
export class EntityAutocompleteSelectComponent<T extends DomainEntityBase>
  implements ControlValueAccessor
{
  constructor(private _store: EntityAutocompleteSelectStore<T>) {
    this._value$ = this.formCtrl.valueChanges
      .pipe(
        tap((value) => {
          this._onChange && this._onChange(value);
          this.selectionChange.emit(value);
        })
      )
      .subscribe();
  }

  @Input() public set entityType(entityType: EntityAutocompleteEntityType) {
    this._store.fetchEntities(entityType);
  }

  @Input() public set value(value: T | T[keyof T]) {
    this.writeValue(value);
  }

  @Input() public label: string = '';
  @Input() public placeholder?: string;
  @Output() public selectionChange = new EventEmitter<string | number>();

  protected vm$ = combineLatest([
    this._store.entities$,
    this._store.loading$,
  ]).pipe(
    map(([entities, loading]) => ({
      entities,
      loading,
    }))
  );

  protected formCtrl = new FormControl();

  private _onChange!: (value: T | T[keyof T]) => void;
  private _onTouched!: () => void;
  private _value$: Subscription;

  writeValue(value: T | T[keyof T]): void {
    this.formCtrl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: T | T[keyof T]) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled && this.formCtrl.enabled) {
      this.formCtrl.disable({ emitEvent: false });
    }

    if (!isDisabled && this.formCtrl.disabled) {
      this.formCtrl.enable({ emitEvent: false });
    }
  }
}
