import { Injectable, inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { Checklist } from '@fieldos/models';

@Injectable({
  providedIn: 'root',
})
export class ChecklistsDataService {
  private readonly _http = inject(HttpService);

  fetchAll(): Promise<Checklist[]> {
    return this._http.get<Checklist[]>('/checklists');
  }

  fetchById(id: number): Promise<Checklist> {
    return this._http.get<Checklist>(`/checklists/${id}`);
  }

  save(checklist: Checklist): Promise<Checklist> {
    if (checklist.id) {
      return this._http.put<Checklist>(
        `/checklists/${checklist.id}`,
        checklist
      );
    } else {
      return this._http.post<Checklist>('/checklists', checklist);
    }
  }

  delete(id: number): Promise<void> {
    return this._http.delete<void>(`/checklists/${id}`);
  }
}
