import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ToastStore } from '@fieldos/store/toast.store';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FuseAlertComponent, TranslocoModule],
})
export class ToastsComponent {
  protected readonly store = inject(ToastStore);
}
