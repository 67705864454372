import { inject, Injectable } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { Team } from '@fieldos/models';
import { TeamUserModel } from '@fieldos/models/teams/team-user-model';

@Injectable({
  providedIn: 'root',
})
export class TeamDataService {
  private readonly _http = inject(HttpService);

  fetch(): Promise<Team[]> {
    return this._http.get<Team[]>(`/teams`);
  }

  fetchOne(id: number): Promise<Team> {
    return this._http.get<Team>(`/teams/${id}`);
  }

  create(name: string) {
    return this._http.post<Team>(`/teams`, { name });
  }

  update(team: Team) {
    return this._http.put<Team[]>(`/teams/${team.id}`, team);
  }

  fetchTeamUsers(teamId: number) {
    return this._http.get<TeamUserModel[]>(`/teams/${teamId}/users`);
  }

  updateTeamUsers(teamId: number, usersIds: number[]) {
    return this._http.put<Team[]>(`/teams/${teamId}/users`, usersIds);
  }

  delete(id: number) {
    return this._http.delete<Team[]>(`/teams/${id}`);
  }
}
