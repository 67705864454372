import { ToastMessage } from '@fieldos/models';
import { createAction, props } from '@ngrx/store';

export const showAlert = createAction(
  '[Alert] Show',
  props<{ alert: ToastMessage }>()
);
export const clearAlert = createAction(
  '[Alert] Clear',
  props<{ name: string }>()
);
