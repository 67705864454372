<!-- Navigation -->
<fuse-vertical-navigation
  [name]="'mainNavigation'"
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [navigation]="navigation"
  [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center h-20 p-4 border-b mb-2">
      <!-- Light version -->
      <img
        class="dark:hidden w-30"
        alt="Logo image"
        src="assets/images/logo-color.png" />
      <!-- Dark version -->
      <img
        class="hidden dark:flex w-30"
        alt="Logo image"
        src="assets/images/logo-white.png" />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-2 md:px-2 z-9999 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
    </button>

    <div class="ml-2 text-2xl font-extrabold tracking-tight">
      <span>{{ layoutService.title() | transloco }}</span>
    </div>

    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <div class="flex gap-4 items-center">
        <app-workspace-select />
        <mat-icon>keyboard_arrow_right</mat-icon>
        <app-workspace-scope-select />
      </div>

      <languages />
      <fuse-fullscreen class="hidden md:block" />
      <notifications />
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    @if (true) {
      <app-layout-drawer>
        <router-outlet />
      </app-layout-drawer>
    }
  </div>

  <app-toasts />
  <app-priority-notifications />
</div>
