import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { NotificationStatusType, PushNotification } from '@fieldos/models';
import { fromServerDate } from '@fieldos/utils';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsDataService {
  private readonly _http = inject(HttpClient); // Replace with your API endpoint

  markAllAsRead(): Observable<void> {
    return this._http.put<void>(`/notifications?readAll=true`, {});
  }

  archiveAll(): Observable<void> {
    return this._http.put<void>(`/notifications?archiveAll=true`, {});
  }

  fetchWithStatus(
    status?: NotificationStatusType,
    page = 1,
    pageSize = 25
  ): Observable<PushNotification[]> {
    let statusQuery = status ? `&${status}=true` : '';
    if (status !== 'archived') {
      statusQuery = `${statusQuery}&archived=false`;
    }

    return this._http
      .get<PushNotification[]>(
        `/notifications?$page=${page}&pageSize=${pageSize}${statusQuery}`
      )
      .pipe(
        map((notifications) =>
          notifications.map((n) => ({
            ...n,
            localDate: fromServerDate(n.date) as Date,
          }))
        )
      );
  }

  patch(id: number, changes: Partial<PushNotification>): Observable<void> {
    return this._http.patch<void>(`/notifications/${id}`, changes);
  }
}
