import { Injectable, inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { CategoryType, CategoryTypeBase } from '@fieldos/models';

@Injectable({
  providedIn: 'root',
})
export class CategoryTypesDataService {
  private readonly _http = inject(HttpService);

  fetchTypes(categoryType: CategoryType): Promise<CategoryTypeBase[]> {
    return this._http.get<CategoryTypeBase[]>(
      this._createTypeUrl(categoryType)
    );
  }

  save(
    categoryType: CategoryType,
    entity: CategoryTypeBase
  ): Promise<CategoryTypeBase> {
    if (entity.id) {
      return this._http.put<CategoryTypeBase>(
        `${this._createTypeUrl(categoryType)}/${entity.id}`,
        entity
      );
    } else {
      return this._http.post<CategoryTypeBase>(
        this._createTypeUrl(categoryType),
        entity
      );
    }
  }

  delete(categoryType: CategoryType, id: number): Promise<void> {
    return this._http.delete(`${this._createTypeUrl(categoryType)}/${id}`);
  }

  private _createTypeUrl(categoryType: CategoryType): string {
    switch (categoryType) {
      case 'locations':
        return '/location-types';
      case 'assets':
        return '/asset-types';
      case 'users':
        return '/user-types';
      case 'customers':
        return '/customer-types';
      case 'contractors':
        return '/contractor-types';
    }
  }
}
