@if (vm$ | async; as vm) {
  <app-autocomplete-select
    [formControl]="formCtrl"
    [label]="label"
    [loading]="vm.loading"
    [options]="vm.entities"
    [placeholder]="placeholder"
    labelProperty="name"
    valueProperty="id" />
}
