import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IdSection } from '@fieldos/models';
import { ToastStore } from '@fieldos/store/toast.store';
import { Guid } from 'guid-typescript';
import { LabelWithInfoComponent } from '../../../../../@components/label-with-info';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-id-section',
  templateUrl: './sections-form-id-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    LabelWithInfoComponent,
  ],
})
export class SectionsFormIdSectionComponent extends SectionsFormSectionBaseComponent<IdSection> {
  private readonly _toastStore = inject(ToastStore);

  protected override initializeForm(): void {
    const id = Guid.create().toString();
    this.form = new FormControl<string>(id, {
      nonNullable: true,
    });

    const workOrder = this.store.entity();

    if (!workOrder) {
      this.store.updateSectionValue(this.sectionId(), id);
    }
  }

  protected copyIdToClipboard(): void {
    navigator.clipboard.writeText(this.form.value || '');
    this._toastStore.showSuccessToast(
      'Work Order ID successfully copied!',
      2000
    );
  }
}
