import { Point } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SafeUrl } from '@angular/platform-browser';
import { MouseCursorElementService } from '@fieldos/directives';
import { AutoUnsubscribe, filterEmpty } from '@fieldos/utils';
import { NgxPanZoomModule, PanZoomConfig } from 'ngx-panzoom';
import { Subscription, filter, mergeMap, of, take, tap } from 'rxjs';
import { RepositionEvent } from './pinning-tool.models';
import { PinningToolStore } from './pinning-tool.store';
import { PointMenuComponent } from './point-menu/point-menu.component';

@Component({
  selector: 'app-pinning-tool',
  templateUrl: './pinning-tool.component.html',
  standalone: true,
  imports: [AsyncPipe, NgxPanZoomModule, MatIconModule, PointMenuComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PinningToolStore],
})
@AutoUnsubscribe()
export class PinningToolComponent implements OnInit {
  constructor(private _mouseService: MouseCursorElementService) {}

  @Input() public imageSrc!: SafeUrl | string;
  @Input() public loading = false;
  @Input() public set canAdd(canAdd: boolean) {
    if (canAdd) {
      this._enableAdd();
    } else {
      this._disableAdd();
    }
  }
  @Input() public canSelect = false;
  @Input() public set points(points: Point[]) {
    this._store.setPoints(points || []);

    this._store.clearSelectedPoint();
    this.repositioningFromIndex = -1;
    if (this._pointMenu) {
      this._pointMenu.hide();
    }
  }

  @Output() public readonly pointsChange = new EventEmitter<Point[]>();
  @Output() public readonly pointClick = new EventEmitter<number>();
  @Output() public readonly remove = new EventEmitter<Point>();
  @Output() public readonly startReposition = new EventEmitter<Point>();
  @Output() public readonly reposition = new EventEmitter<RepositionEvent>();
  @Output() public readonly cancel = new EventEmitter<void>();

  @ViewChild('image') private set _imageRef(
    imageRef: ElementRef<HTMLImageElement>
  ) {
    this._store.setImage(imageRef.nativeElement);
  }

  @ViewChild(PointMenuComponent)
  private readonly _pointMenu!: PointMenuComponent;

  protected repositioningFromIndex = -1;
  protected _canAdd = true;

  protected config = new PanZoomConfig({
    scalePerZoomLevel: 1.25,
    zoomLevels: 15,
    freeMouseWheel: false,
    freeMouseWheelFactor: 0.25,
    invertMouseWheel: true,
  });

  protected points$ = inject(PinningToolStore).relativePoints$;
  protected isEditing$ = inject(PinningToolStore).isEditing$;
  protected selectedPointIndex$ = inject(PinningToolStore).selectedPointIndex$;

  protected pointTitle: string = '';

  private _store = inject(PinningToolStore);
  private _detector = inject(ChangeDetectorRef);

  private _modelChange$!: Subscription;
  private _isPanning$!: Subscription;
  private _scale$!: Subscription;

  ngOnInit(): void {
    this._modelChange$ = this.config.modelChanged
      .pipe(
        tap((model) =>
          setTimeout(() => {
            this._store.onPanelModelUpdate(model);
            this._detector.detectChanges();
          })
        )
      )
      .subscribe();

    this._isPanning$ = this._store.isPanning$
      .pipe(
        filter((isPanning) => !!isPanning),
        tap(() => {
          setTimeout(() => {
            this.cancelAddingPoint();
            this._disableAdd();
          });
        })
      )
      .subscribe();

    this._scale$ = this._store.scale$
      .pipe(
        tap(() => {
          if (this._pointMenu) {
            this.cancelAddingPoint();
            this._disableAdd();
          }
        })
      )
      .subscribe();
  }

  clearSelection(): void {
    this._store.clearSelectedPoint();
    this._pointMenu.hide();
    this.repositioningFromIndex = -1;
  }

  setPointTitle(title: string): void {
    this.pointTitle = title;
  }

  protected onPointClick(index: number, event: MouseEvent): void {
    this._store.setSelectedPointIndex(index);
    this._store.setIsEditing(true);
    this.pointClick.emit(index);
    const targetBox = (event.target as HTMLElement).getBoundingClientRect();

    setTimeout(() =>
      this._pointMenu.show({
        x: targetBox.x + targetBox.width / 2,
        y: targetBox.y + targetBox.height,
      })
    );
  }

  protected onPinPrepared(event: MouseEvent): void {
    this._store.isPreparing$
      .pipe(
        take(1),
        filter((isPreparing) => !isPreparing),
        tap(() => {
          if (this._canAdd || this.repositioningFromIndex > -1) {
            const x = event.pageX;
            const y = event.pageY;
            this._store.prepareNewPoint({ x, y });
            this._pointMenu.show(event);
          }
        })
      )
      .subscribe();
  }

  protected cancelAddingPoint(): void {
    this._store.cancelAddingPin(this.repositioningFromIndex);
    this.repositioningFromIndex = -1;

    this._store.setIsPreparing(false);
    this._pointMenu.hide();
    this.cancel.emit();
  }

  protected confirmAddedPoint(): void {
    if (!this._canAdd && this.repositioningFromIndex === -1) {
      return;
    }

    this._store.setIsPreparing(false);

    this._store.model$
      .pipe(
        mergeMap((model) => {
          if (model && model.isPanning) {
            return of();
          }

          if (this.repositioningFromIndex > -1) {
            return this._store.selectedPoint$.pipe(
              take(1),
              tap((point) =>
                this.reposition.emit({
                  index: this.repositioningFromIndex,
                  point: point as Point,
                })
              ),
              tap(() => this._store.reposition(this.repositioningFromIndex)),
              tap(() => (this.repositioningFromIndex = -1))
            );
          }

          return this._store.points$.pipe(
            take(1),
            tap((points) => {
              this.pointsChange.emit(points);
              this._pointMenu.hide();
            })
          );
        }),
        take(1)
      )
      .subscribe();
  }

  protected repositionSelectedPoint(event: MouseEvent): void {
    this._pointMenu.hide();
    this._store.selectedPointIndex$
      .pipe(
        take(1),
        filterEmpty(),
        tap((index) => (this.repositioningFromIndex = index))
      )
      .subscribe();

    this._store.selectedPoint$
      .pipe(
        filterEmpty(),
        take(1),
        tap((point) => this._mouseService.start(event, point)),
        tap((point) => this.startReposition.emit(point))
      )
      .subscribe();
  }

  protected deleteSelectedPoint(): void {
    this._store.selectedPoint$
      .pipe(
        filterEmpty(),
        take(1),
        tap((point) => this.remove.emit(point))
      )
      .subscribe();
  }

  private _disableAdd(): void {
    if (this._canAdd) {
      this._canAdd = false;
    }
    this.config.zoomOnDoubleClick = true;
    this.config.zoomOnMouseWheel = true;
    this.config.panOnClickDrag = true;
  }

  private _enableAdd(): void {
    if (!this._canAdd) {
      this._canAdd = true;
    }
    this.config.zoomOnDoubleClick = false;
    this.config.zoomOnMouseWheel = false;
    this.config.panOnClickDrag = false;
  }
}
