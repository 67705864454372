import { Pipe, PipeTransform } from '@angular/core';
import { FileModel } from '@fieldos/models';

@Pipe({
  name: 'fileUploaderIsImage',
  standalone: true,
})
export class FileUploaderIsImagePipe implements PipeTransform {
  private readonly _imageExtensions = ['jpg', 'png', 'svg'];

  transform(file: FileModel): boolean {
    const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (this._imageExtensions.includes(extension.toLowerCase())) {
      return true;
    }

    return false;
  }
}
