import { DateTime } from 'luxon';

export const SERVER_DATE_FORMAT = 'yyyy-LL-dd HH:mm:ssZ';

export const toServerDate = (date: Date): string =>
  DateTime.fromJSDate(date).toUTC().toFormat(SERVER_DATE_FORMAT);

export const fromServerDate = (date: string | null): Date | null =>
  date ? DateTime.fromFormat(date, SERVER_DATE_FORMAT).toJSDate() : null;

export const isValidDate = (date: Date) =>
  date instanceof Date && isFinite(+date);
