import { inject } from '@angular/core';
import { DEFAULT_SECTION_COLUMN } from '@fieldos/business';
import {
  ActionsCellRendererComponent,
  DataSourceCellRendererComponent,
  DataSourceSetFilterComponent,
  GridActionButton,
  SetFloatingFilterComponent,
} from '@fieldos/components';
import { WorkOrderRoutesDataService } from '@fieldos/data-services';
import { CreateWorkOrderRouteModel, WorkOrderRoute } from '@fieldos/models';
import {
  RoutesStore,
  StandardDataSourceId,
} from '@fieldos/store/data-source-cache';
import { ClaimsStore } from '@fieldos/store/index';
import { ToastStore } from '@fieldos/store/toast.store';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  removeEntity,
  setAllEntities,
  setEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { ColDef } from 'ag-grid-community';

export const WorkOrderRoutesStore = signalStore(
  withEntities<WorkOrderRoute>(),
  withState<{
    loading: boolean;
    columns: ColDef[];
  }>({
    loading: false,
    columns: [{}],
  }),
  withMethods(
    (
      store,
      dataService = inject(WorkOrderRoutesDataService),
      toast = inject(ToastStore),
      routesStore = inject(RoutesStore)
    ) => ({
      async fetchAll() {
        patchState(store, { loading: true });
        try {
          const response = await dataService.fetchAll();
          patchState(store, setAllEntities(response));
        } catch (err) {
          console.error('Error fetching work order routes', err);
        }
      },
      async create(route: CreateWorkOrderRouteModel): Promise<void> {
        try {
          const newRoute = await dataService.create(route);
          toast.showSuccessToast('work_orders.routes.create_success');

          patchState(store, setEntity({ ...route, ...newRoute }));
          routesStore.fetchAll();
        } catch (err) {
          toast.showErrorToast('work_orders.routes.create_error');
        }
      },
      async update(route: WorkOrderRoute): Promise<void> {
        try {
          const newRoute = await dataService.update(route);
          toast.showSuccessToast('work_orders.routes.update_success');

          patchState(store, setEntity({ ...route, ...newRoute }));
          routesStore.fetchAll();
        } catch (err) {
          toast.showErrorToast('work_orders.routes.update_error');
        }
      },
      async deleteRoute(route: WorkOrderRoute): Promise<void> {
        try {
          await dataService.delete(route.id);
          toast.showSuccessToast('work_orders.routes.delete_success');
          patchState(store, removeEntity(route.id));
          routesStore.fetchAll();
        } catch (err) {
          toast.showErrorToast('work_orders.routes.delete_error');
        }
      },
    })
  ),
  withHooks({
    onInit(
      store,
      defaultColDef = inject(DEFAULT_SECTION_COLUMN),
      claimStore = inject(ClaimsStore)
    ) {
      store.fetchAll();

      const actions: GridActionButton<WorkOrderRoute>[] = [];

      if (claimStore.hasClaim('workorderslocation.route.edit')) {
        actions.push({
          id: 'edit',
          icon: 'edit',
          color: 'primary',
        });
      }

      if (claimStore.hasClaim('workorderslocation.route.delete')) {
        actions.push({
          id: 'delete',
          icon: 'delete',
          color: 'warn',
        });
      }

      patchState(store, {
        columns: [
          {
            ...defaultColDef,
            headerName: 'common.id',
            field: 'id',
            flex: 1,
            sort: 'desc',
          },
          {
            ...defaultColDef,
            headerName: 'common.name',
            field: 'name',
            flex: 1,
          },
          {
            ...defaultColDef,
            flex: 1,
            headerName: 'work_orders.routes.assigned_to',
            field: 'assigneeId',
            filter: DataSourceSetFilterComponent,
            filterParams: {
              dataSourceId: StandardDataSourceId.Assignees,
            },
            floatingFilterComponent: SetFloatingFilterComponent,
            cellRendererParams: {
              dataSourceId: StandardDataSourceId.Assignees,
            },
            cellRenderer: DataSourceCellRendererComponent,
            sortable: false,
          },
          {
            ...defaultColDef,
            flex: 1,
            headerName: 'common.status',
            field: 'statusId',
            cellRendererParams: {
              dataSourceId: StandardDataSourceId.WorkOrderSetStatuses,
            },
            filter: DataSourceSetFilterComponent,
            sortable: false,
            filterParams: {
              dataSourceId: StandardDataSourceId.WorkOrderSetStatuses,
            },
            floatingFilterComponent: SetFloatingFilterComponent,
            cellRenderer: DataSourceCellRendererComponent,
          },
          {
            headerName: 'common.actions',
            cellRenderer: ActionsCellRendererComponent,
            cellRendererParams: {
              actions,
            },
            resizable: false,
            pinned: 'right',
            cellClass: 'px-0',
            width: 120,
            minWidth: 120,
            maxWidth: 120,
          },
        ],
      });
    },
  })
);
