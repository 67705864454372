<mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent">
  <!-- Drawer -->
  <mat-drawer
    #matDrawer
    class="w-full sm:w-[640px] dark:bg-gray-900"
    [opened]="store.opened()"
    [position]="'end'"
    (closed)="store.close()"
    mode="over"
    position="end">
    <app-sections-form-drawer-content
      [canPin]="false"
      [chatOpened]="store.chatOpened()"
      (closeClick)="store.close()" />
  </mat-drawer>

  <mat-drawer-content class="flex flex-col">
    <ng-content />
  </mat-drawer-content>
</mat-drawer-container>
