import { inject } from '@angular/core';
import { LayoutDrawerFacade } from '@fieldos/facades';
import { PushNotification } from '@fieldos/models';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { NotificationsStore } from './notifications';

export const PriorityNotificationStore = signalStore(
  {
    providedIn: 'root',
  },
  withState<{ notifications: PushNotification[] }>({
    notifications: [],
  }),
  withMethods(
    (
      store,
      layoutDrawerFacade = inject(LayoutDrawerFacade),
      notificationStore = inject(NotificationsStore)
    ) => ({
      show(notification: PushNotification): void {
        patchState(store, {
          notifications: [...store.notifications(), notification],
        });
      },
      hide(notification: PushNotification): void {
        patchState(store, {
          notifications: store
            .notifications()
            .filter((n) => n !== notification),
        });
      },
      open(notification: PushNotification): void {
        layoutDrawerFacade.openNotification(notification);
        notificationStore.toggleRead(notification);
      },
    })
  )
);
