import { Injectable, inject } from '@angular/core';
import { AssignExceptionBody } from '@fieldos/business';
import { HttpService } from '@fieldos/core';
import { AssignedException, EntityException } from '@fieldos/models';

@Injectable({
  providedIn: 'root',
})
export class ExceptionsFacade {
  private readonly _http = inject(HttpService);

  fetchWorkOrderExcetions(workOrderId: number) {
    return this._http.get<EntityException[]>(
      `/workorder-exceptions/${workOrderId}`
    );
  }

  async fetchException(exceptionId: number): Promise<AssignedException> {
    return this._http.get<AssignedException>(`/exceptions/${exceptionId}`);
  }

  async createException(exception: AssignExceptionBody): Promise<void> {
    await this._http.post<void>('/exceptions', exception);
  }
}
