import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SimpleFormGroup } from '@browsepedia/ng-forms';
import {
  AssigneesCategoryCost,
  AssigneesSectionCommonProperties,
  AssigneesWorkCategoryCost,
  AssigneesWorkCategoryDuration,
  WorkCategory,
} from '@fieldos/models';
import { FromServerDatePipe } from '@fieldos/pipes';
import { DataSourceStore, StandardDataSourceId } from '@fieldos/store';
import { filterEmpty, toServerDate } from '@fieldos/utils';
import { TranslocoModule } from '@ngneat/transloco';
import { filter, map, tap } from 'rxjs';
import { BoxConfirmDialogComponent } from '../../../../../../@components/box-confirm-dialog';
import {
  DataSourceMultiSelectComponent,
  DataSourceSelectComponent,
  DatetimePickerComponent,
  DurationPickerComponent,
  HtmlEditorComponent,
  NumberFieldComponent,
} from '../../../../../../@components/fields';
import { AssigneeWorkCategoryFormType } from './sections-form-assignee-category-form.form';

@Component({
  selector: 'app-sections-form-assignee-category-form',
  templateUrl: './sections-form-assignee-category-form.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    NgClass,
    DurationPickerComponent,
    MatButtonModule,
    MatIconModule,
    NumberFieldComponent,
    DataSourceSelectComponent,
    DataSourceMultiSelectComponent,
    DatetimePickerComponent,
    HtmlEditorComponent,
    TranslocoModule,
    BoxConfirmDialogComponent,
    FromServerDatePipe,
  ],
})
export class SectionsFormAssigneeCategoryFormComponent implements OnInit {
  @Input({ required: true })
  public form!: FormGroup<AssigneeWorkCategoryFormType>;

  @Input() public canRemove = true;

  @Input({ required: true }) public index: number = 0;
  @Input() public isLast = false;

  @Input({ required: true })
  public properties: AssigneesSectionCommonProperties;

  @Output() public readonly remove = new EventEmitter<void>();

  @ViewChild(BoxConfirmDialogComponent)
  private readonly _confirmDialog!: BoxConfirmDialogComponent;

  private readonly _dataSourceStore = inject(DataSourceStore);

  protected get durationFormGroup(): SimpleFormGroup<AssigneesWorkCategoryDuration> {
    return this.form.controls
      .duration as SimpleFormGroup<AssigneesWorkCategoryDuration>;
  }

  ngOnInit(): void {
    this.form.controls.id.valueChanges
      .pipe(
        filterEmpty(),
        map(
          (id) =>
            this._dataSourceStore.selectNormalizedDataSource(
              StandardDataSourceId.WorkCategory
            )()[id] as WorkCategory
        ),
        tap((category) => this._updateFormCategory(category))
      )
      .subscribe();
  }

  protected onDateChange(date: Date | null): void {
    this.form.patchValue({
      date: date ? toServerDate(date) : '',
    });
  }

  protected onDurationChange(value: number): void {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    const durationForm = this.form.controls
      .duration as SimpleFormGroup<AssigneesWorkCategoryDuration>;

    const costForm = this.form.controls
      .cost as SimpleFormGroup<AssigneesWorkCategoryCost>;

    const categoryCostForm = this.form.controls
      .cost_category as SimpleFormGroup<AssigneesCategoryCost>;

    const categoryCost = categoryCostForm.value;
    const cost = costForm.value;

    cost.calculated_value =
      (minutes + hours * 60) * ((categoryCost.hr_default as number) / 60);

    durationForm.patchValue({
      minutes,
      hours,
      total: minutes + hours * 60,
    });

    costForm.patchValue({
      ...cost,
    });
  }

  protected onRemoveWorkCategory(): void {
    this._confirmDialog
      .open()
      .pipe(
        filter((canRemove) => canRemove),
        tap(() => this.remove.emit())
      )
      .subscribe();
  }

  private readonly _updateFormCategory = (category: WorkCategory): void => {
    this.form.patchValue(
      {
        hr_default: parseInt(category.hr_default),
        currency: category.currency,
        id: category.id,
        name: category.name,
      },
      { emitEvent: false }
    );

    this.form.controls.cost_category.patchValue(
      {
        currency: category.currency,
        duration_default: parseInt(category.duration_default),
        hr_default: parseInt(category.hr_default),
        id: category.id,
        name: category.name,
      },
      { emitEvent: false }
    );

    const costForm = this.form.controls
      .cost as SimpleFormGroup<AssigneesWorkCategoryCost>;

    costForm.patchValue({
      hr: parseInt(category.hr_default),
    });
  };
}
