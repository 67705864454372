import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { Field } from '@fieldos/models';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FIELDS_DESIGNER_STORE } from '../../fields-designer.store';

@Component({
  selector: 'app-fields-designer-field',
  templateUrl: './fields-designer-field.component.html',
  standalone: true,
  imports: [TranslocoModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class FieldsDesignerFieldComponent {
  public readonly field = input.required<Field>();

  protected readonly activeLanguage = toSignal(
    inject(TranslocoService).langChanges$
  ) as Signal<string>;

  protected readonly store = inject(FIELDS_DESIGNER_STORE);

  protected onEdit(): void {
    if (this.field().properties?.arePropertiesReadonly) {
      return;
    }

    this.store.setSelectedFieldId(this.field().id);
  }

  protected onDelete(fieldId: string) {
    this.store.deleteField(this.field().id);
  }
}
