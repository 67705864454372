import { Observable } from 'rxjs';

export const denormalize =
  () =>
  <T>(source: Observable<Record<string | number, T>>): Observable<T[]> =>
    new Observable((subscriber) =>
      source.subscribe({
        next: (value) => subscriber.next(Object.values(value)),
        error: (error) => subscriber.error(error),
        complete: () => subscriber.complete(),
      })
    );
