<div class="text-base font-medium w-full text-left truncate">
  <button
    mat-flat-button
    [disabled]="value().length === 0"
    (click)="$event.stopPropagation(); openGallery()">
    @if (value().length === 1) {
      1 {{ 'common.file' | transloco }}
    } @else {
      {{ 'common.files' | transloco: { count: value().length } }}
    }
  </button>
</div>
