import { inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { CategoryTypesDataService } from '@fieldos/data-services';
import { CategoryType, CategoryTypeBase } from '@fieldos/models';
import {
  patchState,
  signalStore,
  type,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import { AuthStore } from '..';

export const CategoryTypesStore = signalStore(
  { providedIn: 'root' },
  withEntities({
    entity: type<CategoryTypeBase>(),
    collection: 'locations',
  }),
  withEntities({
    entity: type<CategoryTypeBase>(),
    collection: 'assets',
  }),
  withEntities({
    entity: type<CategoryTypeBase>(),
    collection: 'users',
  }),
  withEntities({
    entity: type<CategoryTypeBase>(),
    collection: 'customers',
  }),
  withEntities({
    entity: type<CategoryTypeBase>(),
    collection: 'contractors',
  }),
  withMethods((store, dataService = inject(CategoryTypesDataService)) => ({
    selectCategoryTypes(categoryType: CategoryType) {
      switch (categoryType) {
        case 'assets':
          return store.assetsEntities();
        case 'locations':
          return store.locationsEntities();
        case 'users':
          return store.usersEntities();
        case 'customers':
          return store.customersEntities();
        case 'contractors':
          return store.contractorsEntities();
      }
    },
    async refreshCategoryTypes(categoryType: CategoryType) {
      try {
        const types = await dataService.fetchTypes(categoryType);
        patchState(
          store,
          setAllEntities(types, {
            collection: categoryType,
          })
        );
      } catch (error) {
        console.error('Error fetching category types', error);
      }
    },
    async initialize(): Promise<void> {
      try {
        const [
          assetTypes,
          locationTypes,
          userTypes,
          customerTypes,
          contractorTypes,
        ] = await Promise.all([
          dataService.fetchTypes('assets'),
          dataService.fetchTypes('locations'),
          dataService.fetchTypes('users'),
          dataService.fetchTypes('customers'),
          dataService.fetchTypes('contractors'),
        ]);

        patchState(
          store,
          setAllEntities(assetTypes, {
            collection: 'assets',
          })
        );

        patchState(
          store,
          setAllEntities(locationTypes, {
            collection: 'locations',
          })
        );

        patchState(
          store,
          setAllEntities(userTypes, {
            collection: 'users',
          })
        );

        patchState(
          store,
          setAllEntities(customerTypes, {
            collection: 'customers',
          })
        );

        patchState(
          store,
          setAllEntities(contractorTypes, {
            collection: 'contractors',
          })
        );
      } catch (error) {
        console.error('Error fetching category types', error);
      }
    },
  })),
  withHooks({
    onInit(store, authStore = inject(AuthStore)) {
      toObservable(authStore.isAuthenticated)
        .pipe(
          distinctUntilChanged(),
          filter((isAuthenticated) => isAuthenticated === true),
          tap(() => store.initialize()),
          takeUntilDestroyed()
        )
        .subscribe();
    },
  })
);
