import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, filter, tap } from 'rxjs';
import { TokenService } from './token.service';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const jwtRefreshInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const tokenService = inject(TokenService);

  return next(req).pipe(
    filter((event: HttpEvent<unknown>) => event instanceof HttpResponse),
    tap((event: HttpEvent<unknown>) => {
      const response = event as HttpResponse<unknown>;

      if (response.headers.has('x-fieldos-refreshed-token')) {
        tokenService.updateAccessToken(
          response.headers.get('x-fieldos-refreshed-token') as string
        );
      }
    })
  );
};
