import { Observable, Subject, catchError, of, take, tap } from 'rxjs';

export const callAndTakeOne =
  <T>() =>
  (source: Observable<T>): Observable<T> => {
    const subject = new Subject<T>();
    source
      .pipe(
        tap((value: T) => {
          subject.next(value);
        }),
        catchError((error) => {
          subject.error(error);
          return of();
        })
      )
      .subscribe();

    return subject.asObservable().pipe(take(1));
  };
