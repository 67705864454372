<div
  [id]="field().id"
  [class.bg-slate-100]="store.selectedFieldId() === field().id"
  [class.border]="!field().properties.arePropertiesReadonly"
  [class.dark:bg-slate-700]="store.selectedFieldId() === field().id"
  [class.p-4]="!field().properties.arePropertiesReadonly"
  class="flex flex-col gap-4 cursor-pointer">
  @if (!field().properties.arePropertiesReadonly) {
    <div (click)="onFieldClicked()">
      <h6 class="font-semibold text-md">
        {{ 'fields.' + field().subtype + '.title' | transloco }}
      </h6>
    </div>
  }

  <div
    class="grid grid-cols-2 min-h-18 gap-4"
    [bpDropDisabled]="
      isFixed() || fields().length === 2 || store.isDraggingContainer()
    "
    [bpDropzoneIsHorizontal]="true"
    (bpDrop)="store.moveOrAddField($event, field().id)"
    bpDropzone>
    @for (childField of fields(); track childField.id) {
      <div class="relative">
        @if (!store.validationStatuses()[childField.id]) {
          <mat-icon
            matTooltip="This field has validation errors"
            class="text-orange-500 absolute top-2 right-2"
            svgIcon="mat_solid:warning" />
        }
        <app-fields-designer-field
          [bpDrag]="childField"
          [bpDragDisabled]="!!store.fixedFieldTypes()[childField.subtype]"
          [field]="childField"
          (bpDragEnd)="store.setDraggingFieldId()"
          (bpDragStart)="store.setDraggingFieldId(childField.id)" />
      </div>
    }

    <div
      class="h-18 flex items-center justify-center border bg-card"
      bpDropPlaceholder></div>
  </div>
</div>
