import { computed, effect, inject } from '@angular/core';

import { TextCellRendererComponent } from '@fieldos/components';
import { DialogService } from '@fieldos/core';
import {
  SectionBasedEntityDataService,
  SectionsDataService,
  WorkOrderImportDataService,
} from '@fieldos/data-services';
import { TranslationFacade } from '@fieldos/facades';
import {
  ConfirmLocationSection,
  DataSourceSection,
  Section,
  SectionBasedEntity,
  SectionBasedEntityType,
  SectionLabelInfoProperties,
  SectionSubType,
  SectionsResponse,
  ServiceRequest,
} from '@fieldos/models';
import { ToastStore, WorkspaceStore } from '@fieldos/store';
import { filterEquals } from '@fieldos/utils';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { ColDef } from 'ag-grid-community';
import { orderBy, sortBy } from 'lodash';
import { tap } from 'rxjs';
import {
  ActionsColumnComponent,
  SECTION_SUBTYPE_COL_DEF_MAP,
  SectionBasedEntityListModel,
} from '../section-columns';
import { HIDDEN_SECTION_COLUMNS } from '../section-columns/hidden-section-columns.provider';
import { SectionValueChangeEvent } from '../sections-form-drawer';

interface SectionsEntityListState {
  entityType: SectionBasedEntityType;
  items: SectionBasedEntity[];
  sections: SectionsResponse | undefined;
  loading: boolean;
  loaded: boolean;
  selection: SectionBasedEntityListModel[];
}

const initialState: SectionsEntityListState = {
  entityType: 'workorders',
  items: [],
  loading: false,
  loaded: false,
  sections: undefined,
  selection: [],
};

export const SectionsEntityListStore = signalStore(
  withState(() => initialState),
  withComputed(
    (
      store,
      workspaceStore = inject(WorkspaceStore),
      translationFacade = inject(TranslationFacade),
      columnMap = inject(SECTION_SUBTYPE_COL_DEF_MAP),
      hiddenColumns = inject(HIDDEN_SECTION_COLUMNS)
    ) => ({
      sorted: computed(() => orderBy(store.items(), ['id'], ['desc'])),
      sectionsMap: computed(() =>
        (store.sections()?.sections || []).reduce(
          (acc, current) => ({
            ...acc,
            [current.id]: current,
          }),
          {}
        )
      ),
      columnDefs: computed(() => {
        const scopeId = workspaceStore.selectedWorkspaceScopeId();
        const sectionConfig = store.sections();
        const language = translationFacade.language;
        const entityType = store.entityType();

        if (scopeId && sectionConfig) {
          return _mapSectionsToColumnDefs(
            sectionConfig.sections.filter(
              (e) => !hiddenColumns.includes(e.subtype)
            ),
            language(),
            columnMap
          )
            .concat({
              field: 'actions',
              cellRenderer: ActionsColumnComponent,
              filter: false,
              headerName: translationFacade.translate('common.actions'),
              pinned: 'right',
              initialWidth: 40,
            })
            .concat(
              entityType === 'servicerequests'
                ? {
                    field: 'convertedWoId',
                    headerName: translationFacade.translate(
                      'service_requests.column.converted_wo_id'
                    ),
                    cellRenderer: TextCellRendererComponent,
                    initialWidth: 100,
                  }
                : []
            );
        }

        return [];
      }),
      data: computed(() => {
        const scopeId = workspaceStore.selectedWorkspaceScopeId();
        const sectionConfig = store.sections();
        const sorted = orderBy(store.items(), ['id'], ['desc']);

        if (scopeId && sectionConfig) {
          const sections = sectionConfig.sections;

          return sorted.map((items) => _mapItemToListItem(items, sections));
        }

        return [];
      }),
    })
  ),
  withMethods(
    (
      store,
      sectionsDataService = inject(SectionsDataService),
      dialog = inject(DialogService),
      dataService = inject(SectionBasedEntityDataService),
      toastStore = inject(ToastStore),
      workOrderImportDataService = inject(WorkOrderImportDataService),
      workspaceStore = inject(WorkspaceStore)
    ) => ({
      getSelectedIds(): number[] {
        return store.selection().map((e) => e.id);
      },
      setSelection(selection: SectionBasedEntityListModel[]): void {
        patchState(store, { selection });
      },
      setEntityType(entityType: SectionBasedEntityType): void {
        patchState(store, { entityType });
      },
      async fetchItems(scopeId: number) {
        patchState(store, { loading: true });

        const items = await dataService.fetch(scopeId, store.entityType());

        patchState(store, {
          items: sortBy(items, ['updatedDate']),
          loaded: true,
          loading: false,
        });
      },
      async fetchSectionConfig(workspaceScopeId: number): Promise<void> {
        const sections = await sectionsDataService.fetchSections(
          workspaceScopeId,
          store.entityType()
        );

        patchState(store, { sections });
      },
      updateSectionValue: (event: SectionValueChangeEvent): void => {
        setTimeout(async () => {
          try {
            const entity = await dataService.fetchOne(
              event.entityId,
              store.entityType()
            );

            if (entity) {
              const items = [...store.items()];
              const index = items.findIndex((e) => e.id === entity.id);

              if (index > -1) {
                items.splice(index, 1, entity);
                patchState(store, { items });
              }
            }
          } catch (error) {
            console.error(error);
          }
        }, 2000);
      },
      fetchById(itemId: number): Promise<SectionBasedEntity> {
        return dataService.fetchOne(itemId, store.entityType());
      },
      onCreated(item: SectionBasedEntity): void {
        patchState(store, {
          items: [...store.items(), item],
        });
      },
      async delete(itemId: number): Promise<void> {
        dialog
          .showConfirmDialog(
            'section_based_entity.confirm_delete.title',
            `section_based_entity.${store.entityType()}.confirm_delete.message`
          )
          .pipe(
            filterEquals(true),
            tap(async () => {
              await dataService.delete(itemId, store.entityType());

              const items = [...store.items()];
              const index = items.findIndex((e) => e.id === itemId);
              if (index > -1) {
                items.splice(index, 1);
                patchState(store, { items });
              }

              toastStore.showSuccessToast(
                `section_based_entity.${store.entityType()}.deleted_successfully.message`
              );
            })
          )
          .subscribe();
      },
      async import(file: File) {
        patchState(store, { loading: true });
        try {
          await workOrderImportDataService.import(
            file,
            workspaceStore.selectedWorkspaceScopeId().toString(),
            workspaceStore.selectedWorkspaceScopeId().toString()
          );

          toastStore.showSuccessToast(
            `section_based_entity.${store.entityType()}.imported_successfully.message`
          );

          patchState(store, { loading: true });
          await this.fetchItems(workspaceStore.selectedWorkspaceScopeId());
        } catch (_) {
          patchState(store, { loading: false });
          toastStore.showSuccessToast(
            `section_based_entity.${store.entityType()}.imported_failed.message`
          );
        }
      },
    })
  ),
  withHooks({
    onInit(store, workspaceStore = inject(WorkspaceStore)): void {
      effect(
        () => {
          const scopeId = workspaceStore.selectedWorkspaceScopeId();
          const entityType = store.entityType();

          if (scopeId && entityType) {
            store.fetchSectionConfig(scopeId);
            store.fetchItems(scopeId);
          }
        },
        { allowSignalWrites: true }
      );
    },
  })
);

const _mapSectionsToColumnDefs = (
  sections: Section[],
  language: string,
  columnMap: Record<SectionSubType, ColDef>
): ColDef[] =>
  sections.map((section) => {
    const translatableProperties = section.translatableProperties as Record<
      string,
      SectionLabelInfoProperties
    >;

    if (section.subtype === SectionSubType.SingleStandardDataSource) {
      const dataSourceSection = section as DataSourceSection;

      return {
        ...columnMap[section.subtype],
        headerName: translatableProperties[language]?.label,
        filterParams: {
          dataSourceId: dataSourceSection.properties?.optionId || 0,
        },
        field: section.id,
      };
    }

    return {
      ...columnMap[section.subtype],
      headerName: translatableProperties[language]?.label,
      field: section.id,
    };
  });

const _mapItemToListItem = (
  item: SectionBasedEntity,
  sections: Section[]
): SectionBasedEntityListModel => ({
  ...sections.reduce(
    (acc: any, section) => {
      let value = item.value.find((v) => v.id === section.id)?.value;

      if (section.subtype === SectionSubType.ConfirmLocation) {
        value = !!(value as ConfirmLocationSection['value']);
      }
      return {
        ...acc,
        [section.id]: value,
      };
    },
    {
      id: item.id,
    }
  ),
  actions: item.availableActions,
  id: item.id,
  convertedWoId: (item as ServiceRequest).convertedWoId,
});
