/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DataGridButtonClickEvent } from './data-grid.models';

@Injectable()
export class DataGridActionsService<T, EActionTypes = string> {
  private _buttonClick$ = new Subject<
    DataGridButtonClickEvent<T, EActionTypes>
  >();
  public readonly buttonClick$ = this._buttonClick$.asObservable();

  onButtonClick(buttonId: EActionTypes, data: T): void {
    this._buttonClick$.next({
      data,
      buttonId,
    });
  }
}
