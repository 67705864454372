import {
  ChangeDetectionStrategy,
  Component,
  effect,
  signal,
} from '@angular/core';
import { RolesFacade } from '@fieldos/facades';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-role-cell-renderer',
  templateUrl: './role-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class RoleCellRendererComponent implements ICellRendererAngularComp {
  constructor(readonly _rolesFacade: RolesFacade) {
    this._rolesFacade.fetchRoles();

    effect(
      () => {
        const roleIds = this._roleIds();
        const roles = this._rolesFacade.roles();

        const roleNames = roleIds.reduce((acc, roleId) => {
          const role = roles.find((r) => r.id === roleId);
          if (role) {
            return [...acc, role.name];
          }
          return acc;
        }, [] as string[]);

        this.value.set(roleNames.join(', '));
      },
      { allowSignalWrites: true }
    );
  }

  protected readonly value = signal('');

  private readonly _roleIds = signal<number[]>([]);

  agInit(
    params: ICellRendererParams<string, number | number[], unknown>
  ): void {
    if (params.value) {
      this._roleIds.set(
        typeof params.value === 'number'
          ? [params.value]
          : (params.value as number[])
      );
    }
  }

  refresh(
    params: ICellRendererParams<string, number | number[], unknown>
  ): boolean {
    if (params.value) {
      this._roleIds.set(
        typeof params.value === 'number'
          ? [params.value]
          : (params.value as number[])
      );
    }

    return false;
  }
}
