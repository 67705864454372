import { Injectable } from '@angular/core';
import { defer, fromEvent, Subscription } from 'rxjs';

@Injectable()
export class LineConnectorService {
  public connecting = false;

  private _cursorDiv: HTMLDivElement | null = null;
  private _cursorDivReposition$!: Subscription;
  private _documentClick$!: Subscription;
  private _line: LeaderLine | null = null;
  private _startNodeLineConnectorId: string | null = null;

  startConnection(
    appLineConnectorId: string,
    startConnectionEvent: MouseEvent,
    options: LeaderLine.Options
  ): void {
    if (this.connecting || this._cursorDiv) {
      return;
    }

    this.connecting = true;

    this._startNodeLineConnectorId = appLineConnectorId;
    const element = document.querySelector(
      `[appLineConnectorId='${appLineConnectorId}']`
    ) as Element;

    this._cursorDiv = document.createElement('div');
    this._cursorDiv.style.position = 'absolute';

    this._cursorDiv.style.top = `${startConnectionEvent.pageY}px`;
    this._cursorDiv.style.left = `${startConnectionEvent.pageX}px`;

    document.body.appendChild(this._cursorDiv);

    this._line = new LeaderLine(element, this._cursorDiv, options);

    this._cursorDivReposition$ = defer(() =>
      fromEvent<MouseEvent>(document, 'mousemove')
    ).subscribe((event: MouseEvent) => {
      if (this._cursorDiv) {
        this._cursorDiv.style.top = `${event.pageY}px`;
        this._cursorDiv.style.left = `${event.pageX}px`;
      }

      if (this._line) {
        this._line.position();
      }
    });

    this._documentClick$ = defer(() => fromEvent(document, 'click')).subscribe(
      () => this._cleanupAfterConnection()
    );
  }

  finishConnection(appLineConnectorId: string): string | null {
    if (!this.connecting) {
      return null;
    }

    this.connecting = false;
    if (
      this._startNodeLineConnectorId === appLineConnectorId &&
      this._startNodeLineConnectorId
    ) {
      this._cleanupAfterConnection();

      return null;
    }
    const id = this._startNodeLineConnectorId;
    this._cleanupAfterConnection();

    return id;
  }

  private _cleanupAfterConnection(): void {
    this._cursorDiv = null;
    if (this._line) {
      try {
        this._line.remove();
        this._line = null;
      } catch (e) {
        return;
      }
    }
    this._cursorDivReposition$?.unsubscribe();
    this._documentClick$?.unsubscribe();
    this._startNodeLineConnectorId = null;
  }
}
