import { Route } from '@angular/router';

export const requestsRoutes: Route[] = [
  {
    path: 'requests',
    loadComponent: () =>
      import('./requests/requests.component').then((m) => m.RequestsComponent),
    data: {
      title: 'menu.requests',
    },
  },
  {
    path: 'requests/templates/new',
    loadComponent: () =>
      import('./request-template-form/request-template-form.component').then(
        (m) => m.RequestTemplateFormComponent
      ),
    data: {
      title: 'menu.create_request_template',
    },
  },
  {
    path: 'requests/templates/:requestTemplateId/edit',
    loadComponent: () =>
      import('./request-template-form/request-template-form.component').then(
        (m) => m.RequestTemplateFormComponent
      ),
    data: {
      title: 'menu.edit_request_template',
    },
  },
];
