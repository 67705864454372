import { IFloatingFilterParams } from 'ag-grid-community';

export type StartFloatingFilterParams = IFloatingFilterParams<
  unknown,
  StarFilterModel,
  unknown
>;

export interface StarFilterModel {
  operator: StarFilterOperator;
  value: number;
}

export type StarFilterOperator = 1 | 2 | 3;
