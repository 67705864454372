export type ChecklistTaskType =
  | 'checkbox'
  | 'number'
  | 'radio'
  | 'text'
  | 'options'
  | 'datepicker'
  | 'checkboxes'
  | 'yesNoNa'
  | 'qrcode';
