<ng-template #tpl>
  <div>
    <image-cropper
      class="h-100"
      [allowMoveImage]="false"
      [aspectRatio]="aspectRatio || 1"
      [canvasRotation]="0"
      [containWithinAspectRatio]="false"
      [imageFile]="file"
      [maintainAspectRatio]="!!aspectRatio"
      [onlyScaleDown]="false"
      [output]="'base64'"
      [roundCropper]="false"
      [transform]="transform"
      (imageCropped)="onCrop($event)"
      format="png" />
    <div
      class="bg-base-200 text-on-base rounded-xl mt-3 py-3 px-6 flex items-center flex-wrap gap-4 md:gap-8">
      <div>
        <span class="block font-bold text-base">
          {{ 'image_transformer.rotation.label' | transloco }}
        </span>
        <div class="flex gap-2 mt-1">
          <button
            mat-icon-button
            [matTooltip]="'image_transformer.rotation.btn.right' | transloco"
            (click)="onRotateRight()">
            <mat-icon>rotate_right</mat-icon>
          </button>
          <button
            mat-icon-button
            [matTooltip]="'image_transformer.rotation.btn.left' | transloco"
            (click)="onRotateLeft()">
            <mat-icon>rotate_left</mat-icon>
          </button>
        </div>
      </div>
      <div>
        <span class="block font-bold text-base">
          {{ 'image_transformer.flip.label' | transloco }}
        </span>
        <div class="flex gap-2 mt-1">
          <button
            mat-icon-button
            [matTooltip]="'image_transformer.flip.btn.horizontal' | transloco"
            (click)="onFlipHorizontal()">
            <mat-icon>flip</mat-icon>
          </button>
          <button
            mat-icon-button
            [matTooltip]="'image_transformer.flip.btn.vertical' | transloco"
            (click)="onFlipVertical()">
            <mat-icon class="rotate-90">flip</mat-icon>
          </button>
        </div>
      </div>
      <div>
        <span class="block font-bold text-base">
          {{ 'image_transformer.zoom.label' | transloco }}
        </span>
        <div class="flex gap-2 mt-1">
          <button
            mat-icon-button
            [disabled]="transform.scale! >= 10"
            [matTooltip]="'image_transformer.zoom.btn.in' | transloco"
            (click)="onZoomIn()">
            <mat-icon>zoom_in</mat-icon>
          </button>
          <button
            mat-icon-button
            [disabled]="transform.scale! <= 0"
            [matTooltip]="'image_transformer.zoom.btn.out' | transloco"
            (click)="onZoomOut()">
            <mat-icon>zoom_out</mat-icon>
          </button>
        </div>
      </div>
      <div>
        <span class="block font-bold text-base">
          {{ 'image_transformer.reset.label' | transloco }}
        </span>
        <button
          mat-icon-button
          class="mt-1"
          [disabled]="resetDisabled"
          [matTooltip]="'image_transformer.reset.btn' | transloco"
          (click)="onReset()">
          <mat-icon>restart_alt</mat-icon>
        </button>
      </div>
      <div class="md:ml-auto">
        <span class="block font-bold text-base">
          {{ 'common.cancel' | transloco }}
        </span>
        <button
          mat-icon-button
          class="mt-1"
          [matTooltip]="'image_transformer.cancel.btn' | transloco"
          (click)="onCancel()">
          <mat-icon>block</mat-icon>
        </button>
      </div>
      <div>
        <span class="block font-bold text-base">
          {{ 'image_transformer.save.label' | transloco }}
        </span>
        <button
          mat-icon-button
          class="mt-1"
          [matTooltip]="'image_transformer.save.btn' | transloco"
          (click)="onSave()"
          color="primary">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
