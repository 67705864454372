import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  CustomDataSourceStore,
  DataSourceStore,
} from '@fieldos/store/data-source-cache';

import { SectionSubType, SingleDataSourceSection } from '@fieldos/models';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SectionBasedEntityListAGGridContext } from '../../section-columns.models';

@Component({
  selector: 'work-data-source-cell-renderer',
  templateUrl: './single-data-source-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleDataSourceCellRendererComponent
  implements ICellRendererAngularComp
{
  protected value: string = '';
  private _params!: ICellRendererParams<
    string,
    number | number[],
    SectionBasedEntityListAGGridContext
  >;

  private readonly _dataSourceStore = inject(DataSourceStore);
  private readonly _customDataSourceStore = inject(CustomDataSourceStore);

  agInit(
    params: ICellRendererParams<
      string,
      number | number[],
      SectionBasedEntityListAGGridContext
    >
  ): void {
    this._params = params;

    this._setValue(params);
  }

  refresh(
    params: ICellRendererParams<
      string,
      number | number[],
      SectionBasedEntityListAGGridContext
    >
  ): boolean {
    this._params = params;

    this._setValue(params);
    return true;
  }

  private _setValue(
    params: ICellRendererParams<
      string,
      number | number[],
      SectionBasedEntityListAGGridContext
    >
  ): void {
    const section = this._params.context.sections[
      this._params.colDef?.field as string
    ] as SingleDataSourceSection;

    if (!section) {
      return;
    }

    if (section.subtype === SectionSubType.SingleCustomDataSource) {
      this.value = this._customDataSourceStore.selectNormalizedDataSource(
        section.properties?.optionId as number
      )()[params.value as number]?.name;
    } else {
      this.value = this._dataSourceStore.selectNormalizedDataSource(
        section.properties?.optionId as number
      )()[params.value as number]?.name;
    }
  }
}
