import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import {
  WorkspaceScopeSelectComponent,
  WorkspaceSelectComponent,
} from '@fieldos/business';
import { SearchFieldComponent } from '@fieldos/components';
import { NavigationService } from '@fieldos/core';
import { RouterFacade } from '@fieldos/facades';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import {
  FuseNavigationItem,
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { TranslocoPipe } from '@ngneat/transloco';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filterEmpty } from '@fieldos/utils';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { NotificationsComponent } from 'app/layout/layouts/notifications/notifications.component';
import { Subject, map, takeUntil, tap } from 'rxjs';
import { LayoutService } from '../layout.service';
import { LayoutDrawerComponent } from './layout-drawer.component';
import { PriorityNotificationsComponent } from './priority-notifications/priority-notifications.component';
import { ToastsComponent } from './toasts/toasts.component';

@Component({
  selector: 'classic-layout',
  templateUrl: './classic.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FuseVerticalNavigationComponent,
    MatButtonModule,
    MatIconModule,
    LanguagesComponent,
    FuseFullscreenComponent,
    SearchFieldComponent,
    NotificationsComponent,
    UserComponent,
    RouterOutlet,
    AsyncPipe,
    TranslocoPipe,
    WorkspaceScopeSelectComponent,
    WorkspaceSelectComponent,
    LayoutDrawerComponent,
    ToastsComponent,
    PriorityNotificationsComponent,
  ],
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  navigation: FuseNavigationItem[];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    protected readonly layoutService: LayoutService,
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private readonly _detector: ChangeDetectorRef,
    _routerFacade: RouterFacade
  ) {
    _routerFacade
      .selectAllData()
      .pipe(
        map((params) => params['title']),
        takeUntilDestroyed(),
        filterEmpty(),
        tap((title) => this.layoutService.setTitle(title))
      )
      .subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: FuseNavigationItem[]) => {
        this.navigation = navigation;
        this._detector.detectChanges();
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
}
