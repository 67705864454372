import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private readonly _http = inject(HttpClient);

  get<T>(
    url: string,
    responseType: 'json' | 'blob' = 'json',
    params?: Record<string, string>,
    headers?: HttpHeaders
  ): Promise<T> {
    return lastValueFrom(
      this._http.get<T>(url, {
        responseType: responseType as 'json',
        params,
        headers,
      })
    );
  }

  post<T, K = unknown>(
    url: string,
    body: K,
    responseType: 'text' | 'json' = 'json'
  ): Promise<T> {
    return lastValueFrom(
      this._http.post<T>(url, body, {
        responseType: responseType as 'json',
      })
    );
  }

  put<T>(url: string, body: any): Promise<T> {
    return lastValueFrom(this._http.put<T>(url, body));
  }

  delete<T>(url: string): Promise<T> {
    return lastValueFrom(this._http.delete<T>(url));
  }

  patch<T>(url: string, body: any): Promise<T> {
    return lastValueFrom(this._http.patch<T>(url, body));
  }

  postWithBlobResponse<T>(url: string, body: any): Promise<T> {
    return lastValueFrom(
      this._http.post<T>(url, body, {
        responseType: 'blob' as 'json',
      })
    );
  }
}
