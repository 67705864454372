import { Injectable } from '@angular/core';
import { Contractor, ContractorFieldSubType } from '@fieldos/models';
import { FieldBasedEntitiesServiceBase } from './field-based-entities.service';

@Injectable({
  providedIn: 'root',
})
export class ContractorsDataService extends FieldBasedEntitiesServiceBase<Contractor> {
  constructor() {
    super('contractors', ContractorFieldSubType.ContractorType);
  }
}
