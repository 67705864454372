import { Route } from '@angular/router';
import { checklistsRoutes } from './checklists/checklists.routes';

export const administrationRoutes: Route[] = [
  {
    path: 'administration',
    children: [
      {
        path: 'configure-work-orders',
        loadComponent: () =>
          import('./work-order-designer/work-order-designer.component').then(
            (m) => m.WorkOrderDesignerComponent
          ),
        data: {
          title: 'menu.configure_work_order_sections',
        },
      },
      {
        path: 'request-templates',
        loadComponent: () =>
          import('./request-templates/request-templates.component').then(
            (m) => m.RequestTemplatesComponent
          ),
        data: {
          title: 'menu.request_templates',
        },
      },
      {
        path: 'status-graph/:entityType',
        loadComponent: () =>
          import('./status-graph-editor/status-graph-editor.component').then(
            (m) => m.StatusGraphEditorComponent
          ),
      },
      {
        path: 'configure-rights/:entityType',
        loadComponent: () =>
          import('./configure-rights/configure-rights.component').then(
            (m) => m.ConfigureRightsComponent
          ),
      },
      {
        path: 'location-designer',
        loadComponent: () =>
          import(
            './field-designers/location-designer/location-designer.component'
          ).then((m) => m.LocationDesignerComponent),
        data: {
          title: 'menu.location_field_designer',
        },
      },
      {
        path: 'asset-designer',
        loadComponent: () =>
          import(
            './field-designers/asset-designer/asset-designer.component'
          ).then((m) => m.AssetDesignerComponent),
        data: {
          title: 'menu.asset_field_designer',
        },
      },
      {
        path: 'user-designer',
        loadComponent: () =>
          import(
            './field-designers/user-designer/user-designer.component'
          ).then((m) => m.UserDesignerComponent),
        data: {
          title: 'menu.users_field_designer',
        },
      },
      {
        path: 'customer-designer',
        loadComponent: () =>
          import(
            './field-designers/customer-designer/customer-designer.component'
          ).then((m) => m.CustomerDesignerComponent),
        data: {
          title: 'menu.customer_field_designer',
        },
      },
      {
        path: 'contractor-designer',
        loadComponent: () =>
          import(
            './field-designers/contractor-designer/contractor-designer.component'
          ).then((m) => m.ContractorDesignerComponent),
        data: {
          title: 'menu.contractor_field_designer',
        },
      },
      {
        path: 'category-types',
        loadComponent: () =>
          import('./category-types-list/category-types-list.component').then(
            (m) => m.CategoryTypesComponent
          ),
        data: {
          title: 'menu.category_types',
        },
      },
      {
        path: 'category-types/:categoryType',
        loadComponent: () =>
          import('./category-types-list/category-types-list.component').then(
            (m) => m.CategoryTypesComponent
          ),
        data: {
          title: 'menu.category_types',
        },
      },
      {
        path: 'dpu-statuses',
        loadComponent: () =>
          import('./statuses/dpu-statuses/dpu-statuses.component').then(
            (m) => m.DpuStatusesComponent
          ),
        data: {
          title: 'menu.dpu_statuses',
        },
      },
      {
        path: 'reason-codes',
        loadComponent: () =>
          import('./reason-codes/reason.component').then(
            (m) => m.ReasonComponent
          ),
        data: {
          title: 'menu.reason-codes',
        },
      },
      {
        path: 'packaging',
        loadComponent: () =>
          import('./packaging/packaging.component').then(
            (m) => m.PackagingComponent
          ),
        data: {
          title: 'menu.packaging',
        },
      },
      {
        path: 'order-types',
        loadComponent: () =>
          import('./order-types/order-types.component').then(
            (m) => m.OrderTypesComponent
          ),
        data: {
          title: 'menu.order-types',
        },
      },
      {
        path: 'asset-statuses',
        loadComponent: () =>
          import('./statuses/asset-statuses/asset-statuses.component').then(
            (m) => m.AssetStatusesComponent
          ),
        data: {
          title: 'menu.asset-statuses',
        },
      },
      ...checklistsRoutes,
    ],
  },
];
