import { Asset, LocationModel } from '@fieldos/models';
import { Point } from 'ngx-panzoom/lib/types/point';

export const isAsset = (item: Asset | LocationModel): item is Asset =>
  (item as LocationModel).parentLocationId === undefined;

export const isPoint = (
  item: Asset | LocationModel | Point | undefined
): item is Point =>
  !!item && (item as Point).x !== undefined && (item as Point).y !== undefined;
