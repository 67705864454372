import { inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { WorkOrderRoutesDataService } from '@fieldos/data-services';
import { WorkOrderRoute } from '@fieldos/models';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import { AuthStore } from '../auth/auth.store';

export const RoutesStore = signalStore(
  {
    providedIn: 'root',
  },
  withEntities<WorkOrderRoute>(),
  withState({
    loading: true,
  }),
  withMethods((store, service = inject(WorkOrderRoutesDataService)) => ({
    async fetchAll() {
      try {
        const routes = await service.fetchAll();

        patchState(store, setAllEntities(routes));
        patchState(store, { loading: false });
      } catch (error) {
        patchState(store, { loading: false });
        console.error(error);
      }
    },
  })),
  withHooks({
    async onInit(store, authStore = inject(AuthStore)) {
      toObservable(authStore.isAuthenticated)
        .pipe(
          distinctUntilChanged(),
          filter((isAuthenticated) => isAuthenticated === true),
          tap(() => store.fetchAll()),
          takeUntilDestroyed()
        )
        .subscribe();
    },
  })
);
