import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FileUploadDataService } from '@fieldos/core/file-upload/file-upload.service';
import {
  Asset,
  AssetPlan,
  AssetPlanPin,
  LocationModel,
  PlanDetails,
} from '@fieldos/models';
import { Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlansDataService {
  private readonly _http = inject(HttpClient);
  private readonly _uploader = inject(FileUploadDataService);

  fetchLocations(): Observable<LocationModel[]> {
    return this._http.get<LocationModel[]>(`/plans/locations`);
  }

  fetchAssets(): Observable<Asset[]> {
    return this._http.get<Asset[]>(`/plans/assets`);
  }

  fetchPlans(): Observable<AssetPlan[]> {
    return this._http.get<AssetPlan[]>(`/maps`);
  }

  fetchPlanPins(planId: number): Observable<AssetPlanPin[]> {
    return this._http.get<AssetPlanPin[]>(`/maps/${planId}/pins`);
  }

  fetchPlanDetails(planId: number): Observable<PlanDetails> {
    return this._http.get<AssetPlan>(`/maps/${planId}`);
  }

  deletePlan(planId: number): Observable<void> {
    return this._http.delete<void>(`/maps/${planId}`);
  }

  createPlan(plan: File, name: string): Observable<AssetPlan> {
    return this._uploader.uploadFile(plan, 'maps', 'map').pipe(
      switchMap((fileModel) =>
        this._http.post<AssetPlan>('/maps', {
          name,
          url: fileModel.url,
          fileSize: fileModel.size,
          fileName: fileModel.name,
        })
      )
    );
  }

  savePlanPin(pin: AssetPlanPin, planId: number): Observable<AssetPlanPin> {
    if (pin.id) {
      return this._http.put<AssetPlanPin>(
        `/maps/${planId}/pins/${pin.id}`,
        pin
      );
    }

    return this._http.post<AssetPlanPin>(`/maps/${planId}/pins`, pin);
  }

  deletePlanPin(pin: AssetPlanPin, planId: number): Observable<void> {
    return this._http.delete<void>(`/maps/${planId}/pins/${pin.id}`);
  }
}
