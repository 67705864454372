import { Injectable } from '@angular/core';
import {
  NavigationBehaviorOptions,
  NavigationEnd,
  NavigationExtras,
  Params,
  Router,
  UrlTree,
} from '@angular/router';
import { routeSelectors } from '@fieldos/store/stores';

import { getRouterSelectors } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { Observable, filter, take, tap } from 'rxjs';
import { LayoutDrawerFacade } from './layout-drawer.facade';

export const {
  selectCurrentRoute,
  selectFragment,
  selectQueryParam,
  selectQueryParams,
  selectRouteData,
  selectRouteParam,
  selectRouteParams,
  selectUrl,
} = getRouterSelectors();

@Injectable({
  providedIn: 'root',
})
export class RouterFacade {
  constructor(
    private _store: Store,
    private readonly _router: Router,
    private readonly _layoutDrawerFacade: LayoutDrawerFacade
  ) {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => this._layoutDrawerFacade.close())
      )
      .subscribe();
  }

  public currentRoute = this._store.selectSignal(selectCurrentRoute);
  public fragment = this._store.selectSignal(selectFragment);
  public queryParams = this._store.selectSignal(selectQueryParams);
  public data = this._store.selectSignal(selectRouteData);
  public routeParams = this._store.selectSignal(selectRouteParams);
  public url = this._store.selectSignal(selectUrl);

  navigate(paths: string[], extra?: NavigationExtras): Promise<boolean> {
    return this._router.navigate(paths, extra);
  }

  navigateByUrl(
    url: string | UrlTree,
    extras?: NavigationBehaviorOptions
  ): Promise<boolean> {
    return this._router.navigateByUrl(url, extras);
  }

  navigateToRedirectUrl(): void {
    const queryParams = this.queryParams();
    const path = queryParams['redirectUrl'] || '/dashboard';

    this.navigateByUrl(path);
  }

  navigateAfterLogin(): void {
    if (
      location.pathname.startsWith('/sign-in') ||
      location.pathname.startsWith('/auth/return')
    ) {
      this.navigateToRedirectUrl();
    }
  }

  selectQueryParam(param: string): Observable<string | undefined> {
    return this._store.select(selectQueryParam(param)).pipe(take<any>(1));
  }

  selectRouteParam(param: string): Observable<string | undefined> {
    return this._store.select(selectRouteParam(param)).pipe(take(1));
  }

  selectAllData(): Observable<Params> {
    return this._store.select(routeSelectors.selectAllRoutesData);
  }
}
