<app-field-wrapper [errors]="errors">
  <mat-form-field
    class="fuse-mat-dense w-full"
    (click)="onFieldClick()"
    subscriptSizing="dynamic">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }

    @if (prefixIcon) {
      <mat-icon matPrefix class="mr-2" [svgIcon]="prefixIcon" />
    }

    <mat-chip-grid
      #chipGrid
      [class.ml-2]="!!prefixIcon"
      [disabled]="formCtrl.disabled">
      @for (option of selectedOptions(); track option.id) {
        <mat-chip-row
          [disabled]="formCtrl.disabled"
          (removed)="onRemoveOption($index)"
          color="primary">
          {{ option.name }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
      <input
        #input
        [formControl]="formCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipGrid"
        [placeholder]="placeholder ?? ''" />
    </mat-chip-grid>

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onOptionSelected($event)">
      @for (option of filteredOptions(); track option.id) {
        <mat-option [value]="option.id">
          {{ displayProperty ? $any(option)[displayProperty] : option.name }}
        </mat-option>
      }
    </mat-autocomplete>

    <div matSuffix>
      @if (loading) {
        <mat-progress-spinner color="primary" diameter="24" />
      } @else if (formCtrl.value) {
        <button
          mat-icon-button
          class="-mr-3"
          (click)="clearValue()"
          appClickStopPropagation>
          <mat-icon>clear</mat-icon>
        </button>
      }
    </div>
  </mat-form-field>
</app-field-wrapper>
