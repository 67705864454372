export type ReasonCodesModel = {
  id: number;
  translatableProperties: Record<string, {
    name: string;
    description: string;
  }>;
  properties: {
    code: string,
    requireValidation: boolean,
    roles: number[],
  };
  enabled: boolean;
}
