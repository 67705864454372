export type PackagingModel = {
  id: number;
  translatableProperties: Record<string, {
    name: string;
    description?: string;
  }>;
  properties: {
    code: string,
    sapValue: string,
    measurementUnit: string,
  };
  enabled: boolean;
}
