import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  effect,
  forwardRef,
  input,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { tap } from 'rxjs';
import { FieldError } from '../field-wrapper';
import { TextFieldComponent } from '../text-field/text-field.component';
import { CoordinateType } from './coordinate-field.models';
import { CoordinateValidator } from './coordinate.validator';

@Component({
  selector: 'app-coordinate-field',
  templateUrl: './coordinate-field.component.html',
  standalone: true,
  imports: [TextFieldComponent, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoordinateFieldComponent),
      multi: true,
    },
  ],
})
export class CoordinateFieldComponent implements ControlValueAccessor, OnInit {
  constructor() {
    this.formCtrl.valueChanges
      .pipe(
        tap((value) => {
          if (!this._onChange) {
            return;
          }

          this._onChange(value);
        })
      )
      .subscribe();

    effect(() => {
      if (this.isRequired()) {
        this.formCtrl.setValidators([Validators.required]);
      } else {
        this.formCtrl.clearValidators();
      }

      setTimeout(() => {
        this.formCtrl.updateValueAndValidity();
        this.formCtrl.markAsTouched();
      });
    });
  }

  @Input() public label: string = '';
  @Input() public coordinateType: CoordinateType;

  public readonly isRequired = input<boolean>();

  protected readonly formCtrl = new FormControl(0, {
    nonNullable: true,
  });

  private _onChange: (value: number) => void;
  private _onTouched: () => void;

  protected get errors(): FieldError[] {
    if (this.formCtrl.hasError('coordinate')) {
      return [{ message: 'form.errors.coordinate' }];
    }

    if (this.formCtrl.hasError('longitude')) {
      return [{ message: 'fields.gps_x_coordinates.error' }];
    }

    if (this.formCtrl.hasError('latitude')) {
      return [{ message: 'fields.gps_y_coordinates.error' }];
    }

    return [];
  }

  ngOnInit(): void {
    this.formCtrl.setValidators(CoordinateValidator(this.coordinateType));
  }

  writeValue(value: number): void {
    this.formCtrl.setValue(value);
  }

  registerOnChange(fn: (value: number) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }
}
