import { computed } from '@angular/core';
import { Field, FieldSubTypes } from '@fieldos/models';
import { FieldRights } from '@fieldos/models/fields/field-permissions';
import { normalize } from '@fieldos/utils';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';

interface FieldsFormState {
  entityId: number | undefined;
  fields: Field[];
  fieldsMap: Record<string, Field>;
  fieldValidityMap: Record<string, boolean>;
  lastUpdatedField: Field | null;
  permissions: Record<string, FieldRights>;
}

const initialState: FieldsFormState = {
  entityId: undefined,
  fields: [],
  fieldValidityMap: {},
  fieldsMap: {},
  lastUpdatedField: null,
  permissions: {},
};

export const FieldsFormStore = signalStore(
  withState<FieldsFormState>({
    ...initialState,
  }),
  withComputed((store) => ({
    topLevelFields: computed(() =>
      store.fields().filter((field) => !field.properties?.containerId)
    ),
    isEditing: computed(() => !!store.entityId()),
    invalid: computed(() =>
      Object.values(store.fieldValidityMap()).some((v) => !v)
    ),
    values: computed(() => Object.values(store.fieldsMap())),
  })),
  withMethods((store) => ({
    setPermissions(permissions: Record<string, FieldRights>) {
      patchState(store, { permissions });
    },
    initialize(entityId: string | undefined, fields: Field[]) {
      patchState(store, {
        ...initialState,
        entityId: entityId ? +entityId : undefined,
        fields,
        fieldValidityMap: fields
          .filter((e) => e.subtype !== FieldSubTypes.TwoColumnContainer)
          .reduce((acc, field) => ({ ...acc, [field.id]: false }), {}),
        fieldsMap: normalize(fields, 'id'),
      });
    },
    selectFieldById(fieldId: string) {
      return computed(() => store.fieldsMap()[fieldId]);
    },
    updateFieldValue(fieldId: string, value: unknown, isValid: boolean) {
      const updatedField = store.fieldsMap()[fieldId];
      updatedField.value = value;
      patchState(store, {
        lastUpdatedField: updatedField,
        fieldsMap: {
          ...store.fieldsMap(),
          [fieldId]: updatedField,
        },
        fieldValidityMap: {
          ...store.fieldValidityMap(),
          [fieldId]: isValid,
        },
      });
    },
  }))
);
