import { InjectionToken } from '@angular/core';
import { SectionSubType, SectionType } from '@fieldos/models';

export const SECTION_SUBTYPE_TYPE_MAP = new InjectionToken<
  Record<SectionSubType, SectionType>
>('SECTION_SUBTYPE_TYPE_MAP', {
  factory: () => ({
    [SectionSubType.Asset]: 'standard',
    [SectionSubType.Assignees]: 'standard',
    [SectionSubType.Container]: 'custom',
    [SectionSubType.Customer]: 'standard',
    [SectionSubType.CustomerContract]: 'standard',
    [SectionSubType.SingleCustomDataSource]: 'custom',
    [SectionSubType.SingleStandardDataSource]: 'custom',
    [SectionSubType.Description]: 'standard',
    [SectionSubType.DueBy]: 'standard',
    [SectionSubType.EstimatedDuration]: 'standard',
    [SectionSubType.Identifier]: 'standard',
    [SectionSubType.Location]: 'standard',
    [SectionSubType.Media]: 'standard',
    [SectionSubType.Parts]: 'standard',
    [SectionSubType.Priority]: 'standard',
    [SectionSubType.Rating]: 'standard',
    [SectionSubType.Signature]: 'standard',
    [SectionSubType.SolvingDescription]: 'standard',
    [SectionSubType.StartBy]: 'standard',
    [SectionSubType.Status]: 'standard',
    [SectionSubType.Teams]: 'standard',
    [SectionSubType.Title]: 'standard',
    [SectionSubType.Type]: 'standard',
    [SectionSubType.Documents]: 'standard',
    [SectionSubType.Supervisors]: 'standard',
    [SectionSubType.ShowInformation]: 'custom',
    [SectionSubType.ResponsiblePerson]: 'standard',
    [SectionSubType.StartingLocation]: 'standard',
    [SectionSubType.LoadByDate]: 'standard',
    [SectionSubType.Set]: 'standard',
    [SectionSubType.ConfirmLocation]: 'standard',
    [SectionSubType.DPU]: 'standard',
    [SectionSubType.ShowSealWithConfirmation]: 'standard',
    [SectionSubType.InputSealWithConfirmation]: 'standard',
    [SectionSubType.Seal]: 'standard',
    [SectionSubType.PackagingLoaded]: 'standard',
    [SectionSubType.PackagingUnloaded]: 'standard',
    [SectionSubType.PackagingLeft]: 'standard',
    [SectionSubType.ConfirmEmtpy]: 'standard',
  }),
});
