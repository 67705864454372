import { Directive, HostListener, Input } from '@angular/core';
import { MouseCursorElementService } from './mouse-cursor-element.service';

@Directive({
  selector: '[appMouseCursorElement]',
  standalone: true,
})
export class MouseCursorElementDirective<T> {
  constructor(private _service: MouseCursorElementService) {}

  @Input() public appMouseCursorElement: T | undefined;

  @HostListener('click', ['$event'])
  private _onClick(event: MouseEvent): void {
    this._service.start(event, this.appMouseCursorElement);
  }
}
