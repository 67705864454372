@if (show) {
  <div
    class="w-full h-full top-0 left-0 bg-slate-700 bg-opacity-12 absolute z-99 shadow">
    <div class="relative h-full w-full">
      <div
        class="absolute top-8 left-1/2 transform -translate-x-1/2 p-4 bg-card max-w-[75%]">
        <h4 class="text-xl font-semibold">
          {{ 'box_confirm_dialog.title' | transloco }}
        </h4>
        <span>
          {{ 'box_confirm_dialog.message' | transloco }}
        </span>

        <div class="flex gap-2 justify-center mt-2">
          <button mat-flat-button (click)="close(false)">
            {{ 'common.no' | transloco }}
          </button>
          <button mat-flat-button (click)="close(true)" color="primary">
            {{ 'common.yes' | transloco }}'
          </button>
        </div>
      </div>
    </div>
  </div>
}
