import { Pipe, PipeTransform } from '@angular/core';
import { fromServerDate, isValidDate } from '@fieldos/utils';

@Pipe({
  name: 'fromServerDate',
  standalone: true,
})
export class FromServerDatePipe implements PipeTransform {
  transform(value: string): Date | null {
    if (!value) {
      return null;
    }

    const date = fromServerDate(value);
    if (date && isValidDate(date)) {
      return date;
    }
    return null;
  }
}
