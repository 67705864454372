import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthStore } from '@fieldos/store/auth';
import { filter, map } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (
  route,
  state
) => {
  const router: Router = inject(Router);

  const store = inject(AuthStore);

  const isAuthenticated = store.isAuthenticated;

  // Check the authentication status
  return toObservable(isAuthenticated).pipe(
    filter((authenticated) => authenticated !== null),
    map((authenticated) => {
      // If the user is authenticated...
      if (authenticated) {
        return router.parseUrl('');
      }

      // Allow the access
      return true;
    })
  );
};
