import { FieldBase } from './field';
import {
  FieldCommonPropertiesBase,
  FieldProperties,
  FieldSimpleTranslatableProperties,
} from './properties';

export interface GeofencingFieldProperties extends FieldCommonPropertiesBase {
  defaultStatus: boolean;
}

export type GeofencingField = FieldBase &
  FieldProperties<FieldSimpleTranslatableProperties, GeofencingFieldProperties>;
