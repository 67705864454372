@if (commonProperties) {
  <div class="flex flex-col w-full">
    <app-multiple-select
      class="block mb-2"
      [formControl]="personelCtrl"
      [options]="availableAssignees()"
      (valueRemoved)="onAssigneeRemoved($event)"
      (valueSelected)="onAssigneeSelected($event)"
      label="{{
        'work_orders.sections.assignees.select_personel' | transloco
      }}" />

    @for (assigneeForm of assigneesFormGroups; track $index) {
      @if (assigneeForm.value.id) {
        <app-sections-form-assignee-form
          [form]="assigneeForm"
          [properties]="commonProperties"
          (removeAssignee)="onRemoveAssignee($index)" />
      }
    }
  </div>
}
