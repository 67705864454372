import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { IFloatingFilterParams } from 'ag-grid-community';
import { FloatingFilterLayoutComponent } from '../../layouts';
import { DateFilterComponent } from '../date-filter/date-filter.component';

@Component({
  selector: 'app-date-floating-filter',
  templateUrl: './date-floating-filter.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslocoModule,
    DatePipe,
    FloatingFilterLayoutComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        margin-top: auto;
        margin-bottom: auto;
      }
    `,
  ],
})
export class DateFloatingFilterComponent implements IFloatingFilterAngularComp {
  protected readonly isParentFilterActive = signal<boolean>(false);

  protected readonly searchFormCtrl = new FormControl({
    value: '',
    disabled: true,
  });

  private readonly _params = signal<IFloatingFilterParams<
    never,
    string,
    never
  > | null>(null);

  agInit(params: IFloatingFilterParams<never, string, never>): void {
    this._params.set(params);
  }

  onParentModelChanged(parentModel: string): void {
    this.searchFormCtrl.setValue(parentModel, {});
    this.isParentFilterActive.set(!!parentModel);
  }

  protected onClear(): void {
    this.isParentFilterActive.set(false);

    this._params()?.parentFilterInstance((instance) => {
      const parentFilterInstance = instance as DateFilterComponent;

      parentFilterInstance.clearValue();
    });
  }
}
