import { ToastMessage, ToastMessageButton } from '@fieldos/models';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { Guid } from 'guid-typescript';

interface ToastState {
  toasts: ToastMessage[];
}

export const ToastStore = signalStore(
  { providedIn: 'root' },
  withState<ToastState>({
    toasts: [],
  }),
  withMethods((store) => ({
    showSuccessToast(
      message: string,
      duration = 5000,
      buttons: ToastMessageButton[] = []
    ): void {
      const toast: ToastMessage = {
        message,
        duration,
        type: 'success',
        name: Guid.create().toString(),
        showCloseButton: false,
        actions: buttons,
      };

      this.show(toast);
    },
    showWarnToast(
      message: string,
      duration = 5000,
      buttons: ToastMessageButton[] = []
    ): void {
      const toast: ToastMessage = {
        message,
        duration,
        type: 'warn',
        name: Guid.create().toString(),
        showCloseButton: false,
        actions: buttons,
      };

      this.show(toast);
    },
    showErrorToast(
      message: string,
      duration = 5000,
      buttons: ToastMessageButton[] = []
    ): void {
      const toast: ToastMessage = {
        message,
        duration,
        type: 'error',
        name: Guid.create().toString(),
        showCloseButton: false,
        actions: buttons,
      };

      this.show(toast);
    },
    showInfoToast(
      message: string,
      duration = 5000,
      buttons: ToastMessageButton[] = []
    ): void {
      const toast: ToastMessage = {
        message,
        duration,
        type: 'info',
        name: Guid.create().toString(),
        showCloseButton: false,
        actions: buttons,
      };

      this.show(toast);
    },
    removeToast(toastName: string): void {
      patchState(store, {
        toasts: store.toasts().filter((toast) => toast.name !== toastName),
      });
    },
    show(toast: ToastMessage): void {
      patchState(store, {
        toasts: [...store.toasts(), toast],
      });

      setTimeout(() => this.removeToast(toast.name), toast.duration);
    },
  }))
);
