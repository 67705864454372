import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { FileModel, MimeType } from '@fieldos/models';
import { isArray } from 'lodash';

export const minLengthArrayValidator =
  (minLength = 0): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    if (
      control &&
      isArray(control.value) &&
      control.value.length >= minLength &&
      control instanceof FormArray
    ) {
      const childrenHaveErrors =
        control.controls.some((child) => child.invalid) &&
        control.controls.length <= minLength;
      return childrenHaveErrors
        ? {
            arrayMinLength: {
              minLength,
              actualLength: control.value.length,
            },
          }
        : null;
    }

    if (
      control instanceof FormControl &&
      isArray(control.value) &&
      control.value.length < minLength
    ) {
      return {
        arrayMinLength: {
          minLength,
          actualLength: control.value.length,
        },
      };
    }

    if (!control.value) {
      return {
        arrayMinLength: {
          minLength,
          actualLength: 0,
        },
      };
    }

    if (!control || control.value.length >= minLength) {
      return null;
    }

    return {
      arrayMinLength: {
        minLength,
        actualLength: control.value.length,
      },
    };
  };

export const RequireIfFieldHasValue =
  (field: string): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const parent = control.parent;

    if (parent instanceof FormGroup) {
      const fieldCtrl = parent.get(field) as FormControl;
      if (fieldCtrl && !fieldCtrl.value) {
        return {
          requireIf: true,
        };
      }
    }

    return null;
  };

export const fileMimeTypeValidator =
  (mimeType: MimeType[]): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const file: FileModel = control.value;
    if (file && !mimeType.includes(file.mimeType)) {
      return { invalidMimeType: true };
    }
    return null;
  };

export const passwordValidator =
  (passwordCtrlName: string, confirmPasswordCtrlName: string) =>
  (control: AbstractControl): ValidationErrors | null => {
    const form = control as FormGroup;

    if (form instanceof FormGroup) {
      const passwordCtrl = form.get(passwordCtrlName) as FormControl;
      const confirmPasswordCtrl = form.get(
        confirmPasswordCtrlName
      ) as FormControl;

      if (
        passwordCtrl &&
        confirmPasswordCtrl &&
        passwordCtrl.value !== confirmPasswordCtrl.value
      ) {
        return {
          passwordMismatch: true,
        };
      }
    }

    return null;
  };
