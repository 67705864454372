<div class="flex flex-col gap-4">
  <div class="flex justify-between items-center">
    <span class="text-base font-medium">
      {{
        'work_orders.sections.dpu.selected'
          | transloco: { count: this.form.value?.length }
      }}
    </span>
    <button
      mat-flat-button
      [disabled]="!rights()?.canEdit"
      (click)="onAddDPUs()"
      color="primary">
      {{ 'work_orders.dpus.add' | transloco }}
    </button>
  </div>

  <mat-divider />

  @for (dpuId of form.value || []; track dpuId) {
    <app-dpu-section-item
      [dpuId]="dpuId"
      [canEdit]="rights()?.canEdit || false"
      (remove)="onRemove($index)" />

    @if (!$last) {
      <mat-divider />
    }
  }
</div>
