import { SelectionModel } from '@angular/cdk/collections';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RolesDataService } from '@fieldos/data-services';
import { DomainEntityBase } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { IFilterAngularComp } from 'ag-grid-angular';
import {
  AgPromise,
  IDoesFilterPassParams,
  IFilterParams,
} from 'ag-grid-community';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  map,
  startWith,
} from 'rxjs';

@Component({
  selector: 'app-role-set-filter',
  templateUrl: './role-set-filter.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    AsyncPipe,
    TranslocoModule,
  ],
  styles: [
    `
      :host {
        max-height: 500px;
        @apply p-4 block;
      }
    `,
  ],
})
export class RoleSetFilterComponent implements IFilterAngularComp {
  constructor() {
    this.options$ = combineLatest([
      this.searchFormCtrl.valueChanges.pipe(startWith('')),
      this._options$.asObservable(),
    ]).pipe(
      map(([searchFilter, options]) =>
        options.filter((option) =>
          option.name.toLowerCase().includes(searchFilter.toLowerCase())
        )
      )
    );
  }

  protected options$: Observable<DomainEntityBase[]>;
  protected readonly searchFormCtrl = new FormControl('', {
    nonNullable: true,
  });

  protected readonly selection = new SelectionModel<number>(true);
  protected get isSelectAllIntermediate(): boolean {
    return (
      this.selection.selected.length !== 0 &&
      this.selection.selected.length < this._options$.value.length
    );
  }

  protected get isSelectAllChecked(): boolean {
    return this.selection.selected.length === this._options$.value.length;
  }

  private _params!: IFilterParams;

  private readonly _roleDataService = inject(RolesDataService);
  private readonly _options$ = new BehaviorSubject<DomainEntityBase[]>([]);

  agInit(params: IFilterParams<Record<string, any>, unknown>): void {
    this._params = params;

    this._roleDataService.fetch().then((roles) => {
      const options = Object.values(roles);
      this._options$.next(options);
      this.selection.setSelection(...options.map((e) => e.id));
    });
  }

  isFilterActive(): boolean {
    return true;
  }

  doesFilterPass(
    params: IDoesFilterPassParams<Record<string, unknown>>
  ): boolean {
    if (this._params.colDef.field) {
      const columnValue = params.data[this._params.colDef.field] as number;
      return this.selection.isSelected(columnValue);
    }

    return false;
  }

  getModel(): number[] {
    return this.selection.selected;
  }

  setModel(model: number[]): void | AgPromise<void> {
    this.selection.setSelection(...model);
  }

  getModelAsString?(model: number[]): string {
    return this._options$.value
      .filter((e) => model.includes(e.id))
      .map((e) => e.name)
      .join(', ');
  }

  protected onSelectAllChange(event: MatCheckboxChange): void {
    if (this.isSelectAllIntermediate || this.selection.selected.length === 0) {
      this.selection.setSelection(...this._options$.value.map((e) => e.id));
    } else {
      this.selection.clear();
    }
    this._onFilterChange();
  }

  protected onOptionSelected(option: DomainEntityBase): void {
    this.selection.toggle(option.id);
    this._onFilterChange();
  }

  private _onFilterChange(): void {
    this.setModel(this.selection.selected);
    this._params.filterChangedCallback();
  }
}
