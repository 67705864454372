import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';

import {
  ExceptionDialogsFacade,
  ExceptionTargetType,
} from '@fieldos/business/exceptions';
import { HasPermissionDirective } from '@fieldos/directives';
import { WorkOrder } from '@fieldos/models';
import { ToastStore } from '@fieldos/store/toast.store';
import { TranslocoModule } from '@ngneat/transloco';
import { ChatWindowComponent } from '../../../@components/chat-window';
import { SectionsFormComponent, SectionsFormStore } from '../sections-form';

@Component({
  selector: 'app-sections-form-drawer-content',
  templateUrl: './sections-form-drawer-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    SectionsFormComponent,
    TranslocoModule,
    MatSidenavModule,
    ChatWindowComponent,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    HasPermissionDirective,
  ],
})
export class SectionsFormDrawerContentComponent {
  @Input({ required: true }) public canPin = true;
  @Input() public mode: MatDrawerMode = 'over';

  @Output() public readonly pinClick = new EventEmitter<void>();
  @Output() public readonly closeClick = new EventEmitter<void>();
  @Output() public readonly saveClick = new EventEmitter<void>();
  @Output() public readonly chatCloseClick = new EventEmitter<void>();

  @Input() public set chatOpened(chatOpened: boolean) {
    this.chatOpen = chatOpened;
  }

  protected chatOpen = false;

  protected readonly store = inject(SectionsFormStore);

  private readonly _assignExceptionFacade = inject(ExceptionDialogsFacade);
  private readonly _toast = inject(ToastStore);

  protected async openAddReasonCodeDialog(): Promise<void> {
    const entityId = this.store.entity()?.id as number;
    const entity = this.store.entity() as WorkOrder;

    const shouldRefresh = await this._assignExceptionFacade.openDialog({
      targetId: entityId,
      targetType: ExceptionTargetType.WorkOrder,
      reasonCodeName: entity.isReasonCodeLocked || '',
      parentId: undefined,
      scopeId: entity.scopeId,
    });

    if (shouldRefresh) {
      this.store.reloadEntity();
    }
  }

  protected async downloadDPUCodes(): Promise<void> {
    const entity = this.store.entity();

    if (entity) {
      try {
        await this._assignExceptionFacade.downloadDPUCodes(entity.id);
      } catch (error) {
        console.error('Error downloading DPU codes', error);
      }
    }
  }

  protected async sendDPUCodesEmail(): Promise<void> {
    const entity = this.store.entity();

    if (entity) {
      try {
        await this._assignExceptionFacade.sendDPUCodesEmail(entity.id);
        this._toast.showSuccessToast('assign_reason_code.email_sent');
      } catch (error) {
        console.error('Error sending DPU codes email', error);
      }
    }
  }
}
