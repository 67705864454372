import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ShowInformationSectionValue } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { IFilterAngularComp } from 'ag-grid-angular';
import {
  AgPromise,
  IDoesFilterPassParams,
  IFilterParams,
} from 'ag-grid-community';
import { TextFieldComponent } from 'app/@components/fields';
import { debounceTime, tap } from 'rxjs';

@Component({
  selector: 'app-text-filter',
  templateUrl: './text-filter.component.html',
  standalone: true,
  imports: [TextFieldComponent, TranslocoModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        max-height: 500px;
        @apply p-4 block;
      }
    `,
  ],
})
export class TextFilterComponent implements IFilterAngularComp {
  constructor() {
    this.searchFormCtrl.valueChanges
      .pipe(
        takeUntilDestroyed(),
        debounceTime(200),
        tap(() => this._params()?.filterChangedCallback())
      )
      .subscribe();
  }

  protected readonly searchFormCtrl = new FormControl('', {
    nonNullable: true,
  });

  private readonly _params = signal<IFilterParams<string, never> | null>(null);

  onFloatingFilterChanged(value: string): void {
    this.searchFormCtrl.setValue(value, { emitEvent: false });
    this._params()?.filterChangedCallback();
  }

  agInit(params: IFilterParams<string, never>): void {
    this._params.set(params);
  }

  isFilterActive(): boolean {
    return true;
  }

  getModel() {
    return this.searchFormCtrl.value;
  }

  setModel(model: string): void | AgPromise<void> {
    this.searchFormCtrl.setValue(model);
  }

  refresh?(newParams: IFilterParams<string, never>): boolean {
    this._params.set(newParams);
    return true;
  }

  doesFilterPass(
    params: IDoesFilterPassParams<Record<string, unknown>>
  ): boolean {
    if (this._params()?.colDef.field) {
      let columnValue = params.data[this._params()?.colDef.field as string] as
        | string
        | number
        | ShowInformationSectionValue;

      if (typeof columnValue === 'string') {
        columnValue = columnValue || '';
      }

      if (typeof columnValue === 'number') {
        columnValue = columnValue?.toString() || '';
      }

      if (
        columnValue &&
        typeof columnValue !== 'number' &&
        typeof columnValue !== 'string'
      ) {
        columnValue = columnValue.value || '';
      }

      return (columnValue || '')
        .toLowerCase()
        .includes(this.searchFormCtrl.value.toLowerCase());
    }

    return false;
  }
}
