<div
  class="graph-designer-boundary relative h-full w-full"
  appGraphDesignerRoot>
  <div
    class="flex justify-between h-full p-4 overflow-auto gap-16"
    (scroll)="onScroll($event)">
    @for (nodes of nodeLevels; track $index) {
      <div class="flex flex-col justify-between gap-8">
        <div></div>

        @for (
          node of nodes;
          track index;
          let last = $last;
          let index = $index
        ) {
          <span
            [id]="node.id"
            [class.pb-4]="last && index !== 0"
            class="cursor-pointer inline-block"
            (mouseenter)="onMouseEnter(node.id)"
            (mouseleave)="onMouseLeave()"
            appGraphDesignerNode>
            <ng-container
              [ngTemplateOutlet]="nodeTemplate"
              [ngTemplateOutletContext]="$any({ $implicit: node })" />
          </span>
        }

        <div></div>
      </div>
    }
  </div>
</div>
