import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Injector, inject } from '@angular/core';
import { AuthStore } from '@fieldos/store/index';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, catchError, throwError } from 'rxjs';
import { TokenService } from './token.service';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  let cloned = req.clone();

  if (req.url.startsWith('./assets')) {
    return next(cloned);
  }

  const _oauthHeaderName = `OAuth-ClientId`;
  const _authorizationHeaderName = `Authorization`;
  const _injector = inject(Injector);

  const _authStore = _injector.get(AuthStore);

  const _tokenService = inject(TokenService);
  const accessToken = _tokenService.accessToken;
  const clientId = _tokenService.clientId;
  const transloco = inject(TranslocoService);

  let headers = req.headers;

  if (clientId) {
    headers = headers.set(_oauthHeaderName, clientId);
  }

  if (accessToken) {
    headers = headers.set(
      _authorizationHeaderName,
      `Bearer ${accessToken.accessToken}`
    );
  }

  headers = headers.set('lang', transloco.getActiveLang());

  cloned = req.clone({
    url: `${cloned.url}`,
    headers,
  });

  return next(cloned).pipe(
    catchError((error: HttpErrorResponse) => {
      // Catch "401 Unauthorized" responses
      if (error instanceof HttpErrorResponse && error.status === 401) {
        // Sign out
        _authStore.logout();
      }
      return throwError(() => error);
    })
  );
};
