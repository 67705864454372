import { Route } from '@angular/router';

export const errorRoutes: Route[] = [
  {
    path: '404',
    loadComponent: () =>
      import('./error-404/error-404.component').then(
        (m) => m.Error404Component
      ),
  },
];
