export enum CommonFieldSubType {
  SingleSelect = 'customsingleselect',
  TwoColumnContainer = 'twocolumncontainer',
  Name = 'name',
  Text = 'custominputtext',
  UniqueCode = 'code',
  QRCode = 'qrcode',
  Active = 'active',
  Photos = 'photos',
  Address = 'address',
  Location = 'location',
  Contractor = 'contractor',
  User = 'user',
  GPSXCoordinates = 'gpscoordinatesx',
  GPSYCoordinates = 'gpscoordinatesy',
  GPSCoordinates = 'gpscoordinates',
  GPSPositionOnMap = 'gpscoordinatesonmap',
  Email = 'customemail',
  Geofencing = 'geofencing',
  Teams = 'teams',
  CustomMultipleSelect = 'custommultipleselect',
  Description = 'description',
  Assets = 'assets',
  AddressDetails = 'addressdetails',
  FinancialInfo = 'financialinfo',
  BankInfo = 'bankinfo',
  ApplicationAdminEmails = 'adminapplicationemail',
  Contracts = 'contractorcontracts',
  TimeSlots = 'timeslots',
}

export enum AssetFieldSubType {
  AssetType = 'assettype',
  AssetStatus = 'assetstatus',
  AssetMasterConfirm = 'assetmasterconfirm',
}

export enum LocationFieldSubType {
  LocationType = 'locationtype',
  LocationStatus = 'locationstatus',
}

export enum UserFieldSubType {
  UserEmail = 'email',
  Role = 'role',
  GivenName = 'givenname',
  FamilyName = 'familyname',
  UserType = 'usertype',
  UserStatus = 'userstatus',
  Password = 'password',
  AccountType = 'acctype',
  Assigned = 'assigned',
}

export enum CustomerFieldSubType {
  CustomerType = 'customertype',
  CustomerStatus = 'customerstatus',
}

export enum ContractorFieldSubType {
  ContractorType = 'contractortype',
  ContractorStatus = 'contractorstatus',
}

export const FieldSubTypes = {
  ...CommonFieldSubType,
  ...LocationFieldSubType,
  ...AssetFieldSubType,
  ...UserFieldSubType,
  ...CustomerFieldSubType,
  ...ContractorFieldSubType,
};

export type FieldSubType =
  | CommonFieldSubType
  | LocationFieldSubType
  | AssetFieldSubType
  | UserFieldSubType
  | CustomerFieldSubType
  | ContractorFieldSubType;
