<app-header-content-layout>
  <ng-container toolbar>
    <div class="flex-grow"></div>

    <button
      *appHasPermission="[taxonomyPermission()]"
      mat-flat-button
      (click)="onNewTaxonomy()"
      color="primary">
      {{ 'common.add' | transloco }}
    </button>
  </ng-container>

  @if (store.loading()) {
    <div class="flex items-center justify-center w-full h-full">
      <mat-spinner color="primary" diameter="32" />
    </div>
  } @else {
    @if (store.items().length) {
      <div class="relative flex flex-grow flex-shrink min-h-0">
        <app-data-grid
          gridClass="flex-grow"
          class="flex-grow flex flex-col"
          [columns]="store.columns()"
          [data]="store.listItems()"
          defaultSizeToFit
          rowSelection="multiple"
          showSelectionColumn />
      </div>
    }
    @if (!store.items().length) {
      <div class="flex items-center justify-center w-full h-full">
        {{ 'taxonomies.noItemsFound' | transloco }}
      </div>
    }
  }
</app-header-content-layout>
