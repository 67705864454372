import { inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { AuthDataService } from '@fieldos/data-services';
import { Claim } from '@fieldos/models';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import { AuthStore } from '..';

interface ClaimState {
  claims: Claim[];
  loaded: boolean;
}

export const ClaimsStore = signalStore(
  { providedIn: 'root' },
  withState<ClaimState>(() => ({ claims: [], loaded: false })),
  withMethods(
    (
      store,
      authService = inject(AuthDataService),
      authStore = inject(AuthStore)
    ) => ({
      fetchClaims: async () => {
        const roleId = authStore.roleId() as number;
        try {
          const claims = await authService.fetchClaims(roleId);
          patchState(store, {
            claims,
            loaded: true,
          });
        } catch (error) {
          console.error(error);
        }
      },
      hasClaim: (claim: Claim) => !!store.claims().find((c) => c === claim),
    })
  ),
  withHooks({
    async onInit(store, authStore = inject(AuthStore)) {
      toObservable(authStore.isAuthenticated)
        .pipe(
          distinctUntilChanged(),
          filter((isAuthenticated) => isAuthenticated === true),
          tap(() => store.fetchClaims()),
          takeUntilDestroyed()
        )
        .subscribe();
    },
  })
);
