<app-field-wrapper class="w-full" [errors]="errors">
  <mat-form-field
    class="fuse-mat-dense w-full"
    [autofocus]="false"
    subscriptSizing="dynamic">
    @if (label) {
      <mat-label>
        {{ label }}
      </mat-label>
    }

    @if (prefixIcon) {
      <mat-icon matPrefix class="mr-2" [svgIcon]="prefixIcon" />
    }

    <input
      matInput
      [formControl]="formCtrl"
      [matAutocomplete]="auto"
      (input)="onSearchChange($event)"
      placeholder="{{ placeholder }}"
      type="text" />

    <mat-autocomplete #auto [displayWith]="displayFn">
      @for (option of filteredOptions(); track option.id) {
        <mat-option [value]="option" (click)="$event.stopPropagation()">
          {{ option.name | transloco }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</app-field-wrapper>
