import { inject } from '@angular/core';
import { CategoryTypeFieldsDataService } from '@fieldos/data-services';
import { CategoryType, Field } from '@fieldos/models';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';

interface CategoryTypeFieldsState {
  fieldsMap: Record<CategoryType, Record<number, Field[]>>;
  loading: boolean;
}

export const CategoryTypeFieldsStore = signalStore(
  { providedIn: 'root' },
  withState<CategoryTypeFieldsState>({
    fieldsMap: {
      locations: {},
      assets: {},
      users: {},
      customers: {},
      contractors: {},
    },
    loading: false,
  }),
  withMethods((store) => ({
    selectFields(categoryType: CategoryType, categoryTypeId: number): Field[] {
      const categoryTypeFields = store.fieldsMap()[categoryType];
      return categoryTypeFields[categoryTypeId] || [];
    },
  })),
  withHooks({
    async onInit(store, dataService = inject(CategoryTypeFieldsDataService)) {
      patchState(store, { loading: true });

      try {
        const [
          locationFields,
          assetFields,
          userFields,
          customerFields,
          contractorFields,
        ] = await Promise.all([
          dataService.fetchAllFields('locations'),
          dataService.fetchAllFields('assets'),
          dataService.fetchAllFields('users'),
          dataService.fetchAllFields('customers'),
          dataService.fetchAllFields('contractors'),
        ]);

        patchState(store, {
          loading: false,
          fieldsMap: {
            locations: locationFields,
            assets: assetFields,
            users: userFields,
            customers: customerFields,
            contractors: contractorFields,
          },
        });
      } catch (error) {
        patchState(store, { loading: false });
      }
    },
  })
);
