<div [class.hidden]="store.draggedFieldId() === field().id" class="relative">
  @if (!field().properties.arePropertiesReadonly) {
    <mat-icon
      class="absolute top-2 right-8 scale-75 cursor-pointer hover:text-blue-500"
      (click)="onEdit()"
      color="primary"
      svgIcon="mat_solid:edit"></mat-icon>
    <mat-icon
      class="absolute top-2 right-2 scale-75 cursor-pointer hover:text-red-500"
      (click)="onDelete(field().id)"
      color="warn"
      svgIcon="mat_solid:delete"></mat-icon>
  }
  <div
    [class.bg-slate-100]="store.selectedFieldId() === field().id"
    [class.dark:bg-slate-700]="store.selectedFieldId() === field().id"
    class="h-18 flex items-center justify-center border bg-card">
    <h6 class="font-semibold text-md">
      @if (field().properties.arePropertiesReadonly) {
        {{ field().translatableProperties[activeLanguage()].label | transloco }}
      } @else {
        {{ 'fields.' + field().subtype + '.title' | transloco }}
      }
    </h6>
  </div>
</div>
