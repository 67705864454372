<div class="flex items-center justify-center w-full">
  @for (action of actions; track action.id) {
    <button
      mat-icon-button
      [color]="action | actionsCellRendererColor: data"
      (click)="onButtonClick(action.id)">
      <mat-icon [svgIcon]="action | actionsCellRendererIcon: data" />
    </button>
  }
</div>
