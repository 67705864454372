import { NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  booleanAttribute,
  computed,
  inject,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileModel, MimeType } from '@fieldos/models';
import { FileSizePipe } from '@fieldos/pipes';
import { EXTENSION_MIME_TYPE_MAP } from '@fieldos/providers';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { TranslocoModule } from '@ngneat/transloco';
import { saveAs } from 'file-saver';
import {
  ImageTransformerComponent,
  ImageTransformerModule,
} from '../image-transformer';
import { FileUploaderDropzoneDirective } from './file-uploader-dropzone.directive';
import { FileUplaoderImageUrlPipe } from './file-uploader-image-url.pipe';
import { FileUploaderIsImagePipe } from './file-uploader-is-image.pipe';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    FileUplaoderImageUrlPipe,
    FileUploaderIsImagePipe,
    MatIconModule,
    MatButtonModule,
    FileUploaderDropzoneDirective,
    FuseScrollbarDirective,
    FileSizePipe,
    MatProgressSpinnerModule,
    TranslocoModule,
    ImageTransformerModule,
    NgStyle,
  ],
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
      }
    `,
  ],
})
export class FileUploaderComponent implements AfterViewInit {
  @Input() public accept: MimeType[] | '*' = '*';
  @Input() public maxFileSize?: number;
  @Input() public maxNumberOfFiles?: number;
  @Input() public placeholder = 'Click or drop file';
  @Input() public useExternalAdd = false;
  @Input() public set files(files: FileModel[]) {
    this.innerFiles.set([...files]);
  }
  @Input({ transform: booleanAttribute }) public enableDownload = false;
  @Input() public imageClass = 'object-scale-down';
  @Input() public progressReport: Record<string, number> = {};
  @Input() public disabled = false;
  @Input({ transform: booleanAttribute }) public hideInfoOverlay = false;
  @Input({ transform: booleanAttribute }) public readonly = false;

  @Output() public readonly filesAdded = new EventEmitter<FileModel[]>();
  @Output() public readonly fileClick = new EventEmitter<number>();
  @Output() public readonly fileRemoved = new EventEmitter<number>();
  @Output() public readonly addClick = new EventEmitter<void>();
  @Output() public readonly filesChange = new EventEmitter<FileModel[]>();

  @ViewChild(FuseScrollbarDirective)
  private set scroll(scroll: FuseScrollbarDirective) {
    this._scroll = scroll;
    this._detector.detectChanges();
  }

  @ViewChild('scrollContainer')
  private readonly _scrollContainer!: ElementRef<HTMLDivElement>;

  @ViewChild('uploader')
  private readonly _uploader!: ElementRef<HTMLInputElement>;

  @ViewChild(ImageTransformerComponent)
  private readonly _imageTransformer!: ImageTransformerComponent;

  protected readonly displayFiles = computed(() => this.innerFiles().reverse());

  protected readonly innerFiles = signal<FileModel[]>([]);

  protected get canAdd(): boolean {
    if (!this.maxNumberOfFiles) {
      return true;
    }

    return this.maxNumberOfFiles > this.innerFiles().length;
  }

  protected get isScrollActive(): boolean {
    if (!this._scrollContainer) {
      return false;
    }

    return (
      this._scrollContainer.nativeElement.clientWidth <=
      this._scrollContainer.nativeElement.scrollWidth
    );
  }

  protected get canScrollRight(): boolean {
    return (
      this.isScrollActive &&
      this._scrollContainer.nativeElement.scrollLeft +
        this._scrollContainer.nativeElement.clientWidth <
        this._scrollContainer.nativeElement.scrollWidth
    );
  }

  protected get canScrollLeft(): boolean {
    if (this._scroll && this._scroll.ps) {
      return (
        this.isScrollActive &&
        this._scrollContainer.nativeElement.scrollLeft !== 0
      );
    }

    return false;
  }

  private _scroll!: FuseScrollbarDirective;
  private readonly _detector = inject(ChangeDetectorRef);
  private readonly _mimeTypes = inject(EXTENSION_MIME_TYPE_MAP);

  reset(): void {
    this.files = [];
  }

  ngAfterViewInit(): void {
    this._detector.detectChanges();
  }

  protected onFilesAdded(files: FileList): void {
    if (files.length === 1 && !!files[0]?.type.includes('image/')) {
      this._imageTransformer.open(files[0]);
      return;
    }

    this._onFilesAdded(Array.from(files));
  }

  protected onImageCropped(file: File): void {
    this._onFilesAdded([file]);
  }

  protected onRemoveFile(index: number): void {
    const actualIndex = this.innerFiles().length - index - 1;

    const files = this.innerFiles();
    files.splice(index, 1);
    this.innerFiles.set([...files]);
    this.fileRemoved.emit(actualIndex);

    setTimeout(() => {
      this._scroll?.update();
    });

    this.filesChange.emit(files);
  }

  protected scrollLeft(): void {
    const position = this._scroll.position(true);
    const x = typeof position.x === 'string' ? 0 : position.x;
    this._scroll.scrollToLeft(x - 250, 250);
    this._scroll.update();
    setTimeout(() => this._detector.detectChanges(), 300);
  }

  protected scrollRight(): void {
    const position = this._scroll.position(true);
    const x = typeof position.x === 'string' ? 0 : position.x;
    this._scroll.scrollToX(x + 250, 250);
    this._scroll.update();
    setTimeout(() => this._detector.detectChanges(), 300);
  }

  protected onAddClick(): void {
    if (this.useExternalAdd) {
      this.addClick.emit();
    } else {
      this._uploader.nativeElement.click();
    }
  }

  protected readonly displayFilesTrackBy = (
    _: number,
    file: FileModel
  ): string => file.url;

  protected onDownloadFile(file: FileModel): void {
    saveAs(file.file || file.url, file.name);
  }

  private _createFileUploadModel(file: File): FileModel {
    const extension = file.name.substring(file.name.lastIndexOf('.'));
    const url = URL.createObjectURL(file);

    return {
      url,
      id: '',
      description: '',
      size: file.size,
      mimeType: this._mimeTypes[extension.toLowerCase()],
      name: file.name,
      thumbnailUrl: url,
      file,
    };
  }

  private _onFilesAdded(files: File[]): void {
    let allowedFiles = Array.from(files);

    if (this.maxNumberOfFiles) {
      const missingFilesCount =
        this.maxNumberOfFiles - this.innerFiles().length;
      allowedFiles = allowedFiles.slice(0, missingFilesCount);
    }

    const newFiles = [
      ...this.innerFiles(),
      ...allowedFiles.map((file) => this._createFileUploadModel(file)),
    ];

    this.innerFiles.set(newFiles);

    setTimeout(() => {
      this._scroll?.scrollTo(0, 0);
      this._scroll?.update();
    });

    this.filesAdded.emit(newFiles);
    this.filesChange.emit(newFiles);

    this._uploader.nativeElement.value = '';
  }
}
