import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileUploaderComponent, TextFieldComponent } from '@fieldos/components';
import { DialogService } from '@fieldos/core';
import { ExceptionsFacade } from '@fieldos/facades';
import { AssignedException } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-assign-exception-parent-code',
  templateUrl: './assign-exception-parent-code.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslocoModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TextFieldComponent,
    FileUploaderComponent,
  ],
})
export class AssignExceptionParentCodeComponent {
  constructor() {
    effect(
      () => {
        if (this.exceptionId()) {
          this.fetchException();
        }
      },
      { allowSignalWrites: true }
    );
  }

  public readonly exceptionId = input.required<number | null>();
  public readonly reasonCodeName = input.required<string | null>();

  protected readonly exception = signal<AssignedException | null>(null);
  protected readonly loading = signal<boolean>(false);

  private readonly _facade = inject(ExceptionsFacade);
  private readonly _dialog = inject(DialogService);

  protected onFileClick(index: number): void {
    const exception = this.exception();

    if (exception) {
      this._dialog.openFileGallery(exception.media || [], index, {
        removeDisabled: true,
      });
    }
  }

  protected async fetchException(): Promise<void> {
    const exceptionId = this.exceptionId();
    this.loading.set(true);
    if (exceptionId) {
      try {
        const exception = await this._facade.fetchException(exceptionId);

        this.exception.set(exception);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading.set(false);
      }
    }
  }
}
