import { Injectable, effect, inject, signal } from '@angular/core';
import { PushNotification, PushNotificationScope } from '@fieldos/models';
import { AuthStore, NotificationsStore } from '@fieldos/store';
import { PriorityNotificationStore } from '@fieldos/store/priority-notification.store';
import { fromServerDate } from '@fieldos/utils';
import { Socket } from 'socket.io-client';
import { createSocketConnection } from './create-socket-connection';

@Injectable({
  providedIn: 'root',
})
export class NotificationsSocket {
  constructor() {
    effect(() => {
      const isAuthenticated = this._auth.isAuthenticated();

      if (isAuthenticated) {
        this.connect();
      } else if (isAuthenticated === null) {
        this.disconnect();
      }
    });
  }

  public readonly latestNotification = signal<PushNotification | undefined>(
    undefined
  );

  private _socket?: Socket;

  private readonly _notificationStore = inject(NotificationsStore);
  private readonly _priorityNotificationsStore = inject(
    PriorityNotificationStore
  );
  private readonly _auth = inject(AuthStore);

  private readonly _priorityNotifications: PushNotificationScope[] = [
    PushNotificationScope.NewDPUReasonCode,
    PushNotificationScope.NewRouteReasonCode,
    PushNotificationScope.NewWorkOrderReasonCode,
  ];

  connect(): void {
    const user = this._auth.profile();

    if (user) {
      this._socket = createSocketConnection(user);

      this._socket.on(
        'connected',
        () => this._socket?.emit('wo_ready', { ready: true })
      );

      this._socket.on('wo_update', (message) => {
        const notification: PushNotification = JSON.parse(message.message);
        const pushNotification: PushNotification = {
          ...notification,
          localDate: fromServerDate(notification.date) as Date,
        };

        this._notificationStore.addNotification(pushNotification);

        if (this._priorityNotifications.includes(pushNotification.scope)) {
          this._priorityNotificationsStore.show(pushNotification);
        }

        this.latestNotification.set(pushNotification);
      });
    }
  }

  disconnect(): void {
    this._socket?.disconnect();
  }
}
