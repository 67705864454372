import { computed } from '@angular/core';
import { DataSource } from '@fieldos/models';
import {
  patchState,
  signalStoreFeature,
  withMethods,
  withState,
} from '@ngrx/signals';
import { DataSourceCache, StandardDataSourceId } from './data-source.models';

interface DataSourceCacheState<
  TKey extends StandardDataSourceId | number = number,
> {
  cache: DataSourceCache<TKey>;
  dataSources: DataSource[];
}

export const withDataSourceState = <
  TKey extends StandardDataSourceId | number = number,
>() =>
  signalStoreFeature(
    withState<DataSourceCacheState<TKey>>({
      cache: {},
      dataSources: [],
    } as DataSourceCacheState<TKey>),
    withMethods((store) => ({
      getIsLoading: (dataSourceId: TKey) =>
        store.cache()[dataSourceId]?.isLoading ?? false,
      getDataSource: (dataSourceId: TKey) => {
        const data = store.cache()[dataSourceId];
        if (data && data.data) {
          return Object.values(data.data);
        }
        return [];
      },
      selectDataSource: (dataSourceId: TKey) =>
        computed(() => {
          const cache = store.cache()[dataSourceId];
          if (cache && cache.data) {
            return Object.values(cache.data);
          }
          return [];
        }),
      selectNormalizedDataSource: (dataSourceId: TKey) =>
        computed(() => {
          const cache = store.cache()[dataSourceId];
          if (cache && cache.data) {
            return cache.data;
          }
          return {};
        }),

      clearCache: () => patchState(store, {}),
    }))
  );
