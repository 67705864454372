import { SectionLabelInfoProperties } from './simple-section-properties';

export interface SectionProperties<
  TTranslatableProperties = SectionLabelInfoProperties,
  TProperties = undefined,
> {
  translatableProperties: Record<string, TTranslatableProperties>;
  properties?: TProperties;
}

export interface SectionPropertiesChangeEvent<
  TTranslatableProperties extends
    SectionLabelInfoProperties = SectionLabelInfoProperties,
  TProperties = undefined,
> {
  translatableProperties: TTranslatableProperties;
  properties?: TProperties;
}

export interface SectionCommonPropertiesBase {
  containerId?: string;
}
