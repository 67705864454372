import { Route } from '@angular/router';

export const contractorRoutes: Route[] = [
  {
    path: 'contractors',
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./contractors/contractors.component').then(
            (m) => m.ContractorsComponent
          ),
        data: {
          title: 'contractors.list',
        },
      },
      {
        path: 'new/:typeId',
        pathMatch: 'full',
        loadComponent: () =>
          import('./contractor-form/contractor-form.component').then(
            (m) => m.ContractorFormComponent
          ),
        data: {
          title: 'contractors.add_new',
        },
      },
      {
        path: ':contractorId/edit',
        pathMatch: 'full',
        loadComponent: () =>
          import('./contractor-form/contractor-form.component').then(
            (m) => m.ContractorFormComponent
          ),
        data: {
          title: 'contractors.edit',
        },
      },
    ],
  },
];
