import { Injectable } from '@angular/core';
import { LocationFieldSubType, LocationModel } from '@fieldos/models';
import { FieldBasedEntitiesServiceBase } from './field-based-entities.service';

@Injectable({
  providedIn: 'root',
})
export class LocationsDataService extends FieldBasedEntitiesServiceBase<LocationModel> {
  constructor() {
    super('locations', LocationFieldSubType.LocationType);
  }
}
