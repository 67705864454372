import {
  ChangeDetectionStrategy,
  Component,
  computed,
  forwardRef,
  inject,
  input,
} from '@angular/core';
import { ContainerSection } from '@fieldos/models';
import { SectionsFormStore } from '../../sections-form.store';
import { SectionsFormSectionComponent } from '../sections-form-section.component';

@Component({
  selector: 'app-sections-form-container-section',
  templateUrl: './sections-form-container-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [forwardRef(() => SectionsFormSectionComponent)],
})
export class SectionsFormContainerSectionComponent {
  public readonly sectionId = input.required<string>();

  protected readonly store = inject(SectionsFormStore);

  protected readonly containerSection = computed(
    () => this.store.sectionsMap()[this.sectionId()] as ContainerSection
  );
}
