<div class="flex justify-between items-center">
  <span>
    {{ 'assign_reason_code.seal' | transloco }}
  </span>
  <button
    mat-icon-button
    [disabled]="sealsFormArray.invalid"
    (click)="addSealCtrl()"
    color="primary">
    <mat-icon svgIcon="mat_solid:add" />
  </button>
</div>
<div class="flex flex-col gap-4">
  @for (sealCtrl of sealsFormArray.controls; track $index) {
    <div class="flex gap-4 items-center">
      <app-text-field
        [formControl]="sealCtrl"
        placeholder="{{ 'assign_reason_code.seal_code' | transloco }}" />

      <button
        mat-icon-button
        [disabled]="sealsFormArray.controls.length === 0"
        (click)="removeSeal($index)"
        color="warn">
        <mat-icon svgIcon="mat_solid:clear" />
      </button>
    </div>
  }
</div>
