import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { base64ToFile } from '@fieldos/utils';
import { isEqual } from 'lodash';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { getDefaultTransformOptions } from './image-transformer.utils';

@Component({
  selector: 'app-image-transformer',
  templateUrl: './image-transformer.component.html',
  styleUrls: ['./image-transformer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageTransformerComponent {
  constructor(private _dialog: MatDialog) {}

  @Input() public aspectRatio: number | undefined;

  @Output() public save = new EventEmitter<File>();
  @Output() public cancel = new EventEmitter<void>();

  @ViewChild('tpl')
  private _tpl!: TemplateRef<any>;

  protected file: File | undefined;
  protected transform: ImageTransform = getDefaultTransformOptions();

  private _croppedFile: File | undefined;
  private _dialogRef: MatDialogRef<any> | undefined;

  open(file: File): void {
    this.transform = getDefaultTransformOptions();
    this.file = file;
    this._dialogRef = this._dialog.open(this._tpl, {
      width: '50rem',
      panelClass: 'image-cropper-panel',
      closeOnNavigation: true,
      disableClose: true,
    });
  }

  protected onCancel(): void {
    this.file = undefined;
    this._croppedFile = undefined;
    this._dialogRef?.close();
    this.cancel.emit();
  }

  protected onSave(): void {
    this.save.emit(this._croppedFile);
    this.file = undefined;
    this._croppedFile = undefined;
    this._dialogRef?.close();
  }

  protected onCrop(event: ImageCroppedEvent): void {
    if (event.base64) {
      this._croppedFile = base64ToFile(event.base64, this.file?.name || '');
    }
  }

  protected onReset(): void {
    this.transform = getDefaultTransformOptions();
  }

  protected onFlipHorizontal(): void {
    this.transform = { ...this.transform, flipH: !this.transform.flipH };
  }

  protected onFlipVertical(): void {
    this.transform = { ...this.transform, flipV: !this.transform.flipV };
  }

  protected onRotateRight(): void {
    this.transform = { ...this.transform, rotate: this.transform.rotate! + 90 };
  }

  protected onRotateLeft(): void {
    this.transform = { ...this.transform, rotate: this.transform.rotate! - 90 };
  }

  protected onZoomIn(): void {
    this.transform = { ...this.transform, scale: this.transform.scale! + 0.1 };
  }

  protected onZoomOut(): void {
    this.transform = { ...this.transform, scale: this.transform.scale! - 0.1 };
  }

  protected get resetDisabled(): boolean {
    return isEqual(this.transform, getDefaultTransformOptions());
  }
}
