<span class="mr-2 font-medium">{{ 'common.workspace' | transloco }}</span>
<mat-form-field class="fuse-mat-dense min-w-[220px]" subscriptSizing="dynamic">
  <mat-select [formControl]="workspaceCtrl" placeholder="Select Workspace">
    @for (workspace of store.workspaceScopesEntities(); track workspace.id) {
      <mat-option [value]="workspace.id">
        {{ workspace.name }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
