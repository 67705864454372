export * from './section-columns.models';
export * from './section-columns.provider';
export * from './section-grid-renderers';

import {
  RatingCellRendererComponent,
  TextCellRendererComponent,
} from 'app/@components/ag-grid-renderers';
import {
  AssigneesCellRendererComponent,
  DataSourceCellRendererComponent,
  DateCellRendererComponent,
  DurationCellRendererComponent,
  FilesCellRendererComponent,
  HtmlCellRedererComponent,
  LocationCellRendererComponent,
  PriorityCellRendererComponent,
  SingleDataSourceCellRendererComponent,
  StarFilterComponent,
  StatusCellRendererComponent,
} from './section-grid-renderers';

export const sectionBasedListGridComponents = [
  PriorityCellRendererComponent,
  TextCellRendererComponent,
  DateCellRendererComponent,
  DataSourceCellRendererComponent,
  StarFilterComponent,
  RatingCellRendererComponent,
  LocationCellRendererComponent,
  HtmlCellRedererComponent,
  AssigneesCellRendererComponent,
  StatusCellRendererComponent,
  DurationCellRendererComponent,
  SingleDataSourceCellRendererComponent,
  FilesCellRendererComponent,
];
