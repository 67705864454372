import { FieldBasedEntity } from '../fields';

export interface User extends FieldBasedEntity {
  enabled: boolean;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  roleId: number;
  teamIds: number[];
}
