<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
  <div class="flex-auto h-full flex flex-col">
    <div class="flex-auto flex flex-col rounded-t-2xl shadow max-h-full">
      <div class="min-w-full min-h-full flex flex-col">
        <!-- <div class="flex-grow m-4 card min-h-0 bg-card"> -->
        <ng-content></ng-content>
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
