import { DomainEntityBase } from '@fieldos/models';

export interface UserProfile {
  userId: number;
  parentUserId: number;
  name: string;
  firstName: string;
  email: string;
  phone: string;
  lang: string;
  dateFormat: string;
  avatar: string;
  timeZone: string;
  roleId: number;
  activeWorkspace: DomainEntityBase;
  activeWorkspaceScope: DomainEntityBase;
  tid: string;
  userParentId: number;
  woStartLocMandatoryComplete: boolean;
  woStartLocShow: boolean;
}
