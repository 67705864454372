import { ExceptionTargetType } from '@fieldos/business';

export interface EntityException {
  blocksWorkOrder: boolean;
  confirmedId: number | null;
  id: number;
  label: string;
  labelConfirmed: boolean | null;
  targetType: ExceptionTargetType;
}

export interface EntityExceptionDetails {
  taxonomyId: number;
  taxonomyLabel: string;
  media: null;
  comments: null;
}
