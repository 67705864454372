export interface UserForm {
  id?: number;
  accountTypeId: UserFormAccountType;
  accountEntityId: number;
  roleId: number;
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  notes: string;
  enabled: boolean;
  password: string;
  teamIds: number[];
  avatar: string;
}

export type UserFormAccountType = 1 | 2 | 3;
