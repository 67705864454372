import { inject, Injectable } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { DomainEntityBase } from '@fieldos/models';

@Injectable({
  providedIn: 'root',
})
export class RolesDataService {
  private readonly _http = inject(HttpService);

  fetch(): Promise<DomainEntityBase[]> {
    return this._http.get<DomainEntityBase[]>(`/roles`);
  }
}
