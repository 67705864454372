import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { PushNotification } from '@fieldos/models';
import { PriorityNotificationStore } from '@fieldos/store/priority-notification.store';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-priority-notifications',
  templateUrl: './priority-notifications.component.html',
  standalone: true,
  imports: [MatButtonModule, TranslocoModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityNotificationsComponent {
  protected readonly store = inject(PriorityNotificationStore);

  protected onOpenNotification(notification: PushNotification): void {
    this.store.open(notification);
    this.store.hide(notification);
  }
}
