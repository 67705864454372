export interface SimpleReportFilterValue<T> {
  value: T | null;
}

export interface ReportDateFilterValue {
  min: string;
  max: string;
}

export interface ReportStringFilterValue {
  value: string;
}

export interface ReportDataSourceFilterValue<
  T extends number | string | unknown,
> {
  ids: T[];
  include: boolean;
}

export type ReportFilterValue =
  | ReportDateFilterValue
  | ReportDataSourceFilterValue<unknown>
  | ReportStringFilterValue
  | SimpleReportFilterValue<unknown>
  | number[]
  | null;
