import { Injectable } from '@angular/core';
import { LocationsDataService } from '@fieldos/data-services';
import { LocationModel } from '@fieldos/models';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import {
  Observable,
  combineLatest,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';

interface LocationsSelectState {
  locations: LocationModel[];
  selectedLocationId?: number;
  loading: boolean;
}

@Injectable()
export class LocationsSelectStore extends ComponentStore<LocationsSelectState> {
  constructor(private _service: LocationsDataService) {
    super({
      loading: false,
      locations: [],
    });
  }

  public readonly locations$ = this.select((state) => state.locations).pipe(
    map((locations) =>
      locations.filter((location) => !location.parentLocationId)
    )
  );

  public readonly subLocations$ = this.select(
    (state) => state.selectedLocationId
  ).pipe(
    mergeMap((locationId) =>
      locationId
        ? this.select((state) =>
            state.locations.filter(
              (location) => location.parentLocationId === locationId
            )
          )
        : of([])
    )
  );

  public readonly loading$ = this.select((state) => state.loading);

  public readonly vm$ = combineLatest([
    this.locations$,
    this.subLocations$,
    this.loading$,
  ]).pipe(
    map(([locations, subLocations, loading]) => ({
      locations,
      subLocations,
      loading,
    }))
  );

  public readonly fetchLocations = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap(() =>
        from(this._service.fetchAll()).pipe(
          tapResponse(
            (locations) => this.patchState({ locations, loading: false }),
            console.error
          )
        )
      )
    )
  );

  public readonly setSelectedLocation = this.updater(
    (state, selectedLocationId: number | undefined) => ({
      ...state,
      selectedLocationId,
    })
  );
}
