import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-text-input-dialog',
  templateUrl: './text-input-dialog.component.html',
})
export class TextInputDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      placeholder: string;
      value?: string;
      type: 'text' | 'number' | 'password' | 'link';
    }
  ) {
    this.formControl.setValue(data.value || '');

    if (data.type === 'link') {
      this.formControl.setValidators(
        Validators.pattern(
          /^(http|https):\/\/[a-zA-Z0-9-.]+\.[a-zA-Z]{2,}(\/\S*)?$/
        )
      );
    }
  }

  public formControl = new FormControl();
}
