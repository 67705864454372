import { Injectable, inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import {
  SectionBasedEntity,
  SectionBasedEntityType,
  SectionValueEntry,
} from '@fieldos/models';

@Injectable({
  providedIn: 'root',
})
export class SectionBasedEntityDataService {
  private readonly _http = inject(HttpService);

  fetchOne(
    id: number,
    entityType: SectionBasedEntityType
  ): Promise<SectionBasedEntity> {
    return this._http.get<SectionBasedEntity>(
      `${this._getBaseUrl(entityType)}/${id}`
    );
  }

  fetch(
    workspaceScopeId: number,
    entityType: SectionBasedEntityType
  ): Promise<SectionBasedEntity[]> {
    return this._http.get<SectionBasedEntity[]>(
      `${this._getBaseUrl(entityType)}?scope_id=${workspaceScopeId}`
    );
  }

  async convertServiceRequestToWorkOrder(srId: number): Promise<number> {
    const { id } = await this._http.post<{ id: number }>('/sr-to-wo', {
      srId,
    });

    return id;
  }

  async create(
    entity: Omit<SectionBasedEntity, 'id' | 'statusId' | 'availableActions'>,
    entityType: SectionBasedEntityType
  ): Promise<SectionBasedEntity> {
    const result = await this._http.post<SectionBasedEntity>(
      this._getBaseUrl(entityType),
      entity
    );

    return result;
  }

  update(
    entity: SectionBasedEntity,
    entityType: SectionBasedEntityType
  ): Promise<SectionBasedEntity> {
    return this._http.put<SectionBasedEntity>(
      `${this._getBaseUrl(entityType)}/${entity.id}`,
      entity.value
    );
  }

  delete(id: number, entityType: SectionBasedEntityType): Promise<void> {
    return this._http.delete<void>(`${this._getBaseUrl(entityType)}/${id}`);
  }

  updateSectionValue(
    entityId: number,
    value: SectionValueEntry,
    entityType: SectionBasedEntityType
  ): Promise<void> {
    return this._http.put<void>(`${this._getBaseUrl(entityType)}/${entityId}`, [
      value,
    ]);
  }

  updateSectionValues(
    entityId: number,
    value: SectionValueEntry[],
    entityType: SectionBasedEntityType
  ): Promise<void> {
    return this._http.put<void>(
      `${this._getBaseUrl(entityType)}/${entityId}`,
      value
    );
  }

  refuseServiceRequest(
    serviceRequestId: number,
    reason: string
  ): Promise<void> {
    return this._http.put<void>(`/service-request-reason/${serviceRequestId}`, {
      reason,
    });
  }

  private _getBaseUrl(type: SectionBasedEntityType): string {
    if (type === 'workorders') {
      return '/work-orders';
    }

    return '/service-requests';
  }
}
