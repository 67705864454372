import { JsonPipe, KeyValuePipe, TitleCasePipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslationFacade } from '@fieldos/facades';
import { LangDefinition, TranslocoModule } from '@ngneat/transloco';
import { TaxonomyFormConstraint } from './taxonomy-form.model';

@Component({
  selector: 'app-translatable-properties-dialog',
  templateUrl: './translate-taxonomy-form-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    KeyValuePipe,
    MatButtonModule,
    TranslocoModule,
    TitleCasePipe,
  ],
})
export class TranslatableTaxonomyFormDialogComponent implements OnInit {
  protected translatableForm: FormGroup;
  protected availableLanguages: LangDefinition[];
  protected readonly data: { form: FormGroup<TaxonomyFormConstraint> } =
    inject(MAT_DIALOG_DATA);

  private readonly _fb = inject(FormBuilder);
  private readonly _translationFacade = inject(TranslationFacade);
  private readonly _dialogRef = inject(
    MatDialogRef<TranslatableTaxonomyFormDialogComponent>
  );

  ngOnInit(): void {
    const translatablePropertiesExistingLanguages = Object.keys(
      this.data.form.controls.translatableProperties.controls
    );
    this.availableLanguages = this._translationFacade
      .getAvailableLangs()
      .filter(
        (lang) => !translatablePropertiesExistingLanguages.includes(lang.id)
      );
  }

  protected onLanguageSelected(langKey: string) {
    if (!langKey) return;
    const translatablePropertiesGroup = this.data.form.get(
      'translatableProperties'
    ) as FormGroup;
    // The language FormGroup already exists
    if (translatablePropertiesGroup.controls[langKey]) {
      return;
    }

    // Use an existing language as a template to create the new FormGroup structure
    const defaultLangKey = Object.keys(translatablePropertiesGroup.controls)[0];
    const defaultLangFormGroup = translatablePropertiesGroup.controls[
      defaultLangKey
    ] as FormGroup;
    const newLangFormGroup = this.cloneFormGroupStructure(defaultLangFormGroup);

    // Add the newly created FormGroup to the 'translatableProperties' FormGroup
    translatablePropertiesGroup.addControl(langKey, newLangFormGroup);

    // Update available languages
    this.availableLanguages = this.availableLanguages.filter(
      (lang) => lang.id !== langKey
    );
  }

  protected onConfirm() {
    this._dialogRef.close(this.data.form);
  }

  protected keepOrder() {
    return 0;
  }

  private cloneFormGroupStructure(formGroup: FormGroup): FormGroup {
    const newGroup = this._fb.group({});
    Object.keys(formGroup.controls).forEach((key) => {
      const currentControl = formGroup.get(key);
      if (currentControl instanceof FormControl) {
        newGroup.addControl(
          key,
          this._fb.control(currentControl.value, currentControl.validator)
        );
      } else if (currentControl instanceof FormGroup) {
        newGroup.addControl(key, this.cloneFormGroupStructure(currentControl));
      }
    });

    return newGroup;
  }
}
