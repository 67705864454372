import { SectionProperties } from './section-properties';
import { SectionLabelInfoProperties } from './simple-section-properties';

export interface CustomerContractSectionCommonProperties {
  showForSelectedCustomer: boolean;
}

export type CustomerContractSectionProperties = SectionProperties<
  SectionLabelInfoProperties,
  CustomerContractSectionCommonProperties
>;
