import { InjectionToken, computed, inject } from '@angular/core';
import { DialogService } from '@fieldos/core';
import { TaxonomyDataService } from '@fieldos/data-services';
import { TranslationFacade } from '@fieldos/facades';
import { Field, Taxonomy, TaxonomyModelConstraint } from '@fieldos/models';
import { ToastStore } from '@fieldos/store/toast.store';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { ColDef } from 'ag-grid-community';
import { firstValueFrom } from 'rxjs';

export interface TaxonomyListState<T extends TaxonomyModelConstraint> {
  loading: boolean;
  items: Taxonomy<T>[];
  columns: ColDef[];
  fields: Field[];
}

export const createTaxonomyListStore = <T extends TaxonomyModelConstraint>(
  createColumnDefsFn: () => () => ColDef[]
) =>
  signalStore(
    withState<TaxonomyListState<T>>({
      loading: false,
      items: [],
      columns: [],
      fields: [],
    }),
    withComputed((store, translationFacade = inject(TranslationFacade)) => ({
      listItems: computed(() => {
        const language = translationFacade.language();
        const items = store.items();
        return items.map((item) => ({
          ...item,
          ...item.properties,
          ...item.translatableProperties[language],
        }));
      }),
    })),
    withMethods(
      (
        store,
        toastStore = inject(ToastStore),
        dataService = inject(TaxonomyDataService),
        dialogService = inject(DialogService)
      ) => ({
        async fetch(): Promise<void> {
          patchState(store, { loading: true });
          try {
            const items = await dataService.get();
            patchState(store, { items, loading: false });
          } catch (error) {
            patchState(store, { loading: false });
            toastStore.showErrorToast(`taxonomy.fetch_failed.message`);
          }
        },
        async create(data: TaxonomyModelConstraint): Promise<void> {
          patchState(store, { loading: true });
          try {
            const result = await dataService.create(data);
            const newTaxonomy = { ...data, id: result.id } as Taxonomy<T>;
            patchState(store, {
              loading: false,
              items: [...store.items(), newTaxonomy],
            });
            toastStore.showSuccessToast(`taxonomy.add_success.message`);
          } catch (error) {
            patchState(store, { loading: false });
            toastStore.showErrorToast(`taxonomy.add_failed.message`);
          }
        },
        async update(data: Taxonomy<T>): Promise<void> {
          patchState(store, { loading: true });
          try {
            await dataService.update(data);
            patchState(store, {
              loading: false,
              items: store
                .items()
                .map((item) => (item.id === data.id ? data : item)),
            });
            toastStore.showSuccessToast(`taxonomy.update_success.message`);
          } catch (error) {
            patchState(store, { loading: false });
            toastStore.showErrorToast(`taxonomy.update_failed.message`);
          }
        },
        async delete(id: number): Promise<void> {
          const confirmed = await firstValueFrom(
            dialogService.showConfirmDialog(
              'common.confirm',
              'taxonomy.delete_confirm_message'
            )
          );
          if (confirmed) {
            patchState(store, { loading: true });
            try {
              await dataService.delete(id);
              const items = store.items().filter((x) => x.id !== id);
              patchState(store, { items: items, loading: false });
            } catch (error) {
              patchState(store, { loading: false });
              toastStore.showErrorToast(`taxonomy.delete_failed.message`);
            }
          }
        },
      })
    ),
    withHooks({
      onInit(store) {
        store.fetch();
        const createColumnsFn = createColumnDefsFn();
        patchState(store, {
          columns: createColumnsFn(),
        });
      },
    })
  );

export const TAXONOMY_LIST_STORE = new InjectionToken<
  InstanceType<ReturnType<typeof createTaxonomyListStore>>
>('TAXONOMY_LIST_STORE');
