import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomainEntityBase } from '@fieldos/models';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DataGridActionsService } from '../../data-grid';
import { ActionsCellRendererColorPipe } from './actions-cell-renderer-color.pipe';
import { ActionsCellRendererIconPipe } from './actions-cell-renderer-icon.pipe';
import {
  ActionsCellRendererParams,
  GridActionButton,
} from './actions-cell-renderer.models';

@Component({
  selector: 'app-actions-cell-renderer.component',
  templateUrl: './actions-cell-renderer.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    ActionsCellRendererIconPipe,
    ActionsCellRendererColorPipe,
  ],
})
export class ActionsCellRendererComponent<T = DomainEntityBase>
  implements ICellRendererAngularComp
{
  constructor(private _buttonService: DataGridActionsService<T, string>) {}

  private _params!: ActionsCellRendererParams<T>;

  agInit(params: ActionsCellRendererParams<T>): void {
    this._params = params;
  }

  refresh(): boolean {
    return false;
  }

  protected get actions(): GridActionButton<T>[] {
    return this._params.actions || [];
  }

  protected get data(): T {
    return this._params.data as T;
  }

  protected onButtonClick(buttonId: string): void {
    if (this._params.data) {
      this._buttonService.onButtonClick(buttonId, this._params.data);
    }
  }
}
