import { computed, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { WorkspaceDataService } from '@fieldos/data-services';
import { Workspace, WorkspaceScope } from '@fieldos/models';
import {
  patchState,
  signalStore,
  type,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import { AuthStore } from './auth';

interface WorkspaceState {
  selectedWorkspaceId: number;
  selectedWorkspaceScopeId: number;

  loadingWorkspaces: boolean;
  loadingWorkspaceScopes: boolean;
}

export const WorkspaceStore = signalStore(
  {
    providedIn: 'root',
  },
  withEntities({
    collection: 'workspaces',
    entity: type<Workspace>(),
  }),
  withEntities({
    collection: 'workspaceScopes',
    entity: type<WorkspaceScope>(),
  }),
  withState<WorkspaceState>(() => ({
    selectedWorkspaceId: 0,
    selectedWorkspaceScopeId: 0,
    loadingWorkspaces: false,
    loadingWorkspaceScopes: false,
  })),
  withComputed((store) => ({
    isScopeSelected: computed(() => store.selectedWorkspaceScopeId() > 0),
  })),
  withMethods(
    (
      store,
      service = inject(WorkspaceDataService),
      authStore = inject(AuthStore)
    ) => ({
      async reloadWorkspaces(): Promise<void> {
        patchState(store, { loadingWorkspaces: true });
        try {
          const workspaces = await service.fetchWorkspaces();
          patchState(
            store,
            setAllEntities(workspaces, { collection: 'workspaces' })
          );

          const defaultWorkspace = workspaces.find((e) => e.default);
          const profile = authStore.profile();

          if (profile && profile.activeWorkspace) {
            this.selectWorkspace(profile.activeWorkspace.id);
          } else if (defaultWorkspace) {
            this.selectWorkspace(defaultWorkspace.id);
          }

          patchState(store, { loadingWorkspaces: false });
        } catch (error) {
          console.error(error);
        }
      },
      async reloadWorkspaceScopes(workspaceId?: number): Promise<void> {
        patchState(store, { loadingWorkspaceScopes: true });
        try {
          let scopes: WorkspaceScope[] = [];
          if (!workspaceId) {
            scopes = await service.fetchWorkspaceScopes();
          } else {
            scopes =
              await service.fetchWorkspaceScopesForWorkspace(workspaceId);
          }
          patchState(
            store,
            setAllEntities(scopes, { collection: 'workspaceScopes' })
          );

          const defaultWorkspaceScope = scopes.find((e) => e.default);
          if (defaultWorkspaceScope) {
            this.selectWorkspaceScope(defaultWorkspaceScope.id);
          }

          patchState(store, { loadingWorkspaceScopes: false });
        } catch (error) {
          console.error(error);
        }
      },
      selectWorkspace(selectedWorkspaceId: number): void {
        patchState(store, { selectedWorkspaceId });

        try {
          service.changeWorkspace(selectedWorkspaceId);
        } catch (error) {
          console.error(error);
        }

        this.reloadWorkspaceScopes();
      },
      selectWorkspaceScope(selectedWorkspaceScopeId: number): void {
        patchState(store, { selectedWorkspaceScopeId });
      },
    })
  ),
  withHooks({
    onInit: async (store, authStore = inject(AuthStore)) => {
      toObservable(authStore.isAuthenticated)
        .pipe(
          distinctUntilChanged(),
          filter((isAuthenticated) => isAuthenticated === true),
          tap(() => store.reloadWorkspaces()),
          takeUntilDestroyed()
        )
        .subscribe();
    },
  })
);
