import { FlatGraphNode } from '@fieldos/business';

export interface StatusGraphNodeData {
  label: string;
  statusType: GraphStatusType;
  order?: number;
}

export enum GraphStatusType {
  Open = 'open',
  InProgress = 'in progress',
  OnHold = 'on-hold',
  Completed = 'completed',
  Refused = 'refused',
  Converted = 'converted',
}

export type StatusGraphNode = FlatGraphNode<StatusGraphNodeData>;

export const statusGraphTypeIdMap: Record<GraphStatusType, number> = {
  [GraphStatusType.Open]: 1,
  [GraphStatusType.InProgress]: 2,
  [GraphStatusType.OnHold]: 3,
  [GraphStatusType.Completed]: 4,
  [GraphStatusType.Refused]: 5,
  [GraphStatusType.Converted]: 6,
};
