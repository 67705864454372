import { Observable } from 'rxjs';

export const filterEmpty =
  () =>
  <T>(source: Observable<T | null | undefined>): Observable<T> =>
    new Observable((subscriber) =>
      source.subscribe({
        next: (value) => {
          if (value !== undefined && value !== null) {
            subscriber.next(value);
          }
        },
        error: (error) => subscriber.error(error),
        complete: () => subscriber.complete(),
      })
    );
