import { NgComponentOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TableColumn } from '../table.models';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [MatTableModule, NgComponentOutlet, MatPaginatorModule],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class TableComponent<T> {
  public readonly columns = input.required<TableColumn[]>();
  public readonly data = input.required<T[]>();
  public readonly usePaginator = input<boolean>(false);
  public readonly pageSize = input<number>(50);
  protected readonly currentPageIndex = signal(0);

  protected readonly displayedColumns = computed(() =>
    this.columns().map((column) => column.propertyName)
  );

  protected currentPageData = computed(() =>
    this.data().slice(
      this.currentPageIndex() * this.pageSize(),
      this.currentPageIndex() * this.pageSize() + this.pageSize()
    )
  );

  protected onPageChange(event: PageEvent): void {
    this.currentPageIndex.set(event.pageIndex);
  }
}
