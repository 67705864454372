import { DomainEntityBase } from '../entity-base';
import { ReportColumnTranslatableProperties } from './report-columns';
import { ReportFilterValue } from './report-filter-value';
import { ReportType } from './report-type';
import { ReportFilterPropertiesBase } from './reports-column-filters';

export interface ReportConfig {
  reportType: ReportType;
  columns: ReportColumnConfig[];
}

export interface ReportColumnConfig {
  filter: ReportFilterValue;
  filterHidden?: boolean;
  type: string;
  translatableProperties: Record<string, ReportColumnTranslatableProperties>;
  filterProperties?: ReportFilterPropertiesBase;
}

export interface Report extends DomainEntityBase {
  config: ReportConfig;
}
