import { inject, Pipe, PipeTransform } from '@angular/core';
import { Section, SectionLabelInfoProperties } from '@fieldos/models';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'workOrderSectionTranslatableTitle',
  standalone: true,
})
export class WorkOrderSectionTranslatableTitlePipe implements PipeTransform {
  private readonly _transloco = inject(TranslocoService);

  transform(value: Section): string {
    const language = this._transloco.getActiveLang();

    const properties = value.translatableProperties[
      language
    ] as SectionLabelInfoProperties;

    return properties.label;
  }
}
