import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[fileUploaderDropzone]',
  standalone: true,
})
export class FileUploaderDropzoneDirective {
  @Output() public readonly filesDropped = new EventEmitter<FileList>();

  @HostBinding('class.bg-slate-100')
  private _fileOver = false;

  @HostListener('dragover', ['$event'])
  private _onDragOver(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this._fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  private _onDragLeave(event: Event): void {
    this._fileOver = false;
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  private _onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this._fileOver = false;

    const files = event.dataTransfer?.files;

    if (files) {
      this.filesDropped.emit(files);
    }
  }
}
