import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  signal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { StatusGraphNode } from '@fieldos/models';
import { StatusGraphStore } from '@fieldos/store/status-graph';
import { TranslocoModule } from '@ngneat/transloco';
import { IFilterAngularComp } from 'ag-grid-angular';
import {
  AgPromise,
  IDoesFilterPassParams,
  IFilterParams,
} from 'ag-grid-community';

@Component({
  selector: 'app-status-filter',
  templateUrl: './status-filter.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoModule, MatCheckboxModule, ReactiveFormsModule],
})
export class StatusFilterComponent implements IFilterAngularComp {
  constructor() {
    effect(
      () => {
        const statuses =
          this._store.selectCurrentStatusGraphSignal('workorders');
        this.statuses.set(statuses());

        this.selection.setSelection(...statuses().map((e) => e.id));
      },
      { allowSignalWrites: true }
    );
  }

  protected readonly searchFormCtrl = new FormControl('', {
    nonNullable: true,
  });

  protected readonly selection = new SelectionModel<string>(true);

  protected readonly statuses = signal<StatusGraphNode[]>([]);

  private readonly _store = inject(StatusGraphStore);

  protected get isSelectAllIntermediate(): boolean {
    return (
      this.selection.selected.length !== 0 &&
      this.selection.selected.length < this.statuses().length
    );
  }

  protected get isSelectAllChecked(): boolean {
    return this.selection.selected.length === this.statuses().length;
  }

  private _params!: IFilterParams<Record<string, any>, unknown>;

  private readonly _floatingFilterValue = signal<string | null>(null);

  onFloatingFilterChanged(value: string): void {
    this._floatingFilterValue.set(value);

    if (!value) {
      this.selection.setSelection(...this.statuses().map((e) => e.id));
      this._params.filterChangedCallback();
      return;
    }

    const passedFilterValues = this.statuses().filter((e) =>
      e.data.label.toLowerCase().includes(value.toLowerCase())
    );

    this.selection.setSelection(...passedFilterValues.map((e) => e.id));
    this._params.filterChangedCallback();
  }

  agInit(params: IFilterParams): void {
    this._params = params;
  }

  isFilterActive(): boolean {
    return true;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (this._params.colDef.field) {
      const columnValue = params.data[this._params.colDef.field] as string;
      if (this.selection.selected.length === 0) {
        return true;
      } else {
        return this.selection.isSelected(columnValue);
      }
    }

    return false;
  }

  getModel(): string | string[] {
    return this._floatingFilterValue() || this.selection.selected;
  }

  setModel(model: string[]): void | AgPromise<void> {
    this.selection.setSelection(...model);
    this._params.filterChangedCallback();
  }

  getModelAsString?(model: string[]): string {
    return this.statuses()
      .filter((e) => model.includes(e.id))
      .map((e) => e.data.label)
      .join(', ');
  }

  protected onSelectAllChange(): void {
    this._floatingFilterValue.set(null);
    if (this.isSelectAllIntermediate || this.selection.selected.length === 0) {
      this.selection.setSelection(...this.statuses().map((e) => e.id));
    } else {
      this.selection.clear();
    }
    this._onFilterChange();
  }

  protected onOptionSelected(option: StatusGraphNode): void {
    this._floatingFilterValue.set(null);
    this.selection.toggle(option.id);
    this._onFilterChange();
  }

  private _onFilterChange(): void {
    this.setModel(this.selection.selected);
    this._params.filterChangedCallback();
  }
}
