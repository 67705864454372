import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-floating-filter-layout',
  templateUrl: './floating-filter-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
})
export class FloatingFilterLayoutComponent {
  public readonly isActive = input.required<boolean>();

  public readonly clear = output<void>();
}
