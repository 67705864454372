export interface PushNotification {
  id: number;
  tid: string;
  userId: number;
  scope: PushNotificationScope;
  title: string;
  targetEntityId: number;
  message: string;
  date: string;
  read: boolean;
  bookmarked: boolean;
  archived: boolean;
  localDate: Date;
}

export enum PushNotificationScope {
  NewComment = 'wo.comment.new',
  NewWorkOrderReasonCode = 'wo.reason.code.new',
  NewDPUReasonCode = 'dpu.reason.code.new',
  NewRouteReasonCode = 'route.reason.code.new',
}

export type NotificationStatusType =
  | 'active'
  | 'archived'
  | 'bookmarked'
  | 'read';
