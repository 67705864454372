import {
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  OnInit,
  Signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Section } from '@fieldos/models';
import { AutoUnsubscribe, getTranslatableProperties } from '@fieldos/utils';
import { TranslocoService } from '@ngneat/transloco';
import { debounceTime, distinctUntilChanged, Observable, of, tap } from 'rxjs';
import { SectionFormStatus } from '../sections-form.models';
import { SectionsFormStore } from '../sections-form.store';
import { SectionFormType } from './sections-form.models';

@Component({
  selector: '',
  template: '',
})
@AutoUnsubscribe()
export class SectionsFormSectionBaseComponent<
  TSection extends Section<unknown> = Section<unknown>,
> implements OnInit
{
  public readonly sectionId = input.required<string>();

  protected readonly section = computed(
    () => this.store.sectionsMap()[this.sectionId()] as TSection
  );

  protected readonly sectionValue = computed<TSection['value']>(
    () => this.store.sectionValues()[this.sectionId()]
  );

  protected readonly rights = computed(() => {
    const allRights = this.store.rights();
    if (allRights) {
      return allRights[this.sectionId()];
    }

    return {
      show: false,
      canEdit: false,
      mandatory: false,
    };
  });

  protected readonly languageFacade = inject(TranslocoService);
  protected readonly store = inject(SectionsFormStore);
  protected readonly detector = inject(ChangeDetectorRef);

  protected readonly sectionStatus$: Observable<SectionFormStatus> = of({
    valid: false,
    dirty: false,
  });

  protected readonly translatableProperties = computed(() => {
    const lang = this._activeLang();

    return getTranslatableProperties(this.section(), lang) as NonNullable<
      TSection['translatableProperties'][keyof TSection['translatableProperties']]
    >;
  });

  protected get commonProperties(): TSection['properties'] {
    return this.section().properties;
  }

  protected form!: SectionFormType<TSection>;

  protected readonly _destroyRef = inject(DestroyRef);
  protected readonly _emitOnValid: boolean = false;
  protected _sectionValue!: TSection['value'];

  private readonly _activeLang = toSignal(
    inject(TranslocoService).langChanges$
  ) as Signal<string>;

  ngOnInit(): void {
    this.initializeForm();

    if (this.rights()?.mandatory) {
      this.setValidation();
      this.form.updateValueAndValidity();
    }

    if (!this.rights()?.canEdit) {
      this.setDisabled();
    }

    if (this.sectionValue()) {
      this.form.patchValue(this.sectionValue() as any, { emitEvent: true });
      this.detector.detectChanges();
    }

    (this.form.valueChanges as Observable<TSection['value']>)
      .pipe(
        debounceTime(this.store.entity() ? 250 : 0),
        distinctUntilChanged(),
        tap(() => {
          if (this._emitOnValid && this.form.invalid) {
            return;
          }

          this.store.updateSectionValue(
            this.sectionId(),
            this.form.getRawValue()
          );

          this.store.updateSelectedSectionStatus(this.sectionId(), {
            dirty: this.form.dirty,
            valid: !this.form.invalid,
          });
        }),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();

    this.store.updateSelectedSectionStatus(this.sectionId(), {
      dirty: this.form.dirty,
      valid: !this.form.invalid,
    });
  }

  protected initializeForm(): void {
    return;
  }

  protected setValidation(): void {
    return;
  }

  protected setDisabled(): void {
    this.form.disable();
  }
}
