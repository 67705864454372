import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  EventEmitter,
  forwardRef,
  inject,
  input,
  Input,
  Output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DomainEntityBase } from '@fieldos/models';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs';
import { FieldError, FieldWrapperComponent } from '../field-wrapper';

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    TranslocoModule,
    FieldWrapperComponent,
    MatAutocompleteModule,
    MatInputModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectFieldComponent),
      multi: true,
    },
  ],
})
export class SelectFieldComponent<T extends number | string>
  implements ControlValueAccessor
{
  constructor() {
    this.formCtrl.valueChanges
      .pipe(
        tap((value) => {
          if (this._onChange && typeof value !== 'string' && value) {
            this._onChange(value?.id);
          }

          if (value && typeof value !== 'string') {
            this.valueSelected.emit(value.id);
          }
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    effect(
      () => {
        if (this.value()) {
          this._value.set(this.value() || null);
        } else {
          if (!this.formCtrl.touched) {
            return;
          }

          this.clearValue();
        }
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      this.options();
      this._setValue(this._value());
    });

    effect(() => {
      if (this.isRequired()) {
        this.formCtrl.setValidators([Validators.required]);
      } else {
        this.formCtrl.clearValidators();
      }

      setTimeout(() => {
        this.formCtrl.updateValueAndValidity();
        this.formCtrl.markAsTouched();
      });
    });
  }

  @Input() public loading = false;
  @Input() public errors?: FieldError[];
  @Input() public label: string = '';
  @Input() public placeholder?: string;
  @Input() public prefixIcon?: string;

  @Output() public readonly valueSelected = new EventEmitter<T>();
  @Output() public readonly valueRemoved = new EventEmitter<T>();

  public readonly isRequired = input<boolean>();
  public readonly value = input<T | null>();
  public readonly options = input.required<DomainEntityBase<T>[]>();

  protected readonly searchText = signal<string>('');

  protected readonly formCtrl = new FormControl<DomainEntityBase<T> | null>(
    null,
    {
      nonNullable: true,
    }
  );

  protected readonly formChange = toSignal(this.formCtrl.valueChanges);

  protected readonly filteredOptions = computed(() => {
    const filter = this.searchText().toLowerCase();
    return this.options().filter((option) =>
      option.name.toLowerCase().includes(filter)
    );
  });

  private _onChange!: (value: T | null) => void;
  private _onTouch!: () => void;

  private readonly _value = signal<T | null>(null);
  private readonly _transloco = inject(TranslocoService);

  writeValue(value: T | null): void {
    this._value.set(value);
  }

  registerOnChange(fn: (value: T | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled && this.formCtrl.enabled) {
      this.formCtrl.disable({ emitEvent: false });
    }

    if (!isDisabled && this.formCtrl.disabled) {
      this.formCtrl.enable({ emitEvent: false });
    }
  }

  protected readonly displayFn = (entity?: DomainEntityBase): string =>
    entity && entity.name ? this._transloco.translate(entity.name) : '';

  protected onSearchChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.searchText.set(input.value);
  }

  protected clearValue(): void {
    this.formCtrl.setValue(null);
    this._onChange && this._onChange(null);
  }

  private _setValue(value: T | null): void {
    const option = this.options().find((o) => o.id === value) || null;

    this.formCtrl.setValue(option, { emitEvent: false });
  }
}
