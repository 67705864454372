import { Priority } from '../../priority';
import { SectionProperties } from './section-properties';
import { SectionLabelInfoProperties } from './simple-section-properties';

export interface PrioritySectionCommonProperties {
  options: Priority[];
  defaultOption: Priority;
}

export type PrioritySectionProperties = SectionProperties<
  SectionLabelInfoProperties,
  PrioritySectionCommonProperties
>;
