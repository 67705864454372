import {
  SectionCommonPropertiesBase,
  SectionProperties,
} from './section-properties';
import { SectionLabelInfoProperties } from './simple-section-properties';

export interface SingleDataSourceSectionCommonProperties
  extends SectionCommonPropertiesBase {
  optionId: number;
}

export type SingleDataSourceSectionProperties = SectionProperties<
  SectionLabelInfoProperties,
  SingleDataSourceSectionCommonProperties
>;
