import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TitleSection } from '@fieldos/models';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-title-section',
  templateUrl: './sections-form-title-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, JsonPipe],
})
export class SectionsFormTitleSectionComponent extends SectionsFormSectionBaseComponent<TitleSection> {
  protected override initializeForm(): void {
    this.form = new FormControl<string>('', { nonNullable: true });
  }

  protected override setValidation(): void {
    this.form.setValidators([Validators.minLength(3), Validators.required]);
    this.form.updateValueAndValidity({ emitEvent: true });
  }
}
