<div class="flex flex-col gap-4" [formGroup]="form">
  <app-autocomplete-select
    formControlName="locationId"
    [options]="locations() || []"
    [searchProperties]="['name', 'locationCode']"
    (clear)="onLocationCleared()"
    label="{{ translatableProperties().levelOneLabel }}" />

  @if (commonProperties?.enableLocationLevelTwo) {
    <app-autocomplete-select
      formControlName="subLocationId"
      [options]="subLocations() || []"
      [searchProperties]="['name', 'locationCode']"
      label="{{ translatableProperties().levelTwoLabel }}" />
  }
</div>
