import { Injectable, inject } from '@angular/core';
import { DialogService, FileUploadFacade, HttpService } from '@fieldos/core';
import { ExceptionsFacade } from '@fieldos/facades';
import { EntityException, FileModel } from '@fieldos/models';
import { ToastStore } from '@fieldos/store/toast.store';
import { lastValueFrom } from 'rxjs';
import {
  AssignExceptionBody,
  AssignExceptionDialogData,
} from './assign-exception/assign-exception.models';

@Injectable({
  providedIn: 'root',
})
export class ExceptionDialogsFacade {
  private readonly _dialog = inject(DialogService);
  private readonly _http = inject(HttpService);
  private readonly _fileUploader = inject(FileUploadFacade);
  private readonly _toast = inject(ToastStore);
  private readonly _facade = inject(ExceptionsFacade);

  async openInfoDialog(exception: EntityException): Promise<void> {
    const component = await import(
      './exception-info-dialog/exception-info-dialog.component'
    ).then((m) => m.ExceptionInfoDialogComponent);

    await lastValueFrom(
      this._dialog.openDialog<EntityException, void>(component, {
        data: exception,
        autoFocus: false,
        maxWidth: '560px',
        width: '560px',
      })
    );
  }

  async openDialog(data: AssignExceptionDialogData): Promise<boolean> {
    const component = await import(
      './assign-exception/assign-exception-dialog.component'
    ).then((m) => m.AssignExceptionDialogComponent);

    const result = await lastValueFrom(
      this._dialog.openDialog<AssignExceptionDialogData, AssignExceptionBody>(
        component,
        {
          data,
          autoFocus: false,
          maxWidth: '560px',
          width: '560px',
        }
      )
    );

    if (result) {
      let files = [] as FileModel[];
      if (result.media.length > 0) {
        files = await lastValueFrom(
          this._fileUploader.uploadFiles(
            result.media.map((e) => e.file as File),
            'reasoncode'
          )
        );
      }

      try {
        await this._facade.createException({
          ...result,
          media: files,
        });

        this._toast.showSuccessToast('assign_reason_code.assign_success');
        return true;
      } catch (e) {
        const error = e as {
          error: {
            error: { message: string };
          };
        };
        this._toast.showErrorToast(
          error.error?.error?.message || 'assign_reason_code.assign_error'
        );
        return false;
      }
    }

    return false;
  }

  async sendDPUCodesEmail(workOrderId: number): Promise<void> {
    return await this._http.get<void>(
      `/docgenerator/wodpu/email/?woid=${workOrderId}&tpl_id=1`
    );
  }

  async downloadDPUCodes(workOrderId: number): Promise<void> {
    const response = await this._http.get<{
      filename: string;
      content: string;
    }>(`/docgenerator/wodpu/?woid=${workOrderId}&tpl_id=1`);

    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${response.content}`;
    link.download = response.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
