import { Injectable } from '@angular/core';
import { AccessToken } from '@fieldos/models';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  setTemporaryToken(token: string): void {
    const accessToken: AccessToken = {
      accessToken: token,
      expiresIn: 0,
      tokenType: 'Bearer',
      scope: '',
    };

    this.accessToken = accessToken;
  }

  public set accessToken(token: AccessToken | null) {
    localStorage.setItem('accessToken', JSON.stringify(token));
  }

  public get accessToken(): AccessToken | null {
    const token = localStorage.getItem('accessToken');
    return token ? JSON.parse(token) : null;
  }

  public set clientId(clientId: string) {
    localStorage.setItem('clientId', clientId);
  }

  public get clientId(): string {
    return localStorage.getItem('clientId') ?? '';
  }

  clearTokens(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('clientId');
  }

  updateAccessToken(newToken: string): void {
    const token = this.accessToken as AccessToken;
    token.accessToken = newToken;

    this.accessToken = token;
  }
}
