import { DomainEntityBase } from '../entity-base';
import { FieldBasedEntity } from '../fields';

export interface LocationModel extends FieldBasedEntity {
  typeId: number;
  parentLocationId: number | null;
}

export interface LocationDataSourceModel extends DomainEntityBase<number> {
  locationCode: string;
}
