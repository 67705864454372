<div *ngIf="opened" class="fuse-vertical-navigation-wrapper">
  <!-- Header -->
  <div class="fuse-vertical-navigation-header">
    <ng-content select="[fuseVerticalNavigationHeader]"></ng-content>
  </div>

  <!-- Content -->
  <div class="flex">
    <div
      #navigationContent
      class="fuse-vertical-navigation-content min-w-[220px] max-w-[220px] w-[220px] h-full border-r"
      [fuseScrollbarOptions]="{
        wheelPropagation: inner,
        suppressScrollX: true
      }"
      fuseScrollbar>
      <!-- Content header -->
      <div class="fuse-vertical-navigation-content-header">
        <ng-content select="[fuseVerticalNavigationContentHeader]"></ng-content>
      </div>

      <!-- Items -->
      <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
        <!-- Skip the hidden items -->
        <ng-container *appHasPermissionStartingWith="item.permissionStartsWith">
          <ng-container *appHasPermission="item.permissions">
            <ng-container
              *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
              <!-- Aside -->
              <ng-container *ngIf="item.type === 'aside'">
                <fuse-vertical-navigation-aside-item
                  [activeItemId]="activeAsideItemId || ''"
                  [name]="name"
                  [autoCollapse]="autoCollapse"
                  [item]="item"
                  [skipChildren]="true"
                  (click)="
                    toggleAside(item)
                  "></fuse-vertical-navigation-aside-item>
              </ng-container>

              <!-- Basic -->
              <ng-container *ngIf="item.type === 'basic'">
                <fuse-vertical-navigation-basic-item
                  [name]="name"
                  [item]="item"
                  [shouldUnselect]="!!activeAsideItemId"
                  (click)="closeAside()"></fuse-vertical-navigation-basic-item>
              </ng-container>

              <!-- Collapsable -->
              <ng-container *ngIf="item.type === 'collapsable'">
                <fuse-vertical-navigation-collapsable-item
                  [name]="name"
                  [autoCollapse]="autoCollapse"
                  [item]="item"></fuse-vertical-navigation-collapsable-item>
              </ng-container>

              <!-- Divider -->
              <ng-container *ngIf="item.type === 'divider'">
                <fuse-vertical-navigation-divider-item
                  [name]="name"
                  [item]="item"></fuse-vertical-navigation-divider-item>
              </ng-container>

              <!-- Group -->
              <ng-container *ngIf="item.type === 'group'">
                <fuse-vertical-navigation-group-item
                  [name]="name"
                  [autoCollapse]="autoCollapse"
                  [item]="item"></fuse-vertical-navigation-group-item>
              </ng-container>

              <!-- Spacer -->
              <ng-container *ngIf="item.type === 'spacer'">
                <fuse-vertical-navigation-spacer-item
                  [name]="name"
                  [item]="item"></fuse-vertical-navigation-spacer-item>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Content footer -->
      <div class="fuse-vertical-navigation-content-footer">
        <ng-content select="[fuseVerticalNavigationContentFooter]"></ng-content>
      </div>
    </div>

    <!-- Aside -->
    <ng-container *ngIf="activeAsideItemId">
      <div
        class="fuse-vertical-navigation-aside-wrapper min-w-[220px] w-[220px] max-w-[220px]"
        [fuseScrollbarOptions]="{
          wheelPropagation: false,
          suppressScrollX: true
        }"
        fuseScrollbar>
        <!-- Items -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
          <!-- Skip the hidden items -->
          <ng-container
            *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
            <!-- Aside -->
            <ng-container
              *ngIf="item.type === 'aside' && item.id === activeAsideItemId">
              <fuse-vertical-navigation-aside-item
                [name]="name"
                class="pt-0"
                [autoCollapse]="autoCollapse"
                [item]="item"></fuse-vertical-navigation-aside-item>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
