import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  effect,
  forwardRef,
  input,
  Input,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { filter, tap } from 'rxjs';
import { FieldError, FieldWrapperComponent } from '../field-wrapper';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    FieldWrapperComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldComponent),
      multi: true,
    },
  ],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class TextFieldComponent implements ControlValueAccessor {
  constructor() {
    this.formCtrl.valueChanges
      .pipe(
        filter(() => this.formCtrl.dirty),
        tap((value) => {
          const parsedValue =
            this.type === 'number' && value
              ? parseFloat(value || '0')
              : value || '';

          if (this._onChange) {
            this._onChange(parsedValue);
          }

          this.valueChange.emit(parsedValue as string);
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    effect(() => {
      if (this.isRequired()) {
        this.formCtrl.setValidators([Validators.required]);
      } else {
        this.formCtrl.clearValidators();
      }

      setTimeout(() => {
        this.formCtrl.updateValueAndValidity();
        this.formCtrl.markAsTouched();
      });
    });
  }

  @Input() public fieldClass: string = '';
  @Input() public hint = '';
  @Input() public prefixIcon?: string;
  @Input() public type: 'text' | 'password' | 'number' = 'text';
  @Input() public label: string = '';
  @Input() public placeholder: string = '';
  @Input() public errors?: FieldError[];
  @Input({ transform: booleanAttribute }) public isTextarea = false;

  @Input({ transform: booleanAttribute }) public set readonly(value: boolean) {
    if (value) {
      this.formCtrl.disable();
    } else {
      this.formCtrl.enable();
    }
  }

  @Input() public set value(value: string) {
    this.writeValue(value);
  }

  public readonly valueChange = output<string>();
  public readonly isRequired = input(false, {
    transform: booleanAttribute,
  });

  protected readonly formCtrl = new FormControl<string>('');

  private _onChange?: (value: string | number) => void;
  private _onTouch?: () => void;

  writeValue(value: string): void {
    this.formCtrl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: string | number) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled && this.formCtrl.enabled) {
      this.formCtrl.disable();
    }

    if (!isDisabled && this.formCtrl.disabled) {
      this.formCtrl.enable();
    }
  }
}
