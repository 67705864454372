<div class="flex justify-start">
  <button
    mat-icon-button
    [disabled]="disabled"
    (click)="onStarClick($event, 0)">
    <mat-icon class="text-6xl">
      @if (rating === 0) {
        star_border
      } @else if (rating === 0.5) {
        star_half
      } @else if (rating >= 1) {
        star
      }
    </mat-icon>
  </button>

  <button
    mat-icon-button
    [disabled]="disabled"
    (click)="onStarClick($event, 1)">
    <mat-icon class="text-6xl">
      @if (rating <= 1) {
        star_border
      } @else if (rating === 1.5) {
        star_half
      } @else if (rating >= 2) {
        star
      }
    </mat-icon>
  </button>

  <button
    mat-icon-button
    [disabled]="disabled"
    (click)="onStarClick($event, 2)">
    <mat-icon class="text-6xl">
      @if (rating <= 2) {
        star_border
      } @else if (rating === 2.5) {
        star_half
      } @else if (rating >= 3) {
        star
      }
    </mat-icon>
  </button>

  <button
    mat-icon-button
    [disabled]="disabled"
    (click)="onStarClick($event, 3)">
    <mat-icon class="text-6xl">
      @if (rating <= 3) {
        star_border
      } @else if (rating === 3.5) {
        star_half
      } @else if (rating >= 4) {
        star
      }
    </mat-icon>
  </button>

  <button
    mat-icon-button
    [disabled]="disabled"
    (click)="onStarClick($event, 4)">
    <mat-icon class="text-6xl">
      @if (rating <= 4) {
        star_border
      } @else if (rating === 4.5) {
        star_half
      } @else if (rating >= 5) {
        star
      }
    </mat-icon>
  </button>
</div>
