import { inject, Injectable } from '@angular/core';
import { HttpService } from '@fieldos/core';
import {
  Section,
  SectionBasedEntityType,
  SectionsResponse,
} from '@fieldos/models';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root',
})
export class SectionsDataService {
  private readonly _http = inject(HttpService);

  fetchForScopeId(
    scopeId: number,
    entityType: SectionBasedEntityType
  ): Promise<SectionsResponse> {
    return this._http.get<SectionsResponse>(
      `${this._getUrl(entityType)}/${scopeId}`
    );
  }

  fetchSectionsById(
    workspaceScopeId: number,
    sectionConfigId: string,
    entityType: SectionBasedEntityType,
    includeAllTranslations?: boolean
  ): Promise<SectionsResponse> {
    return this._http.get<SectionsResponse>(
      `${this._getUrl(entityType)}/${workspaceScopeId}?${
        includeAllTranslations ? 'includeTranslations=1&' : ''
      }version=${sectionConfigId}`
    );
  }

  fetchSections(
    workspaceScopeId: number,
    entityType: SectionBasedEntityType,
    includeAllTranslations?: boolean
  ): Promise<SectionsResponse> {
    return this._http.get<SectionsResponse>(
      `${this._getUrl(entityType)}/${workspaceScopeId}${
        includeAllTranslations ? '?includeTranslations=1' : ''
      }`
    );
  }

  saveSections(
    workspaceScopeId: number,
    sections: Section[],
    entityType: SectionBasedEntityType
  ): Promise<void> {
    return this._http.put<void>(
      `${this._getUrl(entityType)}/${workspaceScopeId}`,
      {
        id: Guid.create().toString(),
        timestamp: Date.now(),
        sections,
      }
    );
  }

  private _getUrl(entityType: SectionBasedEntityType): string {
    if (entityType === 'servicerequests') {
      return '/sr-scopes-sections';
    }
    return '/wo-scopes-sections';
  }
}
