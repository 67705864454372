import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { NAVIGATION_CONFIG } from '@fieldos/core/config';
import { Claim } from '@fieldos/models';
import { AuthStore, ClaimsStore } from '@fieldos/store/auth';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { combineLatest, filter, map } from 'rxjs';

export const ClaimsGuard: CanActivateFn | CanActivateChildFn = (
  route,
  state
) => {
  const router: Router = inject(Router);
  const navigationConfig = inject(NAVIGATION_CONFIG);

  const store = inject(AuthStore);
  const claimsStore = inject(ClaimsStore);

  const url = state.url;

  const claimsLoaded = claimsStore.loaded;

  // Check the authentication status
  return combineLatest([
    toObservable(store.isAuthenticated),
    toObservable(claimsLoaded),
  ]).pipe(
    filter(
      ([authenticated, claimsLoaded]) => authenticated !== null && claimsLoaded
    ),
    map((authenticated) => {
      const claims = claimsStore.claims();
      const hasDashboardAccess = claims.includes('dashboard.list');

      const routeClaims = (route.data['permissions'] || []) as Claim[];
      const routeConfig = findRouteConfig(url, navigationConfig);

      if (url === '/' && hasDashboardAccess) {
        router.navigateByUrl('/dashboard');
        return true;
      }

      if (url === '/' && !hasDashboardAccess) {
        return true;
      }

      if (routeConfig) {
        if (authenticated) {
          if (
            (!routeConfig.permissions && !routeConfig.permissionStartsWith) ||
            routeConfig.permissions?.length === 0
          ) {
            return true;
          }

          const canAccess =
            routeConfig.permissions?.some((permission) =>
              claims.includes(permission)
            ) ||
            claims.some(
              (claim) =>
                routeConfig.permissionStartsWith &&
                claim.startsWith(routeConfig.permissionStartsWith)
            );

          if (!canAccess && url !== '/dashboard') {
            router.navigate(['/404']);
          }

          if (!canAccess && url === '/dashboard') {
            router.navigate(['/home']);
          }

          return canAccess;
        }
      }

      if (!routeConfig && routeClaims.length) {
        const canAccess = routeClaims.some((claim) => claims.includes(claim));

        if (!canAccess && url !== '/dashboard') {
          router.navigate(['/404']);
        }

        if (!canAccess && url === '/dashboard') {
          router.navigate(['/home']);
        }

        return canAccess;
      }

      return true;
    })
  );
};

const findRouteConfig = (
  url: string,
  navigationConfig: FuseNavigationItem[]
): FuseNavigationItem | undefined =>
  navigationConfig.find((item: FuseNavigationItem) => {
    if (item.link === url) {
      return item;
    }

    if (item.children) {
      return findRouteConfig(url, item.children);
    }

    return undefined;
  });
