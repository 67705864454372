<div class="bg-card border-l flex flex-col w-full h-full">
  <div
    class="h-14 flex-shrink-0 w-full flex justify-between items-center border-b px-4">
    <h3 class="text-on-base font-bold">
      {{ 'common.discussion' | transloco }}
    </h3>

    <div class="flex items-center gap-1">
      <button
        mat-icon-button
        matTooltip="{{ 'chat_window.close' | transloco }}"
        (click)="drawerClose.emit()">
        <mat-icon svgIcon="mat_solid:arrow_back" />
      </button>

      @if (showClearButton()) {
        <button
          mat-icon-button
          matTooltip="{{ 'common.escape_to_close' | transloco }}"
          (click)="closeClick.emit()">
          <mat-icon svgIcon="mat_solid:clear" />
        </button>
      }
    </div>
  </div>

  <app-chat-messages
    [chatMessages]="chatMessages()"
    [loading]="loading()"
    (fileAdded)="onFileUploaded($event)"
    (sendMessage)="onSendMessage($event)" />
</div>
