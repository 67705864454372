export interface GraphNode<T> {
  id: string;
  data: T;
}

export interface GraphNodeConnection {
  sourceId: string;
  targetId: string;
}

export interface GraphTreeNode<T> extends GraphNode<T> {
  children: GraphTreeNode<T>[];
  level: number;
}

export interface FlatGraphNode<T = any> {
  id: string;
  data: T;
  connections: string[];
}
