export const fileToBase64 = (
  file: File | undefined,
  fallbackImg = ''
): Promise<string> =>
  new Promise((resolve) => {
    const isImage = !!file?.type.includes('image/');
    if (!file || !isImage) {
      return resolve(fallbackImg);
    }

    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      resolve(e.target?.result as string);
    };
    reader.readAsDataURL(file);
  });

export const base64ToFile = (base64: string, filename: string): File => {
  const arr = base64.split(',');
  const obj = arr[0].match(/:(.*?);/);
  const mimeType = obj ? obj[1] : '';
  const bitString = atob(arr[1]);
  const bits = new Uint8Array(bitString.length);
  let idx = bitString.length;

  while (idx--) {
    bits[idx] = bitString.charCodeAt(idx);
  }

  return new File([bits], filename, { type: mimeType });
};
