<div class="flex gap-2 my-auto" [formGroup]="form">
  <mat-select formControlName="operator" class="w-auto flex-shrink">
    <mat-option [value]="1"> = </mat-option>

    <mat-option [value]="2"> > </mat-option>

    <mat-option [value]="3"> < </mat-option>
  </mat-select>

  <div
    class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper flex-grow"
    ref="eWrapper"
    role="presentation">
    <input
      id="ag-132-input"
      formControlName="value"
      class="ag-input-field-input ag-text-field-input"
      max="5"
      min="0"
      placeholder="{{ 'common.search' | transloco }}"
      ref="eInput"
      type="number" />
  </div>
</div>
