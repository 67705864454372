<div class="flex flex-col gap-4 flex-grow overflow-auto">
  @if (errors() && errors()?.length) {
    @for (error of errors(); track error.message) {
      <fuse-alert dismissible type="error">
        {{ error.message }}
      </fuse-alert>
    }
  }
  @for (field of store.topLevelFields(); track field.id) {
    <app-field-form [fieldId]="field.id" [fieldSubType]="field.subtype" />
  }
</div>

<div class="flex justify-end">
  <button mat-flat-button (click)="cancel.emit()">
    {{ 'common.cancel' | transloco }}
  </button>

  <button
    mat-flat-button
    [disabled]="store.invalid()"
    (click)="save.emit(store.values())"
    color="primary">
    {{ 'common.save' | transloco }}
  </button>
</div>
