import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

export const apiUrlInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  if (_shouldSetApiUrl(req.url)) {
    const url = `${
      environment.apiUrl.endsWith('/')
        ? environment.apiUrl
        : `${environment.apiUrl}/`
    }${req.url.startsWith('/') ? req.url.substring(1) : req.url}`;

    return next(
      req.clone({
        url,
      })
    );
  }

  return next(req);
};

const _shouldSetApiUrl = (requestUrl: string): boolean =>
  requestUrl.indexOf('http') !== 0 &&
  requestUrl.indexOf('./assets/fonts') !== 0 &&
  requestUrl.indexOf('./assets/icons') !== 0 &&
  requestUrl.indexOf('./assets/images') !== 0 &&
  requestUrl.indexOf('./assets/styles') !== 0 &&
  requestUrl.indexOf('./assets/i18n') !== 0 &&
  requestUrl.indexOf('./assets/fonts') !== 0 &&
  requestUrl.indexOf('./assets/icons') !== 0 &&
  requestUrl.indexOf('./assets/images') !== 0 &&
  requestUrl.indexOf('./assets/styles') !== 0 &&
  requestUrl.indexOf('./assets/i18n') !== 0 &&
  requestUrl.indexOf('assets/fonts') !== 0 &&
  requestUrl.indexOf('assets/icons') !== 0 &&
  requestUrl.indexOf('assets/images') !== 0 &&
  requestUrl.indexOf('assets/styles') !== 0 &&
  requestUrl.indexOf('assets/i18n') !== 0 &&
  requestUrl.indexOf('assets/fonts') !== 0 &&
  requestUrl.indexOf('assets/icons') !== 0 &&
  requestUrl.indexOf('assets/images') !== 0 &&
  requestUrl.indexOf('assets/styles') !== 0 &&
  requestUrl.indexOf('assets/i18n') !== 0;
