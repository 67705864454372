<mat-button-toggle-group [value]="value()">
  <mat-button-toggle value="dontShow">{{
    'rights.dontShow' | transloco
  }}</mat-button-toggle>
  <mat-button-toggle value="canEdit">
    {{ 'rights.canEdit' | transloco }}
  </mat-button-toggle>
  <mat-button-toggle value="mandatory">
    {{ 'rights.mandatory' | transloco }}
  </mat-button-toggle>
</mat-button-toggle-group>
