import { Point } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { filterEmpty } from '@fieldos/utils';
import { ComponentStore } from '@ngrx/component-store';
import { PanZoomModel } from 'ngx-panzoom';
import { mergeMap, Observable } from 'rxjs';

interface PinningToolState {
  points: Point[];
  model: PanZoomModel | null;
  scale: number;
  image: HTMLImageElement | null;
  selectedPointIndex?: number;
  isEditing: boolean;
  isPreparing: boolean;
}

const initialState: PinningToolState = {
  points: [],
  model: null,
  scale: 1,
  image: null,
  isEditing: false,
  isPreparing: false,
};

@Injectable()
export class PinningToolStore extends ComponentStore<PinningToolState> {
  constructor() {
    super(initialState);
  }

  public readonly points$ = this.select((state) => state.points);
  public readonly model$ = this.select((state) => state.model);
  public readonly isEditing$ = this.select((state) => state.isEditing);
  public readonly selectedPointIndex$ = this.select(
    (state) => state.selectedPointIndex
  );
  public readonly scale$ = this.select((state) => state.scale);
  public readonly isPanning$ = this.select((state) => state.model?.isPanning);
  public readonly isPreparing$ = this.select((state) => state.isPreparing);
  public readonly selectedPoint$ = this.select((state) =>
    state.selectedPointIndex !== undefined
      ? state.points[state.selectedPointIndex]
      : null
  );

  public readonly setIsPreparing = this.updater(
    (state, isPreparing: boolean) => ({
      ...state,
      isPreparing,
    })
  );

  public readonly setIsEditing = this.updater((state, isEditing: boolean) => ({
    ...state,
    isEditing,
  }));

  public readonly setSelectedPointIndex = this.updater(
    (state, selectedPointIndex: number) => ({
      ...state,
      selectedPointIndex,
    })
  );

  public readonly setImage = this.updater((state, image: HTMLImageElement) => ({
    ...state,
    image,
  }));

  public readonly prepareNewPoint = this.updater((state, point: Point) => ({
    ...state,
    isEditing: false,
    isPreparing: true,
    points: [
      ...state.points,
      this._calculateActualPoint(
        point,
        state.image?.getBoundingClientRect() as DOMRect,
        state.scale
      ),
    ],
    selectedPointIndex: state.points.length,
  }));

  public readonly reposition = this.updater((state, fromIndex: number) => {
    const points = [...state.points];
    points.splice(fromIndex, 1);

    return {
      ...state,
      points,
    };
  });

  public readonly cancelAddingPin = this.updater(
    (state, repositioningFromIndex: number) => {
      if (state.selectedPointIndex === undefined && !state.isPreparing) {
        return state;
      }

      if (state.isEditing) {
        return {
          ...state,
          isEditing: false,
          isPreparing: false,
          selectedPointIndex: undefined,
        };
      }

      const points = [...state.points];

      if (repositioningFromIndex > -1) {
        points.splice(points.length - 1, 1);
      } else {
        points.splice(state.selectedPointIndex as number, 1);
      }

      return {
        ...state,
        points,
        selectedPointIndex: undefined,
        isPreparing: false,
      };
    }
  );

  public readonly setPoints = this.updater((state, points: Point[]) => ({
    ...state,
    points,
  }));

  public readonly clearSelectedPoint = this.updater((state) => ({
    ...state,
    selectedPointIndex: undefined,
  }));

  public readonly relativePoints$: Observable<Point[]> = this.select(
    (state) => state.model
  ).pipe(
    filterEmpty(),
    mergeMap(() =>
      this.select((state) =>
        state.points.map((point) =>
          this._calculateRelativePoint(
            point,
            state.scale,
            state.model?.pan as Point
          )
        )
      )
    )
  );

  public readonly onPanelModelUpdate = this.updater(
    (state, model: PanZoomModel) => {
      const style = state.image?.parentElement?.style?.transform || '';
      const scaleArr = new RegExp(/\(([^)]+)\)/).exec(style) as RegExpExecArray;
      const scale = parseFloat(scaleArr[1]);

      return {
        ...state,
        scale,
        model,
      };
    }
  );

  public readonly getPointAt = (index: number): Observable<Point> =>
    this.select((state) => state.points[index]);

  private _calculateActualPoint(
    cursorPoint: Point,
    imageBox: DOMRect,
    scale: number
  ): Point {
    const imageX = Math.floor((1 / scale) * (cursorPoint.x - imageBox.left));
    const imageY = Math.floor((1 / scale) * (cursorPoint.y - imageBox.top));

    return {
      x: imageX,
      y: imageY,
    };
  }

  private _calculateRelativePoint(
    point: Point,
    scale: number,
    pan: Point
  ): Point {
    let x = point.x * scale;
    let y = point.y * scale;

    x += pan.x;
    y += pan.y;

    return {
      x,
      y,
    };
  }
}
