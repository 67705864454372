import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { FieldError } from '@fieldos/components';
import {
  CategoryTypeFieldsDataService,
  FieldEntityDataService,
} from '@fieldos/data-services';
import {
  AssetFieldSubType,
  CategoryType,
  CommonFieldSubType,
  ContractorFieldSubType,
  CustomerFieldSubType,
  Field,
  FieldSubType,
  LocationFieldSubType,
  UserFieldSubType,
} from '@fieldos/models';
import { FieldRights } from '@fieldos/models/fields/field-permissions';
import {
  CategoryTypeFieldsStore,
  CategoryTypePermissionsStore,
  ClaimsStore,
  ToastStore,
} from '@fieldos/store';
import {
  patchState,
  signalStoreFeature,
  withMethods,
  withState,
} from '@ngrx/signals';

interface TypedFieldsFormState {
  categoryType: CategoryType;
  fields: Field[];
  entityId: number | undefined;
  loading: boolean;
  typeFieldSubType: FieldSubType | undefined;
  errors: FieldError[];
  permissions: Record<string, FieldRights>;
}

const typeFieldSubTypeMap: Record<CategoryType, FieldSubType> = {
  assets: AssetFieldSubType.AssetType,
  locations: LocationFieldSubType.LocationType,
  users: UserFieldSubType.UserType,
  contractors: ContractorFieldSubType.ContractorType,
  customers: CustomerFieldSubType.CustomerType,
};

export const withFieldsFormStore = (categoryType: CategoryType) =>
  signalStoreFeature(
    withState<TypedFieldsFormState>({
      categoryType,
      fields: [],
      loading: true,
      entityId: undefined,
      typeFieldSubType: typeFieldSubTypeMap[categoryType],
      errors: [],
      permissions: {},
    }),
    withMethods(
      (
        store,
        fieldsStore = inject(CategoryTypeFieldsStore),
        fieldsDataService = inject(CategoryTypeFieldsDataService),
        dataService = inject(FieldEntityDataService),
        typeFieldsDataService = inject(CategoryTypeFieldsDataService),
        toast = inject(ToastStore),
        categoryTypePermissionsStore = inject(CategoryTypePermissionsStore),
        claimStore = inject(ClaimsStore)
      ) => ({
        initializePermissions(
          categoryType: CategoryType,
          typeId: number
        ): void {
          const permissions = categoryTypePermissionsStore.selectRights(
            categoryType,
            typeId
          );

          const fields = store.fields();

          if (permissions) {
            patchState(store, {
              permissions: fields.reduce(
                (acc, field) => ({
                  ...acc,
                  [field.id]: permissions[field.id] ?? {
                    canEdit: false,
                    mandatory: false,
                    show: true,
                  },
                }),
                {}
              ),
            });
          } else {
            const canEdit = claimStore
              .claims()
              .includes(`${store.categoryType()}.edit`);

            patchState(store, {
              permissions: fields.reduce(
                (acc, field) => {
                  acc[field.id] = {
                    canEdit,
                    mandatory: false,
                    show: true,
                  };
                  return acc;
                },
                {} as Record<string, FieldRights>
              ),
            });
          }
        },
        async initializeCreateForm(typeId: number): Promise<void> {
          const { fields } = await fieldsDataService.fetchFields(
            categoryType,
            typeId
          );

          const typeField = fields.find(
            (e) => e.subtype === store.typeFieldSubType()
          ) as Field;

          if (typeField) {
            typeField.value = typeId;
          }

          const activeField = fields.find(
            (e) => e.subtype === CommonFieldSubType.Active
          ) as Field<boolean>;

          if (activeField) {
            activeField.value = true;
          }

          patchState(store, {
            fields,
            loading: false,
          });

          this.initializePermissions(categoryType, typeId);
        },
        async initializeEditForm(entityId: string): Promise<void> {
          patchState(store, { loading: false, entityId: +entityId });
          try {
            const entity = await dataService.fetchOne(categoryType, +entityId);

            const typeIdFields = await typeFieldsDataService.fetchFields(
              store.categoryType() as CategoryType,
              entity.typeId
            );

            const fields = typeIdFields.fields.map((field) => ({
              ...field,
              value: entity.value.find((f) => f.id === field.id)?.value,
            }));

            patchState(store, {
              fields,
              loading: false,
            });

            this.initializePermissions(categoryType, entity.typeId);
          } catch (e) {
            console.error(e);
          }
        },
        async save(fields: Field[]): Promise<boolean> {
          patchState(store, { errors: [] });
          const entityId = store.entityId();

          try {
            if (entityId) {
              await dataService.update(store.categoryType(), entityId, fields);
              toast.showInfoToast(`fields.${store.categoryType()}.updated`);
            } else {
              await dataService.create(store.categoryType(), fields);
              toast.showInfoToast(`fields.${store.categoryType()}.created`);
            }
            return true;
          } catch (e) {
            toast.showErrorToast(
              `fields.${store.categoryType()}.created_error`
            );

            if (e instanceof HttpErrorResponse) {
              const errors = e.error as { error: Record<string, string> };
              patchState(store, {
                errors: Object.entries(errors.error).map(([_, value]) => ({
                  message: value,
                })),
              });
            }
            return false;
          }
        },
        async changeType(typeId: number): Promise<void> {
          patchState(store, { loading: true });

          try {
            const fields = fieldsStore.selectFields(
              store.categoryType(),
              typeId
            );

            const entityTypeField = fields.find(
              (field) => field.subtype === store.typeFieldSubType()
            ) as Field;

            if (entityTypeField) {
              entityTypeField.value = typeId;
            }

            const activeField = fields.find(
              (e) => e.subtype === CommonFieldSubType.Active
            ) as Field<boolean>;

            if (activeField) {
              activeField.value = true;
            }

            patchState(store, {
              fields,
              loading: false,
            });

            this.initializePermissions(store.categoryType(), typeId);
          } catch (e) {
            toast.showErrorToast('location_types.could_not_fetch_error');
          }
        },
        onFieldValueChange(field: Field): void {
          if (field.subtype === store.typeFieldSubType()) {
            this.changeType(field.value as number);
          }
        },
      })
    )
  );
