import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
  ExceptionDialogsFacade,
  ExceptionTargetType,
} from '@fieldos/business/exceptions';

import { HasPermissionDirective } from '@fieldos/directives';
import {
  DataSourceStore,
  StandardDataSourceId,
} from '@fieldos/store/data-source-cache';
import { DPUStore } from '@fieldos/store/dpu.store';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-dpu-section-item',
  templateUrl: './dpu-section-item.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    TranslocoModule,
    MatIconModule,
    MatMenuModule,
    HasPermissionDirective,
  ],
})
export class DpuSectionItemComponent {
  public readonly dpuId = input.required<number>();
  public readonly canEdit = input.required<boolean>();

  public readonly remove = output<void>();

  protected readonly dpu = computed(() => {
    this._store.entityMap()[this.dpuId()];

    return this._store.entityMap()[this.dpuId()];
  });

  protected readonly dpuPackagingName = computed(() => {
    if (this.dpu()) {
      const packagingDataSource =
        this._dataSourceStore.selectNormalizedDataSource(
          StandardDataSourceId.Packaging
        );
      const packaging = packagingDataSource()[this.dpu().packagingId];

      return packaging?.name || 'n/a';
    }

    return 'n/a ';
  });

  protected readonly dpuStatus = computed(() => {
    if (this.dpu()) {
      const statusDataSource = this._dataSourceStore.selectNormalizedDataSource(
        StandardDataSourceId.DPUStatuses
      )();

      const status = statusDataSource[this.dpu().statusId];
      return status?.name || 'n/a';
    }

    return 'n/a';
  });

  private readonly _store = inject(DPUStore);
  private readonly _dataSourceStore = inject(DataSourceStore);
  private readonly _exceptionDialogFacade = inject(ExceptionDialogsFacade);

  protected openAddReasonCodeDialog(): void {
    this._exceptionDialogFacade.openDialog({
      targetId: this.dpuId(),
      targetType: ExceptionTargetType.DPU,
    });
  }
}
