import { SectionProperties } from './section-properties';
import { SectionLabelInfoProperties } from './simple-section-properties';

export interface RatingSectionCommonProperties {
  showStars: boolean;
  showComments: boolean;
}

export type RatingSectionProperties = SectionProperties<
  SectionLabelInfoProperties,
  RatingSectionCommonProperties
>;
