@if (
  {
    points: points$ | async,
    selectedPointIndex: selectedPointIndex$ | async,
    isEditing: isEditing$ | async
  };
  as vm
) {
  <div class="h-full w-full relative">
    <pan-zoom #panZoom class="w-full h-full" [config]="config">
      <img
        #image
        class="max-w-none w-auto m-0"
        [src]="imageSrc"
        (click)="onPinPrepared($event)" />
    </pan-zoom>

    @for (point of vm.points; track $index) {
      <div
        class="rounded-full absolute -translate-x-1/2 -translate-y-7 cursor-pointer"
        [style.left.px]="point.x"
        [style.top.px]="point.y"
        (click)="onPointClick($index, $event)">
        <mat-icon
          class="scale-150"
          [color]="
            $index === vm.selectedPointIndex &&
            $index !== repositioningFromIndex
              ? 'success'
              : $index === repositioningFromIndex
                ? 'warn'
                : 'primary'
          ">
          location_on
        </mat-icon>
      </div>
    }
  </div>

  <app-point-menu
    [isEditing]="vm.isEditing || false"
    [loading]="loading"
    [pointTitle]="pointTitle"
    (cancel)="cancelAddingPoint()"
    (confirm)="confirmAddedPoint()"
    (remove)="deleteSelectedPoint()"
    (reposition)="repositionSelectedPoint($event)"></app-point-menu>
}
