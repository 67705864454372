import { OverlayModule } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  booleanAttribute,
  forwardRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import {
  ClickOutsideDirective,
  ClickStopPropagationDirective,
} from '@fieldos/directives';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    ClickStopPropagationDirective,
    ClickOutsideDirective,
    OverlayModule,
    TranslocoModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true,
    },
  ],
})
export class DatetimePickerComponent implements ControlValueAccessor, OnInit {
  constructor() {
    inject(TranslocoService)
      .langChanges$.pipe(
        tap((language) => (this._dateTransformer = new DatePipe(language))),
        takeUntilDestroyed()
      )
      .subscribe();

    this.hoursCtrl.valueChanges
      .pipe(
        takeUntilDestroyed(),
        tap((value) => {
          if (value > 24) {
            this.hoursCtrl.setValue(24);
          }

          if (value < 0) {
            this.hoursCtrl.setValue(0);
          }
        })
      )
      .subscribe();

    this.minutesCtrl.valueChanges
      .pipe(
        takeUntilDestroyed(),
        tap((value) => {
          if (value > 59) {
            this.minutesCtrl.setValue(59);
          }

          if (value < 0) {
            this.minutesCtrl.setValue(0);
          }
        })
      )
      .subscribe();
  }

  @Input() public useTime = true;
  @Input() public label = '';
  @Input({ transform: booleanAttribute }) public disabled = false;

  @Input() public set value(date: Date | null) {
    this.localValue = date;
    if (date) {
      this.hoursCtrl.setValue(date.getHours());
      this.minutesCtrl.setValue(date.getMinutes());
    }
  }

  @Output() public readonly valueChange = new EventEmitter<Date | null>();

  protected hoursCtrl = new FormControl<number>(new Date().getHours(), {
    nonNullable: true,
  });

  protected minutesCtrl = new FormControl<number>(new Date().getMinutes(), {
    nonNullable: true,
  });

  protected localValue: Date | null = null;
  protected menuOpen = false;

  protected showTime = false;

  protected get formattedValue(): string {
    if (!this.localValue) {
      return '';
    }

    if (this.useTime) {
      return (
        this._dateTransformer.transform(this.localValue, 'MMM d, y HH:mm') || ''
      );
    } else {
      return this._dateTransformer.transform(this.localValue) || 'MMM d, y';
    }
  }

  protected isDisabled = false;

  private _onChange!: (date: Date | null) => void;
  private _onTouch!: () => void;
  private _dateTransformer = new DatePipe(navigator.language || 'en-US');

  ngOnInit(): void {
    if (this.useTime) {
      this.showTime = true;
    }
  }

  writeValue(value: Date | null): void {
    this.localValue = value;
  }

  registerOnChange(fn: (date: Date | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  protected onSelectionChange(event: Date): void {
    this.localValue = event;
  }

  protected onSave(): void {
    this.menuOpen = false;
    this._emitValue();
  }

  protected closeMenu(): void {
    this.menuOpen = false;
    this._emitValue();
  }

  protected openMenu(): void {
    if (!this.disabled && !this.isDisabled) {
      this.menuOpen = true;
    }
  }

  protected clear(): void {
    this.localValue = null;
    this.minutesCtrl.setValue(0);
    this.hoursCtrl.setValue(0);

    this.valueChange.emit(null);
    this._onChange && this._onChange(null);
  }

  private readonly _emitValue = (): void => {
    if (this.localValue) {
      const date = new Date(this.localValue);
      if (this.useTime) {
        date.setHours(this.hoursCtrl.value);
        date.setMinutes(this.minutesCtrl.value);
      }

      this.valueChange.emit(date);
      this._onChange && this._onChange(date);
    }
  };
}
