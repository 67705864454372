<div
  [class.border-b]="!isLast"
  class="flex flex-col gap-2 relative p-4"
  [formGroup]="form">
  <div class="flex items-center">
    <span class="font-bold flex-grow">
      {{ 'work_orders.sections.assigness.work_category' | transloco }}
      #{{ index + 1 }}
    </span>

    @if (canRemove) {
      <button mat-icon-button (click)="onRemoveWorkCategory()" color="warn">
        <mat-icon>delete</mat-icon>
      </button>
    }
  </div>

  @if (properties.workCategory) {
    <app-data-source-select
      [dataSourceId]="9"
      formControlName="id"
      label=" {{ 'work_orders.sections.assigness.work_category' | transloco }}"
      required />
  }

  @if (properties.workDuration) {
    <app-duration-picker
      [disabled]="!form.controls.id.value"
      [value]="durationFormGroup.controls.total.value"
      (valueChange)="onDurationChange($event)"
      label="{{
        'work_orders.sections.assigness.work_duration' | transloco
      }}" />
  }

  @if (properties.cost) {
    <app-number-field
      [formControl]="form.controls.cost.controls.calculated_value"
      label="{{ 'common.cost' | transloco }}">
      <p>{{ form.controls.currency.value }}</p>
    </app-number-field>
  }

  @if (properties.contract) {
    <app-data-source-select
      [dataSourceId]="3"
      formControlName="contract"
      label="{{ 'common.contract' | transloco }}" />
  }

  @if (properties.notes) {
    <app-html-editor formControlName="notes" label="Notes" />
  }

  @if (properties.date) {
    <app-datetime-picker
      [value]="form.controls.date.value | fromServerDate"
      (valueChange)="onDateChange($event)"
      label="{{ 'common.date' | transloco }}" />
  }

  @if (properties.singleDataSourceId && properties.singleDataSource) {
    <app-data-source-select
      [dataSourceId]="properties.singleDataSourceId"
      formControlName="customSingleDropdown"
      label="{{
        'work_orders.sections.assigness.custom_single_dropdown' | transloco
      }}" />
  }

  @if (properties.multipledataSourcesId && properties.multipleDataSources) {
    <app-data-source-multi-select
      [dataSourceId]="properties.multipledataSourcesId"
      formControlName="customMultipleDropdown"
      label="{{
        'work_orders.sections.assigness.custom_multiple_dropdown' | transloco
      }}" />
  }

  <app-box-confirm-dialog #dialog />
</div>
