<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
  <div class="flex-auto h-full flex flex-col">
    <div class="flex-auto flex flex-col rounded-t-2xl shadow max-h-full">
      <div class="min-w-full flex-grow flex flex-col h-full">
        <!-- Header -->
        @if (!hideToolbar) {
          <div
            class="border-b p-2 flex gap-4 items-center justify-end bg-card dark:bg-transparent w-full z-9999">
            <ng-content select="[toolbar]" />
          </div>
        }

        <!-- <div class="flex-grow m-4 card min-h-0 bg-card"> -->
        <ng-content />
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
