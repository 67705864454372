import { FileModel } from '@fieldos/models';
import { Priority } from '../priority';
import { Section } from './section';
import {
  AssetSectionProperties,
  AssigneesSectionProperties,
  CustomerContractSectionProperties,
  DatepickerSectionProperties,
  LocationSectionProperties,
  PartsSectionProperties,
  PrioritySectionProperties,
  RatingSectionProperties,
  SectionLabelInfoProperties,
  ShowInformationSectionProperties,
  SimpleSectionProperties,
  SingleDataSourceSectionProperties,
} from './section-properties';
import {
  AssetSectionValue,
  AssigneesSectionValue,
  ConfirmLocationSectionData,
  LocationSectionValue,
  PackagingLoadedSectionValueEntry,
  RatingSectionValue,
  ShowInformationSectionValue,
} from './section-value';

import { SectionSubType } from './section-type.enum';

export type IdSection = Section<string> &
  SimpleSectionProperties & {
    subtype: SectionSubType.Identifier;
    type: 'standard';
  };

export type TypeSection = Section<string> &
  SimpleSectionProperties & {
    subtype: SectionSubType.Type;
    type: 'standard';
  };

export type TitleSection = Section<string> &
  SimpleSectionProperties & {
    subtype: SectionSubType.Title;
    type: 'standard';
  };

export type DescriptionSection = Section<string> &
  SimpleSectionProperties & {
    subtype: SectionSubType.Description;
    type: 'standard';
  };

export type SolvingDescriptionSection = Section<string> &
  SimpleSectionProperties & {
    subtype: SectionSubType.SolvingDescription;
    type: 'standard';
  };

export type PrioritySection = Section<Priority> &
  PrioritySectionProperties & {
    subtype: SectionSubType.Priority;
    type: 'standard';
  };

export type LocationSection = Section<LocationSectionValue> &
  LocationSectionProperties & {
    subtype: SectionSubType.Location;
    type: 'standard';
  };

export type ResponsiblePersonSection = Section<number> &
  SimpleSectionProperties & {
    subtype: SectionSubType.ResponsiblePerson;
    type: 'standard';
  };

export type DataSourceSection = Section<number | number[]> &
  SingleDataSourceSectionProperties & {
    type: 'standard';
    subtype:
      | SectionSubType.Type
      | SectionSubType.Customer
      | SectionSubType.Parts
      | SectionSubType.CustomerContract
      | SectionSubType.Teams
      | SectionSubType.StartingLocation
      | SectionSubType.Type;
  };

export type CustomerContractSection = Section<number | number[]> &
  CustomerContractSectionProperties & {
    type: 'standard';
    subtype: SectionSubType.CustomerContract;
  };

export type SetSection = Section<number> &
  SimpleSectionProperties & {
    type: 'standard';
    subtype: SectionSubType.CustomerContract;
  };

export type AssetSection = Section<AssetSectionValue> &
  AssetSectionProperties & {
    type: 'standard';
    subtype: SectionSubType.Asset;
  };

export type DatepickerSection = Section<string> &
  DatepickerSectionProperties & {
    type: 'standard';
    subtype: SectionSubType.StartBy | SectionSubType.DueBy;
  };

export type AssigneesSection = Section<AssigneesSectionValue> &
  AssigneesSectionProperties & {
    type: 'standard';
    subtype: SectionSubType.Assignees;
  };

export type RatingSection = Section<RatingSectionValue> &
  RatingSectionProperties & {
    type: 'standard';
    subtype: SectionSubType.Rating;
  };

export type DocumentsSection = Section<FileModel[]> &
  SimpleSectionProperties & {
    type: 'standard';
    subtype: SectionSubType.Documents;
  };

export type PackagingSection = Section<PackagingLoadedSectionValueEntry[]> &
  SimpleSectionProperties & {
    type: 'standard';
    subtype:
      | SectionSubType.PackagingLoaded
      | SectionSubType.PackagingUnloaded
      | SectionSubType.PackagingLeft;
  };

export type SignatureSection = Section<FileModel[]> &
  SimpleSectionProperties & {
    type: 'standard';
    subtype: SectionSubType.Signature;
  };

export type StatusSection = Section<string> &
  SimpleSectionProperties & {
    type: 'standard';
    subtype: SectionSubType.Status;
  };

export type EstimatedDurationSection = Section<number> &
  SectionLabelInfoProperties & {
    type: 'standard';
    subtype: SectionSubType.EstimatedDuration;
  };

export type DPUSection = Section<number[]> &
  SectionLabelInfoProperties & {
    type: 'standard';
    subtype: SectionSubType.DPU;
  };

export type SingleDataSourceSection = Section<number> &
  SingleDataSourceSectionProperties & {
    type: 'custom';
    subtype:
      | SectionSubType.SingleCustomDataSource
      | SectionSubType.SingleStandardDataSource;
  };

export type ShowInformationSection = Section<ShowInformationSectionValue> &
  ShowInformationSectionProperties & {
    type: 'custom';
    subtype: SectionSubType.ShowInformation;
  };

export type ConfirmLocationSection = Section<string | null> &
  SimpleSectionProperties & {
    subtype: SectionSubType.ConfirmLocation;
    type: 'standard';
    data: ConfirmLocationSectionData;
  };

export type PartsSection = Section<number[]> &
  PartsSectionProperties & {
    type: 'standard';
    subtype: SectionSubType.Parts;
  };
