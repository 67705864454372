import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RightsStatusType } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-rights-selector',
  templateUrl: './rights-selector.component.html',
  imports: [MatButtonToggleModule, TranslocoModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightsSelectorComponent {
  public readonly value = input<RightsStatusType>();

  public readonly valueChange = output<RightsStatusType>();
}
