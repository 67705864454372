import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { LineConnectorService } from './line-connector.service';

@Directive({
  selector: '[appLineConnectorDropzone]',
  standalone: true,
})
export class LineConnectorDropzoneDirective {
  constructor(_elementRef: ElementRef, private _service: LineConnectorService) {
    this._element = _elementRef.nativeElement;
  }

  @Input() public set appLineConnectorDropzone(id: string) {
    this._element.setAttribute('appLineConnectorDropzoneId', id);
    this._id = id;
  }

  @Output() public appLineConnectorConnected = new EventEmitter<string>();

  private _element: HTMLElement;
  private _id: string = '';

  @HostListener('click', ['$event'])
  finishConnection(event: MouseEvent): void {
    event.stopPropagation();

    const startNodeData = this._service.finishConnection(this._id);
    if (startNodeData) {
      this.appLineConnectorConnected.emit(startNodeData);
    }
  }
}
