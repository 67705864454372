<div class="h-full overflow-auto w-full">
  <h5 class="font-semibold p-4 border-b">
    {{ 'fields_designer.properties.title' | transloco }}
  </h5>

  @if (store.selectedFieldId() && component()) {
    <ng-container *ngComponentOutlet="component()" />
  } @else {
    <div
      class="font-semilight text-center items-center mt-8 text-base mx-auto w-[75%]">
      {{ 'fields_designer.properties.placeholder' | transloco }}
    </div>
  }
</div>
