import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export const createGetRequestFn: () => <T>(
  url: string
) => Observable<T> = () => {
  const http = inject(HttpClient);

  return <T>(url: string): Observable<T> => http.get<T>(url);
};
