import { Injectable } from '@angular/core';
import { Asset, AssetFieldSubType, PaginatedResponse } from '@fieldos/models';
import { FieldBasedEntitiesServiceBase } from './field-based-entities.service';

@Injectable({
  providedIn: 'root',
})
export class AssetsDataService extends FieldBasedEntitiesServiceBase<Asset> {
  constructor() {
    super('assets', AssetFieldSubType.AssetType);
  }

  override async fetchAll(
    query?: Record<string, string> | undefined
  ): Promise<Asset[]> {
    if (query) {
      const queryParams = Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join('&');
      return this._http
        .get<PaginatedResponse<Asset>>(`/assets?${queryParams}`)
        .then((m) => m.data);
    }

    return this._http
      .get<PaginatedResponse<Asset>>(`/assets`)
      .then((m) => m.data);
  }

  async export(assetType: number): Promise<void> {
    const data = await this._http.get<Blob>(
      `/export/asset?typeId=${assetType}`,
      'blob'
    );

    const url = window.URL.createObjectURL(data);
    window.open(url, '_blank');
  }

  upload(typeId: number, file: File): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);

    return this._http.post<string>(
      `/import/asset?typeId=${typeId}`,
      formData,
      'text'
    );
  }
}
