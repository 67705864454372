<div class="relative flex flex-grow h-full w-full min-h-0">
  @if (store.selectedCategoryTypeId()) {
    <div class="w-[320px] bg-card flex flex-col overflow-auto">
      <app-fields-designer-available-fields />
    </div>

    <div class="flex-grow h-full bg-card flex flex-col overflow-auto border-x">
      <app-fields-designer-enabled-fields />
    </div>

    <div class="w-[420px] bg-card">
      <app-fields-designer-field-properties />
    </div>
  } @else {
    <div class="flex-grow flex items-center justify-center">
      <span>
        {{
          'fields_designer.' + categoryType() + '.select_category_type'
            | transloco
        }}
      </span>
    </div>
  }
</div>
