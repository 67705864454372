import { Pipe, PipeTransform } from '@angular/core';
import { DomainEntityBase, Assignee } from '@fieldos/models';

@Pipe({
  name: 'asWorkOrderAssignee',
  standalone: true,
})
export class AsWorkOrderAssigneePipe implements PipeTransform {
  transform(value: DomainEntityBase | undefined): Assignee {
    return value as Assignee;
  }
}
