import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { AutocompleteSelectComponent } from '@fieldos/components';
import { SetSection } from '@fieldos/models';
import { RoutesStore } from '@fieldos/store';
import { AutoUnsubscribe } from '@fieldos/utils';
import { WorkOrderRoutesStore } from 'app/modules/work-orders/work-order-routes/work-order-routes.store';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-set-section',
  templateUrl: './sections-form-set-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, AutocompleteSelectComponent, ReactiveFormsModule],
  providers: [WorkOrderRoutesStore],
})
@AutoUnsubscribe()
export class SectionsFormSetSectionComponent
  extends SectionsFormSectionBaseComponent<SetSection>
  implements OnInit
{
  protected readonly routesStore = inject(RoutesStore);

  protected override initializeForm(): void {
    this.form = new FormControl<number>(0, { nonNullable: true });
  }

  protected override setValidation(): void {
    this.form.setValidators(Validators.required);
    this.form.updateValueAndValidity();
  }
}
