import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { EnvironmentProviders, Provider } from '@angular/core';
import { apiUrlInterceptor } from './api-url.interceptor';
import { errorInterceptor } from './error.interceptor';
import { workspaceScopeHeadersInterceptor } from './workspace-scope-headers.interceptor';

export const provideInterceptors = (): Array<
  Provider | EnvironmentProviders
> => [
  provideHttpClient(
    withInterceptors([
      apiUrlInterceptor,
      errorInterceptor,
      workspaceScopeHeadersInterceptor,
    ]), 
  ),
];
