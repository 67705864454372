import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { SolvingDescriptionSection } from '@fieldos/models';
import { HtmlEditorComponent } from '../../../../../@components/fields/html-editor';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-solving-description-section',
  templateUrl: './sections-form-solving-description-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, HtmlEditorComponent],
})
export class SectionsFormSolvingDescriptionSectionComponent
  extends SectionsFormSectionBaseComponent<SolvingDescriptionSection>
  implements OnInit
{
  protected override initializeForm(): void {
    this.form = new FormControl<string>('', { nonNullable: true });
  }

  protected override setValidation(): void {
    this.form.setValidators([Validators.minLength(3), Validators.required]);
    this.form.updateValueAndValidity();
  }
}
