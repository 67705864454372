import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SectionBasedEntityListAGGridContext } from '../../section-columns.models';

@Component({
  selector: 'app-html-cell-rederer',
  templateUrl: './html-cell-rederer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlCellRedererComponent implements ICellRendererAngularComp {
  protected value = '';

  private readonly _detector = inject(ChangeDetectorRef);

  agInit(
    params: ICellRendererParams<unknown, string, SectionBasedEntityListAGGridContext>
  ): void {
    this.value = params.value as string;
    this._detector.detectChanges();
  }
  refresh(params: ICellRendererParams<unknown, string, void>): boolean {
    this.value = params.value as string;
    return true;
  }
}
