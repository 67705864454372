/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, inject, signal } from '@angular/core';
import {
  PushNotification,
  SectionBasedEntity,
  SectionBasedEntityType,
} from '@fieldos/models';
import { SectionBasedEntityFacade } from './section-based-entity.facade';

@Injectable({
  providedIn: 'root',
})
export class LayoutDrawerFacade {
  private readonly _sectionEntityFacade = inject(SectionBasedEntityFacade);

  public readonly opened = signal(false);
  public readonly entity = signal<SectionBasedEntity | undefined>(undefined);
  public readonly entityType = signal<SectionBasedEntityType>('workorders');
  public readonly chatOpened = signal(false);

  open(): void {
    this.opened.set(true);
  }

  async openNotification(notification: PushNotification): Promise<void> {
    const scope = notification.scope.split('.')[0];

    const entityType =
      scope === 'wo' || scope === 'dpu' ? 'workorders' : 'servicerequests';

    const entity = await this._sectionEntityFacade.fetchById(
      notification.targetEntityId,
      entityType
    );

    if (notification.scope.includes('comment')) {
      this.chatOpened.set(true);
    }

    this.entityType.set(entityType);
    this.entity.set(entity);
    this.opened.set(true);
  }

  async openWorkOrder(workOrderId: number, openChat = false): Promise<void> {
    this.opened.set(true);
    this.entityType.set('workorders');

    const workOrder = await this._sectionEntityFacade.fetchById(
      workOrderId,
      'workorders'
    );
    this.entity.set(workOrder);

    if (openChat) {
      this.chatOpened.set(true);
    }
  }

  close(): void {
    this.opened.set(false);
    this.entity.set(undefined);
    this.chatOpened.set(false);
  }
}
