import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DurationInputService {
  public numRegx: string = '[0-9]*';
  public decimalRegx: string = '[0-9]*[\\.,][0-9]*[1-9][0-9]*';
  public spaceRegx: string = '[\\s]*';
  public hrRegx: string = `${this.spaceRegx}[óÓhH:]${this.spaceRegx}`; // TODO : handle differently ( 1: -> 1ó engedjük e), :15 nem parsolja
  public minRegx: string = `${this.spaceRegx}[pPmM]${this.spaceRegx}`;

  public justNumbers: string = `^${this.numRegx}${this.spaceRegx}$`; // 150 -> 150 perc
  public numbersWithMinPostfix: string = `^${this.numRegx}${this.spaceRegx}${this.minRegx}${this.spaceRegx}$`; // 150p -> 150 perc
  public numbersWithHourPostfix: string = `^${this.numRegx}${this.spaceRegx}${this.hrRegx}${this.spaceRegx}$`; // 2ó -> 120 perc
  public decimalNumbers: string = `^${this.decimalRegx}${this.spaceRegx}$`; // 1.5 -> 90 perc
  public decimalNumbersWithHourPostfix: string = `^${this.decimalRegx}${this.spaceRegx}${this.hrRegx}${this.spaceRegx}$`; // 1.5ó -> 90 perc
  public numbersWithHourInfix: string = `^${this.numRegx}${this.hrRegx}${this.numRegx}${this.spaceRegx}$`; // 1ó15 -> 75 perc
  public numbersWithWhitespaceInfix: string = `^${this.numRegx}${this.spaceRegx}${this.numRegx}${this.spaceRegx}$`; // 1 15 -> 75 perc
  public numbersWithAllPostfix: string = `^${this.numRegx}${this.hrRegx}${this.numRegx}${this.minRegx}$`; // 1ó15p -> 75 perc

  public durationRegex: string =
    '(' +
    [
      this.justNumbers,
      this.numbersWithMinPostfix,
      this.numbersWithHourPostfix,
      this.decimalNumbers,
      this.decimalNumbersWithHourPostfix,
      this.numbersWithHourInfix,
      this.numbersWithWhitespaceInfix,
      this.numbersWithAllPostfix,
    ].join(')|(') +
    ')';

  getDurationString(duration: number): string {
    if (isNaN(duration)) {
      return '';
    }

    let value = '';
    const hr = Math.floor(duration / 60);
    const min = duration - 60 * hr;

    if (hr) {
      // value = `${hr} ${this.translateService.instant('HOUR_SHORT')}`;
      value = `${hr}h`;
    }

    if (hr && min) {
      value += ' ';
    }

    if (min || duration === 0) {
      // value += `${min} ${this.translateService.instant('MINUTE_SHORT')}`;
      value += `${min}m`;
    }

    return value;
  }

  parseDurationString(value: string | number): number | null {
    value = `${value} `.trim();

    if (this._isEmptyString(value)) {
      return null;
    }

    // convert to <x>h<y> format
    if (value.match(this.justNumbers)) {
      // 150 -> 150 perc
      value = `0h${value}`;
    } else if (value.match(this.numbersWithMinPostfix)) {
      // 150p -> 150 perc
      value = `0h${this._removeMin(value)}`;
    } else if (value.match(this.numbersWithHourPostfix)) {
      // 2ó -> 120 perc
      value = `${this._removeHour(value)}h0`;
    } else if (value.match(this.decimalNumbers)) {
      // 1.5 -> 90 perc
      value = `${this._stdizeSeparator(value)}h0`;
    } else if (value.match(this.decimalNumbersWithHourPostfix)) {
      // 1.5ó -> 90 perc
      value = `${this._removeHour(this._stdizeSeparator(value))}h0`;
    } else if (value.match(this.numbersWithHourInfix)) {
      // 1ó15 1:15 -> 75 perc
      value = this._stdizeHour(value);
    } else if (value.match(this.numbersWithWhitespaceInfix)) {
      // 1 15 -> 75 perc
      value = value.toLocaleLowerCase().split(/\s+/).join('h');
    } else if (value.match(this.numbersWithAllPostfix)) {
      // 1ó15p -> 75 perc
      value = this._removeMin(value.toLocaleLowerCase().replace('ó', 'h'));
    } else {
      return null;
    }

    const values = value.split('h');
    const hr = parseFloat(values[0].trim());
    const min = parseFloat(values[1].trim());

    return Math.ceil(hr * 60) + min;
  }

  isParseable(value: string): boolean {
    if (this._isEmptyString(value)) {
      return false;
    }
    return !!value.match(this.durationRegex);
  }

  private _isEmptyString(value: string | number): boolean {
    return `${value} `.trim() === '';
  }

  private _stdizeSeparator(value: string): string {
    return value.replace(',', '.');
  }

  private _stdizeHour(value: string): string {
    return value.toLocaleLowerCase().replace('ó', 'h').replace(':', 'h');
  }

  private _removeMin(value: string): string {
    return value.toLocaleLowerCase().replace('p', '').replace('m', '');
  }

  private _removeHour(value: string): string {
    return value.toLocaleLowerCase().replace('ó', '').replace('h', '').replace(':', '');
  }
}
