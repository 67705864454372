import { inject, InjectionToken, signal } from '@angular/core';
import { StatusGraphDataService } from '@fieldos/data-services';
import { StatusGraphNode } from '@fieldos/models';
import { from, tap } from 'rxjs';

export const STATUS_GRAPH_STORE = new InjectionToken('STATUS_GRAPH_STORE', {
  factory: () => {
    const service = inject(StatusGraphDataService);

    const items = signal<StatusGraphNode[]>([]);
    const loading = signal(true);

    const reloadStatusGraph = (scopeId: number) =>
      from(service.fetchForScopeId(scopeId))
        .pipe(
          tap((response) => {
            items.set(response);
            loading.set(false);
          })
        )
        .subscribe();

    return {
      items,
      loading,
      reloadStatusGraph,
    };
  },
});
