import { SectionBasedEntity, SectionValueEntry } from '@fieldos/models';

export interface SectionValueChangeEvent {
  entityId: number;
  value: SectionValueEntry;
}

export interface SectionStatusChangeEvent {
  statusId: string;
  entity: SectionBasedEntity;
}
