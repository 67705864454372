import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { tap } from 'rxjs';
import { GraphDesignerRootDirective } from './graph-designer-root.directive';
import { FlatGraphNode, GraphNode } from './graph-designer.models';
import { GraphDesignerService } from './graph-designer.service';

@Component({
  selector: 'app-graph-designer',
  templateUrl: 'graph-designer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DragDropModule, GraphDesignerRootDirective, NgTemplateOutlet],
})
export class GraphDesignerComponent<T>
  implements AfterViewInit, OnChanges, OnDestroy
{
  constructor(
    _service: GraphDesignerService,
    private _ngZone: NgZone
  ) {
    _service.change$.pipe(tap(() => this._repositionLines())).subscribe();
  }

  @Input() public nodes: FlatGraphNode<T>[] = [];
  @Input() public nodeTemplate!: TemplateRef<GraphNode<T>>;
  @Input() public rootNodeId: string = '';

  @Input() public forwardColor: string = '#00FF00';
  @Input() public backwardsColor: string = '#FF0000';
  @Input() public bidirectionalColor: string = 'cadetblue';

  @ViewChild(GraphDesignerRootDirective, { read: ElementRef })
  public readonly graphDesignerRoot!: ElementRef;

  protected nodeLevels: FlatGraphNode<T>[][] = [];

  private _lines: Record<string, LeaderLine> = {};
  private _nodeMap: Record<string, FlatGraphNode<T>> = {};
  private _nodeLevelMap: Record<string, number> = {};
  private _initialized = false;
  private _lineIdLineDirectionMap: Record<string, number> = {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['nodes']) {
      this._nodeMap = this.nodes.reduce(
        (acc: Record<string, FlatGraphNode<T>>, node: FlatGraphNode<T>) => ({
          ...acc,
          [node.id]: node,
        }),
        {}
      );

      this._clearLines();
      this._lineIdLineDirectionMap = {};
      this._nodeLevelMap = {};
      this.nodeLevels = [];

      const rootNodeIds = this._getNextRootNode(changes['nodes'].currentValue);

      this._buildLevels(rootNodeIds);
      this._initConnections();
    }

    if (
      (changes['forwardColor'] && changes['forwardColor'].currentValue) ||
      (changes['backwardsColor'] && changes['backwardsColor'].currentValue) ||
      (changes['bidirectionalColor'] &&
        changes['bidirectionalColor'].currentValue)
    ) {
      this._redrawColors();
    }
  }

  ngOnDestroy(): void {
    Object.values(this._lines).map((e) => e.remove());
  }

  ngAfterViewInit(): void {
    this._initialized = true;
    this._initConnections();
  }

  protected onScroll(event: any): void {
    Object.values(this._lines).forEach((line) => line.position());
  }

  protected onMouseEnter(elementId: string): void {
    Object.keys(this._lines).forEach((key: string) => {
      if (key.includes(elementId)) {
        this._lines[key].show('draw');
      } else {
        this._lines[key].hide('draw');
      }
    });
  }

  protected onMouseLeave(): void {
    Object.keys(this._lines).forEach((key: string) =>
      this._lines[key].show('draw')
    );
  }

  private _buildLevels(nodeIds: string[]): void {
    const nodes = this._getNodesByIds(
      nodeIds.filter((id) => !this._nodeLevelMap[id])
    );

    this.nodeLevels.push(nodes);

    nodes.forEach(
      (node) => (this._nodeLevelMap[node.id] = this.nodeLevels.length)
    );

    const childrenNodeIds = nodes
      .reduce(
        (acc: string[], current: FlatGraphNode<T>) => [
          ...acc,
          ...current.connections,
        ],
        []
      )
      .filter((nodeId) => !this._nodeLevelMap[nodeId])
      .filter((value, index, self) => self.indexOf(value) === index);

    if (childrenNodeIds.length) {
      this._buildLevels(childrenNodeIds);
    } else {
      const remainingNodes = this._getRemainingNodes();
      const rootNodes = this._getNextRootNode(remainingNodes);
      if (rootNodes.length) {
        this._buildLevels(rootNodes);
      }
    }
  }

  private _getNodesByIds(nodeIds: string[]): FlatGraphNode<T>[] {
    return nodeIds.map((id) => this._nodeMap[id]).filter((e) => !!e);
  }

  private _initConnections(): void {
    this._lineIdLineDirectionMap = {};
    if (this._initialized) {
      setTimeout(
        () =>
          Object.values(this._nodeMap).forEach((node: FlatGraphNode<T>) => {
            node.connections.forEach((targetId: string) =>
              this._drawConnection(node.id, targetId)
            );
          }),
        0
      );
    }
  }

  private _drawConnection(sourceId: string, targetId: string): void {
    if (sourceId === targetId) {
      return;
    }

    const lineId = `${sourceId}::${targetId}`;

    if (this._lines[lineId]) {
      return;
    }

    const reversedLine = this._lines[`${targetId}::${sourceId}`];

    if (reversedLine) {
      reversedLine.color = this.bidirectionalColor;
      reversedLine.endPlug = 'arrow1';
      reversedLine.startPlug = 'arrow1';
      this._lineIdLineDirectionMap[`${targetId}::${sourceId}`] = 0;
      return;
    }

    const element: HTMLDivElement = this.graphDesignerRoot.nativeElement;

    const source = element.querySelector(`[id='${sourceId}']`) as HTMLElement;
    const target = element.querySelector(`[id='${targetId}']`) as HTMLElement;

    if (!source || !target) {
      return;
    }

    let startSocket: LeaderLine.SocketType = 'auto';
    let endSocket: LeaderLine.SocketType = 'auto';

    const sourceNodeLevel = this._nodeLevelMap[sourceId];
    const targetNodeLevel = this._nodeLevelMap[targetId];
    let path: LeaderLine.PathType = 'fluid';

    const isSourceHigher = source.offsetTop - target.offsetTop < 0;
    const isSameHeight = source.offsetTop - target.offsetTop === 0;

    let color: string | undefined = this.forwardColor;
    this._lineIdLineDirectionMap[lineId] = 1;
    const size = 1.5;
    const sourceAnchor = undefined;

    // connection backwards 1 level
    if (targetNodeLevel <= sourceNodeLevel - 1) {
      if (isSourceHigher) {
        startSocket = 'left';
        endSocket = 'right';
      } else if (isSameHeight) {
        startSocket = 'bottom';
        endSocket = 'bottom';
      } else {
        startSocket = 'left';
        endSocket = 'right';
      }

      color = this.backwardsColor;
      this._lineIdLineDirectionMap[lineId] = -1;

      path = 'arc';
      color = this.backwardsColor;
      this._lineIdLineDirectionMap[lineId] = -1;
    }

    if (targetNodeLevel === sourceNodeLevel) {
      path = 'arc';
    }

    const options: LeaderLine.Options = {
      path,
      color,
      startSocket,
      endSocket,
      size,
    };

    this._drawLine(
      lineId,
      sourceAnchor ? sourceAnchor : source,
      target,
      options
    );
  }

  private _drawLine(
    lineId: string,
    source: Element | LeaderLine.AnchorAttachment,
    target: Element | LeaderLine.AnchorAttachment,
    options: LeaderLine.Options
  ): void {
    const line = new LeaderLine(source, target, options);
    this._lines[lineId] = line;
  }

  private _clearLines(): void {
    Object.values(this._lines).forEach((line) => line.remove());
    this._lines = {};
  }

  private _redrawColors(): void {
    Object.keys(this._lineIdLineDirectionMap).forEach((key: string) => {
      switch (this._lineIdLineDirectionMap[key]) {
        case -1:
          this._lines[key].color = this.backwardsColor;
          break;
        case 0:
          this._lines[key].color = this.bidirectionalColor;
          break;
        case 1:
          this._lines[key].color = this.forwardColor;
          break;
      }
    });
  }

  private _getNextRootNode(nodes: FlatGraphNode[]): string[] {
    let rootNodeIds = [];

    if (this.rootNodeId) {
      rootNodeIds = [this.rootNodeId];
    } else {
      const allConnections = nodes.reduce(
        (acc: string[], current: FlatGraphNode<T>) => [
          ...acc,
          ...current.connections,
        ],
        []
      );

      const nodesThatAreNotChildren = nodes
        .filter((node) => !allConnections.includes(node.id))
        .map((e) => e.id);

      rootNodeIds = nodesThatAreNotChildren.length
        ? nodesThatAreNotChildren
        : nodes && nodes[0]
          ? [nodes[0].id]
          : [];

      return rootNodeIds;
    }

    return [];
  }

  private _getRemainingNodes(): FlatGraphNode<T>[] {
    return this.nodes.filter((node) => !this._nodeLevelMap[node.id]);
  }

  private _repositionLines(): void {
    this._ngZone.runOutsideAngular(() => {
      Object.values(this._lines).forEach((line) => line.position());
    });
  }
}
