import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { StarRatingFieldComponent } from '@fieldos/components';
import { RatingSectionValue } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { IFilterAngularComp } from 'ag-grid-angular';
import {
  AgPromise,
  IDoesFilterPassParams,
  IFilterParams,
} from 'ag-grid-community';
import { tap } from 'rxjs';
import { SectionBasedEntityListAGGridContext } from '../../../section-columns.models';
import { StarFilterModel } from '../star-filter.models';

@Component({
  selector: 'app-star-filter',
  templateUrl: './star-filter.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    StarRatingFieldComponent,
    ReactiveFormsModule,
    MatCheckboxModule,
    TranslocoModule,
    MatRadioModule,
  ],
})
export class StarFilterComponent implements IFilterAngularComp {
  constructor() {
    this.form.valueChanges
      .pipe(
        tap(() => {
          this._params.filterChangedCallback();
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  protected readonly form = new FormGroup({
    operator: new FormControl(2, { nonNullable: true }),
    value: new FormControl(0, { nonNullable: true }),
  });

  private _params!: IFilterParams<Record<string, any>>;

  onFloatingFilterChanged(value: StarFilterModel) {
    this.form.patchValue(value, { emitEvent: false });
    this._params?.filterChangedCallback();
  }

  agInit(
    params: IFilterParams<
      Record<string, any>,
      SectionBasedEntityListAGGridContext
    >
  ): void {
    this._params = params;
  }

  isFilterActive(): boolean {
    return true;
  }

  doesFilterPass(
    params: IDoesFilterPassParams<Record<string, unknown>>
  ): boolean {
    if (this._params.colDef.field) {
      const columnValue = params.data[
        this._params.colDef.field
      ] as RatingSectionValue;

      if (columnValue?.rating) {
        const value = this.form.value.value as number;

        switch (this.form.value.operator) {
          case 1:
            return columnValue.rating === value;
          case 2:
            return columnValue.rating > value;
          case 3:
            return columnValue.rating < value;
        }
      }
    }

    return false;
  }

  getModel() {
    return this.form.value;
  }

  setModel(model: StarFilterModel): void | AgPromise<void> {
    this.form.patchValue(model, { emitEvent: false });
  }

  getModelAsString?(model: number): string {
    return `${model}/5`;
  }
}
