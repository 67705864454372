import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Signal,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FlatFormGroup } from '@browsepedia/ng-forms';
import {
  LocationDataSourceModel,
  LocationSection,
  LocationSectionValue,
} from '@fieldos/models';
import { DataSourceStore, StandardDataSourceId } from '@fieldos/store';
import { AutoUnsubscribe } from '@fieldos/utils';
import { distinctUntilChanged, filter, mergeMap, startWith, tap } from 'rxjs';
import { AutocompleteSelectComponent } from '../../../../../@components/fields/autocomplete-select';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-location-section',
  templateUrl: './sections-form-location-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    AutocompleteSelectComponent,
    MatSelectModule,
  ],
})
@AutoUnsubscribe()
export class SectionsFormLocationSectionComponent
  extends SectionsFormSectionBaseComponent<LocationSection>
  implements OnInit
{
  protected readonly locations = inject(DataSourceStore).selectDataSource(
    StandardDataSourceId.Locations
  ) as Signal<LocationDataSourceModel[]>;

  protected readonly subLocations = signal<LocationDataSourceModel[]>([]);

  private readonly _dataSourceStore = inject(DataSourceStore);

  override ngOnInit(): void {
    super.ngOnInit();

    this.form.controls.locationId.valueChanges
      .pipe(
        startWith(this.form.controls.locationId.value),
        filter((value) => !!value),
        distinctUntilChanged(),
        mergeMap((locationId) =>
          this._dataSourceStore.fetchWithoutCache(5, {
            parent: `${locationId}`,
          })
        ),
        tap((subLocations) => {
          this.form.controls.subLocationId.enable({ emitEvent: false });
          this.subLocations.set(subLocations as LocationDataSourceModel[]);
          this.detector.detectChanges();
        }),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }

  protected onLocationCleared(): void {
    this.form.setValue({
      locationId: 0,
      subLocationId: 0,
    });
  }

  protected override initializeForm(): void {
    this.form = new FlatFormGroup<LocationSectionValue>({
      locationId: new FormControl<number>(0, { nonNullable: true }),
      subLocationId: new FormControl<number>(
        {
          value: 0,
          disabled: true,
        },
        {
          nonNullable: true,
        }
      ),
    });
  }

  protected override setValidation(): void {
    this.form.controls.locationId.setValidators(
      Validators.compose([Validators.required, Validators.min(1)])
    );

    this.form.controls.locationId.updateValueAndValidity();

    if (this.section().properties?.enableLocationLevelTwo) {
      this.form.controls.subLocationId.setValidators(
        Validators.compose([Validators.required, Validators.min(1)])
      );

      this.form.controls.subLocationId.updateValueAndValidity();
    }

    this.store.updateSelectedSectionStatus(this.sectionId(), {
      dirty: this.form.dirty,
      valid: !this.form.invalid,
    });
  }
}
