import { Pipe, PipeTransform } from '@angular/core';
import { GridActionButton } from './actions-cell-renderer.models';

@Pipe({
  name: 'actionsCellRendererIcon',
  standalone: true,
})
export class ActionsCellRendererIconPipe<T> implements PipeTransform {
  transform(value: GridActionButton<T>, data: T): string {
    if (typeof value.icon === 'string') {
      return value.icon;
    }

    return value.icon(data);
  }
}
