import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-duration-cell-renderer',
  templateUrl: './duration-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
})
export class DurationCellRendererComponent implements ICellRendererAngularComp {
  protected cellValue = '';

  agInit(params: ICellRendererParams<unknown, number, void>): void {
    const value = params.value || 0;
    const hours = `0${Math.floor(value / 60)}`.slice(-2);
    const minutes = `0${value % 60}`.slice(-2);

    this.cellValue = `${hours}:${minutes}`;
  }

  refresh(params: ICellRendererParams<unknown, number, void>): boolean {
    const value = params.value || 0;
    const hours = `0${Math.floor(value / 60)}`.slice(-2);
    const minutes = `0${value % 60}`.slice(-2);

    this.cellValue = `${hours}:${minutes}`;

    return true;
  }
}
