import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SectionBasedEntity } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { SectionsFormEntityLockedInfoPanelComponent } from './sections-form-entity-locked-info-panel/sections-form-entity-locked-info-panel.component';
import { SectionsFormStore } from './sections-form.store';
import { SectionsFormSectionComponent } from './sections/sections-form-section.component';

@Component({
  selector: 'app-sections-form',
  templateUrl: './sections-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SectionsFormSectionComponent,
    MatProgressBarModule,
    TranslocoModule,
    SectionsFormEntityLockedInfoPanelComponent,
    MatCardModule,
  ],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class SectionsFormComponent<T extends SectionBasedEntity> {
  constructor() {
    this.store.hardReset();
  }

  public readonly entity = input<T>();

  protected readonly store = inject(SectionsFormStore);
}
