import { ThemePalette } from '@angular/material/core';
import { FuseAlertType } from '@fuse/components/alert';

export interface ToastMessage {
  name: string;
  type: FuseAlertType;
  message: string;
  duration: number;
  showCloseButton: boolean;
  actions?: ToastMessageButton[];
}

export interface ToastMessageButton {
  color?: ThemePalette;
  label: string;
  onClick: () => void;
}
