import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-confirmation-seal-section',
  templateUrl: './sections-form-confirmation-seal-section.component.html',
  standalone: true,
  imports: [TranslocoModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionsFormConfirmationSealSectionComponent
  extends SectionsFormSectionBaseComponent
  implements OnInit
{
  override ngOnInit(): void {
    return;
  }
}
