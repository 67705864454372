import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { SimpleFormGroup } from '@browsepedia/ng-forms';
import { AssetSection, DomainEntityBase } from '@fieldos/models';
import { DataSourceStore, StandardDataSourceId } from '@fieldos/store';
import { AutoUnsubscribe } from '@fieldos/utils';
import { distinctUntilChanged, filter, mergeMap, startWith, tap } from 'rxjs';
import { AutocompleteSelectComponent } from '../../../../../@components/fields/autocomplete-select';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-asset-section',
  templateUrl: './sections-form-asset-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AutocompleteSelectComponent, ReactiveFormsModule],
})
@AutoUnsubscribe()
export class SectionsFormAssetSectionComponent
  extends SectionsFormSectionBaseComponent<AssetSection>
  implements OnInit
{
  protected readonly assets = inject(DataSourceStore).selectDataSource(
    StandardDataSourceId.Assets
  );

  protected subAssets = signal<DomainEntityBase[]>([]);

  private readonly _dataSourceStore = inject(DataSourceStore);

  override ngOnInit(): void {
    super.ngOnInit();

    this.form.controls.assetId.valueChanges
      .pipe(
        startWith(this.form.controls.assetId.value),
        filter((value) => !!value),
        distinctUntilChanged(),
        mergeMap((assetId) =>
          this._dataSourceStore.fetchWithoutCache(
            StandardDataSourceId.Subassets,
            {
              parent: `${assetId}`,
            }
          )
        ),
        tap((subAssets) => {
          this.form.controls.subAssetId.enable({ emitEvent: false });
          this.subAssets.set(subAssets);
          this.detector.detectChanges();
        }),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }
  override initializeForm(): void {
    this.form = new SimpleFormGroup({
      assetId: new FormControl<number>(0, { nonNullable: true }),
      subAssetId: new FormControl<number>(
        {
          value: 0,
          disabled: true,
        },
        { nonNullable: true }
      ),
    });
  }

  protected onAssetCleared(): void {
    this.form.setValue({
      assetId: 0,
      subAssetId: 0,
    });
  }

  protected override setValidation(): void {
    if (this.section().properties?.showAsset) {
      this.form.controls.assetId.setValidators(Validators.required);
      this.form.controls.assetId.updateValueAndValidity();
    }

    if (this.section().properties?.showSubasset) {
      this.form.controls.subAssetId.setValidators(Validators.required);
      this.form.controls.subAssetId.updateValueAndValidity();
    }
  }
}
