import { InjectionToken } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';

export const NAVIGATION_CONFIG = new InjectionToken('NAVIGATION_CONFIG', {
  factory: (): FuseNavigationItem[] => [
    {
      id: 'Dashboard',
      type: 'basic',
      title: 'menu.dashboard',
      link: '/dashboard',
      icon: 'heroicons_outline:presentation-chart-bar',
      permissions: ['dashboard.list'],
    },
    {
      id: 'Inbox',
      type: 'basic',
      title: 'menu.inbox',
      link: '/inbox',
      icon: 'heroicons_solid:inbox-arrow-down',
      permissions: ['inbox.list'],
    },
    {
      type: 'divider',
      permissions: ['inbox.list', 'dashboard.list'],
    },
    {
      id: 'requests',
      title: 'menu.requests',
      type: 'basic',
      icon: 'mat_solid:perm_phone_msg',
      exactMatch: true,
      link: '/requests',
      permissionStartsWith: 'requests',
    },
    {
      id: 'work-orders',
      title: 'menu.work_orders',
      type: 'basic',
      icon: 'heroicons_outline:briefcase',
      exactMatch: true,
      link: '/work-orders',
      permissions: ['workorders.list'],
    },
    {
      id: 'work-orders-locations',
      title: 'menu.work_order_locations',
      exactMatch: true,
      type: 'basic',
      icon: 'heroicons_outline:map-pin',
      link: '/work-orders/locations',
      permissions: ['workorderslocation.list'],
    },
    {
      id: 'work-orders-planner',
      title: 'menu.planner',
      exactMatch: true,
      type: 'basic',
      icon: 'heroicons_outline:calendar',
      link: '/planner',
      permissions: ['planner.list'],
    },

    {
      id: 'plans',
      title: 'menu.plans',
      type: 'basic',
      icon: 'heroicons_outline:map',
      link: '/plans',
      permissions: ['plans.list'],
    },
    {
      id: 'customers',
      title: 'menu.customers',
      type: 'basic',
      icon: 'mat_solid:home_repair_service',
      link: '/customers',
      permissionStartsWith: 'customers',
    },
    {
      id: 'contractors',
      title: 'menu.contractors',
      type: 'basic',
      icon: 'mat_solid:home_repair_service',
      link: '/contractors',
      permissionStartsWith: 'contractors',
    },
    {
      id: 'assets',
      title: 'menu.assets',
      type: 'basic',
      icon: 'mat_solid:engineering',
      link: '/equipments',
      permissionStartsWith: 'assets',
    },
    {
      id: 'locations',
      title: 'menu.locations',
      type: 'basic',
      icon: 'mat_solid:location_on',
      link: '/locations',
      permissionStartsWith: 'locations',
    },
    {
      icon: 'heroicons_outline:user-group',
      id: 'workforce',
      title: 'menu.workforce',
      type: 'basic',
      link: '/workforce',
      permissions: ['users.list', 'teams.list'],
    },
    {
      icon: 'mat_solid:format_list_bulleted',
      id: 'reports',
      title: 'menu.reports',
      type: 'basic',
      link: '/reports',
      permissions: ['reports.list'],
    },
    {
      id: 'administration',
      title: 'menu.administration',
      type: 'aside',
      icon: 'heroicons_outline:cog-8-tooth',
      link: '/administration',
      permissionStartsWith: 'administration',
      children: [
        {
          icon: 'heroicons_outline:cog-8-tooth',
          id: 'configure-work-orders',
          title: 'menu.configure_work_order_sections',
          exactMatch: true,
          type: 'basic',
          link: '/administration/configure-work-orders',
          permissions: ['administration.configureworkordersections.edit'],
        },
        {
          icon: 'heroicons_outline:cog',
          id: 'request-templates',
          title: 'menu.request_templates',
          type: 'basic',
          link: '/administration/request-templates',
          permissions: ['administration.requesttemplates.list'],
        },
        {
          icon: 'heroicons_outline:presentation-chart-line',
          id: 'status-graph-work-orders',
          title: 'menu.status_graph.workorders',
          exactMatch: true,
          type: 'basic',
          link: '/administration/status-graph/workorders',
          permissions: ['administration.workorderstatusgraph.list'],
        },
        {
          icon: 'heroicons_outline:presentation-chart-line',
          id: 'status-graph-requests',
          title: 'menu.status_graph.servicerequests',
          exactMatch: true,
          type: 'basic',
          link: '/administration/status-graph/servicerequests',
          permissions: ['administration.requeststatusgraph.list'],
        },
        {
          icon: 'heroicons_outline:check-circle',
          id: 'checklists',
          title: 'menu.checklists',
          type: 'basic',
          link: '/administration/checklists',
          permissions: ['administration.checklists.list'],
        },
        {
          icon: 'heroicons_outline:adjustments-vertical',
          id: 'configure-work-order-rights',
          title: 'menu.work_order_rights',
          type: 'basic',
          link: '/administration/configure-rights/workorders',
          permissions: ['administration.workorderrights.list'],
        },
        {
          icon: 'heroicons_outline:adjustments-vertical',
          id: 'configure-request-rights',
          title: 'menu.request_rights',
          type: 'basic',
          link: '/administration/configure-rights/servicerequests',
          permissions: ['administration.requestrights.list'],
        },
        {
          icon: 'mat_solid:category',
          id: 'location_types',
          title: 'menu.category_types',
          type: 'basic',
          link: '/administration/category-types',
          exactMatch: false,
          permissionStartsWith: 'administration.categorytypes',
        },
        {
          icon: 'mat_solid:edit_note',
          id: 'location-designer',
          title: 'menu.location_field_designer',
          link: '/administration/location-designer',
          type: 'basic',
          permissions: ['administration.locationdesigner.edit'],
        },
        {
          icon: 'mat_solid:edit_note',
          id: 'customers-designer',
          title: 'menu.customer_field_designer',
          link: '/administration/customer-designer',
          type: 'basic',
          permissions: ['administration.customerdesigner.edit'],
        },
        {
          icon: 'mat_solid:edit_note',
          id: 'contractors-designer',
          title: 'menu.contractor_field_designer',
          link: '/administration/contractor-designer',
          type: 'basic',
          permissions: ['administration.contractordesigner.edit'],
        },
        {
          icon: 'mat_solid:edit_note',
          id: 'asset-designer',
          title: 'menu.asset_field_designer',
          link: '/administration/asset-designer',
          type: 'basic',
          permissions: ['administration.assetdesigner.edit'],
        },
        {
          icon: 'mat_solid:edit_note',
          id: 'user-designer',
          title: 'menu.users_field_designer',
          link: '/administration/user-designer',
          type: 'basic',
          permissions: ['administration.userdesigner.edit'],
        },
        {
          icon: 'mat_solid:multiple_stop',
          id: 'dpu-statuses',
          title: 'menu.dpu_statuses',
          link: '/administration/dpu-statuses',
          type: 'basic',
          permissionStartsWith: 'administration.dpustatuses',
        },
        {
          icon: 'mat_solid:pin',
          id: 'reason-codes',
          title: 'menu.reason-codes',
          link: '/administration/reason-codes',
          type: 'basic',
          permissionStartsWith: 'administration.reasoncodes',
        },
        {
          icon: 'mat_solid:redeem',
          id: 'reason-codes',
          title: 'menu.packaging',
          link: '/administration/packaging',
          type: 'basic',
          permissions: ['administration.packaging.list'],
        },
        {
          icon: 'mat_solid:inventory',
          id: 'reason-codes',
          title: 'menu.order-types',
          link: '/administration/order-types',
          type: 'basic',
          permissions: ['administration.ordertypes.list'],
        },
        {
          icon: 'mat_solid:edit_note',
          id: 'asset-statuses',
          title: 'menu.asset-statuses',
          link: '/administration/asset-statuses',
          type: 'basic',
          permissions: ['administration.assetstatuses.list'],
        },
      ],
    },
  ],
});
