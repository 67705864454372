import { DomainEntityBase } from './entity-base';

export interface AssetPlan extends DomainEntityBase {
  url: string;
  fileName: string;
  fileSize: number;
}

export type PlanDetails = AssetPlan;

export interface AssetPlanPin extends DomainEntityBase<string> {
  id: string;
  coordinateX: number;
  coordinateY: number;
  assetId?: number;
  subassetId?: number;
  locationId?: number;
  sublocationId?: number;
}

export type AssetPlanPinForm = Omit<AssetPlanPin, 'id'>;
