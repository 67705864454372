/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, signal } from '@angular/core';
import { RolesDataService } from '@fieldos/data-services';
import { DomainEntityBase } from '@fieldos/models';

@Injectable({
  providedIn: 'root',
})
export class RolesFacade {
  constructor(private readonly _rolesDataService: RolesDataService) {
    this.fetchRoles();
  }

  private readonly _roles = signal<DomainEntityBase[]>([]);
  private readonly _loaded = signal(false);
  private readonly _loading = signal(false);

  public readonly roles = this._roles.asReadonly();

  fetchRoles() {
    if (this._loaded() || this._loading()) {
      return;
    }

    this._loading.set(true);
    this._rolesDataService.fetch().then((roles) => {
      this._roles.set(roles);
      this._loaded.set(true);
      this._loading.set(false);
    });
  }

  refreshRoles() {
    this._loaded.set(false);
    this.fetchRoles();
  }
}
