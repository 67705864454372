import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AssigneesSectionValue } from '@fieldos/models';
import { DataSourceStore, StandardDataSourceId } from '@fieldos/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isArray } from 'lodash';
import { SectionBasedEntityListAGGridContext } from '../../section-columns.models';

@Component({
  selector: 'app-assignees-cell-renderer',
  templateUrl: './assignees-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssigneesCellRendererComponent
  implements ICellRendererAngularComp
{
  protected value = '';

  private readonly _dataSourceStore = inject(DataSourceStore);

  agInit(
    params: ICellRendererParams<
      unknown,
      AssigneesSectionValue,
      SectionBasedEntityListAGGridContext
    >
  ): void {
    this._setValue(params);
  }

  refresh(
    params: ICellRendererParams<
      unknown,
      AssigneesSectionValue,
      SectionBasedEntityListAGGridContext
    >
  ): boolean {
    this._setValue(params);
    return true;
  }

  private _setValue(
    params: ICellRendererParams<
      unknown,
      AssigneesSectionValue,
      SectionBasedEntityListAGGridContext
    >
  ) {
    const assignees = this._dataSourceStore.selectDataSource(
      StandardDataSourceId.Assignees
    )();

    if (!params.value) {
      return;
    }

    //temporary fix
    const value: any[] = isArray(params.value)
      ? params.value
      : (params.value as any)['assignees'];

    this.value =
      value
        ?.map((e) => assignees.find((f) => f.id === e.id))
        ?.map((assignee) => assignee?.name)
        .join(', ') || '';
  }
}
