import { Injectable, inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import {
  CreateWorkOrderRouteModel,
  DomainEntityBase,
  WorkOrderRoute,
} from '@fieldos/models';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderRoutesDataService {
  private readonly _http = inject(HttpService);

  fetchAll(): Promise<WorkOrderRoute[]> {
    return this._http.get<WorkOrderRoute[]>('/routes-pod');
  }

  async create(route: CreateWorkOrderRouteModel): Promise<WorkOrderRoute> {
    const createdRoute = await this._http.post<DomainEntityBase>(
      '/routes-pod',
      route
    );

    return {
      ...route,
      id: createdRoute.id,
    };
  }

  update(route: WorkOrderRoute): Promise<WorkOrderRoute> {
    return this._http.put<WorkOrderRoute>(`/routes-pod/${route.id}`, route);
  }

  delete(id: number): Promise<void> {
    return this._http.delete(`/routes-pod/${id}`);
  }
}
