import { StandardDataSourceId } from '@fieldos/store/data-source-cache';

export interface ReportFilterPropertiesBase {
  type: ReportFilterType;
}

export interface ReportDateFilterProperties extends ReportFilterPropertiesBase {
  type: 'date';
}

export interface ReportDataSourceFilterProperties
  extends ReportFilterPropertiesBase {
  type: 'dataSource';
  dataSourceId: StandardDataSourceId;
  displayProperty?: string;
}

export interface ReportTextFilterProperties extends ReportFilterPropertiesBase {
  type: 'text';
}

export interface ReportEndpointFilterProperties
  extends ReportFilterPropertiesBase {
  type: 'endpoint';
  endpointUrl: string;
}

export type ReportFilterType =
  | 'dataSource'
  | 'text'
  | 'date'
  | 'hhmm'
  | 'endpoint'
  | null;
