import { InjectionToken, Signal, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomainEntityBase } from '@fieldos/models';
import { TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs';

export enum AccountType {
  Employee = 1,
  Customer = 2,
  Contractor = 3,
}

export const ACCOUNT_TYPE_OPTIONS = new InjectionToken<
  Signal<Record<AccountType, DomainEntityBase>>
>('ACCOUNT_TYPE_OPTIONS', {
  factory: () => {
    const transloco = inject(TranslocoService);

    const result = signal<Record<AccountType, DomainEntityBase>>({
      [AccountType.Employee]: {
        id: 1,
        name: transloco.translate('fields.acctype.type.employee'),
      },
      [AccountType.Customer]: {
        id: 2,
        name: transloco.translate('fields.acctype.type.customer'),
      },
      [AccountType.Contractor]: {
        id: 3,
        name: transloco.translate('fields.acctype.type.contractor'),
      },
    });

    transloco.langChanges$
      .pipe(
        tap(() => {
          result.set({
            [AccountType.Employee]: {
              id: 1,
              name: transloco.translate('fields.acctype.type.employee'),
            },
            [AccountType.Customer]: {
              id: 2,
              name: transloco.translate('fields.acctype.type.customer'),
            },
            [AccountType.Contractor]: {
              id: 3,
              name: transloco.translate('fields.acctype.type.contractor'),
            },
          });
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    return result;
  },
});
