import { Pipe, PipeTransform } from '@angular/core';
import { SectionSubType } from '@fieldos/models';

@Pipe({
  name: 'workOrderSectionTitle',
  standalone: true,
})
export class WorkOrderSectionTitlePipe implements PipeTransform {
  transform(type: SectionSubType): string {
    return `work_orders.sections.${type}.title`;
  }
}
