import {
  InjectionToken,
  Signal,
  computed,
  effect,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  CategoryTypeFieldsDataService,
  FieldEntityDataService,
} from '@fieldos/data-services';
import { CategoryType, Field, FieldBasedEntity } from '@fieldos/models';
import { CategoryTypePermissionsStore } from '@fieldos/store/category-type';
import { TranslocoService } from '@ngneat/transloco';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { ColDef } from 'ag-grid-community';

export interface FieldEntityListState<T extends FieldBasedEntity> {
  loading: boolean;
  entities: T[];
  columns: ColDef[];
  selectedCategoryType: number;
  fields: Field[];
}

export const createEntityListStore = <T extends FieldBasedEntity>(
  categoryType: CategoryType,
  createColumnDefsFn: () => (
    fields: Field[],
    language: string,
    categoryType: CategoryType,
    typeId: number
  ) => ColDef[],
  dataMapperFn?: (
    data: T[],
    columns: ColDef[],
    fields: Field[],
    selectedCategoryTypeId: number
  ) => unknown[]
) =>
  signalStore(
    withState<FieldEntityListState<T>>({
      loading: false,
      entities: [],
      columns: [],
      selectedCategoryType: 0,
      fields: [],
    }),
    withComputed(
      (store, claimsStore = inject(CategoryTypePermissionsStore)) => ({
        currentEntities: computed(() => {
          if (dataMapperFn) {
            const data = dataMapperFn(
              store.entities(),
              store.columns(),
              store.fields(),
              store.selectedCategoryType() as number
            );

            return data;
          }

          const selectedCategoryType = store.selectedCategoryType();
          const entities = store.entities();

          const columns = store.columns();

          const data = entities
            .filter((entity) => entity.typeId === selectedCategoryType)
            .map((entity) => {
              const row = columns.reduce((acc, column) => {
                if (column.field === 'id') {
                  return {
                    ...acc,
                    id: entity.id,
                  };
                }

                return {
                  ...acc,
                  [column.field as string]: entity.value.find(
                    (e) => e.id === column.field
                  )?.value,
                };
              }, {});

              return { ...row, id: entity.id };
            });

          return data;
        }),
        permissions: computed(() =>
          claimsStore.selectPermission(
            categoryType,
            store.selectedCategoryType() as number
          )
        ),
      })
    ),
    withMethods((store, dataService = inject(FieldEntityDataService)) => ({
      setSelectedType(selectedCategoryType: number): void {
        patchState(store, { selectedCategoryType });
      },
      async fetchEntities(): Promise<void> {
        patchState(store, { loading: true });

        try {
          const entities = (await dataService.fetchAll(categoryType)) as T[];

          patchState(store, { entities, loading: false });
        } catch (error) {
          patchState(store, { loading: false });
        }
      },

      async delete(entityId: number): Promise<void> {
        patchState(store, { loading: true });

        try {
          await dataService.delete(categoryType, entityId);

          const entities = store.entities().filter((e) => e.id !== entityId);

          patchState(store, { entities, loading: false });
        } catch (error) {
          patchState(store, { loading: false });
        }
      },
    })),
    withHooks({
      onInit(
        store,
        transloco = inject(TranslocoService),
        typeDataService = inject(CategoryTypeFieldsDataService)
      ) {
        const language = toSignal(transloco.langChanges$) as Signal<string>;
        const createColumnsFn = createColumnDefsFn();

        effect(
          async () => {
            const selectedCategoryType = store.selectedCategoryType();

            if (!selectedCategoryType) {
              return;
            }

            try {
              const fields = await typeDataService.fetchFields(
                categoryType,
                selectedCategoryType
              );

              patchState(store, {
                fields: fields.fields,
              });
            } catch (error) {
              console.error(error);
            }
          },
          { allowSignalWrites: true }
        );

        effect(
          () => {
            language();
            const fields = store.fields();

            if (fields) {
              patchState(store, {
                columns: createColumnsFn(
                  fields,
                  language(),
                  categoryType,
                  store.selectedCategoryType() as number
                ),
              });
            }
          },
          { allowSignalWrites: true }
        );

        store.fetchEntities();
      },
    })
  );

export const FIELD_ENTITY_LIST_STORE = new InjectionToken<
  InstanceType<ReturnType<typeof createEntityListStore>>
>('FIELD_ENTITY_LIST_STORE');
