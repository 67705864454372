import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { DialogService } from '@fieldos/core';
import { DPUSection } from '@fieldos/models';
import { DPUStore } from '@fieldos/store/dpu.store';
import { filterEmpty, minLengthArrayValidator } from '@fieldos/utils';
import { TranslocoModule } from '@ngneat/transloco';
import { isArray } from 'lodash';
import { lastValueFrom, tap } from 'rxjs';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';
import { DpuSectionItemComponent } from './dpu-section-item/dpu-section-item.component';

@Component({
  selector: 'app-sections-form-dpu-section',
  templateUrl: './sections-form-dpu-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DpuSectionItemComponent,
    TranslocoModule,
    MatButtonModule,
    MatDivider,
  ],
})
export class SectionsFormDpuSectionComponent
  extends SectionsFormSectionBaseComponent<DPUSection>
  implements OnInit
{
  private readonly _dialog = inject(DialogService);
  private readonly _dpuStore = inject(DPUStore);

  override ngOnInit(): void {
    this.initializeForm();

    const sectionValue = this.sectionValue();

    if (isArray(sectionValue)) {
      this.form.setValue(sectionValue, { emitEvent: false });
    }

    if (this.rights()?.mandatory) {
      this.form.setValidators(minLengthArrayValidator(1));
      this.form.updateValueAndValidity();
    }

    if (!this.store.isEditing()) {
      this.form.valueChanges
        .pipe(
          takeUntilDestroyed(this._destroyRef),
          filterEmpty(),
          tap((value) => this.store.updateSectionValue(this.sectionId(), value))
        )
        .subscribe();
    }
  }

  protected override initializeForm(): void {
    this.form = new FormControl<number[]>([], {
      nonNullable: true,
    });
  }

  protected async onAddDPUs(): Promise<void> {
    const component = await import(
      '@fieldos/business/dpus/select-dpus-dialog/select-dpus-dialog.component'
    ).then((m) => m.SelectDpusDialogComponent);

    const selection = await lastValueFrom(
      this._dialog.openDialog<number[], number[] | undefined>(component, {
        width: '800px',
        minHeight: '800px',
        data: this.form.value,
      })
    );

    if (selection) {
      this._updateDPUs(selection);
    }
  }

  protected onRemove(index: number): void {
    const value = this.form.value as number[];
    value.splice(index, 1);
    this._updateDPUs(value);
  }

  private async _updateDPUs(value: number[]): Promise<void> {
    const workOrderId = this.store.entity()?.id;

    if (workOrderId) {
      const isSuccess = await this._dpuStore.updateWorkOrderDPUs(
        workOrderId,
        value
      );

      if (isSuccess) {
        this.form.setValue(value);
        this.detector.detectChanges();
      }
    } else {
      this.form.setValue(value);
      this.detector.detectChanges();
    }
  }
}
