import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { LineConnectorService } from './line-connector.service';

@Directive({
  selector: '[appLineConnector]',
  standalone: true,
})
export class LineConnectorDirective {
  constructor(_elementRef: ElementRef, private _service: LineConnectorService) {
    this._element = _elementRef.nativeElement;
  }

  @Input() public set appLineConnector(id: string) {
    this._element.setAttribute('appLineConnectorId', id);
    this._id = id;
  }
  @Input() public appLineConnectorPathStyle: LeaderLine.PathType = 'magnet';

  private _element: HTMLElement;
  private _id: string = '';

  @HostListener('click', ['$event'])
  protected startConnection(event: MouseEvent): void {
    event.stopPropagation();
    this._service.startConnection(this._id, event, {
      path: this.appLineConnectorPathStyle,
    });
  }
}
