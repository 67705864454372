import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { DialogModule } from '@fieldos/core';
import { LicenseManager } from 'ag-grid-enterprise';
import { AuthStore } from './@store';
import { agGridLicense } from './ag-grid-license';

import { registerLocaleData } from '@angular/common';
import enLocale from '@angular/common/locales/en';
import roLocale from '@angular/common/locales/ro';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterModule,
    DialogModule,
    MatSelectModule,
    ScrollingModule,
    MatSelectModule,
  ],
  styles: [
    `
      :host {
        display: flex;
        flex: 1 1 auto;
        width: 100%;
        height: 100%;
      }
    `,
  ],
})
export class AppComponent implements OnInit {
  constructor() {
    registerLocaleData(roLocale);
    registerLocaleData(enLocale);
  }

  private readonly _authStore = inject(AuthStore);

  ngOnInit(): void {
    this._authStore.initialize();
    LicenseManager.setLicenseKey(agGridLicense);
  }
}
