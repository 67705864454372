<div class="flex gap-1 items-center justify-between">
  <span class="field-label capitalize {{ labelClass() }}">
    {{ label() }}
    @if (required() === true) {
      *
    }
  </span>

  <mat-icon
    matTooltipPosition="left"
    class="cursor-pointer scale-75"
    [matTooltip]="info() || ''"
    svgIcon="mat_outline:info" />
</div>
