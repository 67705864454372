import { Type } from '@angular/core';
import {
  AssetFieldSubType,
  CommonFieldSubType,
  ContractorFieldSubType,
  CustomerFieldSubType,
  Field,
  FieldSubType,
  FieldSubTypes,
  LocationFieldSubType,
  UserFieldSubType,
} from '@fieldos/models';
import { StandardDataSourceId } from '@fieldos/store/data-source-cache';
import { FieldFormBaseComponent } from '../field-form-base.component';

export const loadComponentForFieldSubType = async (
  fieldSubType: FieldSubType
): Promise<Type<FieldFormBaseComponent<Field>>> => {
  switch (fieldSubType) {
    case CommonFieldSubType.TwoColumnContainer:
      return await import('./container-field/container-field.component').then(
        (m) => m.ContainerFieldComponent
      );

    case LocationFieldSubType.LocationType:
    case AssetFieldSubType.AssetType:
    case UserFieldSubType.UserType:
    case ContractorFieldSubType.ContractorType:
    case CustomerFieldSubType.CustomerType:
      return await import(
        './category-type-field-form/category-type-field-form.component'
      ).then((m) => m.CategoryTypeFieldFormComponent);

    case CommonFieldSubType.QRCode:
      return await import(
        './qr-code-field-form/qr-code-field-form.component'
      ).then((m) => m.QrCodeFieldFormComponent);
    case CommonFieldSubType.Photos:
      return await import(
        './photos-field-form/photos-field-form.component'
      ).then((m) => m.PhotosFieldFormComponent as any);
    case CommonFieldSubType.GPSXCoordinates:
    case CommonFieldSubType.GPSYCoordinates:
      return await import(
        './coordinate-field-form/coordinate-field-form.component'
      ).then((m) => m.CoordinateFieldFormComponent);
    case CommonFieldSubType.GPSPositionOnMap:
      return await import(
        './gps-position-form-field/gps-position-form-field.component'
      ).then((m) => m.GpsPositionFormFieldComponent as any);

    case CommonFieldSubType.Active:
    case CommonFieldSubType.Geofencing:
    case AssetFieldSubType.AssetMasterConfirm:
    case UserFieldSubType.Assigned:
      return await import(
        './slider-field-form/slider-field-form.component'
      ).then((m) => m.SliderFieldFormComponent);
    case CommonFieldSubType.SingleSelect:
    case LocationFieldSubType.LocationStatus:
    case AssetFieldSubType.AssetStatus:
    case CommonFieldSubType.Location:
    case UserFieldSubType.UserStatus:
    case UserFieldSubType.Role:
    case CustomerFieldSubType.CustomerStatus:
    case ContractorFieldSubType.ContractorStatus:
    case CommonFieldSubType.Contractor:
      return await import(
        './single-select-field-form/single-select-field-form.component'
      ).then((m) => m.SingleSelectFieldFormComponent);
    case UserFieldSubType.Password:
      return await import(
        './password-field-form/password-field-form.component'
      ).then((m) => m.PasswordFieldFormComponent);
    case UserFieldSubType.AccountType:
      return await import(
        './account-type-field-form/account-type-field-form.component'
      ).then((m) => m.AccountTypeFieldFormComponent as any);
    case FieldSubTypes.ApplicationAdminEmails:
      return await import(
        './text-array-field-form/text-array-field-form.component'
      ).then((m) => m.TextArrayFieldFormComponent as any);
    case FieldSubTypes.TimeSlots:
      return await import(
        './time-slots-field-form/time-slots-field-form.component'
      ).then((m) => m.TimeSlotsFieldFormComponent as any);
    case FieldSubTypes.Contracts:
    case CommonFieldSubType.Teams:
      return await import(
        './multiple-select-field-form/multiple-select-field-form.component'
      ).then((m) => m.MultipleSelectFieldFormComponent);

    default:
      return await import('./text-field-form/text-field-form.component').then(
        (m) => m.TextFieldFormComponent
      );
  }
};

export const getComponentInputs = (
  fieldSubType: FieldSubType,
  fieldId: string
): Record<string, any> => {
  switch (fieldSubType) {
    case LocationFieldSubType.LocationType:
      return { fieldId, categoryType: 'locations' };
    case CustomerFieldSubType.CustomerType:
      return { fieldId, categoryType: 'customers' };
    case ContractorFieldSubType.ContractorType:
      return { fieldId, categoryType: 'contractors' };
    case AssetFieldSubType.AssetType:
      return { fieldId, categoryType: 'assets' };
    case UserFieldSubType.UserType:
      return { fieldId, categoryType: 'users' };
    case CommonFieldSubType.Name:
      return { fieldId, maxLength: 255 };
    case CommonFieldSubType.UniqueCode:
      return { fieldId, maxLength: 2000 };
    case CommonFieldSubType.Address:
      return { fieldId, maxLength: 2000 };
    case CommonFieldSubType.GPSXCoordinates:
      return { fieldId, coordinateType: 'longitude' };
    case CommonFieldSubType.GPSYCoordinates:
      return { fieldId, coordinateType: 'latitude' };
    case CommonFieldSubType.Email:
    case CommonFieldSubType.ApplicationAdminEmails:
    case UserFieldSubType.UserEmail:
      return { fieldId, isEmail: true };
    case LocationFieldSubType.LocationStatus:
      return { fieldId, dataSourceId: StandardDataSourceId.LocationStatus };
    case ContractorFieldSubType.ContractorStatus:
      return { fieldId, dataSourceId: StandardDataSourceId.ContractorStatus };
    case CustomerFieldSubType.CustomerStatus:
      return { fieldId, dataSourceId: StandardDataSourceId.CustomerStatus };
    case AssetFieldSubType.AssetStatus:
      return { fieldId, dataSourceId: StandardDataSourceId.AssetStatus };
    case CommonFieldSubType.Location:
      return { fieldId, dataSourceId: StandardDataSourceId.Locations };
    case CommonFieldSubType.Teams:
      return {
        fieldId,
        dataSourceId: StandardDataSourceId.Teams,
      };
    case CommonFieldSubType.Contracts:
      return { fieldId, dataSourceId: StandardDataSourceId.CustomerContracts };
    case UserFieldSubType.UserStatus:
      return { fieldId, dataSourceId: StandardDataSourceId.UserStatus };
    case UserFieldSubType.Role:
      return { fieldId, dataSourceId: StandardDataSourceId.UserRoles };
    case FieldSubTypes.Contractor:
      return { fieldId, dataSourceId: StandardDataSourceId.Contractors };

    default:
      return { fieldId };
  }
};
