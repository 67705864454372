<app-field-wrapper class="flex-grow {{ fieldClass }}" [errors]="errors">
  <mat-form-field class="fuse-mat-dense w-full" subscriptSizing="dynamic">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }

    @if (prefixIcon) {
      <mat-icon matPrefix [svgIcon]="prefixIcon" />
    }

    @if (isTextarea) {
      <textarea
        matInput
        [formControl]="formCtrl"
        [placeholder]="placeholder"
        [required]="isRequired() || false"
        autocomplete="off"
        rows="3">
      </textarea>
    } @else {
      <input
        matInput
        [attr.autocomplete]="'disabled'"
        [formControl]="formCtrl"
        [placeholder]="placeholder"
        [required]="isRequired() || false"
        [type]="type"
        autocomplete="off" />
    }

    @if (hint) {
      <mat-hint>{{ hint }}</mat-hint>
    }
  </mat-form-field>
</app-field-wrapper>
