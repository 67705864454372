import { SectionProperties } from './section-properties';
import { SectionLabelInfoProperties } from './simple-section-properties';

export interface PartsSectionCommonProperties {
  type: boolean;
  cost: boolean;
  partCategory: boolean;
  inventory: boolean;
}

export type PartsSectionProperties = SectionProperties<
  SectionLabelInfoProperties,
  PartsSectionCommonProperties
>;
