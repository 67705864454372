<div class="prose"></div>

<div class="grid gap-4 grid-cols-2">
  @for (
    section of store.selectContainerSections(containerSection().id);
    track section.id
  ) {
    <app-sections-form-section [sectionId]="section.id" class="border" />
  }
</div>
