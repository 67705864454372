import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[repeatTimes]',
  standalone: true,
})
export class RepeatTimesDirective {
  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewRef: ViewContainerRef
  ) {}

  @Input() public set repeatTimes(repeatTimes: number) {
    this._viewRef.clear();

    let index = 0;
    for (; index < repeatTimes || 0; index++) {
      this._viewRef.createEmbeddedView(this._templateRef, {
        index,
      });
    }
  }
}
