import { NgComponentOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Type,
  effect,
  inject,
  signal,
} from '@angular/core';
import { CommonFieldSubType } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { FIELDS_DESIGNER_STORE } from '../fields-designer.store';

@Component({
  selector: 'app-fields-designer-field-properties',
  templateUrl: './fields-designer-field-properties.component.html',
  standalone: true,
  imports: [TranslocoModule, NgComponentOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class FieldsDesignerFieldPropertiesComponent {
  constructor() {
    effect(async () => {
      const field = this.store.selectedField();
      let component: Type<any> | null = null;

      switch (field?.subtype) {
        case CommonFieldSubType.Geofencing:
          component = await import(
            './fields-designer-field-properties/fields-designer-geofencing-field-properties/fields-designer-geofencing-field-properties.component'
          ).then((m) => m.FieldsDesignerGeofencingFieldPropertiesComponent);
          break;
        case CommonFieldSubType.SingleSelect:
          component = await import(
            './fields-designer-field-properties/fields-custom-select-field-properties/fields-custom-select-field-properties.component'
          ).then((m) => m.FieldsCustomSelectFieldPropertiesComponent);
          break;
        default:
          component = await import(
            './fields-designer-field-properties/fields-designer-simple-field-properties/fields-designer-simple-field-properties.component'
          ).then((m) => m.FieldsDesignerSimpleFieldPropertiesComponent);
      }

      this.component.set(component);
    });
  }

  protected readonly store = inject(FIELDS_DESIGNER_STORE);

  protected readonly component = signal<Type<any> | null>(null);
}
