import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Priority, PrioritySection } from '@fieldos/models';
import { ValueLabelPair } from '@fieldos/models/value-label-pair';
import { WorkOrderFormPriorityButtonColorPipe } from '@fieldos/pipes';
import { PRIORITY_OPTIONS } from '@fieldos/providers';
import { TranslocoModule } from '@ngneat/transloco';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-priority-section',
  templateUrl: './sections-form-priority-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    WorkOrderFormPriorityButtonColorPipe,
    TranslocoModule,
  ],
})
export class SectionsFormPrioritySectionComponent extends SectionsFormSectionBaseComponent<PrioritySection> {
  private _priorityOptions = inject(PRIORITY_OPTIONS);

  protected override initializeForm(): void {
    const defaultPriority = this.section().properties
      ?.defaultOption as Priority;

    this.form = new FormControl<Priority>(defaultPriority, {
      nonNullable: true,
    });

    if (!this.store.entity()) {
      this.store.updateSectionValue(this.sectionId(), defaultPriority);
    }
  }

  protected override setValidation(): void {
    this.form.setValidators(Validators.required);
    this.form.updateValueAndValidity();
  }

  protected onSelectPriority(priority: Priority): void {
    this.form.setValue(priority);
  }

  protected get availableOptions(): ValueLabelPair<Priority>[] {
    if (this.section) {
      return this._priorityOptions.filter(
        (option) => this.section().properties?.options.includes(option.value)
      );
    }
    return [];
  }
}
