import { moveItemInArray } from '@angular/cdk/drag-drop';
import { InjectionToken, computed, effect, inject } from '@angular/core';
import { BpDropEvent } from '@browsepedia/drag-and-drop';
import { CategoryTypeFieldsDataService } from '@fieldos/data-services';
import {
  CategoryType,
  CommonFieldSubType,
  Field,
  FieldCommonPropertiesBase,
  FieldSimpleTranslatableProperties,
  FieldSubType,
  FieldSubTypes,
} from '@fieldos/models';
import { ToastStore } from '@fieldos/store/toast.store';
import { TranslocoService } from '@ngneat/transloco';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { Guid } from 'guid-typescript';
import { FIELD_DESIGNER_ALL_FIELDS } from './fields-designer.provider';
import { DialogService } from '@fieldos/core';
import { lastValueFrom } from 'rxjs';

interface FieldDesignerState {
  allFields: FieldSubType[];
  fixedFields: Field[];
  uniqueFields: Partial<Record<FieldSubType, boolean>>;
  fields: Field[];
  fixedFieldTypes: Partial<Record<FieldSubType, boolean>>;
  validationStatuses: Record<string, boolean>;
  selectedFieldId: string;
  isLoadingFields: boolean;
  isSaving: boolean;

  draggedFieldId: string | undefined;
  draggedNewFieldType: FieldSubType | undefined;

  selectedCategoryTypeId: number;
}

const initialState: FieldDesignerState = {
  allFields: [],
  fixedFields: [],
  uniqueFields: {},
  fields: [],
  fixedFieldTypes: {},
  validationStatuses: {},
  selectedFieldId: '',
  isLoadingFields: false,
  isSaving: false,

  draggedFieldId: undefined,
  draggedNewFieldType: undefined,

  selectedCategoryTypeId: 0,
};

export const createFieldsDesignerStore = (
  categoryType: CategoryType,
  createInitialFields: () => Field[],
  fixedFields: FieldSubType[],
  uniqueFields: FieldSubType[]
) =>
  signalStore(
    withState({
      ...initialState,
      fixedFieldTypes: fixedFields.reduce(
        (acc, curr) => ({ ...acc, [curr]: true }),
        {} as Record<FieldSubType, boolean>
      ),
      uniqueFields: uniqueFields.reduce(
        (acc, curr) => ({ ...acc, [curr]: true }),
        {} as Record<FieldSubType, boolean>
      ),
    }),
    withComputed((store) => ({
      selectedField: computed(() =>
        [...store.fields(), ...store.fixedFields()].find(
          (e) => e.id === store.selectedFieldId()
        )
      ),
      topLevelFields: computed(() =>
        store.fields().filter((e) => !e.properties?.containerId)
      ),
      topLevelFixedFields: computed(() =>
        store.fixedFields().filter((e) => !e.properties?.containerId)
      ),
      isDraggingContainer: computed(() => {
        const draggingField = store
          .fields()
          .find((e) => e.id === store.draggedFieldId());

        return (
          draggingField?.subtype === CommonFieldSubType.TwoColumnContainer ||
          store.draggedNewFieldType() === CommonFieldSubType.TwoColumnContainer
        );
      }),
      availableFields: computed(() => {
        const enabledFields = store.fields().map((e) => e.subtype);

        return store
          .allFields()
          .filter(
            (e) =>
              ((!enabledFields.includes(e) && store.uniqueFields()[e]) ||
                !store.uniqueFields()[e]) &&
              !store.fixedFieldTypes()[e]
          );
      }),
      canSave: computed(() =>
        Object.values(store.validationStatuses()).every((e) => !!e)
      ),
    })),
    withMethods(
      (
        store,
        transloco = inject(TranslocoService),
        dataService = inject(CategoryTypeFieldsDataService),
        toastService = inject(ToastStore),
        dialogService = inject(DialogService),
      ) => ({
        setSelectedCategoryTypeId(selectedCategoryTypeId: number) {
          patchState(store, { selectedCategoryTypeId });
        },
        updateFieldProperties<T extends FieldCommonPropertiesBase>(
          fieldValue: T
        ): void {
          const fields = [...store.fields()];
          const field = fields.find((e) => e.id === store.selectedFieldId());

          if (field) {
            field.properties = {
              ...fieldValue,
              containerId: field.properties?.containerId,
            };
            patchState(store, { fields });
          }
        },
        updateFieldValidationStatus(fieldId: string, isValid: boolean): void {
          patchState(store, {
            validationStatuses: {
              ...store.validationStatuses(),
              [fieldId]: isValid,
            },
          });
        },
        updateFieldTranslations<T extends FieldSimpleTranslatableProperties>(
          language: string,
          formValue: T
        ): void {
          if (!language) {
            return;
          }

          const fields = [...store.fields()];
          const field = fields.find((e) => e.id === store.selectedFieldId());

          const fixedFields = [...store.fixedFields()];
          const fixedField = fixedFields.find(
            (e) => e.id === store.selectedFieldId()
          );

          if (field) {
            field.translatableProperties[language] = formValue;
            patchState(store, { fields });
          } else if (fixedField) {
            fixedField.translatableProperties[language] = formValue;
            patchState(store, { fixedFields });
          }
        },
        setSelectedFieldId(selectedFieldId: string) {
          patchState(store, { selectedFieldId });
        },
        setDraggedNewFieldType(draggedNewFieldType?: FieldSubType) {
          patchState(store, {
            draggedNewFieldType,
          });
        },
        moveOrAddField(
          event: BpDropEvent<Field | FieldSubType>,
          parentContainerId?: string
        ) {
          const fields = [...store.fields()];

          let field = event.data as Field;
          if (
            Object.values(FieldSubTypes).includes(event.data as FieldSubType)
          ) {
            field = {
              id: Guid.create().toString(),
              subtype: event.data as FieldSubType,
              translatableProperties: {
                [transloco.getActiveLang()]: {
                  label: transloco.translate(`fields.${event.data}.title`),
                  info: transloco.translate(`fields.${event.data}.title`),
                },
              },
              properties: {
                containerId: parentContainerId,
              },
              value: undefined,
            };
          } else {
            field = fields.find((e) => e.id === field.id) as Field;
          }

          const topLevelFields = store.topLevelFields();
          const topLevelFieldsSlice = topLevelFields.slice(
            0,
            event.targetIndex
          );
          const topLevelFieldsSliceContainerIds = topLevelFieldsSlice
            .filter((e) => e.subtype === CommonFieldSubType.TwoColumnContainer)
            .map((e) => e.id);

          // Calculate the new index taking into account fields which are in containers
          const index =
            event.targetIndex +
            fields.filter((f) =>
              topLevelFieldsSliceContainerIds.includes(
                f.properties?.containerId || ''
              )
            ).length;

          const fieldIndex = fields.findIndex((e) => e.id === field.id);

          if (fieldIndex > -1 && !parentContainerId) {
            // field exists and is moved from top level or container to top level
            field.properties.containerId = undefined;
            moveItemInArray(fields, fieldIndex, index);
          } else if (fieldIndex > -1 && parentContainerId) {
            // field is moved to container
            const targetContainerIndex = fields.findIndex(
              (e) => e.id === parentContainerId
            );
            field.properties.containerId = parentContainerId;

            moveItemInArray(
              fields,
              fieldIndex,
              targetContainerIndex + event.targetIndex
            );
          } else if (fieldIndex === -1 && parentContainerId) {
            // field is added to container
            const targetContainerIndex = fields.findIndex(
              (e) => e.id === parentContainerId
            );

            fields.splice(
              targetContainerIndex + event.targetIndex + 1,
              0,
              field
            );
          } else if (fieldIndex === -1 && !parentContainerId) {
            // field is added top level
            fields.splice(index, 0, field);
          }

          patchState(store, (state) => ({
            fields,
            draggedFieldId: undefined,
            selectedFieldId: field.id,
            validationStatuses: {
              ...state.validationStatuses,
              [field.id]: true,
            },
          }));
        },
        setDraggingFieldId(draggingFieldId?: string) {
          setTimeout(() => {
            patchState(store, { draggedFieldId: draggingFieldId });
          });
        },
        async save(): Promise<void> {
          patchState(store, { isSaving: true });

          const fields = [...store.fixedFields(), ...store.fields()];
          try {
            await dataService.save(
              categoryType,
              store.selectedCategoryTypeId(),
              fields
            );
            toastService.showSuccessToast('fields_designer.saved_successfully');
          } catch (e) {
            toastService.showErrorToast('fields_designer.save_failed');
          }

          patchState(store, { isSaving: false });
        },
        async deleteField(fieldId: string) {
          const canDelete = await lastValueFrom(dialogService.showConfirmDialog('fields_designer.delete_field_title', 'fields_designer.delete_field_message'));
          if (!canDelete) {
            return;
          }

          const updatedFields = store.fields().filter((field) => field.id !== fieldId);
          const validationStatuses = { ...store.validationStatuses() };
          delete validationStatuses[fieldId];

          patchState(store, { isSaving: true });

          try {
            await dataService.save(
              categoryType,
              store.selectedCategoryTypeId(),
              updatedFields
            );
            patchState(store, {
              fields: updatedFields,
              validationStatuses,
            });
            toastService.showSuccessToast('fields_designer.field_deleted');
          } catch (e) {
            toastService.showErrorToast('fields_designer.save_failed');
          } finally {
            patchState(store, { isSaving: false });
          }
        }

      })
    ),
    withHooks({
      async onInit(
        store,
        dataService = inject(CategoryTypeFieldsDataService),
        toastService = inject(ToastStore)
      ) {
        const defaultFixedFields = [...createInitialFields()];

        effect(async () => {
          const categoryTypeId = store.selectedCategoryTypeId();

          if (!categoryTypeId) {
            return;
          }

          try {
            const fieldsResponse = await dataService.fetchFields(
              categoryType,
              categoryTypeId
            );
            const fields = fieldsResponse.fields || [];
            const fixedFields: Field[] = fieldsResponse.fields
              ? []
              : [...defaultFixedFields];
            const normalFields: Field[] = [];

            fields.forEach((field) => {
              const isFixedField = fixedFieldTypes.includes(field.subtype);

              if (!isFixedField) {
                normalFields.push(field);
                return;
              }

              if (
                field.properties.containerId &&
                !fixedFields.find((e) => e.id === field.properties?.containerId)
              ) {
                const container = fields.find(
                  (e) => e.id === field.properties?.containerId
                ) as Field;
                fixedFields.push(container);
              }
              fixedFields.push(field);
            });

            fixedFields
              .filter(
                (e) => e.subtype === CommonFieldSubType.TwoColumnContainer
              )
              .forEach((fixedContainer) => {
                const containerIndex = normalFields.findIndex(
                  (e) => e.id === fixedContainer.id
                );
                if (containerIndex !== -1) {
                  normalFields.splice(containerIndex, 1);
                }
              });

            patchState(store, {
              fixedFields,
              fields: normalFields,
              validationStatuses: {
                ...fixedFields.reduce(
                  (acc, curr) => ({ ...acc, [curr.id]: true }),
                  {} as Record<string, boolean>
                ),
                ...normalFields.reduce(
                  (acc, curr) => ({ ...acc, [curr.id]: true }),
                  {} as Record<string, boolean>
                ),
              },
            });
          } catch (e) {
            toastService.showErrorToast('fields_designer.fetch_failed');
          }
        });

        const initialFixedFields = createInitialFields();

        patchState(store, {
          fixedFields: initialFixedFields,
          validationStatuses: initialFixedFields.reduce(
            (acc, curr) => ({ ...acc, [curr.id]: true }),
            {} as Record<string, boolean>
          ),
        });

        const fixedFieldTypes = Object.keys(
          store.fixedFieldTypes()
        ) as FieldSubType[];
      },
    }),
    withHooks({
      onInit(store, allFields = inject(FIELD_DESIGNER_ALL_FIELDS)) {
        patchState(store, { allFields });
      },
    })
  );

export type FieldsDesignerStore = InstanceType<
  ReturnType<typeof createFieldsDesignerStore>
>;

export const FIELDS_DESIGNER_STORE = new InjectionToken<FieldsDesignerStore>(
  'FIELD_DESIGNER_STORE'
);
