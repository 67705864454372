import { AsyncPipe } from '@angular/common';
import { Component, Input, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { AutoUnsubscribe } from '@fieldos/utils';
import { TranslocoModule } from '@ngneat/transloco';
import { Subscription, tap } from 'rxjs';
import { AutocompleteSelectComponent } from '../autocomplete-select';
import { LocationSelectValue } from './locations-select.models';
import { LocationsSelectStore } from './locations-select.store';

@Component({
  selector: 'app-locations-select',
  templateUrl: './locations-select.component.html',
  standalone: true,
  providers: [
    LocationsSelectStore,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationsSelectComponent),
      multi: true,
    },
  ],
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    AutocompleteSelectComponent,
    TranslocoModule,
  ],
})
@AutoUnsubscribe()
export class LocationsSelectComponent implements ControlValueAccessor {
  constructor(private _store: LocationsSelectStore) {
    _store.fetchLocations();

    this._value$ = this.form.valueChanges
      .pipe(
        tap(
          (value) =>
            this._onChange && this._onChange(value as LocationSelectValue)
        )
      )
      .subscribe();

    this.form.controls['locationId'].valueChanges
      .pipe(tap((value) => this._store.setSelectedLocation(value || undefined)))
      .subscribe();
  }

  @Input() public hideSublocation = false;
  @Input() public locationLabel?: string;
  @Input() public sublocationLabel?: string;

  protected vm$ = this._store.vm$;

  protected form = new FormGroup({
    locationId: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
    }),
    sublocationId: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
    }),
  });

  private _onChange!: (value: LocationSelectValue) => void;
  private _onTouched!: () => void;
  private _value$: Subscription;

  writeValue(value: LocationSelectValue): void {
    if (value && value.locationId) {
      this._store.setSelectedLocation(value.locationId);
    }
    this.form.patchValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: LocationSelectValue) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled && this.form.enabled) {
      this.form.disable({ emitEvent: false });
    }

    if (!isDisabled && this.form.disabled) {
      this.form.enable({ emitEvent: false });
    }
  }
}
