import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  computed,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import {
  AutocompleteSelectComponent,
  FileUploaderComponent,
  TextFieldComponent,
} from '@fieldos/components';
import { DialogService } from '@fieldos/core';
import { FileModel, ReasonCode, ReasonCodeAction } from '@fieldos/models';
import { DataSourceEntityPipe, FieldErrorsPipe } from '@fieldos/pipes';
import {
  DataSourceStore,
  StandardDataSourceId,
} from '@fieldos/store/data-source-cache';
import { WorkOrderStatusGraphStore } from '@fieldos/store/status-graph';
import { TranslocoModule } from '@ngneat/transloco';
import { AssignExceptionAddSealsActionComponent } from './assign-exception-add-seals-action/assign-exception-add-seals-action.component';
import { AssignExceptionParentCodeComponent } from './assign-exception-parent-code/assign-exception-parent-code.component';
import { AssignExceptionSelectDpusActionComponent } from './assign-exception-select-dpus-action/assign-exception-select-dpus-action.component';
import {
  DPUFormGroup,
  LocationFormGroup,
  SealsFormGroup,
  createAssignReasonCodeForm,
} from './assign-exception.form';
import { AssignExceptionDialogData } from './assign-exception.models';

@Component({
  selector: 'app-assign-exception-dialog',
  templateUrl: './assign-exception-dialog.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    TranslocoModule,
    AutocompleteSelectComponent,
    FileUploaderComponent,
    TextFieldComponent,
    MatButtonModule,
    ReactiveFormsModule,
    MatIconModule,
    AssignExceptionAddSealsActionComponent,
    AssignExceptionSelectDpusActionComponent,
    FieldErrorsPipe,
    DataSourceEntityPipe,
    AssignExceptionParentCodeComponent,
    MatDividerModule,
  ],
})
export class AssignExceptionDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: AssignExceptionDialogData,
    private readonly _dialog: DialogService,
    private readonly _dialogRef: MatDialogRef<AssignExceptionDialogComponent>
  ) {
    this.form.patchValue(data);

    if (data.scopeId) {
      this._statusGraphStore.fetchForScopeId(data.scopeId);
    }
  }

  protected readonly reasonCodes = computed(() => {
    const reasonCodes = this._allReasonCodes() as ReasonCode[];

    const targetType = this.data.targetType;

    return reasonCodes.filter((e) => e.properties.type === targetType);
  });

  protected readonly locations = inject(DataSourceStore).selectDataSource(
    StandardDataSourceId.Locations
  );

  protected readonly statusName = computed(() => {
    if (!this.data.scopeId) {
      return [];
    }

    const statuses = this._statusGraphStore.statusGraphs()[this.data.scopeId];

    return (
      statuses.find(
        (e) =>
          e.id === this.selectedReasonCode().properties.actionValue?.statusGuid
      )?.data.label || ''
    );
  });

  protected readonly reasonCodeAction = computed<ReasonCodeAction | null>(
    () => this.selectedReasonCode()?.properties.action
  );

  protected readonly form = createAssignReasonCodeForm(this.reasonCodeAction);

  protected readonly selectedReasonCode = computed(() => {
    const reasonCodeId = this._selectedReasonCodeId();

    return this.reasonCodes().find((e) => e.id === reasonCodeId) as ReasonCode;
  });

  private readonly _selectedReasonCodeId = toSignal(
    this.form.controls.taxonomyId.valueChanges
  );

  private readonly _allReasonCodes = inject(DataSourceStore).selectDataSource(
    StandardDataSourceId.ReasonCodes
  );
  private readonly _statusGraphStore = inject(WorkOrderStatusGraphStore);
  private readonly _detector = inject(ChangeDetectorRef);

  protected save(): void {
    this._dialogRef.close(this.form.value);
  }

  protected onFileClick(fileIndex: number): void {
    this._dialog.openFileGallery(
      this.form.controls.media.value || [],
      fileIndex,
      {
        removeDisabled: true,
      }
    );
  }

  protected onDPUsChange(dpuIds: number[]): void {
    const form = this.form.controls.actionValue as DPUFormGroup;

    form.setValue({
      dpuIds,
    });

    this._detector.detectChanges();
  }

  protected onSealsChange(seals: string[]): void {
    const form = this.form.controls.actionValue as SealsFormGroup;

    form.setValue({
      seals,
    });

    this._detector.detectChanges();
  }

  protected onLocationChange(locationId: number): void {
    const form = this.form.controls.actionValue as LocationFormGroup;

    form.setValue({
      locationId,
    });
  }

  protected onFilesChange(files: FileModel[]): void {
    this.form.controls.media.setValue(files);
  }
}
