export interface SectionsRights {
  roleId: number;
  scopeId: number;
  updatedDate: string | null;
  rights: SectionRightsModel;
}

export type SectionRightsModel = Record<string, SectionStatusRights[]> & {
  addRights: SectionStatusRights[];
};

export type RightsStatusType = 'show' | 'canEdit' | 'mandatory' | 'dontShow';

export interface SectionRights {
  show: boolean;
  canEdit: boolean;
  mandatory: boolean;
}

export interface SectionStatusRights {
  sectionId: string;
  rights: SectionRights;
}
