import { inject, Pipe, PipeTransform } from '@angular/core';
import { DataSourceStore, StandardDataSourceId } from '@fieldos/store';

@Pipe({
  name: 'dataSourceEntity',
  standalone: true,
})
export class DataSourceEntityPipe implements PipeTransform {
  private readonly _dataSourceStore = inject(DataSourceStore);

  transform(
    dataSouceEntityId: StandardDataSourceId | undefined | null,
    dataSourceId: StandardDataSourceId
  ) {
    if (!dataSouceEntityId) {
      return;
    }

    const values =
      this._dataSourceStore.selectNormalizedDataSource(dataSourceId)();

    return values[dataSouceEntityId];
  }
}
