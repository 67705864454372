import { Route } from '@angular/router';

export const checklistsRoutes: Route[] = [
  {
    path: 'checklists',
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./checklists/checklists.component').then(
            (m) => m.ChecklistsComponent
          ),
        data: {
          title: 'menu.checklists',
        },
      },
      {
        path: 'editor',
        pathMatch: 'full',
        loadComponent: () =>
          import('./checklist-editor/checklist-editor.component').then(
            (m) => m.ChecklistEditorComponent
          ),
        data: {
          title: 'checklists.create',
        },
      },
      {
        path: 'editor/:checklistId',
        pathMatch: 'full',
        loadComponent: () =>
          import('./checklist-editor/checklist-editor.component').then(
            (m) => m.ChecklistEditorComponent
          ),
        data: {
          title: 'checklists.edit',
        },
      },
    ],
  },
];
