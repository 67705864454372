import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { FilterChangedEvent } from 'ag-grid-community';
import { debounceTime, tap } from 'rxjs';
import {
  StarFilterModel,
  StarFilterOperator,
  StartFloatingFilterParams,
} from '../star-filter.models';
import { StarFilterComponent } from '../star-filter/star-filter.component';

@Component({
  selector: 'app-star-floating-filter',
  templateUrl: './star-floating-filter.component.html',
  standalone: true,
  imports: [TranslocoModule, MatSelectModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        width: 100%;
        height: auto;
        margin-top: auto;
        margin-bottom: auto;
      }
    `,
  ],
})
export class StarFloatingFilterComponent implements IFloatingFilterAngularComp {
  constructor() {
    this.form.valueChanges
      .pipe(
        debounceTime(200),
        tap(() => {
          this._params()?.parentFilterInstance((instance: any) => {
            const setFilter = instance as StarFilterComponent;

            setFilter.onFloatingFilterChanged(this.form.getRawValue());
          });
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  protected readonly form = new FormGroup({
    operator: new FormControl<StarFilterOperator>(2, { nonNullable: true }),
    value: new FormControl<number>(0, { nonNullable: true }),
  });

  private readonly _params = signal<StartFloatingFilterParams | null>(null);

  agInit(params: StartFloatingFilterParams): void {
    this._params.set(params);
  }

  onParentModelChanged(
    parentModel: StarFilterModel,
    filterChangedEvent?: FilterChangedEvent | null
  ): void {
    this.form.setValue(parentModel, { emitEvent: false });
  }
}
