<div class="flex flex-col gap-2 p-2" [formGroup]="form">
  <mat-radio-group
    formControlName="operator"
    class="flex flex-col"
    color="primary">
    <mat-radio-button [value]="1">
      {{ 'common.operators.equals' | transloco }}
    </mat-radio-button>

    <mat-radio-button [value]="2">
      {{ 'common.operators.less' | transloco }}
    </mat-radio-button>

    <mat-radio-button [value]="3">
      {{ 'common.operators.greater' | transloco }}
    </mat-radio-button>
  </mat-radio-group>

  <app-star-rating-field formControlName="value" class="block mx-auto" />
</div>
