import { SectionValueEntry } from './section-value';

export interface SectionBasedEntity {
  id: number;
  statusId: string;
  scopeId: number;
  value: SectionValueEntry[];
  sectionVersionId: string;
  availableActions: Record<SectionBaseEntityAction, boolean>;
  isReasonCodeLocked: string | null;
}

export type SectionBasedEntityType = 'workorders' | 'servicerequests';

export type SectionBaseEntityAction = 'delete' | 'edit' | 'forward';
