import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
} from '@angular/core';
import { ShowInformationSection } from '@fieldos/models';
import { pad } from 'lodash';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-show-information-section',
  templateUrl: './sections-form-show-information-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionsFormShowInformationSectionComponent
  extends SectionsFormSectionBaseComponent<ShowInformationSection>
  implements OnInit
{
  protected readonly value = computed(() => {
    const sectionValue = this.sectionValue();

    if (!sectionValue) {
      return '';
    }

    if (!sectionValue?.calculatedByFormula) {
      return sectionValue.value || '';
    }

    if (
      sectionValue.calculatedByFormula === 'applyusertimezone' &&
      sectionValue.value
    ) {
      const timeArr = sectionValue.value.split(':');

      const [hours, minutes] = timeArr;
      const date = new Date();
      date.setUTCHours(Number(hours));
      date.setUTCMinutes(Number(minutes));

      const formattedHours = pad(date.getHours().toString(), 2, '0');
      const formattedMinutes = pad(date.getMinutes().toString(), 2, '0');

      return `${formattedHours}:${formattedMinutes}`;
    }

    return '';
  });

  protected readonly rows = computed(() => {
    const value = this.value();

    if (typeof value === 'string') {
      return value.split('\n');
    }

    return [];
  });

  override ngOnInit(): void {
    return;
  }
}
