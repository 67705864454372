import { Injectable, inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import {
  AccessToken,
  Claim,
  LoginCredentials,
  UserProfile,
} from '@fieldos/models';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class AuthDataService {
  private readonly _http = inject(HttpService);
  private readonly _transloco = inject(TranslocoService);

  forgotPassword(email: string): Promise<void> {
    return this._http.put<void>('/forgot-password', {
      username: email,
      lang: this._transloco.getActiveLang(),
    });
  }

  resetPassword(password: string): Promise<void> {
    return this._http.post<void>('/auth/reset-password', password);
  }

  login(credentials: LoginCredentials): Promise<AccessToken> {
    return this._http.post<AccessToken>(`/login`, {
      ...credentials,
      lang: this._transloco.getActiveLang(),
    });
  }

  loginWithProvider(code: string): Promise<AccessToken> {
    return this._http.post<AccessToken>(`/oauth2-login-token.php`, {
      token: code,
    });
  }

  getProfile(): Promise<UserProfile> {
    return this._http.get<UserProfile>(`/user-profile`);
  }

  async fetchClaims(roleId: number): Promise<Claim[]> {
    const response = await this._http.get<Array<{ claims: Claim[] }>>(
      `/role-permissions/${roleId}`
    );

    return response[0].claims;
  }

  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Promise<void> {
    return this._http.post<void>('/auth/sign-up', user);
  }

  unlockSession(credentials: {
    email: string;
    password: string;
  }): Promise<void> {
    return this._http.post<void>('/auth/unlock-session', credentials);
  }

  updateProfile(profile: Partial<UserProfile>): Promise<UserProfile> {
    return this._http.put<UserProfile>(`/user-profile`, profile);
  }
}
