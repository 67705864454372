<div class="relative flex-grow min-h-48 max-h-full h-full flex flex-col">
  <ng-container
    *ngTemplateOutlet="
      displayFiles().length === 0 ? empty : notEmptyTemplate
    " />
</div>

<input
  #uploader
  class="hidden"
  [accept]="accept"
  [multiple]="maxNumberOfFiles !== 1"
  (change)="onFilesAdded($any($event.target).files)"
  type="file" />

<ng-template #empty>
  @if (!disabled) {
    <div
      class="relative w-full border-2 border-dashed flex-grow cursor-pointer"
      (click)="onAddClick()"
      (filesDropped)="onFilesAdded($event)"
      fileUploaderDropzone>
      <span
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-base">
        {{ placeholder }}
      </span>
    </div>
  }
</ng-template>

<ng-template #notEmptyTemplate>
  <ng-container
    *ngTemplateOutlet="
      maxNumberOfFiles === 1
        ? singleSelectionTemplate
        : multipleSelectionTemplate
    " />
</ng-template>

<ng-template #multipleSelectionTemplate>
  <div class="group/carrousel w-full h-full relative flex-grow flex flex-col">
    <div class="flex gap-4 h-full w-full pb-1 items-center flex-grow">
      @if (canAdd && !disabled && !readonly) {
        <div class="h-full p-4 flex items-center justify-center">
          <div
            class="h-20 w-20 border-2 border-dashed flex items-center justify-center cursor-pointer hover:bg-slate-100"
            (click)="onAddClick()"
            (filesDropped)="onFilesAdded($event)"
            fileUploaderDropzone>
            <mat-icon svgIcon="mat_solid:add" />
          </div>
        </div>
      }

      <div
        #scrollContainer
        class="flex gap-4 w-full pb-1 self-stretch"
        fuseScrollbar>
        @for (file of displayFiles(); track file.id) {
          @if (
            {
              isImage: file | fileUploaderIsImage
            };
            as context
          ) {
            <ng-container
              [ngTemplateOutlet]="
                context.isImage ? imagePreviewTemplate : documentPreviewTemplate
              "
              [ngTemplateOutletContext]="{ file: file, index: $index }" />
          }
        }
      </div>
    </div>

    @if (canScrollLeft) {
      <button
        mat-icon-button
        class="absolute left-28 top-1/2 -translate-y-1/2 bg-slate-700 bg-opacity-75"
        (click)="scrollLeft()">
        <mat-icon class="text-white" svgIcon="mat_solid:keyboard_arrow_left" />
      </button>
    }

    @if (canScrollRight) {
      <button
        mat-icon-button
        class="absolute right-2 top-1/2 -translate-y-1/2 bg-slate-700 bg-opacity-75"
        (click)="scrollRight()">
        <mat-icon class="text-white" svgIcon="mat_solid:keyboard_arrow_right" />
      </button>
    }
  </div>
</ng-template>

<ng-template #singleSelectionTemplate>
  @if (
    {
      isImage: displayFiles()[0] | fileUploaderIsImage
    };
    as context
  ) {
    <ng-container
      *ngTemplateOutlet="
        context.isImage ? imagePreviewTemplate : documentPreviewTemplate;
        context: { file: displayFiles()[0], index: 0 }
      " />
  }
</ng-template>

<ng-template #imagePreviewTemplate let-file="file" let-index="index">
  <div
    class="relative aspect-square group/imagePreview cursor-pointer flex h-full bg-slate-100"
    (click)="fileClick.emit(index)">
    <img class="object-contain" [src]="file.url" />

    @if (progressReport[file.url] && progressReport[file.url] !== 100) {
      <ng-container
        [ngTemplateOutlet]="loadingOverlay"
        [ngTemplateOutletContext]="{
          progress: progressReport[file.url]
        }" />
    }

    @if (
      ((progressReport[file.url] && progressReport[file.url] === 100) ||
        (!progressReport[file.url] && !hideInfoOverlay)) &&
      !readonly
    ) {
      <div
        class="absolute w-full h-full hidden group-hover/imagePreview:flex flex-col items-center justify-center text-sm bg-slate-100 bg-opacity-87 transition-all">
        <div class="flex flex-col text-sm px-4 items-start">
          <span class="font-bold leading-4">{{ file.value ?? file.name }}</span>
          <div class="flex gap-2 flex-col">
            <span class="whitespace-nowrap">{{ file.size | filesize }}</span>

            <div class="flex gap-4">
              <button
                mat-icon-button
                (click)="onDownloadFile(file); $event.stopPropagation()">
                <mat-icon svgIcon="heroicons_solid:cloud-arrow-down" />
              </button>

              <button
                mat-icon-button
                (click)="onRemoveFile(index)"
                color="warn">
                <mat-icon svgIcon="heroicons_solid:trash" />
              </button>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</ng-template>

<ng-template #documentPreviewTemplate let-file="file" let-index="index">
  <div
    class="relative px-4 h-full flex flex-col items-center justify-center gap-2">
    @if (progressReport[file.url] && progressReport[file.url] !== 100) {
      <ng-container
        [ngTemplateOutlet]="loadingOverlay"
        [ngTemplateOutletContext]="{
          progress: progressReport[file.url]
        }" />
    }

    <div class="flex items-center">
      <mat-icon
        class="scale-150"
        color="primary"
        svgIcon="mat_outline:description" />

      <div class="flex flex-col text-sm px-2 items-start">
        <span class="font-bold leading-4">
          {{ file.value ? file.value : file.name }}
        </span>
        <span class="whitespace-nowrap">{{ file.size | filesize }}</span>
      </div>
    </div>
    <div class="flex gap-4">
      <button
        mat-icon-button
        (click)="onDownloadFile(file); $event.stopPropagation()">
        <mat-icon svgIcon="heroicons_solid:download" />
      </button>

      <button mat-icon-button (click)="onRemoveFile(index)" color="warn">
        <mat-icon svgIcon="heroicons_solid:trash" />
      </button>
    </div>
  </div>
</ng-template>

<ng-template #loadingOverlay let-progress="progress">
  <div
    class="absolute flex flex-col items-center justify-center w-full h-full bg-slate-100 bg-opacity-87 transition-all">
    <mat-progress-spinner
      [value]="progress"
      color="primary"
      mode="determinate" />
    <span class="text-xl"> {{ progress }}% </span>
  </div>
</ng-template>

<app-image-transformer (save)="onImageCropped($event)" />
