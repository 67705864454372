import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { ShowInformationSectionValue } from '@fieldos/models';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SectionBasedEntityListAGGridContext } from '../../section-columns.models';

@Component({
  selector: 'app-show-information-cell-renderer',
  templateUrl: './show-information-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowInformationCellRendererComponent
  implements ICellRendererAngularComp
{
  protected readonly value = signal('');

  agInit(
    params: ICellRendererParams<
      string,
      ShowInformationSectionValue,
      SectionBasedEntityListAGGridContext
    >
  ): void {
    this._setValue(params.value as ShowInformationSectionValue);
  }

  refresh(
    params: ICellRendererParams<
      string,
      ShowInformationSectionValue,
      SectionBasedEntityListAGGridContext
    >
  ): boolean {
    this._setValue(params.value as ShowInformationSectionValue);
    return true;
  }

  private _setValue(value: ShowInformationSectionValue): void {
    if (!value) {
      this.value.set('');
      return;
    }

    if (!value.calculatedByFormula) {
      this.value.set(value.value || '');
      return;
    }

    if (value.calculatedByFormula === 'applyusertimezone' && value.value) {
      const timeArr = value.value.split(':');

      const [hours, minutes] = timeArr;
      const date = new Date();
      date.setUTCHours(Number(hours));
      date.setUTCMinutes(Number(minutes));

      const formattedHours = `00${date.getHours()}`.slice(-2);
      const formattedMinutes = `00${date.getMinutes()}`.slice(-2);

      this.value.set(`${formattedHours}:${formattedMinutes}`);
      return;
    }

    this.value.set('');
  }
}
