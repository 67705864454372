import { InjectionToken } from '@angular/core';
import { ColDef } from 'ag-grid-community';

export const DATA_GRID_SELECTION_COLUMN = new InjectionToken(
  'DATA_GRID_SELECTION_COLUMN',
  {
    factory: (): ColDef => ({
      checkboxSelection: true,
      headerCheckboxSelection: true,
      pinned: 'left',
      width: 48,
      maxWidth: 48,
      minWidth: 48,
      lockPinned: true,
      lockPosition: true,
      lockVisible: true,
      filter: false,
    }),
  }
);
