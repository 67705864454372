import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import {
  DataGridActionsService,
  DataGridComponent,
  HeaderContentLayoutComponent,
  SelectFieldComponent,
} from '@fieldos/components';
import { DialogService } from '@fieldos/core';
import { HasPermissionDirective } from '@fieldos/directives';
import { RouterFacade } from '@fieldos/facades';
import { CategoryType, FieldBasedEntity } from '@fieldos/models';
import { CategoryTypesStore } from '@fieldos/store/category-type';
import { ClaimsStore } from '@fieldos/store/index';
import { filterEquals } from '@fieldos/utils';
import { TranslocoModule } from '@ngneat/transloco';
import { of, switchMap, tap } from 'rxjs';
import { FIELD_ENTITY_LIST_STORE } from './field-entity-list.store';

@Component({
  selector: 'app-field-entity-list',
  templateUrl: './field-entity-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DataGridComponent,
    TranslocoModule,
    SelectFieldComponent,
    HeaderContentLayoutComponent,
    RouterModule,
    MatButtonModule,
    HasPermissionDirective,
  ],
  standalone: true,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class FieldEntityListComponent<T extends FieldBasedEntity> {
  constructor() {
    inject(DataGridActionsService<T>)
      .buttonClick$.pipe(
        switchMap((event) => {
          if (event.buttonId === 'delete') {
            return this._dialogService.showConfirmDialog().pipe(
              filterEquals(true),
              tap(() => this.store.delete(event.data.id))
            );
          }

          if (event.buttonId === 'edit') {
            return of(this.edit.emit(event.data));
          }

          return of('');
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    effect(
      () => {
        const types = this.categoryTypes();
        const defaultType = types.find((e) => e.default);

        this.store.setSelectedType(defaultType?.id || 0);
      },
      { allowSignalWrites: true }
    );
  }

  public readonly edit = output<T>();

  public readonly categoryType = input.required<CategoryType>();
  public readonly newEntityLink = input.required<string>();
  public readonly newEntityLabel = input.required<string>();

  protected readonly typesStore = inject(CategoryTypesStore);
  protected readonly store = inject(FIELD_ENTITY_LIST_STORE);
  protected readonly claims = inject(ClaimsStore).claims();

  protected readonly categoryTypes = computed(() =>
    this.typesStore.selectCategoryTypes(this.categoryType())
  );

  protected readonly hasAddPermission = computed(
    () =>
      (this.claims.includes(`${this.categoryType()}.add`) &&
        this.claims.includes(`${this.categoryType()}.simple`)) ||
      (this.claims.includes(`${this.categoryType()}.advanced`) &&
        this.store.permissions()?.addPermissions)
  );

  private readonly _dialogService = inject(DialogService);
  private readonly _router = inject(RouterFacade);

  protected async onAddClick(): Promise<void> {
    this._router.navigateByUrl(
      `${this.newEntityLink()}/${this.store.selectedCategoryType()}`
    );
    // if (this.claims.includes(`${this.categoryType()}.editadvanced`)) {
    //   const component = await import(
    //     './field-entity-select-type-dialog/field-entity-select-type-dialog.component'
    //   ).then((m) => m.FieldEntitySelectTypeDialogComponent);
    //   const typeId = await lastValueFrom(
    //     this._dialogService.openDialog<
    //       FieldEntitySelectTypeDialogData,
    //       number | undefined
    //     >(component, {
    //       data: {
    //         types: this.categoryTypes(),
    //         selectedType: this.store.selectedCategoryType(),
    //       },
    //       autoFocus: false,
    //     })
    //   );
    //   if (typeId) {
    //     const url = `${this.newEntityLink()}/${typeId}`;
    //     this._router.navigateByUrl(url);
    //   }
    // }
  }
}
