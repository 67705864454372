<div class="flex flex-col relative signature-pad">
  @if (showPlaceholder) {
    <div
      class="absolute w-full top-1/3 text-center pointer-events-none font-medium text-md">
      {{ placeholder }}
    </div>
  }

  <signature-pad
    #signature
    class="flex-grow h-[120px]"
    [options]="signaturePadOptions"
    (drawEnd)="onDrawComplete()"
    (drawStart)="onDrawStart()">
  </signature-pad>
  <div class="mt-4 ml-auto">
    <button mat-flat-button (click)="onClear()" color="warn">Clear</button>
  </div>
</div>
