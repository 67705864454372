export type AssigneesSectionValue = AssigneesValue[];

export interface AssigneesValue {
  id: number;
  name: string;
  surname: string;
  email: string;
  avatar: null;
  type: 'employee' | 'contractor';
  contractor: string;
  team: string;
  hr: [];
  bold: false;
  text: string;
  categories: AssigneesWorkCategory[];
}

export interface AssigneesWorkCategory {
  id: number;
  name: string;
  hr_default: number;
  duration_default: number;
  currency: string;
  comment: '';
  cost: AssigneesWorkCategoryCost;
  duration: AssigneesWorkCategoryDuration;
  cost_category: AssigneesCategoryCost;
  date: string;
  contract: number;
  notes: string;
  customSingleDropdown: number;
  customMultipleDropdown: number[];
}

export interface AssigneesWorkCategoryDuration {
  minutes: number;
  hours: number;
  days: 0;
  weeks: 0;
  total: number;
  included: true;
  start: null;
  end: null;
}

export interface AssigneesWorkCategoryCost {
  calculated_value: number;
  hr: number;
  included: true;
}

export interface AssigneesCategoryCost {
  id: number;
  name: string;
  hr_default: number;
  duration_default: number;
  currency: string;
}
