import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  ENVIRONMENT_INITIALIZER,
  EnvironmentProviders,
  Provider,
  inject,
} from '@angular/core';
import { AuthStore } from '@fieldos/store/auth';
import { authInterceptor } from './auth.interceptor';
import { jwtRefreshInterceptor } from './jwt-refresh.interceptor';

export const provideAuth = (): Array<Provider | EnvironmentProviders> => [
  provideHttpClient(withInterceptors([jwtRefreshInterceptor, authInterceptor])),
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => inject(AuthStore),
    multi: true,
  },
];
