<div class="flex-grow h-full bg-card flex flex-col overflow-auto">
  <h5 class="font-semibold p-4 border-b">
    {{ 'fields_designer.enabled_fields' | transloco }}
  </h5>

  <div class="flex flex-col p-4 gap-4">
    @for (field of store.topLevelFixedFields(); track $index) {
      @if (field.subtype === 'twocolumncontainer') {
        <app-fields-designer-container-field [field]="field" [isFixed]="true" />
      } @else {
        <app-fields-designer-field [field]="field" />
      }
    }
  </div>

  <mat-divider />

  <div
    class="flex-grow basis-0 flex flex-col p-4 gap-4"
    (bpDrop)="store.moveOrAddField($event)"
    bpDropzone>
    @for (field of store.topLevelFields(); track $index) {
      <div class="relative">
        @if (!store.validationStatuses()[field.id]) {
          <mat-icon
            matTooltip="This field has validation errors"
            class="text-orange-500 absolute top-2 right-2"
            svgIcon="mat_solid:warning" />
        }

        @if (field.subtype === 'twocolumncontainer') {
          <app-fields-designer-container-field [field]="field" />
        } @else {
          <app-fields-designer-field
            [bpDrag]="field"
            [field]="field"
            (bpDragEnd)="store.setDraggingFieldId()"
            (bpDragStart)="store.setDraggingFieldId(field.id)" />
        }
      </div>
    }

    <div
      class="h-18 w-full border-dashed border border-slate-500"
      bpDropPlaceholder></div>
  </div>
</div>
