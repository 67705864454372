import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  ChatMessage,
  FileModel,
  SectionBasedEntityType,
} from '@fieldos/models';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatDataService {
  private readonly _http = inject(HttpClient);

  fetch(
    entityId: number,
    entityType: SectionBasedEntityType
  ): Promise<ChatMessage[]> {
    return lastValueFrom(
      this._http.get<ChatMessage[]>(`/chat-messages/${entityType}/${entityId}`)
    );
  }

  addMessage(
    entityId: number,
    message: string,
    files: FileModel[],
    entityType: SectionBasedEntityType
  ): Promise<ChatMessage> {
    return lastValueFrom(
      this._http.post<ChatMessage>(`/chat-messages/${entityType}/${entityId}`, {
        message,
        files,
      })
    );
  }
}
