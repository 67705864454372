import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { fromServerDate, toServerDate } from '@fieldos/utils';
import { TranslocoModule } from '@ngneat/transloco';
import { IFilterAngularComp } from 'ag-grid-angular';
import {
  AgPromise,
  IDoesFilterPassParams,
  IFilterParams,
} from 'ag-grid-community';
import { DatetimePickerComponent } from 'app/@components/fields';
import { tap } from 'rxjs';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatetimePickerComponent, ReactiveFormsModule, TranslocoModule],
})
export class DateFilterComponent implements IFilterAngularComp {
  constructor() {
    this.form.valueChanges
      .pipe(
        tap(() => this._params()?.filterChangedCallback()),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  protected readonly form = new FormGroup({
    min: new FormControl<Date | null>(null),
    max: new FormControl<Date | null>(null),
  });

  protected readonly isParentFilterActive = signal<boolean>(false);

  private readonly _params = signal<IFilterParams<any, any> | null>(null);

  agInit(params: IFilterParams<any, any>): void {
    this._params.set(params);

    this._params()?.filterChangedCallback();
  }

  isFilterActive(): boolean {
    return true;
  }

  getModel() {
    const value = this.form.getRawValue();

    if (value.min && value.max) {
      return `${toServerDate(value.min)} - ${toServerDate(value.max)}`;
    }

    if (value.min) {
      return `>= ${toServerDate(value.min)}`;
    }

    if (value.max) {
      return `<= ${toServerDate(value.max)}`;
    }

    return '';
  }

  setModel(model: any): void | AgPromise<void> {
    console.log(model);
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const field = this._params()?.colDef.field;
    const filterValue = this.form.getRawValue();

    if (field && filterValue) {
      const value = params.data[field] as string;

      filterValue.min?.setHours(0, 0, 0, 0);
      filterValue.max?.setHours(23, 59, 59, 999);

      const dateValue = fromServerDate(value) as Date;

      if (filterValue.min && filterValue.max) {
        return dateValue >= filterValue.min && dateValue <= filterValue.max;
      }

      if (filterValue.min) {
        return dateValue >= filterValue.min;
      }

      if (filterValue.max) {
        return dateValue <= filterValue.max;
      }

      return true;
    }

    return true;
  }

  clearValue(): void {
    this.form.reset();
  }
}
