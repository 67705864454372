import { InjectionToken } from '@angular/core';
import { Priority } from '@fieldos/models';
import { ValueLabelPair } from '@fieldos/models/value-label-pair';

export const PRIORITY_OPTIONS = new InjectionToken('PRIORITY_OPTIONS', {
  factory: (): ValueLabelPair<Priority>[] => [
    {
      label: 'work_orders.priority.1',
      value: 1,
    },
    {
      label: 'work_orders.priority.2',
      value: 2,
    },
    {
      label: 'work_orders.priority.3',
      value: 3,
    },
    {
      label: 'work_orders.priority.4',
      value: 4,
    },
    {
      label: 'work_orders.priority.5',
      value: 5,
    },
  ],
});
