export enum StandardDataSourceId {
  Types = 1,
  Customers,
  CustomerContracts,
  Locations,
  Sublocations,
  Assignees,
  Teams,
  ContractorContracts,
  WorkCategory,
  Assets,
  Subassets,
  Parts,
  Checklists,
  WorkOrders,
  SubWorkOrders,
  ProblemTypes,
  Status,
  Employees,
  Contractors,
  SingleSelectCustomDataSource,
  MultipleSelectCustomDataSource,
  WorkOrderRights,
  ShowInformation,
  LocationCriticality,
  LocationSla,

  LocationStatus = 30,
  AssetStatus = 31,
  Users = 32,
  DPUStatuses = 33,
  ReasonCodes = 34,
  Packaging = 35,
  UserStatus = 36,
  CustomerStatus = 37,
  ContractorStatus = 38,
  ContractsStatus = 39,
  OrderTypes = 40,
  UserRoles = 41,
  WorkOrderSetStatuses = 42,
}

import { DomainEntityBase } from '@fieldos/models';

export interface DataSourceCacheEntry {
  data?: Record<number, DomainEntityBase>;
  isLoading: boolean;
  loaded: boolean;
  isStale: boolean;
}

export type DataSourceCache<T extends StandardDataSourceId | number = number> =
  Partial<Record<T, DataSourceCacheEntry>>;

export interface DataSourceWithOptions extends DomainEntityBase {
  data: DomainEntityBase[];
}
