import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  forwardRef,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ClickStopPropagationDirective } from '@fieldos/directives';
import { DomainEntityBase } from '@fieldos/models';
import { AutoUnsubscribe } from '@fieldos/utils';
import { FieldError, FieldWrapperComponent } from '../field-wrapper';

@Component({
  selector: 'app-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    ClickStopPropagationDirective,
    FieldWrapperComponent,
  ],
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
@AutoUnsubscribe()
export class AutocompleteSelectComponent<
  T extends DomainEntityBase<string | number> = DomainEntityBase<
    string | number
  >,
> implements ControlValueAccessor
{
  constructor() {
    effect(
      () => {
        const value = this.value();

        if (value !== this._idValue() && value !== undefined) {
          this.writeValue(value);
        }
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        this.options();
        this.setFormCtrlValue();
      },
      { allowSignalWrites: true }
    );
  }

  public readonly options = input.required<T[]>();
  public readonly loading = input<boolean>();
  public readonly label = input<string>();
  public readonly placeholder = input<string>();
  public readonly prefixIcon = input<string>();
  public readonly searchProperties = input<(keyof T)[]>([]);

  public readonly required = input<boolean, string>(false, {
    transform: (value: string) => Boolean(value),
  });

  public readonly errors = input<FieldError[]>();

  public readonly value = input<T['id']>();

  public readonly clear = output<void>();
  public readonly selectionChange = output<T['id']>();

  protected readonly formCtrl = new FormControl<T | null>(null);

  protected readonly filteredOptions = computed(() => {
    const searchText = this._searchText();

    if (typeof searchText === 'string') {
      return this._filterOptions(this.options(), searchText);
    } else {
      return this.options();
    }
  });

  private readonly _trigger = viewChild<MatAutocompleteTrigger>('trigger');

  private readonly _autocomplete = viewChild<MatAutocomplete>('auto');

  private _onChange!: (value: T | T['id'] | undefined) => void;
  private _onTouched!: () => void;

  private readonly _idValue = signal<number | string>(0);
  private readonly _searchText = toSignal(this.formCtrl.valueChanges);

  private readonly _detector = inject(ChangeDetectorRef);

  writeValue(value: T['id']): void {
    this._idValue.set(value);
    this.setFormCtrlValue();
  }

  registerOnChange(fn: (value: T | T[keyof T] | undefined) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled && this.formCtrl.enabled) {
      this.formCtrl.disable({ emitEvent: false });
    }

    if (!isDisabled && this.formCtrl.disabled) {
      this.formCtrl.enable({ emitEvent: false });
    }
  }

  protected clearValue(): void {
    const option = this._autocomplete()?.options.find(
      (e) => e.value.id === this._idValue()
    );

    if (option) {
      option.deselect();
    }

    this.formCtrl.setValue(null);
    if (this._trigger()?.closePanel) {
      this._trigger()?.closePanel();
    }

    this.clear.emit();
    this._emitValue(null);

    this._detector.detectChanges();
  }

  protected onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this._emitValue(event.option.value);
  }

  protected readonly displayWith = (entity?: T): string => entity?.name || '';

  private _emitValue = (value: T | null): void => {
    this._idValue.set(value?.id || 0);
    if (this._onChange) {
      this._onChange(value?.id || 0);
    }

    this.selectionChange.emit(value?.id || 0);

    this.formCtrl.setValue(value);
  };

  private _filterOptions(options: T[], filterValue: string | undefined): T[] {
    if (filterValue) {
      if (this.searchProperties().length > 0) {
        return options.filter((option: T) =>
          this.searchProperties().some((prop) =>
            (option[prop] as string)
              .toString()
              .toLowerCase()
              .includes(filterValue.toLowerCase())
          )
        );
      } else {
        return options.filter((option: T) =>
          option.name.toLowerCase().includes(filterValue.toLowerCase())
        );
      }
    }

    return options;
  }

  private setFormCtrlValue(): void {
    const option = this.options().find((e) => e.id === this._idValue());
    this.formCtrl.setValue(option || null);
  }
}
