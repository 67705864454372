import { Injectable } from '@angular/core';
import { MapDirectionsService } from '@angular/google-maps';
import { filter, from, map, mergeMap, Observable, of, throwError } from 'rxjs';

type GoogleMapLocationType =
  | string
  | google.maps.LatLng
  | google.maps.Place
  | google.maps.LatLngLiteral;

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  constructor(private _directionsService: MapDirectionsService) {}

  getRoute(
    origin: GoogleMapLocationType,
    destination: GoogleMapLocationType,
    waypoints: GoogleMapLocationType[]
  ): Observable<google.maps.DirectionsResult> {
    if (!destination) {
      return throwError(() => 'WORK_ORDER.LOCATION_MAP.NO_STOPS');
    }

    return this._directionsService
      .route({
        origin,
        destination,
        waypoints: waypoints.map((e) => ({
          location: e as any,
        })),
        travelMode: google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true,
      })
      .pipe(
        filter((response) => !!response.result),
        map((respone) => respone.result)
      ) as Observable<google.maps.DirectionsResult>;
  }

  getDirectionsMatrix(
    origins: GoogleMapLocationType[],
    destinations: GoogleMapLocationType[]
  ): Observable<google.maps.DistanceMatrixResponse> {
    const distanceService = new google.maps.DistanceMatrixService();

    return from(
      distanceService.getDistanceMatrix({
        origins,
        destinations,
        travelMode: google.maps.TravelMode.DRIVING,
      })
    );
  }

  getOptimalRoute(
    routeOrigin: GoogleMapLocationType,
    stops: GoogleMapLocationType[]
  ): Observable<{
    route: google.maps.DirectionsResult;
    distanceMatrix: google.maps.DistanceMatrixResponse;
    routeIndexes: number[];
  }> {
    return this.getDirectionsMatrix(
      [routeOrigin, ...stops],
      [routeOrigin, ...stops]
    ).pipe(
      mergeMap((distanceMatrix) =>
        of(distanceMatrix).pipe(
          map((response) =>
            this._calculateOptimalDirectionsRequest(
              [routeOrigin, ...stops],
              response
            )
          ),
          map((routeIndexes) => ({
            routes: routeIndexes.map((index) =>
              index === 0 ? routeOrigin : stops[index - 1]
            ),
            routeIndexes,
          })),
          map(({ routeIndexes, routes }) => ({
            origin: routes[0],
            destination: routes[routes.length - 1],
            waypoints: routes.slice(1, routes.length - 1),
            routeIndexes,
          })),
          mergeMap(({ origin, destination, waypoints, routeIndexes }) =>
            this.getRoute(origin, destination, waypoints).pipe(
              map((route) => ({
                route,
                distanceMatrix,
                routeIndexes,
              }))
            )
          )
        )
      )
    );
  }

  private _calculateOptimalDirectionsRequest(
    stops: GoogleMapLocationType[],
    response: google.maps.DistanceMatrixResponse
  ): number[] {
    const result: number[] = [0];

    stops.forEach(() => {
      const origin = result[result.length - 1];
      const index = this._getShortestRouteIndex(origin, response, result);
      if (index !== -1) {
        result.push(index);
      }
    });

    return result;
  }

  private _getShortestRouteIndex(
    origin: number,
    response: google.maps.DistanceMatrixResponse,
    usedIndexes: number[]
  ): number {
    let shortestRoute: google.maps.DistanceMatrixResponseElement | null = null;
    response.rows[origin].elements.forEach((value, index) => {
      if (usedIndexes.includes(index)) {
        return;
      }

      if (!shortestRoute || shortestRoute.duration.value === 0) {
        shortestRoute = value;
        return;
      }

      if (index === origin || usedIndexes.includes(index)) {
        return;
      }

      if (
        value.duration.value < shortestRoute.duration.value &&
        shortestRoute.duration.value !== 0 &&
        value.duration.value !== 0
      ) {
        shortestRoute = value;
      }
    });

    return response.rows[origin].elements.findIndex((e) => e === shortestRoute);
  }
}
