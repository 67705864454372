import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  signal,
} from '@angular/core';
import { SectionBasedEntityType } from '@fieldos/models';
import { StatusGraphStore } from '@fieldos/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SectionBasedEntityListAGGridContext } from '../../../section-columns.models';

@Component({
  selector: 'app-status-cell-renderer',
  templateUrl: './status-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusCellRendererComponent implements ICellRendererAngularComp {
  constructor() {
    effect(
      () => {
        const statuses = this._statusGraphCache.selectCurrentStatusGraphSignal(
          this._entityType()
        )();

        if (!statuses) {
          return;
        }

        const status = statuses.find((e) => e.id === this._value());

        if (status) {
          this.value.set(status.data.label);
        }
      },
      { allowSignalWrites: true }
    );
  }

  protected readonly value = signal('');

  private readonly _statusGraphCache = inject(StatusGraphStore);
  private readonly _entityType = signal<SectionBasedEntityType>('workorders');
  private readonly _value = signal<string>('');

  agInit(
    params: ICellRendererParams<
      string,
      string,
      SectionBasedEntityListAGGridContext
    >
  ): void {
    this._setValue(params);
  }

  refresh(
    params: ICellRendererParams<
      string,
      string,
      SectionBasedEntityListAGGridContext
    >
  ): boolean {
    this._setValue(params);
    return true;
  }

  private _setValue(
    params: ICellRendererParams<
      string,
      string,
      SectionBasedEntityListAGGridContext
    >
  ): void {
    this._entityType.set(params.context.entityType);
    this._value.set(params.value || '');
  }
}
