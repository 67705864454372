import {
  ChangeDetectorRef,
  Directive,
  TemplateRef,
  ViewContainerRef,
  effect,
  inject,
  input,
} from '@angular/core';
import { ClaimsStore } from '../@store';

@Directive({
  selector: '[appHasPermissionStartingWith]',
  standalone: true,
})
export class HasPermissionStartingWithDirective {
  constructor() {
    effect(() => {
      const claims = this._claimsStore.claims();
      const startsWith = this.appHasPermissionStartingWith();

      this._viewContainerRef.clear();

      const shouldShow =
        (startsWith && claims.find((e) => e.startsWith(startsWith))) ||
        !startsWith;

      if (shouldShow) {
        this._viewContainerRef.createEmbeddedView(this._templateRef);
        this._detector.detectChanges();
      }
    });
  }

  public readonly appHasPermissionStartingWith = input.required<
    string | undefined
  >();

  private readonly _claimsStore = inject(ClaimsStore);

  private readonly _templateRef = inject(TemplateRef<void>);
  private readonly _viewContainerRef = inject(ViewContainerRef);
  private readonly _detector = inject(ChangeDetectorRef);
}
