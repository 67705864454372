import { Pipe, PipeTransform } from '@angular/core';
import { FlatGraphNode } from './graph-designer.models';

@Pipe({
  name: 'connectedGraphNodes',
  standalone: true,
})
export class ConnectedGraphNodesPipe<T> implements PipeTransform {
  transform(
    node: FlatGraphNode<T>,
    nodes: FlatGraphNode<T>[]
  ): FlatGraphNode<T>[] {
    return node.connections
      .map((connection) => nodes.find((e) => e.id === connection))
      .filter((e) => !!e) as FlatGraphNode<T>[];
  }
}
