<div class="flex flex-col gap-4" [formGroup]="form">
  @if (commonProperties?.showAsset) {
    <app-autocomplete-select
      formControlName="assetId"
      [options]="assets() || []"
      (clear)="onAssetCleared()"
      label="{{ translatableProperties().assetLabel }}" />
  }

  @if (commonProperties?.showSubasset) {
    <app-autocomplete-select
      formControlName="subAssetId"
      [options]="subAssets() || []"
      label="{{ translatableProperties().subassetLabel }}" />
  }
</div>
