import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CoordinateType } from './coordinate-field.models';

export const CoordinateValidator: (type: CoordinateType) => ValidatorFn =
  (type: CoordinateType) =>
  (control: AbstractControl): Record<string, boolean> | null => {
    const stringValue = `${control.value}`;

    if (type === 'longitude') {
      if (control.value > 180 || control.value < -180) {
        return {
          longitude: true,
        };
      }
    } else {
      if (control.value > 90 || control.value < -90) {
        return {
          latitude: true,
        };
      }
    }

    if (stringValue && !MAX_14_DECIMAL_PLACES_REGEX.test(stringValue)) {
      return { coordinateFormat: true };
    }

    return null;
  };

// Max 14 decimal places regex
const MAX_14_DECIMAL_PLACES_REGEX = /^\d{0,3}(\.\d{0,14})?$/;
