@if (store.profile(); as user) {
  <button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
      @if (showAvatar && user.avatar) {
        <img class="w-7 h-7 rounded-full" [src]="user.avatar" />
      }

      @if (!showAvatar || !user.avatar) {
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'" />
      }
    </span>
  </button>
}

<mat-menu #userActions="matMenu" [xPosition]="'before'">
  @if (store.profile(); as user) {
    <button mat-menu-item>
      <span class="flex flex-col leading-none">
        <span>{{ 'common.signed_in_as' | transloco }}</span>
        <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
      </span>
    </button>
  }

  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item>
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>{{ 'common.profile' | transloco }}</span>
  </button>
  <button mat-menu-item>
    <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
    <span>{{ 'common.settings' | transloco }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="userStatus">
    <mat-icon [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'">
    </mat-icon>
    <span>{{ 'common.status' | transloco }}</span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon
      [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
    <span>{{ 'common.signed_out' | transloco }}</span>
  </button>
</mat-menu>

<mat-menu #userStatus="matMenu" class="user-status-menu">
  <button mat-menu-item (click)="updateUserStatus('online')">
    <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
    <span>Online</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('away')">
    <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
    <span>Away</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('busy')">
    <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
    <span>Busy</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('not-visible')">
    <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
    <span>Invisible</span>
  </button>
</mat-menu>
