<div class="flex flex-col items-center">
  <button mat-flat-button (click)="onAddDPUs()" color="primary">
    {{ 'assign_reason_code.change_dpus' | transloco }}
  </button>

  @if (selection() && selection().length) {
    <span class="font-base font-bold mt-2">
      {{ 'assign_reason_code.dpus_selected' | transloco }}:
      {{ selectedDPUsNames() }}
    </span>
  }
</div>
