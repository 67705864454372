import { JsonPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  computed,
  effect,
  forwardRef,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomainEntityBase } from '@fieldos/models';
import { minLengthArrayValidator } from '@fieldos/utils';
import { filter, tap } from 'rxjs';
import { FieldError, FieldWrapperComponent } from '../field-wrapper';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatChipsModule,
    MatInputModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FieldWrapperComponent,
    JsonPipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleSelectComponent),
      multi: true,
    },
  ],
})
export class MultipleSelectComponent<T extends string | number>
  implements ControlValueAccessor
{
  constructor() {
    this.formCtrl.valueChanges
      .pipe(
        filter((searchText) => typeof searchText === 'string'),
        tap((searchText) => this.searchText.set(searchText)),
        takeUntilDestroyed()
      )
      .subscribe();

    effect(() => {
      if (this.isRequired()) {
        this.formCtrl.setValidators([minLengthArrayValidator(1)]);
      } else {
        this.formCtrl.clearValidators();
      }

      setTimeout(() => {
        this.formCtrl.updateValueAndValidity();
        this.formCtrl.markAsTouched();
      });
    });
  }

  @Input() public displayProperty: string = 'name';

  @Input({ required: true }) public set options(
    options: DomainEntityBase<T>[]
  ) {
    this.dropdownOptions.set(options || []);
  }

  @Input() public loading = false;
  @Input() public label: string = '';
  @Input() public placeholder?: string;
  @Input() public prefixIcon?: string;
  @Input() public errors: FieldError[] = [];

  @Output() public readonly valueSelected = new EventEmitter<T>();
  @Output() public readonly valueRemoved = new EventEmitter<T>();
  @Output() public readonly valueChanged = new EventEmitter<T[]>();

  @ViewChild(MatAutocompleteTrigger)
  private readonly _trigger!: MatAutocompleteTrigger;

  public readonly isRequired = input<boolean>();

  protected readonly dropdownOptions = signal<DomainEntityBase<T>[]>([]);
  protected readonly searchText = signal<string>('');

  protected readonly filteredOptions = computed(() => {
    const searchText = this.searchText().toLowerCase();
    const selectedOptions = this._selectedOptions();

    return this.dropdownOptions().filter((option) => {
      if (this.displayProperty) {
        return (
          (option as any)[this.displayProperty]
            .toLowerCase()
            .includes(searchText) && !selectedOptions.includes(option.id)
        );
      } else {
        return (
          option.name.toLowerCase().includes(searchText) &&
          !selectedOptions.includes(option.id)
        );
      }
    });
  });

  protected readonly formCtrl = new FormControl<string>('', {
    nonNullable: true,
  });

  protected readonly selectedOptions = computed<DomainEntityBase[]>(() => {
    const options = this.dropdownOptions();
    const selectedOptions = this._selectedOptions();

    return selectedOptions
      .map(
        (optionId) => options.find((e) => e.id === optionId) as DomainEntityBase
      )
      .filter((e) => !!e);
  });

  private readonly _selectedOptions = signal<T[]>([]);

  private _onChange!: (value: T[]) => void;
  private _onTouch!: () => void;

  writeValue(value: T[]): void {
    this._selectedOptions.set(value || []);
  }

  registerOnChange(fn: (value: T[]) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled && this.formCtrl.enabled) {
      this.formCtrl.disable({ emitEvent: false });
    }

    if (!isDisabled && this.formCtrl.disabled) {
      this.formCtrl.enable({ emitEvent: false });
    }
  }

  protected clearValue(): void {
    this.formCtrl.setValue('');
    this._selectedOptions.set([]);
    this._onChange && this._onChange([]);
    this.valueChanged.emit([]);
  }

  protected onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const newValue = [...this._selectedOptions(), event.option.value];
    this._selectedOptions.set(newValue);
    this._onChange && this._onChange(newValue);
    this.valueChanged.emit(newValue);
    this.formCtrl.reset();
    this.valueSelected.emit(event.option.value);
  }

  protected onRemoveOption(index: number): void {
    const selectedOptions = this._selectedOptions();
    const removedValue = selectedOptions.splice(index, 1);
    this._selectedOptions.set([...selectedOptions]);
    this._onChange && this._onChange([...selectedOptions]);
    this.valueChanged.emit([...selectedOptions]);
    this.valueRemoved.emit(removedValue[0]);
  }

  protected onFieldClick(): void {
    if (this._trigger) {
      this._trigger.openPanel();
    }
  }
}
