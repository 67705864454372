<h2 mat-dialog-title class="text-center font-bold">
  {{ 'taxonomy.add_language_for_fields' | transloco }}
</h2>
<mat-dialog-content class="flex flex-col items-center">
  <form class="w-full" [formGroup]="data.form">
    <mat-form-field class="w-full" floatLabel="always">
      <mat-label></mat-label>
      <input
        matInput
        [matAutocomplete]="auto"
        panelOpen="false"
        placeholder="{{ 'taxonomy.select_language' | transloco }}"
        type="text" />
      <mat-autocomplete
        #auto="matAutocomplete"
        class="w-full"
        (optionSelected)="onLanguageSelected($event.option.value)">
        @for (lang of availableLanguages; track $index) {
          <mat-option [value]="lang.id">
            {{ lang.label }}
          </mat-option>
        }
        @if (availableLanguages.length === 0) {
          <mat-option disabled>
            {{ 'taxonomy.no_languages_available' | transloco }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <div formGroupName="translatableProperties" class="flex flex-col space-y-4">
      @for (
        lang of data.form.controls.translatableProperties.controls
          | keyvalue: keepOrder;
        track $index
      ) {
        <div [formGroupName]="lang.key" class="flex flex-col gap-2 w-full">
          <div class="flex flex-row gap-2 w-full">
            <h3 class="self-center">{{ lang.key }}</h3>
            @for (
              field of lang.value.controls | keyvalue: keepOrder;
              track $index
            ) {
              <mat-form-field class="w-full">
                <mat-label>{{ field.key | titlecase }}</mat-label>
                <input
                  matInput
                  [formControlName]="field.key"
                  autofocus
                  type="text" />
              </mat-form-field>
            }
          </div>
        </div>
      }
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <!-- Cancel Button -->
  <button mat-flat-button matDialogClose color="secondary" type="submit">
    {{ 'common.cancel' | transloco }}
  </button>
  <!-- Confirm -->
  <button
    mat-flat-button
    [disabled]="data.form.controls.translatableProperties.invalid"
    (click)="onConfirm()"
    color="primary"
    type="submit">
    {{ 'taxonomy.set_translations' | transloco }}
  </button>
</mat-dialog-actions>
