import { FileModel } from '@fieldos/models';

export enum ExceptionTargetType {
  Route = 'route',
  WorkOrder = 'wo',
  DPU = 'dpu',
}

export interface AssignExceptionDialogData {
  targetId: number;
  targetType: ExceptionTargetType;
  parentId?: number;
  reasonCodeName?: string | null;
  scopeId?: number;
}

export interface AssignExceptionBody
  extends Omit<AssignExceptionDialogData, 'scopeId'> {
  taxonomyId: number;
  comments: string;
  media: FileModel[];
  actionValue: AssignExceptionValue;
}

export type AssignExceptionValue =
  | AssignExceptionChangeSealAction
  | AssignExceptionChangeLocationAction
  | AssignExceptionChangeDPUsAction
  | null;

export interface AssignExceptionChangeSealAction {
  seals: string[];
}

export interface AssignExceptionChangeLocationAction {
  locationId: number;
}

export interface AssignExceptionChangeDPUsAction {
  dpuIds: number[];
}
