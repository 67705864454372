import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DatepickerSection } from '@fieldos/models';
import { FromServerDatePipe } from '@fieldos/pipes';
import { toServerDate } from '@fieldos/utils';
import { DatetimePickerComponent } from '../../../../../@components/fields/datetime-picker';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-datepicker-section',
  templateUrl: './sections-form-datepicker-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DatetimePickerComponent, ReactiveFormsModule, FromServerDatePipe],
})
export class SectionsFormDatepickerSectionComponent extends SectionsFormSectionBaseComponent<DatepickerSection> {
  protected override readonly form = new FormControl('', { nonNullable: true });

  onDateChange(date: Date | null): void {
    if (date) {
      this.form.setValue(toServerDate(date));
    } else {
      this.form.setValue('');
    }
  }

  protected override setValidation(): void {
    this.form.setValidators(Validators.required);
    this.form.updateValueAndValidity();
  }
}
