<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="languages">
  <ng-container
    *ngTemplateOutlet="flagImage; context: { $implicit: activeLang }" />
</button>

<!-- Language menu -->
<mat-menu #languages="matMenu" [xPosition]="'before'">
  @for (lang of $any(availableLangs); track lang.id || $index) {
    <button mat-menu-item (click)="setActiveLang(lang.id)">
      <span class="flex items-center">
        <ng-container
          *ngTemplateOutlet="flagImage; context: { $implicit: lang.id }" />
        <span class="ml-3">{{ lang.label }}</span>
      </span>
    </button>
  }
</mat-menu>

<!-- Flag image template -->
<ng-template #flagImage let-lang>
  <span class="relative w-6 shadow rounded-sm overflow-hidden">
    <span
      class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"></span>
    <img
      class="w-full"
      [alt]="'Flag image for ' + lang"
      [src]="'assets/images/flags/' + flagCodes[lang].toUpperCase() + '.svg'" />
  </span>
</ng-template>
