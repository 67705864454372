import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { SetFilterBase } from '../../set-filter-base.component';
import { OptionsFilterParams } from './options-filter.models';

@Component({
  selector: 'app-options-filter',
  templateUrl: './options-filter.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    AsyncPipe,
    TranslocoModule,
    ScrollingModule,
  ],
  styles: [
    `
      :host {
        max-height: 500px;
        width: 320px;
        @apply p-4 block;
      }
    `,
  ],
})
export class OptionsFilterComponent extends SetFilterBase<OptionsFilterParams> {
  override agInit(params: OptionsFilterParams): void {
    this.params.set(params);
    this.options.set(params.options);
  }
}
