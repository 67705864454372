import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BpDragDropModule } from '@browsepedia/drag-and-drop';
import { TranslocoModule } from '@ngneat/transloco';
import { FIELDS_DESIGNER_STORE } from '../fields-designer.store';

@Component({
  selector: 'app-fields-designer-available-fields',
  templateUrl: './fields-designer-available-fields.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoModule, BpDragDropModule, MatIconModule, MatTooltipModule],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class FieldsDesignerAvailableFieldsComponent {
  protected readonly store = inject(FIELDS_DESIGNER_STORE);
}
