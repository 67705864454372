import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { Priority } from '@fieldos/models';
import { ValueLabelPair } from '@fieldos/models/value-label-pair';
import { WorkOrderFormPriorityButtonColorPipe } from '@fieldos/pipes';
import { PRIORITY_OPTIONS } from '@fieldos/providers';
import { TranslocoModule } from '@ngneat/transloco';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-priority-cell-renderer',
  templateUrl: './priority-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    WorkOrderFormPriorityButtonColorPipe,
    MatButtonModule,
    TranslocoModule,
    MatChipsModule,
  ],
  styles: [
    `
      :host {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    `,
  ],
})
export class PriorityCellRendererComponent implements ICellRendererAngularComp {
  protected cellValue?: ValueLabelPair<Priority>;

  private _priorities = inject(PRIORITY_OPTIONS);

  agInit(params: ICellRendererParams<unknown, Priority, void>): void {
    this.cellValue = this._priorities.find((e) => e.value === params.value);
  }

  refresh(params: ICellRendererParams<unknown, Priority, void>): boolean {
    this.cellValue = this._priorities.find((e) => e.value === params.value);
    return true;
  }
}
