import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { DataSourceStore } from '@fieldos/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { OptionsCellRendererParams } from './options-cell-renderer.models';

@Component({
  selector: 'app-options-cell-renderer',
  templateUrl: './options-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsCellRendererComponent implements ICellRendererAngularComp {
  protected value = signal('');

  private readonly _dataSourceStore = inject(DataSourceStore);

  agInit(params: OptionsCellRendererParams): void {
    this._setValue(params);
  }

  refresh(params: OptionsCellRendererParams): boolean {
    this._setValue(params);
    return true;
  }

  private _setValue(params: OptionsCellRendererParams): void {
    const options = params.options || [];
    const option = options.find((e) => e.id === params.value);

    this.value.set(option?.name || '');
  }
}
