<div class="flex gap-2 items-center">
  <ng-content />

  @if (isActive()) {
    <div class="flex gap-1 items-center">
      <div class="h-2 w-2 bg-primary rounded-full"></div>

      <mat-icon
        class="cursor-pointer scale-75"
        (click)="clear.emit()"
        svgIcon="mat_solid:clear" />
    </div>
  }
</div>
