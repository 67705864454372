import { EnvironmentProviders, Provider } from '@angular/core';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';

export const provideAppRouterStore = (): Array<
  Provider | EnvironmentProviders
> => [
  provideStore({ router: routerReducer }),
  provideRouterStore({
    stateKey: 'router',
  }),
];
