import { computed, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { NotificationsDataService } from '@fieldos/data-services';
import { callAndTakeOne } from '@fieldos/utils';
import {
  signalStore,
  withComputed,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import { distinctUntilChanged, filter, map, tap } from 'rxjs';
import { AuthStore } from '..';
import { withNotificationsStore } from './notification-entity.store';

export const NotificationsStore = signalStore(
  { providedIn: 'root' },
  withNotificationsStore('active'),
  withComputed((store) => ({
    mostRecent: computed(() => store.notifications().slice(0, 5)),
    mostRecentUnread: computed(
      () =>
        store
          .notifications()
          .slice(0, 5)
          .filter((n) => !n.read).length
    ),
  })),
  withMethods((store, service = inject(NotificationsDataService)) => ({
    markAllAsRead: () =>
      service.markAllAsRead().pipe(
        tap(() => {
          store.updateAllAsRead()
        }),
        map(() => undefined),
        callAndTakeOne()
      ),
    archiveAll: () => service.archiveAll().pipe(callAndTakeOne()),
  })),
  withHooks({
    onInit(store, authStore = inject(AuthStore)) {
      toObservable(authStore.isAuthenticated)
        .pipe(
          distinctUntilChanged(),
          filter((isAuthenticated) => isAuthenticated === true),
          tap(() => store.fetchNotifications()),
          takeUntilDestroyed()
        )
        .subscribe();
    },
  })
);
