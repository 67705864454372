import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { DomainEntityBase } from '@fieldos/models';
import { DataSourceStore } from '@fieldos/store';
import { tap } from 'rxjs';
import { AutocompleteSelectComponent } from '../autocomplete-select';

@Component({
  selector: 'app-data-source-select',
  templateUrl: './data-source-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AutocompleteSelectComponent, ReactiveFormsModule, AsyncPipe],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataSourceSelectComponent),
      multi: true,
    },
  ],
})
export class DataSourceSelectComponent implements ControlValueAccessor {
  constructor() {
    this.formCtrl.valueChanges
      .pipe(
        tap((entityId: number) => this._onChange(entityId)),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  @Input() public label = '';
  @Input({ required: true }) public set dataSourceId(dataSourceId: number) {
    const options = this._dataSourceStore.selectDataSource(dataSourceId)();
    this.options.set(options);
  }

  protected readonly formCtrl = new FormControl(0, { nonNullable: true });
  protected readonly options = signal<DomainEntityBase[]>([]);

  private _onChange!: (value: number) => void;
  private _onTouch!: () => void;

  private readonly _dataSourceStore = inject(DataSourceStore);

  writeValue(entityId: number): void {
    this.formCtrl.setValue(entityId, { emitEvent: false });
  }

  registerOnChange(fn: (value: number) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }
}
