export enum SectionSubType {
  Container = 'container',
  Identifier = 'woid',
  Title = 'title',
  Priority = 'priority',
  Description = 'description',
  SolvingDescription = 'solvingdescription',
  Location = 'location',
  Type = 'type',
  Customer = 'customer',
  CustomerContract = 'customercontract',
  StartBy = 'startby',
  DueBy = 'dueby',
  Assignees = 'assignees',
  Teams = 'teams',
  Media = 'media',
  Documents = 'documents',
  Asset = 'asset',
  Parts = 'parts',
  Signature = 'signatures',
  Rating = 'rating',
  Status = 'status',
  EstimatedDuration = 'estimatedduration',
  SingleCustomDataSource = 'singleselectcustomdatasource',
  SingleStandardDataSource = 'singleselectstandarddatasource',
  Supervisors = 'supervisors',
  ShowInformation = 'showinformation',
  ResponsiblePerson = 'responsibleperson',
  StartingLocation = 'startinglocation',
  LoadByDate = 'loadby',
  Set = 'set',
  ConfirmLocation = 'confirmlocation',
  DPU = 'dpu',
  ShowSealWithConfirmation = 'showsealwithconfirmation',
  InputSealWithConfirmation = 'inputsealwithconfirmation',
  Seal = 'seal',
  PackagingLoaded = 'packagingloaded',
  PackagingUnloaded = 'packagingunloaded',
  PackagingLeft = 'packagingleft',
  ConfirmEmtpy = 'confirmempty',
}

export type SectionType = 'standard' | 'custom';
