import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { filter, tap } from 'rxjs';

@Component({
  selector: 'app-duration-picker',
  templateUrl: './duration-picker.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatFormFieldModule, MatInputModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DurationPickerComponent),
      multi: true,
    },
  ],
})
export class DurationPickerComponent implements ControlValueAccessor {
  constructor() {
    this.form.controls.minutes.valueChanges
      .pipe(
        filter((value) => value > 59),
        tap(() =>
          this.form.patchValue(
            {
              hours: this.form.controls.hours.value + 1,
              minutes: 0,
            },
            { emitEvent: false }
          )
        ),
        takeUntilDestroyed()
      )
      .subscribe();

    this.form.controls.minutes.valueChanges
      .pipe(
        filter((value) => value < 0),
        tap(() =>
          this.form.patchValue(
            {
              hours: Math.max(
                this.form.controls.hours.value,
                this.form.controls.hours.value - 1
              ),
              minutes: this.form.controls.hours.value === 0 ? 0 : 59,
            },
            { emitEvent: false }
          )
        ),
        takeUntilDestroyed()
      )
      .subscribe();

    this.form.valueChanges
      .pipe(
        tap(() => {
          const value = this.form.getRawValue();
          const minutes = value.hours * 60 + value.minutes;

          this.valueChange.emit(minutes);
          this._onChange(minutes);
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  @Input() public emitMinutes = false;

  @Input() public label = '';
  @Input({ transform: booleanAttribute }) public set disabled(
    isDisabled: boolean
  ) {
    if (isDisabled && this.form.enabled) {
      this.form.disable({ emitEvent: false });
    }

    if (!isDisabled && this.form.disabled) {
      this.form.enable({ emitEvent: false });
    }
  }

  @Input() public set value(value: number) {
    this._setValue(value);
  }

  @Output() public valueChange = new EventEmitter<number>();

  protected readonly form = new FormGroup({
    hours: new FormControl(0, { nonNullable: true }),
    minutes: new FormControl(0, { nonNullable: true }),
  });

  private _onChange!: (value: number) => void;
  private _onTouch!: () => void;

  writeValue(value: number): void {
    this._setValue(value);
  }

  registerOnChange(fn: (value: number) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  private _setValue(value: number): void {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    this.form.patchValue(
      {
        hours,
        minutes,
      },
      { emitEvent: false }
    );
  }
}
