import { inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { CategoryTypeFieldsDataService } from '@fieldos/data-services';
import { CategoryType } from '@fieldos/models';
import {
  FieldPermissions,
  FieldRights,
} from '@fieldos/models/fields/field-permissions';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import { AuthStore, ClaimsStore } from '../auth';

interface CategoryTypePermissionsState {
  permissions: Partial<Record<CategoryType, FieldPermissions[]>>;
}

export const CategoryTypePermissionsStore = signalStore(
  { providedIn: 'root' },
  withState<CategoryTypePermissionsState>(() => ({
    permissions: {},
  })),
  withMethods(
    (
      store,
      dataService = inject(CategoryTypeFieldsDataService),
      claimsStore = inject(ClaimsStore)
    ) => ({
      selectRights(
        categoryType: CategoryType,
        typeId: number
      ): Record<string, FieldRights> | null {
        const permissions = store.permissions()[categoryType];

        if (!permissions) {
          return null;
        }

        const typePermissions = permissions.find(
          (permission) => permission.typeId === typeId
        );

        if (!typePermissions) {
          return null;
        }

        return typePermissions.editPermissions.reduce(
          (acc, permission) => ({
            ...acc,
            [permission.id]: { ...permission.properties },
          }),
          {}
        );
      },
      selectPermission(
        categoryType: CategoryType,
        typeId: number
      ): FieldPermissions | null {
        const permissions = store.permissions()[categoryType];

        if (!permissions) {
          return null;
        }

        return (
          permissions.find((permission) => permission.typeId === typeId) || null
        );
      },
      async initializePermissions() {
        const categoryTypesEnum: CategoryType[] = [
          'assets',
          'contractors',
          'customers',
          'locations',
          'users',
        ];

        const claims = claimsStore.claims();

        const categoryTypes = categoryTypesEnum.filter((type) =>
          claims.includes(`${type}.advanced`)
        );

        const requests = categoryTypes.map((type) =>
          dataService.fetchFieldPermissions(type)
        );

        const responses = await Promise.allSettled(requests);

        const permissions = categoryTypes.reduce((acc, type, index) => {
          const response = responses[index];
          if (response.status === 'fulfilled') {
            return {
              ...acc,
              [type]: response.value,
            };
          } else {
            return { ...acc, type: [] };
          }
        }, {});

        patchState(store, {
          permissions,
        });
      },
    })
  ),
  withHooks({
    onInit(store, authStore = inject(AuthStore)) {
      toObservable(authStore.isAuthenticated)
        .pipe(
          distinctUntilChanged(),
          filter((isAuthenticated) => isAuthenticated === true),
          tap(() => store.initializePermissions()),
          takeUntilDestroyed()
        )
        .subscribe();
    },
  })
);
