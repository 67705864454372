<div class="flex flex-col">
  @if (label) {
    <span class="font-medium text-md">{{ label }}</span>
  }
  <div class="flex gap-4" [formGroup]="form">
    <mat-form-field class="fuse-mat-dense" subscriptSizing="dynamic">
      <input matInput formControlName="hours" type="number" />
      <div matSuffix>h</div>
    </mat-form-field>

    <mat-form-field class="fuse-mat-dense" subscriptSizing="dynamic">
      <input matInput formControlName="minutes" type="number" />
      <div matSuffix>m</div>
    </mat-form-field>
  </div>
</div>
