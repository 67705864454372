import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { LocationSectionValue } from '@fieldos/models';
import { DataSourceStore, StandardDataSourceId } from '@fieldos/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SectionBasedEntityListAGGridContext } from '../../section-columns.models';

@Component({
  selector: 'app-location-cell-renderer',
  templateUrl: './location-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationCellRendererComponent implements ICellRendererAngularComp {
  protected value: {
    locationName: string;
    subLocationName: string;
  } = {
    locationName: '',
    subLocationName: '',
  };

  private readonly _dataSourceStore = inject(DataSourceStore);

  agInit(
    params: ICellRendererParams<
      unknown,
      LocationSectionValue,
      SectionBasedEntityListAGGridContext
    >
  ): void {
    this.setValue(params);
  }

  refresh(
    params: ICellRendererParams<
      unknown,
      LocationSectionValue,
      SectionBasedEntityListAGGridContext
    >
  ): boolean {
    this.setValue(params);
    return true;
  }

  private setValue(
    params: ICellRendererParams<
      unknown,
      LocationSectionValue,
      SectionBasedEntityListAGGridContext
    >
  ): void {
    const locations = this._dataSourceStore.selectDataSource(
      StandardDataSourceId.Locations
    )();

    if (params.value?.locationId) {
      const location = locations.find((e) => e.id === params.value?.locationId);
      this.value.locationName = location?.name || '';
    }

    if (params.value?.subLocationId) {
      const subLocation = locations.find(
        (e) => e.id === params.value?.subLocationId
      );
      this.value.subLocationName = subLocation?.name || '';
    }
  }
}
