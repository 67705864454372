import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { EstimatedDurationSection } from '@fieldos/models';
import { DurationPickerComponent } from '../../../../../@components/fields/duration-picker';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-estimated-duration-section',
  templateUrl: './sections-form-estimated-duration-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DurationPickerComponent, ReactiveFormsModule],
})
export class SectionsFormEstimatedDurationSectionComponent extends SectionsFormSectionBaseComponent<EstimatedDurationSection> {
  protected override initializeForm(): void {
    this.form = new FormControl<number>(0, {
      nonNullable: true,
    });
  }
}
