import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  DomainEntityBase,
  SectionSubType,
  SingleDataSourceSection,
} from '@fieldos/models';
import { CustomDataSourceStore, DataSourceStore } from '@fieldos/store';
import { AutocompleteSelectComponent } from '../../../../../@components/fields/autocomplete-select';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-single-data-source-section',
  templateUrl: './sections-form-single-data-source-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AutocompleteSelectComponent, ReactiveFormsModule],
})
export class SectionsFormSingleDataSourceSectionComponent
  extends SectionsFormSectionBaseComponent<SingleDataSourceSection>
  implements OnInit
{
  protected readonly items = signal<DomainEntityBase[]>([]);

  private readonly _dataSourceStore = inject(DataSourceStore);
  private readonly _customDataSourceStore = inject(CustomDataSourceStore);

  override ngOnInit(): void {
    super.ngOnInit();

    let options: DomainEntityBase[] = [];
    if (this.section().subtype === SectionSubType.SingleCustomDataSource) {
      options = this._customDataSourceStore.selectDataSource(
        this.section().properties?.optionId as number
      )();
      this.items.set(options);
    } else {
      options = this._dataSourceStore.selectDataSource(
        this.section().properties?.optionId as number
      )();
    }

    this.items.set(options);
  }

  protected override initializeForm(): void {
    this.form = new FormControl<number>(0, {
      nonNullable: true,
    });
  }

  protected override setValidation(): void {
    this.form.setValidators([Validators.required, Validators.min(1)]);
  }
}


