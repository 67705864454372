import {
  AngularSignaturePadModule,
  NgSignaturePadOptions,
  SignaturePadComponent,
} from '@almothafar/angular-signature-pad';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .signature-pad .signature-pad-canvas {
        @apply border-2 #{'!important'};
        @apply border-dashed #{'!important'};
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AngularSignaturePadModule, MatButtonModule],
})
export class SignatureComponent implements AfterViewInit {
  constructor(private readonly _elementRef: ElementRef) {}

  @Input() public placeholder: string = '';

  @Input() public set height(height: number) {
    this.signaturePad?.set('canvasHeight', height);
    this._height = height;
  }

  @Output() public readonly valueChange = new EventEmitter<
    string | undefined
  >();

  @ViewChild('signature')
  protected readonly signaturePad!: SignaturePadComponent;

  protected showPlaceholder = true;
  protected signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 500,
    canvasHeight: 120,
    velocityFilterWeight: 2,
  };

  private _height: number = 0;

  ngAfterViewInit(): void {
    this.signaturePad.set(
      'canvasWidth',
      this._elementRef.nativeElement.clientWidth
    );
    this.signaturePad.set('penColor', 'rgb(0, 0, 0)');
    this.signaturePad.set(
      'canvasHeight',
      this._height ? this._height : this._elementRef.nativeElement.clientWidth
    );
    this.signaturePad.set('backgroundColor', 'rgb(255, 255, 255)');
    this.signaturePad.clear(); // clearing is needed to set the background colour
  }

  protected onDrawComplete(): void {
    const dataUrl = this.signaturePad.toDataURL();
    this.valueChange.emit(dataUrl);
  }

  protected onDrawStart(): void {
    this.showPlaceholder = false;
  }

  protected onClear(): void {
    this.signaturePad.clear();
    this.valueChange.emit();
    this.showPlaceholder = true;
  }
}
