import { InjectionToken, inject } from '@angular/core';
import { CategoryType } from '@fieldos/models';
import { AssetsDataService } from './assets.service';
import { ContractorsDataService } from './contractors.service';
import { CustomersDataService } from './customers.service';
import { FieldBasedEntitiesServiceBase } from './field-based-entities.service';
import { LocationsDataService } from './locations.service';
import { UsersDataService } from './users.service';

export const FIELD_BASED_ENTITIES_DATA_SERVICE_MAP = new InjectionToken(
  'FIELD_BASED_ENTITIES_DATA_SERVICE_MAP',
  {
    factory: (): Record<CategoryType, FieldBasedEntitiesServiceBase> => {
      const _locationDataService = inject(LocationsDataService);
      const _assetDataService = inject(AssetsDataService);
      const _userDataService = inject(UsersDataService);
      const _customersDataService = inject(CustomersDataService);
      const _contractorsDataService = inject(ContractorsDataService);

      return {
        locations: _locationDataService,
        assets: _assetDataService,
        users: _userDataService,
        customers: _customersDataService,
        contractors: _contractorsDataService,
      };
    },
  }
);
