import { Injectable, inject } from '@angular/core';
import { Taxonomy, TaxonomyModelConstraint } from '@fieldos/models';
import { HttpService } from '@fieldos/core';
import { TAXONOMY_NAME } from '@fieldos/business/taxonomy';

@Injectable()
export class TaxonomyDataService<T extends TaxonomyModelConstraint> {
  private readonly _http = inject(HttpService);
  private readonly _url: string = `taxonomy/${inject(TAXONOMY_NAME)}`;

  get() {
    return this._http.get<T[]>(`/${this._url}`);
  }

  create(data: T): Promise<{ id: number }> {
    return this._http.post<{ id: number }>(`/${this._url}`, data);
  }

  update(data: Taxonomy<T>): Promise<{ id: number }> {
    return this._http.put<{ id: number }>(`/${this._url}/${data.id}`, data);
  }

  delete(id: number) {
    return this._http.delete<T[]>(`/${this._url}/${id}`);
  }
}
