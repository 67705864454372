export type AssetStatusesModel = {
  id: number;
  translatableProperties: Record<
    string,
    {
      name: string;
      description: string;
    }
  >;
  properties: Record<string, string | number>;
  enabled: boolean;
};
