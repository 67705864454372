import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { StatusGraphDataService } from '@fieldos/data-services';
import {
  GraphStatusType,
  StatusGraphNode,
  StatusSection,
} from '@fieldos/models';
import { AutoUnsubscribe } from '@fieldos/utils';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { tap } from 'rxjs';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-status-section',
  templateUrl: './sections-form-status-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    FuseAlertComponent,
    TranslocoModule,
    MatIconModule,
  ],
})
@AutoUnsubscribe()
export class SectionsFormStatusSectionComponent
  extends SectionsFormSectionBaseComponent<StatusSection>
  implements OnInit
{
  constructor() {
    super();

    effect(
      () => {
        const isValid = this.store.isFormValid();

        if (!this.form) {
          return;
        }

        this._setDisabled(isValid || false);

        this.detector.detectChanges();
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      const sectionValue = this.sectionValue();

      if (sectionValue && this.form && sectionValue !== this.form.value) {
        this.form.patchValue(sectionValue, { emitEvent: false });
      }
    });
  }

  protected readonly formValid = this.store.isFormValid;

  protected statusNodes = computed(() => {
    const currentStatus = this._currentStatus();

    const currentStatusNode = this._statusGraph().find(
      (e) => e.id === currentStatus
    );

    if (currentStatusNode) {
      const connectedNodes = this._statusGraph().filter(
        (e) => currentStatusNode?.connections.includes(e.id)
      );

      return [...connectedNodes, currentStatusNode].map((node) => ({
        id: node.id,
        name: node.data.label,
      }));
    } else {
      const openNode = this._statusGraph().find(
        (e) => e.data.statusType === GraphStatusType.Open
      ) as StatusGraphNode;

      if (!openNode) {
        return [];
      }

      return [
        {
          id: openNode.id,
          name: openNode.data.label,
        },
      ];
    }
  });

  private readonly _currentStatus = signal<string>('');

  private readonly _statusGraphDataService = inject(StatusGraphDataService);
  private readonly _statusGraph = signal<StatusGraphNode[]>([]);

  override async ngOnInit(): Promise<void> {
    const nodes = await this._statusGraphDataService.fetch(
      this.store.entityType()
    );

    this._statusGraph.set(nodes);

    this.initializeForm();

    this._updateNodes();

    if (!this.store.entity()) {
      this.form.disable();
    }

    this.form.valueChanges
      .pipe(
        tap(() => {
          this.store.updateSectionValue(
            this.sectionId(),
            this.form.getRawValue()
          );

          this.store.updateSelectedSectionStatus(this.sectionId(), {
            dirty: this.form.dirty,
            valid: !this.form.invalid,
          });

          this._currentStatus.set(this.form.getRawValue() as string);
        }),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();

    this.detector.detectChanges();
  }

  protected override initializeForm(): void {
    this.form = new FormControl<string>('', { nonNullable: true });
  }

  protected override setValidation(): void {
    this.form.setValidators(Validators.required);
    this.form.updateValueAndValidity();
  }

  private _setDisabled(isValid: boolean): void {
    if (isValid && this.form.disabled) {
      this.form.enable();
    }

    if (
      (!isValid && this.form.enabled) ||
      !this.store.entity() ||
      !this.rights()?.canEdit
    ) {
      this.form.disable();
    }
  }

  private _updateNodes(): void {
    const workOrder = this.store.entity();

    if (!workOrder) {
      const firstOpenStatus = this._statusGraph().find(
        (node) => node.data.statusType === GraphStatusType.Open
      );

      this.form.setValue(firstOpenStatus?.id || '');
      this.store.updateSectionValue(this.sectionId(), this.form.getRawValue());
      this.form.disable({ emitEvent: false });
      this._currentStatus.set(firstOpenStatus?.id || '');
    } else {
      const values = this.store.sectionValues() as Record<string, unknown>;
      this.form.setValue(values[this.sectionId()] as string);
      this._currentStatus.set(values[this.sectionId()] as string);
    }

    this._setDisabled(this.formValid() || false);

    this.detector.detectChanges();
  }
}
