import { SectionProperties } from './section-properties';
import { SectionLabelInfoProperties } from './simple-section-properties';

export interface LocationSectionCommonProperties {
  enableLocationLevelTwo: boolean;
}

export interface LocationSectionTranslatableProperties
  extends SectionLabelInfoProperties {
  levelOneLabel: string;
  levelTwoLabel?: string;
}

export type LocationSectionProperties = SectionProperties<
  LocationSectionTranslatableProperties,
  LocationSectionCommonProperties
>;
