import { Injectable, inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { DPU, DPUCreateModel } from '@fieldos/models';

@Injectable({
  providedIn: 'root',
})
export class DPUDataService {
  private readonly _http = inject(HttpService);

  fetchAll(): Promise<DPU[]> {
    return this._http.get<DPU[]>('/dpu');
  }

  delete(id: number): Promise<void> {
    return this._http.delete<void>(`/dpu/${id}`);
  }

  update(dpu: DPU): Promise<void> {
    return this._http.put<void>(`/dpu/${dpu.id}`, dpu);
  }

  createMany(dpus: DPUCreateModel[]): Promise<{ id: number }[]> {
    return this._http.post<{ id: number }[]>(
      '/dpu',
      dpus.map((dpu) => ({
        number: dpu.number,
        statusId: 19,
        weight: dpu.weight || 30,
        packagingId: null,
        woId: null,
      }))
    );
  }

  updateWorkOrderDPUs(woId: number, dpuIds: number[]): Promise<void> {
    return this._http.patch<void>(`/dpu-update-wo`, {
      woId,
      dpuIds,
    });
  }
}
