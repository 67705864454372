import { Section } from '@fieldos/models';

export interface SectionsFormSectionUpdateEvent<
  TSection extends Section = Section,
> {
  value: TSection['value'];
  section: Section;
}

export interface SectionFormStatus {
  dirty: boolean;
  valid: boolean;
}
