import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  SectionBasedEntityType,
  SectionsRights,
  SectionStatusRights,
} from '@fieldos/models';
import { lastValueFrom, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RightsDataService {
  private readonly _http = inject(HttpClient);

  fetchRightsForScope(
    scopeId: number,
    roleId: number,
    entityType: SectionBasedEntityType
  ): Promise<SectionsRights> {
    return lastValueFrom(
      this._http
        .get<SectionsRights[]>(
          `${this._getUrl(entityType)}/role/${roleId}/scope/${scopeId}`
        )
        .pipe(map((rights) => rights[0]))
    );
  }

  saveRightsForScope(
    rights: SectionsRights,
    entityType: SectionBasedEntityType
  ): Observable<void> {
    return this._http.post<void>(this._getUrl(entityType), rights);
  }

  fetchRightsForType(
    scopeId: number,
    roleId: number,
    workOrderTypeId: number,
    entityType: SectionBasedEntityType
  ): Observable<SectionsRights> {
    return this._http.get<SectionsRights>(
      `${this._getUrl(
        entityType
      )}/role/${roleId}/scope/${scopeId}/type/${workOrderTypeId}`
    );
  }

  fetchStatusRights(
    statusId: string,
    roleId: number,
    scopeId: number,
    entityType: SectionBasedEntityType
  ): Observable<SectionStatusRights[]> {
    return this._http.get<SectionStatusRights[]>(
      `${this._getUrl(
        entityType
      )}/role/${roleId}/scope/${scopeId}/status/${statusId}`
    );
  }

  fetchRights(
    statusId: string,
    entityType: SectionBasedEntityType
  ): Promise<SectionStatusRights[]> {
    return lastValueFrom(
      this._http.get<SectionStatusRights[]>(
        `role-${this._getUrl(entityType).substring(1)}/${statusId}`
      )
    );
  }

  private _getUrl(entityType: SectionBasedEntityType): string {
    return entityType === 'workorders' ? '/wo-rights' : '/sr-rights';
  }
}
