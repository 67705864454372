import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-box-confirm-dialog',
  templateUrl: './box-confirm-dialog.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, TranslocoModule],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class BoxConfirmDialogComponent {
  protected show = false;

  private readonly _detector = inject(ChangeDetectorRef);
  private readonly _afterClosed = new Subject<boolean>();

  open(): Observable<boolean> {
    this.show = true;
    this._detector.detectChanges();
    return this._afterClosed.asObservable();
  }

  protected close(result: boolean): void {
    this.show = false;
    this._afterClosed.next(result);
    this._detector.detectChanges();
  }
}
