import { AsyncPipe } from '@angular/common';
import { Component, effect, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ClickOutsideDirective } from '@fieldos/directives';
import { WorkspaceStore } from '@fieldos/store';
import { TranslocoModule } from '@ngneat/transloco';
import { tap } from 'rxjs';

@Component({
  selector: 'app-workspace-scope-select',
  templateUrl: './workspace-scope-select.component.html',
  standalone: true,
  imports: [
    AsyncPipe,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslocoModule,
    ClickOutsideDirective,
  ],
})
export class WorkspaceScopeSelectComponent {
  constructor() {
    effect(
      () => {
        const workspaceScopeId = this.store.selectedWorkspaceScopeId();

        this.workspaceScopeCtrl.setValue(workspaceScopeId, {
          emitEvent: false,
        });
      },
      { allowSignalWrites: true }
    );

    this.workspaceScopeCtrl.valueChanges
      .pipe(
        tap((scopeId) => this.store.selectWorkspaceScope(scopeId)),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  protected readonly workspaceScopeCtrl = new FormControl();
  protected readonly store = inject(WorkspaceStore);
}
