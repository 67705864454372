import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  LOCALE_ID,
  inject,
} from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from '@angular/material/select';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import {
  TranslocoHttpLoader,
  provideIcons,
  provideInterceptors,
} from '@fieldos/core';
import { provideAppStore } from '@fieldos/store';
import { provideFuse } from '@fuse';
import { TranslocoService, provideTransloco } from '@ngneat/transloco';
import { appRoutes } from 'app/app.routes';
import { provideQuillConfig } from 'ngx-quill';
import { firstValueFrom } from 'rxjs';
import { authInterceptor } from './@core/auth/auth.interceptor';
import { jwtRefreshInterceptor } from './@core/auth/jwt-refresh.interceptor';
import { apiUrlInterceptor } from './@core/interceptors/api-url.interceptor';
import { errorInterceptor } from './@core/interceptors/error.interceptor';
import { workspaceScopeHeadersInterceptor } from './@core/interceptors/workspace-scope-headers.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: LOCALE_ID,
      deps: [TranslocoService],
      useFactory: (translocoService: TranslocoService) => {
        const locales = {
          ro: 'ro-RO',
          en: 'en-US',
        } as any;

        return locales[translocoService.getActiveLang()] || 'en-US';
      },
    },

    // Transloco Config
    provideTransloco({
      config: {
        availableLangs: [
          {
            id: 'en',
            label: 'English',
          },
          {
            id: 'ro',
            label: 'Romanian',
          },
        ],
        defaultLang: 'en',
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: true,
      },
      loader: TranslocoHttpLoader,
    }),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        jwtRefreshInterceptor,
        apiUrlInterceptor,
        errorInterceptor,
        workspaceScopeHeadersInterceptor,
      ])
    ),
    provideAppStore(),
    provideQuillConfig({
      modules: {},
    }),
    provideAnimations(),
    provideHttpClient(),
    provideRouter(
      appRoutes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
      withComponentInputBinding()
    ),

    // Material Date Adapter
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 250,
        hideDelay: 0,
        touchendHideDelay: 0,
      },
    },
    MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'D',
        },
        display: {
          dateInput: 'DDD',
          monthYearLabel: 'LLL yyyy',
          dateA11yLabel: 'DD',
          monthYearA11yLabel: 'LLLL yyyy',
        },
      },
    },

    {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide: APP_INITIALIZER,
      useFactory: () => {
        const translocoService = inject(TranslocoService);
        const defaultLang = translocoService.getDefaultLang();
        translocoService.setActiveLang(defaultLang);

        translocoService
          .getAvailableLangs()
          .forEach((lang) =>
            translocoService
              .load(typeof lang === 'string' ? lang : lang.id)
              .subscribe()
          );

        return () => firstValueFrom(translocoService.load(defaultLang));
      },
      multi: true,
    },

    // Fuse
    provideInterceptors(),
    provideIcons(),
    provideFuse({
      fuse: {
        layout: 'classy',
        scheme: 'light',
        screens: {
          sm: '600px',
          md: '960px',
          lg: '1280px',
          xl: '1440px',
        },
        theme: 'theme-brand',
        themes: [
          {
            id: 'theme-default',
            name: 'Default',
          },
          {
            id: 'theme-brand',
            name: 'Brand',
          },
          {
            id: 'theme-teal',
            name: 'Teal',
          },
          {
            id: 'theme-rose',
            name: 'Rose',
          },
          {
            id: 'theme-purple',
            name: 'Purple',
          },
          {
            id: 'theme-amber',
            name: 'Amber',
          },
        ],
      },
    }),
  ],
};
