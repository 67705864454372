import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Assignee } from '@fieldos/models';
import { AssigneeWorkCategoryFormType } from './sections-form-assignee-category-form/sections-form-assignee-category-form.form';
import { AssigneeFormType } from './sections-form-assignees.form-models';

export const createAssigneeForm = (
  assignee: Assignee
): FormGroup<AssigneeFormType> =>
  new FormGroup<AssigneeFormType>({
    id: new FormControl<number>(assignee.id, { nonNullable: true }),
    categories: new FormArray<FormGroup<AssigneeWorkCategoryFormType>>([
      createWorkCategoryForm(),
    ]),
    avatar: new FormControl(null),
    bold: new FormControl(false, { nonNullable: true }),
    contractor: new FormControl('', { nonNullable: true }),
    email: new FormControl(assignee.email, { nonNullable: true }),
    hr: new FormControl<[]>([], { nonNullable: true }),
    name: new FormControl(assignee.name, { nonNullable: true }),
    surname: new FormControl(assignee.surname, { nonNullable: true }),
    team: new FormControl(assignee.team || '', { nonNullable: true }),
    text: new FormControl(assignee.text, {
      nonNullable: true,
    }),
    type: new FormControl('employee', { nonNullable: true }),
  });

export const createEmptyAssigneeForm = () =>
  new FormGroup<AssigneeFormType>({
    id: new FormControl<number>(0, { nonNullable: true }),
    categories: new FormArray<FormGroup<AssigneeWorkCategoryFormType>>([
      createWorkCategoryForm(),
    ]),
    avatar: new FormControl(null),
    bold: new FormControl(false, { nonNullable: true }),
    contractor: new FormControl('', { nonNullable: true }),
    email: new FormControl('', { nonNullable: true }),
    hr: new FormControl<[]>([], { nonNullable: true }),
    name: new FormControl('', { nonNullable: true }),
    surname: new FormControl('', { nonNullable: true }),
    team: new FormControl('' || '', { nonNullable: true }),
    text: new FormControl('', {
      nonNullable: true,
    }),
    type: new FormControl('employee', { nonNullable: true }),
  });

export const createWorkCategoryForm =
  (): FormGroup<AssigneeWorkCategoryFormType> =>
    new FormGroup<AssigneeWorkCategoryFormType>({
      date: new FormControl('', { nonNullable: true }),
      team: new FormControl(0, { nonNullable: true }),
      type: new FormControl(0, { nonNullable: true }),
      duration: new FormGroup({
        minutes: new FormControl(0, { nonNullable: true }),
        hours: new FormControl(0, { nonNullable: true }),
        days: new FormControl(0, { nonNullable: true }),
        weeks: new FormControl(0, { nonNullable: true }),
        total: new FormControl(0, { nonNullable: true }),
        included: new FormControl(true, { nonNullable: true }),
        start: new FormControl(null),
        end: new FormControl(null),
      }),
      comment: new FormControl('', { nonNullable: true }),
      notes: new FormControl('', { nonNullable: true }),
      cost: new FormGroup({
        calculated_value: new FormControl(0, { nonNullable: true }),
        hr: new FormControl(0, { nonNullable: true }),
        included: new FormControl(true, { nonNullable: true }),
      }),
      customMultipleDropdown: new FormControl<number[]>([], {
        nonNullable: true,
      }),
      customSingleDropdown: new FormControl(0, { nonNullable: true }),
      contract: new FormControl(0, { nonNullable: true }),
      cost_category: new FormGroup({
        currency: new FormControl('', { nonNullable: true }),
        duration_default: new FormControl(0, { nonNullable: true }),
        hr_default: new FormControl(0, { nonNullable: true }),
        id: new FormControl(1, { nonNullable: true }),
        name: new FormControl('', { nonNullable: true }),
      }),
      currency: new FormControl('', { nonNullable: true }),
      duration_default: new FormControl(0, { nonNullable: true }),
      hr_default: new FormControl(0, { nonNullable: true }),
      id: new FormControl(0, {
        nonNullable: true,
        validators: Validators.min(1),
      }),
      name: new FormControl('', { nonNullable: true }),
    });
