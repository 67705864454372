import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  SectionBasedEntity,
  SectionValueEntry,
  WorkOrder,
} from '@fieldos/models';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderDataService {
  private _http = inject(HttpClient);

  fetch(workspaceScopeId: number): Promise<WorkOrder[]> {
    return lastValueFrom(
      this._http.get<WorkOrder[]>(`/work-orders?scope_id=${workspaceScopeId}`)
    );
  }

  fetchRequests(workspaceScopeId: number): Observable<SectionBasedEntity[]> {
    return this._http.get<WorkOrder[]>(
      `/service-requests?scope_id=${workspaceScopeId}`
    );
  }

  create(
    workOrder: Omit<WorkOrder, 'id' | 'statusId' | 'availableActions'>
  ): Promise<WorkOrder> {
    return lastValueFrom(this._http.post<WorkOrder>('/work-orders', workOrder));
  }

  fetchOne(workOrderId: number): Observable<WorkOrder> {
    return this._http.get<WorkOrder>(`/work-orders/${workOrderId}`);
  }

  update(workOrder: WorkOrder): Promise<WorkOrder> {
    return lastValueFrom(
      this._http.put<WorkOrder>(`/work-orders/${workOrder.id}`, workOrder.value)
    );
  }

  delete(id: number): Observable<void> {
    return this._http.delete<void>(`/work-orders/${id}`);
  }

  updateWorkOrderSectionValue(
    workOrderId: number,
    value: SectionValueEntry
  ): Observable<void> {
    return this._http.put<void>(`/work-orders/${workOrderId}`, [value]);
  }

  updateWorkOrderSectionValues(
    workOrderId: number,
    value: SectionValueEntry[]
  ): Promise<void> {
    return lastValueFrom(
      this._http.put<void>(`/work-orders/${workOrderId}`, value)
    );
  }
}
