<div class="flex flex-col gap-4" [formGroup]="form">
  @if (commonProperties?.showStars) {
    <app-star-rating-field formControlName="rating" />
  } @else {
    <mat-form-field class="fuse-mat-dense" subscriptSizing="dynamic">
      <mat-label>
        {{ 'work_orders.sections.rating.title' | transloco }}
      </mat-label>
      <input
        matInput
        formControlName="rating"
        max="5"
        min="0"
        placeholder=" {{ 'work_orders.sections.rating.title' | transloco }}"
        type="number" />
    </mat-form-field>
  }

  @if (commonProperties?.showComments) {
    <mat-form-field class="fuse-mat-dense" subscriptSizing="dynamic">
      <mat-label>
        {{ 'work_orders.sections.rating.comments' | transloco }}
      </mat-label>
      <textarea
        matInput
        formControlName="comments"
        placeholder="{{
          'work_orders.sections.rating.comments' | transloco
        }}"></textarea>
    </mat-form-field>
  }
</div>
