@if (currentPreviewFile) {
  <div
    class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 z-[999] flex flex-col">
    <div class="w-full flex justify-end p-4 items-center">
      <button mat-icon-button (click)="downloadCurrentFile()">
        <mat-icon class="text-3xl text-white">cloud_download</mat-icon>
      </button>

      @if (!data.options.removeDisabled) {
        <button mat-icon-button (click)="onDelete()">
          <mat-icon class="text-3xl text-white">delete</mat-icon>
        </button>
      }

      <button mat-icon-button [mat-dialog-close]="">
        <mat-icon class="text-3xl text-white">clear</mat-icon>
      </button>
    </div>

    <div class="flex flex-grow items-center w-full min-h-0">
      <button mat-icon-button class="mx-4" (click)="onPreviousClick()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      @if (
        {
          isImage:
            currentPreviewFile && (currentPreviewFile | fileUploaderIsImage)
        };
        as context
      ) {
        <div class="relative flex flex-grow h-full w-full">
          <ng-container
            *ngTemplateOutlet="
              context.isImage ? imageTemplate : documentTemplate
            " />
        </div>
      }

      <button mat-icon-button class="mx-4" (click)="onNextClick()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>

    <div class="w-full text-white flex py-2 justify-center">
      {{ currentPreviewFile.value || currentPreviewFile.name }}
    </div>
  </div>
}

<ng-template #documentTemplate>
  @if (currentPreviewFile) {
    <div class="px-4 h-full w-full flex items-center justify-center text-white">
      <div class="flex gap-2 items-center">
        <mat-icon
          class="scale-150"
          color="primary"
          svgIcon="mat_outline:description" />

        <div class="flex flex-col text-sm px-2 items-start">
          <span class="font-bold leading-4">{{ currentPreviewFile.name }}</span>
          <div class="flex gap-2">
            <span class="whitespace-nowrap">{{
              currentPreviewFile.size | filesize
            }}</span>
          </div>
        </div>
      </div>
    </div>
  }
</ng-template>

<ng-template #imageTemplate>
  @if (
    currentPreviewFile &&
    (currentPreviewFile.url || currentPreviewFile.thumbnailUrl)
  ) {
    <img
      class="object-contain"
      [src]="currentPreviewFile.url || currentPreviewFile.thumbnailUrl" />
  }
</ng-template>
