import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { Assignee, AssigneesSection } from '@fieldos/models';
import { DataSourceStore, StandardDataSourceId } from '@fieldos/store';
import { minLengthArrayValidator } from '@fieldos/utils';
import { TranslocoModule } from '@ngneat/transloco';
import { MultipleSelectComponent } from '../../../../../@components/fields/multiple-select/multiple-select.component';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';
import { SectionsFormAssigneeFormComponent } from './sections-form-assignee-form/sections-form-assignee-form.component';

import {
  createAssigneeForm,
  createEmptyAssigneeForm,
  createWorkCategoryForm,
} from './sections-form-assignees.form';
import { AssigneeFormType } from './sections-form-assignees.form-models';

@Component({
  selector: 'app-sections-form-assignees-section',
  templateUrl: './sections-form-assignees-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MultipleSelectComponent,
    TranslocoModule,
    ReactiveFormsModule,
    SectionsFormAssigneeFormComponent,
    AsyncPipe,
  ],
})
export class SectionsFormAssigneesSectionComponent
  extends SectionsFormSectionBaseComponent<AssigneesSection>
  implements OnInit
{
  constructor() {
    super();
    this._dataSourceStore.fetchDataSource(6, { extraInfo: '1' });
    this._dataSourceStore.fetchDataSource(9, { extraInfo: '1' });
  }

  protected readonly personelCtrl = new FormControl<number[]>([], {
    nonNullable: true,
  });

  protected readonly availableAssignees = inject(
    DataSourceStore
  ).selectDataSource(StandardDataSourceId.Assignees);

  protected get assigneesFormGroups(): FormGroup<AssigneeFormType>[] {
    return this.form.controls;
  }

  protected override _emitOnValid = true;

  private readonly _dataSourceStore = inject(DataSourceStore);
  private readonly _detector = inject(ChangeDetectorRef);

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.sectionValue()) {
      const assignees = this.sectionValue() || [];
      this.personelCtrl.setValue(assignees.map((e) => e.id));

      assignees.forEach((assignee) => {
        const ctrl = createEmptyAssigneeForm();

        ctrl.controls.categories.clear();
        ctrl.patchValue(assignee, { emitEvent: false });

        assignee.categories.forEach((category) => {
          const categoryCtrl = createWorkCategoryForm();
          categoryCtrl.patchValue(category, { emitEvent: false });
          ctrl.controls.categories.push(categoryCtrl, {
            emitEvent: false,
          });
        });

        this.form.push(ctrl, { emitEvent: false });
      });

      this._detector.detectChanges();
    }

    if (!this.rights()?.canEdit) {
      this.form.disable();
      this.personelCtrl.disable();
    }
  }

  onAssigneeRemoved(assigneeId: number | string): void {
    const index = this.form.controls.findIndex(
      (e) => e.controls.id.value === (assigneeId as number)
    );

    if (index > -1) {
      this.form.removeAt(index);
    }
  }

  protected override initializeForm(): void {
    this.form = new FormArray<FormGroup<AssigneeFormType>>([]);
  }

  protected onAssigneeSelected(assigneeId: number | string): void {
    const assignees = this._dataSourceStore.getDataSource(
      StandardDataSourceId.Assignees
    );
    const assignee = assignees.find((e) => e.id === assigneeId);
    const workCategories = this._dataSourceStore.getDataSource(
      StandardDataSourceId.WorkCategory
    );

    const form = createAssigneeForm(assignee as Assignee);
    form.controls.categories.controls[0].patchValue({
      id: workCategories[0].id,
    });
    this.form.push(form, { emitEvent: true });
    this.form.updateValueAndValidity({ emitEvent: true });
  }

  protected onRemoveAssignee(index: number): void {
    this.form.removeAt(index);
    const assignees = [...this.personelCtrl.value];
    assignees.splice(index, 1);
    this.personelCtrl.setValue(assignees);
  }

  protected override setValidation(): void {
    this.form.setValidators(minLengthArrayValidator(1));
    this.form.updateValueAndValidity();
  }
}
