import { Inject, Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, ReplaySubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NAVIGATION_CONFIG } from '../config';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  /**
   * Constructor
   */
  constructor(
    @Inject(NAVIGATION_CONFIG) private _navigationConfig: FuseNavigationItem[],
    _translate: TranslocoService
  ) {
    this._updateTranslations(_navigationConfig);

    _translate.langChanges$
      .pipe(tap(() => this._updateTranslations(_navigationConfig)))
      .subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  public get navigation$(): Observable<FuseNavigationItem[]> {
    return this._navigation.asObservable();
  }

  private _navigation: ReplaySubject<FuseNavigationItem[]> = new ReplaySubject<
    FuseNavigationItem[]
  >(1);

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<FuseNavigationItem[]> {
    return of(this._navigationConfig).pipe(
      tap((navigation) => {
        this._navigation.next(navigation);
      })
    );
  }

  private _updateTranslations(navigationConfig: FuseNavigationItem[]): void {
    this._navigation.next([...navigationConfig]);
  }
}
