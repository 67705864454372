import {
  SectionLabelInfoProperties,
  SimpleSectionProperties,
} from './section-properties';
import {
  SectionCommonPropertiesBase,
  SectionProperties,
} from './section-properties/section-properties';
import { SectionSubType, SectionType } from './section-type.enum';

export interface SectionBase<
  TTranslatableProperties = SectionLabelInfoProperties,
  TCommonProperties = SectionCommonPropertiesBase,
> extends SectionProperties<TTranslatableProperties, TCommonProperties> {
  id: string;
  type: SectionType;
  subtype: SectionSubType;
}

export type Section<
  T = any,
  TTranslatableProperties = SectionLabelInfoProperties,
  TCommonProperties = SectionCommonPropertiesBase,
> = SectionBase<TTranslatableProperties, TCommonProperties> & {
  value?: T;
  defaultValue?: T;
};

export const isSectionType = (type: SectionDropData): type is SectionSubType =>
  Object.values(SectionSubType).includes(type as SectionSubType);

export type SectionDropData = Section<unknown> | SectionSubType;

export type ContainerSection = SectionBase & SimpleSectionProperties;

export const isContainerSection = (
  section: SectionBase
): section is ContainerSection => section.subtype === SectionSubType.Container;
