@if (store.error()) {
  <div class="w-full h-full flex justify-center items-center bg-card">
    {{ 'work_orders.form.create.insufficient_permissions' | transloco }}
  </div>
} @else {
  @if (!store.isInitializing()) {
    @if (
      store.entityType() === 'workorders' &&
      store.entity() &&
      store.entity()?.isReasonCodeLocked
    ) {
      <mat-card class="mx-4 mt-4">
        <mat-card-content>
          <app-sections-form-entity-locked-info-panel
            [workOrderId]="store.entity()?.id" />
        </mat-card-content>
      </mat-card>
    }

    <div class="flex flex-col p-4 gap-4">
      @for (sectionId of store.sectionIds(); track sectionId) {
        @if (store.rights(); as rights) {
          @if (rights[sectionId]?.show) {
            <app-sections-form-section [sectionId]="sectionId" />
          }
        }
      }
    </div>
  } @else {
    <div class="w-full flex justify-center">
      <mat-progress-bar
        class="w-full -mx-2"
        color="primary"
        mode="indeterminate" />
    </div>
  }
}
