import { InjectionToken } from '@angular/core';
import { SectionSubType } from '@fieldos/models';
import { StandardDataSourceId } from '../@store';

export const DATA_SOURCE_SECTION_SUBTYPE_MAP = new InjectionToken(
  'DATA_SOURCE_SECTION_SUBTYPE_MAP',
  {
    factory: (): Partial<Record<SectionSubType, number>> => ({
      [SectionSubType.Type]: StandardDataSourceId.Types,
      [SectionSubType.Customer]: StandardDataSourceId.Customers,
      [SectionSubType.CustomerContract]: StandardDataSourceId.CustomerContracts,
      [SectionSubType.Teams]: StandardDataSourceId.Teams,
      [SectionSubType.Asset]: StandardDataSourceId.Assets,
      [SectionSubType.Parts]: StandardDataSourceId.Parts,
      [SectionSubType.Status]: StandardDataSourceId.Status,
      [SectionSubType.Supervisors]: StandardDataSourceId.Employees,
      [SectionSubType.ResponsiblePerson]: StandardDataSourceId.Assignees,
      [SectionSubType.StartingLocation]: StandardDataSourceId.Locations,
    }),
  }
);

export const DATA_SOURCE_ENTITY_SELECT_TYPE_MAP = new InjectionToken(
  'DATA_SOURCE_ENTITY_SELECT_TYPE_MAP',
  {
    factory: (): Partial<Record<SectionSubType, 'single' | 'multiple'>> => ({
      [SectionSubType.Type]: 'single',
      [SectionSubType.Customer]: 'single',
      [SectionSubType.CustomerContract]: 'single',
      [SectionSubType.Teams]: 'multiple',
      [SectionSubType.Asset]: 'single',
      [SectionSubType.Parts]: 'single',
      [SectionSubType.Status]: 'single',
      [SectionSubType.Supervisors]: 'multiple',
      [SectionSubType.ResponsiblePerson]: 'single',
      [SectionSubType.StartingLocation]: 'single',
    }),
  }
);
