export interface ReportResponse {
  header: ReportHeader[];
  data: ReportEntry[];
}

export interface ReportHeader {
  name: string;
}

export type ReportEntry = Array<ReportColumn>;

export interface ReportColumn {
  value: string | null;
}
