import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  effect,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import {
  SectionBasedEntity,
  SectionBasedEntityType,
  SectionSubType,
} from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { SectionsFormComponent, SectionsFormStore } from '../sections-form';
import { SectionsFormDrawerContentComponent } from './sections-form-drawer-content.component';
import {
  SectionStatusChangeEvent,
  SectionValueChangeEvent,
} from './sections-form-drawer.models';

@Component({
  selector: 'app-sections-form-drawer',
  templateUrl: './sections-form-drawer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    TranslocoModule,
    SectionsFormComponent,
    SectionsFormDrawerContentComponent,
  ],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class SectionsFormDrawerComponent implements OnChanges {
  constructor() {
    effect(
      () => {
        const value = this.store.lastSectionChange();
        const entity = this.store.entity();
        const sections = Object.values(this.store.sectionsMap());

        const statusSection = sections.find(
          (e) => e.subtype === SectionSubType.Status
        );

        if (statusSection && value?.id === statusSection.id && entity) {
          this.statusChange.emit({
            entity,
            statusId: value.value as string,
          });
        }

        if (value && entity) {
          this.sectionChange.emit({
            entityId: entity.id,
            value,
          });

          this.store.resetLastSectionChange();
        }
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        const markedForClose = this.store.markedForClose();

        if (markedForClose) {
          this.close();
          this.store.resetMarkedForClose();
        }
      },
      { allowSignalWrites: true }
    );
  }

  @Input() public set entityType(entityType: SectionBasedEntityType) {
    this.store.setEntityType(entityType);
  }

  @Input() public canPin = true;

  @Output() public readonly closed = new EventEmitter<boolean>();
  @Output() public readonly sectionChange =
    new EventEmitter<SectionValueChangeEvent>();
  @Output() public readonly statusChange =
    new EventEmitter<SectionStatusChangeEvent>();

  @Output() public readonly created = new EventEmitter<SectionBasedEntity>();

  public get isOpen(): boolean {
    return this.opened;
  }

  public get isEditMode(): boolean {
    return !!this.store.entity();
  }

  protected readonly messages = inject(SectionsFormStore).messages;

  protected mode: MatDrawerMode = 'over';
  protected opened = false;

  protected readonly store = inject(SectionsFormStore);

  private readonly _detector = inject(ChangeDetectorRef);

  @HostListener('window:keyup.escape')
  private _onEscape(): void {
    this.close();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['canPin'] && this.canPin === false) {
      this.mode = 'over';
    }
  }

  close(): void {
    this.opened = false;
    this.closed.emit(this.store.isChanged());
    this.store.hardReset();
  }

  openCreateMode(
    scopeId: number,
    prefilledValues?: Record<string, unknown>
  ): void {
    this.store.resetState(scopeId, prefilledValues);
    this.store.hardReset();
    this.opened = true;
    this._detector.detectChanges();
  }

  openEditMode(entity: SectionBasedEntity): void {
    this.store.initialize(entity);
    this.opened = true;
    this._detector.detectChanges();
  }

  protected onPinChange(): void {
    this.mode = this.mode === 'over' ? 'side' : 'over';
  }

  protected onOpenChange(isOpen: boolean): void {
    this.opened = isOpen;

    if (!isOpen) {
      this.closed.emit(this.store.isChanged());
    }
  }

  protected async onCreate(): Promise<void> {
    const createdEntity = await this.store.create();

    if (createdEntity) {
      this.created.emit(createdEntity);

      this.close();
      this._detector.detectChanges();
      this.store.setIsChanged(true);
    }
  }
}
