type TranslatableProperty = Record<string, string | number>;
type TranslatableProperties = Record<string, TranslatableProperty>;
type ModelProperties = Record<
  string,
  string | number | string[] | boolean | number[]
>;

export type TaxonomyModelConstraint = {
  translatableProperties: TranslatableProperties;
  properties: ModelProperties;
  enabled: boolean;
};

export type Taxonomy<T extends TaxonomyModelConstraint> = T & {
  id: number;
};

export type TaxonomyType =
  | 'ordertypes'
  | 'dpustatuses'
  | 'reasoncodes'
  | 'packaging'
  | 'assetstatuses';

export type TaxonomyFormDialogAction = 'edit' | 'add' | 'modifyTranslations';
