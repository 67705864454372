import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  HeaderContentLayoutComponent,
  SelectFieldComponent,
} from '@fieldos/components';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CategoryTypesStore } from '@fieldos/store/category-type';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { TranslocoModule } from '@ngneat/transloco';
import { FieldsDesignerAvailableFieldsComponent } from './fields-designer-available-fields/fields-designer-available-fields.component';
import { FieldsDesignerEnabledFieldsComponent } from './fields-designer-enabled-fields/fields-designer-enabled-fields.component';
import { FieldsDesignerFieldPropertiesComponent } from './fields-designer-field-properties/fields-designer-field-properties.component';

import { CategoryType } from '@fieldos/models';
import { FIELDS_DESIGNER_STORE } from './fields-designer.store';

@Component({
  selector: 'app-fields-designer',
  templateUrl: './fields-designer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  imports: [
    HeaderContentLayoutComponent,
    TranslocoModule,
    MatButtonModule,
    FuseScrollbarDirective,
    FieldsDesignerAvailableFieldsComponent,
    FieldsDesignerFieldPropertiesComponent,
    FieldsDesignerEnabledFieldsComponent,
    MatProgressSpinnerModule,
    SelectFieldComponent,
  ],
})
export class FieldsDesignerComponent {
  public readonly categoryType = input.required<CategoryType>();

  protected readonly store = inject(FIELDS_DESIGNER_STORE);
  protected readonly categoryTypesStore = inject(CategoryTypesStore);
}
