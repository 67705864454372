<div class="flex flex-col items-center text-base">
  <span> {{ 'assign_reason_code.blocked' | transloco }}: </span>

  <span class="font-bold">
    {{ reasonCodeName() }}
  </span>

  @if (loading()) {
    <mat-spinner color="primary" diameter="24" />
  }

  @if (exceptionId(); as exceptionId) {
    @if (exception(); as exceptionValue) {
      <div class="flex flex-col gap-4 w-full mt-4">
        @if (exceptionValue.comments) {
          <app-text-field
            class="w-full"
            fieldClass="w-full"
            [value]="exceptionValue?.comments || ''"
            isTextarea
            label="{{ 'common.comments' | transloco }}"
            readonly />
        }

        @if (exceptionValue.media && exceptionValue.media.length) {
          <app-file-uploader
            class="max-h-48 h-48"
            [files]="exceptionValue.media"
            (fileClick)="onFileClick($event)"
            readonly />
        }
      </div>
    }
  }
</div>
