<div class="h-full overflow-auto w-full">
  <h5 class="font-semibold p-4 border-b">
    {{ 'fields_designer.available_fields' | transloco }}
  </h5>

  <div class="flex flex-col flex-grow flex-shrink-0 min-h-0 overflow-auto">
    <div class="flex flex-col gap-4 m-4 prose flex-grow">
      @for (field of store.availableFields(); track $index) {
        <div
          class="cursor-pointer bg-card h-18 border hover:bg-slate-100 dark:hover:bg-slate-700"
          [bpDrag]="field"
          (bpDragEnd)="store.setDraggedNewFieldType()"
          (bpDragStart)="store.setDraggedNewFieldType(field)">
          <div class="relative w-full h-full flex items-center justify-center">
            <h6 class="font-semibold text-md">
              {{ 'fields.' + field + '.title' | transloco }}
            </h6>

            @if (!store.uniqueFields()[field]) {
              <mat-icon
                matTooltip="{{ 'fields.non_unique_field.tooltip' | transloco }}"
                class="cursor-pointer scale-75 absolute top-2 left-2">
                star
              </mat-icon>
            }
          </div>
        </div>
      }
    </div>
  </div>
</div>
