import { Directive, ElementRef } from '@angular/core';
import { MouseCursorElementService } from './mouse-cursor-element.service';

@Directive({
  selector: '[appMouseCursorElementPlaceholder]',
  standalone: true,
})
export class MouseCursorElementPlaceholderDirective {
  constructor(
    _elementRef: ElementRef<HTMLElement>,
    _service: MouseCursorElementService
  ) {
    _elementRef.nativeElement.style.display = 'none !important';
    _service.setPlaceholder(_elementRef);
  }
}
