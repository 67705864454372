import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  signal,
} from '@angular/core';
import { DataSourceSection } from '@fieldos/models';
import {
  DATA_SOURCE_ENTITY_SELECT_TYPE_MAP,
  DATA_SOURCE_SECTION_SUBTYPE_MAP,
} from '@fieldos/providers';
import { DataSourceStore } from '@fieldos/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SectionBasedEntityListAGGridContext } from '../../section-columns.models';

@Component({
  selector: 'app-data-source-cell-renderer',
  templateUrl: './data-source-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourceCellRendererComponent
  implements ICellRendererAngularComp
{
  constructor() {
    effect(
      () => {
        this._dataSourceStore.cache();

        const section = this._section();

        if (!section) {
          return;
        }

        const normalizedDataSource =
          this._dataSourceStore.selectNormalizedDataSource(
            this._workOrderSubtypeDataSourceMap[section.subtype] as number
          )();

        if (this._selectTypeMap[section.subtype] === 'single') {
          this.value.set(
            normalizedDataSource[this._value() as number]?.name || ''
          );
        } else {
          ((this._value() as number[]) || [])
            .map((id) => normalizedDataSource[id]?.name || '')
            ?.join(', ') || '';
        }
      },
      { allowSignalWrites: true }
    );
  }

  protected readonly value = signal('');

  protected readonly _selectTypeMap = inject(
    DATA_SOURCE_ENTITY_SELECT_TYPE_MAP
  );

  private readonly _dataSourceStore = inject(DataSourceStore);
  private readonly _workOrderSubtypeDataSourceMap = inject(
    DATA_SOURCE_SECTION_SUBTYPE_MAP
  );

  private readonly _value = signal<number | number[]>([]);
  private readonly _section = signal<DataSourceSection | undefined>(undefined);

  agInit(
    params: ICellRendererParams<
      string,
      number | number[],
      SectionBasedEntityListAGGridContext
    >
  ): void {
    this._setValue(params);
  }

  refresh(
    params: ICellRendererParams<
      string,
      number | number[],
      SectionBasedEntityListAGGridContext
    >
  ): boolean {
    this._setValue(params);
    return true;
  }

  private _setValue(
    params: ICellRendererParams<
      string,
      number | number[],
      SectionBasedEntityListAGGridContext
    >
  ): void {
    const section = params.context.sections[
      params.colDef?.field as string
    ] as DataSourceSection;

    this._section.set(section);

    this._value.set(params.value as number | number[]);
  }
}
