import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { HtmlEditorComponent } from '@fieldos/components';
import { TranslocoModule } from '@ngneat/transloco';
import { HtmlEditorDialogData } from './html-editor-dialog.models';

@Component({
  selector: 'app-html-editor-dialog',
  templateUrl: './html-editor-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    HtmlEditorComponent,
    TranslocoModule,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlEditorDialogComponent {
  constructor() {
    this.formCtrl.setValue(this.data.html);
  }

  protected readonly data = inject<HtmlEditorDialogData>(MAT_DIALOG_DATA);

  protected readonly formCtrl = new FormControl('', { nonNullable: true });
}
