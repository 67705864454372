<div class="p-2">
  <div
    class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper mb-2"
    ref="eWrapper"
    role="presentation">
    <input
      id="ag-132-input"
      class="ag-input-field-input ag-text-field-input"
      [formControl]="searchFormCtrl"
      placeholder="{{ 'common.search' | transloco }}"
      ref="eInput"
      type="text" />
  </div>

  <div class="flex flex-col">
    <mat-checkbox
      [checked]="isSelectAllChecked"
      [indeterminate]="isSelectAllIntermediate"
      (change)="onSelectAllChange()"
      color="primary">
      {{ 'common.select_all' | transloco }}
    </mat-checkbox>

    @for (option of statuses(); track option.id) {
      <mat-checkbox
        [checked]="selection.isSelected(option.id)"
        (change)="onOptionSelected(option)"
        color="primary">
        {{ option.data.label }}
      </mat-checkbox>
    }
  </div>
</div>
