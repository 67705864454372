import { Component, effect, inject, signal } from '@angular/core';
import { DomainEntityBase } from '@fieldos/models';
import { DataSourceStore, StandardDataSourceId } from '@fieldos/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface MultipleDataSourceCellRendererParams
  extends ICellRendererParams<string, number[], unknown> {
  dataSourceId: StandardDataSourceId;
}

@Component({
  selector: 'app-multiple-data-source-cell-renderer',
  templateUrl: './multiple-data-source-cell-renderer.component.html',
})
export class MultipleDataSourceCellRendererComponent
  implements ICellRendererAngularComp
{
  constructor() {
    effect(
      () => {
        const ids = this._ids();
        const entries = this._dataSourceEntries();

        if (entries) {
          this.value.set(
            ids
              .map((id) => (entries[id] ? entries[id].name : ''))
              .filter((e) => !!e)
              .join(', ')
          );
        }
      },
      { allowSignalWrites: true }
    );
  }

  protected readonly value = signal('');

  private readonly _dataSourceEntries = signal<
    Record<number, DomainEntityBase> | undefined
  >(undefined);

  private readonly _ids = signal<number[]>([]);
  private readonly _dataSourceStore = inject(DataSourceStore);

  agInit(params: MultipleDataSourceCellRendererParams): void {
    const options = this._dataSourceStore.selectNormalizedDataSource(
      params.dataSourceId
    )();
    this._dataSourceEntries.set(options);
    this._ids.set(params.value as number[]);

    this._ids.set(params.value as number[]);
  }

  refresh(params: MultipleDataSourceCellRendererParams): boolean {
    this._ids.set(params.value as number[]);
    return false;
  }
}
