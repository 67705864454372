import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RoutesStore, StandardDataSourceId } from '@fieldos/store';
import { TranslocoModule } from '@ngneat/transloco';
import { IFilterParams } from 'ag-grid-community';
import { SetFilterBase } from 'app/@components/ag-grid-renderers/set-filter-base.component';

export interface DataSourceSetFilterParams
  extends IFilterParams<Record<string, any>, unknown> {
  dataSourceId: StandardDataSourceId;
  defaultExcludedValues?: number[];
  searchProperty?: string;
}

@Component({
  selector: 'app-routes-set-filter',
  templateUrl: './routes-set-filter.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    AsyncPipe,
    TranslocoModule,
    ScrollingModule,
  ],
  styles: [
    `
      :host {
        max-height: 500px;
        width: 320px;
        @apply p-4 block;
      }
    `,
  ],
})
export class RoutesSetFilterComponent extends SetFilterBase {
  constructor() {
    super();
    effect(
      () => {
        const options = this._store.entities();

        if (options) {
          this.options.set(Object.values(options));
        }
      },
      { allowSignalWrites: true }
    );
  }

  private readonly _store = inject(RoutesStore);
}
