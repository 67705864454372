<app-file-uploader
  class="h-48"
  [accept]="accept"
  [disabled]="form.disabled"
  [files]="form.value || []"
  [progressReport]="progress"
  (fileClick)="onFileClick($event)"
  (fileRemoved)="onRemoveFile($event)"
  (filesAdded)="onFilesAdded($event)"
  enableDownload />
