import { inject, Injectable } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { WorkOrderImportResponse } from '@fieldos/models';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderImportDataService {
  private _http = inject(HttpService);

  import(
    file: File,
    workspaceId: string,
    scopeId: string
  ): Promise<WorkOrderImportResponse> {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('module', 'workorder');
    formData.append('type', 'workOrderUserImportedFile');
    formData.append('workspaceId', workspaceId);
    formData.append('scopeId', scopeId);

    return this._http.post<WorkOrderImportResponse>('/fileimport', formData);
  }
}
