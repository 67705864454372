import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  effect,
  inject,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FieldError } from '@fieldos/components';
import { CategoryType, Field } from '@fieldos/models';
import { FieldRights } from '@fieldos/models/fields/field-permissions';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { FieldFormComponent } from './field-form/field-form.component';
import { FieldsFormStore } from './fields-form.store';

@Component({
  selector: 'app-fields-form',
  templateUrl: './fields-form.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FieldFormComponent,
    FuseAlertComponent,
    TranslocoModule,
    MatButtonModule,
  ],
  providers: [FieldsFormStore],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class FieldsFormComponent {
  constructor() {
    effect(
      () => {
        const fields = this.fields();
        if (fields && fields.length && !this.store.fields().length) {
          this.store.initialize(this.entityId(), [...fields]);
        }
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        const lastUpdatedField = this.store.lastUpdatedField();
        if (lastUpdatedField) {
          this.fieldValueChange.emit(lastUpdatedField);
        }
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        this.store.setPermissions(this.permissions());
      },
      { allowSignalWrites: true }
    );
  }

  @Output() public readonly fieldValueChange = new EventEmitter<Field>();
  @Output() public readonly cancel = new EventEmitter<void>();
  @Output() public readonly save = new EventEmitter<Field[]>();

  public readonly categoryType = input.required<CategoryType>();
  public readonly permissions = input.required<Record<string, FieldRights>>();

  public readonly errors = input<FieldError[]>();

  public readonly fields = input.required<Field[]>();
  public readonly entityId = input<string>();

  protected readonly store = inject(FieldsFormStore);
}
