import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  signal,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LocationDataSourceModel } from '@fieldos/models';
import { DataSourceStore, StandardDataSourceId } from '@fieldos/store';
import { TranslocoModule } from '@ngneat/transloco';
import {
  SetFilterBase,
  SetFilterParams,
} from '../../set-filter-base.component';

export interface DataSourceSetFilterParams extends SetFilterParams {
  dataSourceId: StandardDataSourceId;
}

@Component({
  selector: 'app-data-source-set-filter',
  templateUrl: './data-source-set-filter.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    AsyncPipe,
    TranslocoModule,
    ScrollingModule,
  ],
  styles: [
    `
      :host {
        max-height: 500px;
        width: 320px;
        @apply p-4 block;
      }
    `,
  ],
})
export class DataSourceSetFilterComponent extends SetFilterBase<DataSourceSetFilterParams> {
  constructor() {
    super();

    effect(
      () => {
        const dataSourceId = this._dataSourceId();
        if (dataSourceId) {
          const options = this._dataSourceStore.cache()[dataSourceId]?.data;

          if (options) {
            this.options.set(Object.values(options));
          }
        }
      },
      { allowSignalWrites: true }
    );
  }

  private readonly _dataSourceId = signal<StandardDataSourceId | null>(null);
  private readonly _dataSourceStore = inject(DataSourceStore);

  override agInit(params: DataSourceSetFilterParams): void {
    this.params.set(params);
    this._dataSourceId.set(params.dataSourceId);
  }

  protected override updateSelectionOnFloatingFilterChange(): void {
    const value = this.floatingFilterValue();

    if (value === undefined) {
      return;
    }

    if (!value || value === '') {
      this.selection.set(null);
      this.params()?.filterChangedCallback();
      return;
    }

    const searchProperty = this.params()?.searchProperty;

    if (searchProperty) {
      const passedFilterValues = this.options().filter((e) =>
        (e as any)[searchProperty].toLowerCase().includes(value.toLowerCase())
      );
      this.selection.set(passedFilterValues.map((e) => `${e.id}`));
      this.params()?.filterChangedCallback();

      return;
    }

    if (this._dataSourceId() === StandardDataSourceId.Locations) {
      const passedFilterValues = this.options().filter((e) => {
        const location = e as LocationDataSourceModel;

        return (
          location.id.toString() === `${value}` ||
          location.name.toLowerCase().includes(value.toLowerCase()) ||
          location.locationCode.toLowerCase().includes(value.toLowerCase())
        );
      });
      this.selection.set(passedFilterValues.map((e) => `${e.id}`));
      this.params()?.filterChangedCallback();

      return;
    }

    const passedFilterValues = this.options().filter(
      (e) =>
        e.name.toLowerCase().includes(value.toLowerCase()) ||
        e.id.toString() === value
    );

    this.selection.set(passedFilterValues.map((e) => `${e.id}`));
    this.params()?.filterChangedCallback();
  }
}
