export type WorkOrderLocationModel =
  | WorkOrderLocationWithGeolocation
  | WorkOrderLocationWithQuery;

interface WorkOrderLocationWithQuery {
  query: string;
  lat?: number;
  lng?: number;
}

interface WorkOrderLocationWithGeolocation {
  query?: string;
  lat: number;
  lng: number;
}
