import { inject, Injectable } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { ServiceRequestTemplate, ServiceRequestTemplateForm } from '@fieldos/models';
import { omit } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class RequestTemplateDataService {
  private readonly _http = inject(HttpService);

  fetchById(id: number): Promise<ServiceRequestTemplateForm> {
    return this._http.get<ServiceRequestTemplateForm>(
      `/service-request-templates/${id}`
    );
  }

  fetchAll(): Promise<ServiceRequestTemplate[]> {
    return this._http.get<ServiceRequestTemplate[]>('/service-request-templates');
  }

  save(form: ServiceRequestTemplateForm): Promise<void> {
    if (form.id) {
      return this._http.put<void>(
        `/service-request-templates/${form.id}`,
        omit(form, 'id')
      );
    } else {
      return this._http.post<void>(
        '/service-request-templates',
        omit(form, 'id')
      );
    }
  }
}
