import { ActivatedRouteSnapshot, Data, Params } from '@angular/router';
import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { AppState } from 'app/@store/app.state';

const {
  selectCurrentRoute,
  selectFragment,
  selectQueryParam,
  selectQueryParams,
  selectRouteData,
  selectUrl,
} = getRouterSelectors((state: AppState) => state.router);

const selectRouterState = createFeatureSelector<RouterReducerState>('router');

const selectAllRoutesData = createSelector(selectRouterState, (state) => {
  let result = {} as Data;

  if (state) {
    let current: ActivatedRouteSnapshot | null = state.state.root;

    while (current) {
      if (current.data) {
        result = {
          ...result,
          ...current.data,
        };
      }

      current = current.firstChild;
    }
  }

  return result;
});

const selectRouteParam = (
  paramName: string
): MemoizedSelector<AppState, string> =>
  createSelector(selectRouterState, (state: RouterReducerState) => {
    if (state) {
      let current: ActivatedRouteSnapshot | null = state.state.root;

      while (current) {
        if (current.params && current.params[paramName]) {
          return current.params[paramName];
        }

        current = current.firstChild;
      }
    }
  });

const selectRouteParams = createSelector(
  (state: AppState) => state.router,
  (router: RouterReducerState): Params => {
    let params: Params = {};
    if (router) {
      let current: ActivatedRouteSnapshot | null = router.state.root;

      while (current) {
        if (current.params) {
          params = {
            ...params,
            ...current.params,
          };
        }

        current = current.firstChild;
      }

      return params;
    }

    return {};
  }
);

export const routeSelectors = {
  selectUrl,
  selectFragment,
  selectRouteData,
  selectQueryParam,
  selectRouteParam,
  selectQueryParams,
  selectRouteParams,
  selectCurrentRoute,
  selectAllRoutesData,
};
