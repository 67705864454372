import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConfirmLocationSection } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-confirm-location-section',
  templateUrl: './sections-form-confirm-location-section.component.html',
  standalone: true,
  imports: [MatCheckboxModule, TranslocoModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionsFormConfirmLocationSectionComponent
  extends SectionsFormSectionBaseComponent<ConfirmLocationSection>
  implements OnInit
{
  override ngOnInit(): void {
    this.initializeForm();
    const sectionValue = this.sectionValue();

    if (sectionValue) {
      this.form.setValue(sectionValue, { emitEvent: false });
    }
  }

  override initializeForm(): void {
    this.form = new FormControl(null);
  }
}
