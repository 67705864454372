<!-- Notifications toggle -->
<button #notificationsOrigin mat-icon-button (click)="openPanel()">
  @if (store.mostRecentUnread(); as unreadCount) {
    <span
      class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
      <span
        class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium">
      </span>
    </span>
  }

  <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>
  <div
    class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">
    <!-- Header -->
    <div
      class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
      <div class="sm:hidden -ml-1 mr-3">
        <button mat-icon-button (click)="closePanel()">
          <mat-icon
            class="icon-size-5 text-current"
            [svgIcon]="'heroicons_solid:x'"></mat-icon>
        </button>
      </div>
      <div class="text-lg font-medium leading-10">Notifications</div>
      <div class="ml-auto">
        <button
          mat-icon-button
          [matTooltip]="'See inbox'"
          (click)="closePanel()"
          routerLink="/inbox">
          <mat-icon
            class="icon-size-5 text-current"
            [svgIcon]="'heroicons_solid:inbox-arrow-down'" />
        </button>

        <button
          mat-icon-button
          [matTooltip]="'Mark all as read'"
          (click)="markAllAsRead()">
          <mat-icon
            class="icon-size-5 text-current"
            [svgIcon]="'mat_solid:mark_email_read'"></mat-icon>
        </button>
      </div>
    </div>

    <!-- Content -->
    <div
      class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
      <!-- Notifications -->
      @for (notification of store.mostRecent(); track $index) {
        <div
          [class.bg-slate-50]="!notification.read"
          class="flex gap-4 p-4 group hover:bg-slate-100 dark:hover:bg-black cursor-pointer"
          (click)="openNotification(notification)">
          <div class="flex flex-auto">
            <div class="flex flex-col flex-auto">
              @if (notification.title) {
                <div
                  class="font-semibold line-clamp-1"
                  [innerHTML]="notification.title"></div>
              }

              @if (notification.message) {
                <div
                  class="line-clamp-2"
                  [innerHTML]="notification.message"></div>
              }

              <div class="mt-2 text-sm leading-none text-secondary text-right">
                {{ notification.localDate | date: 'MMM dd yyyy, HH:mm' }}
              </div>
            </div>
          </div>

          <div class="relative flex flex-col justify-center">
            <!-- Indicator -->
            <button
              mat-icon-button
              matTooltip="{{
                (notification.read
                  ? 'notifications.mark_as_unread'
                  : 'notifications.mark_as_read'
                ) | transloco
              }}"
              class="w-6 h-6 min-h-6"
              (click)="toggleRead(notification); $event.stopPropagation()">
              <span
                [ngClass]="{
                  'group-hover:bg-gray-400 group:hover:dark:bg-gray-500':
                    notification.read,
                  'bg-primary': !notification.read
                }"
                class="w-2 h-2 rounded-full"></span>
            </button>
          </div>
        </div>
      }

      @if (!store.notifications().length) {
        <div
          class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
          <div
            class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
            <mat-icon
              class="text-primary-500-700"
              [svgIcon]="'heroicons_outline:bell'"></mat-icon>
          </div>
          <div class="mt-5 text-2xl font-semibold tracking-tight">
            No notifications
          </div>
          <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
            When you have notifications, they will appear here.
          </div>
        </div>
      }
    </div>
  </div>
</ng-template>
