import { Injectable, inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { CategoryType, Field, FieldResponse } from '@fieldos/models';
import { FieldPermissions } from '@fieldos/models/fields/field-permissions';
import { AuthStore } from '../@store';

@Injectable({
  providedIn: 'root',
})
export class CategoryTypeFieldsDataService {
  private readonly _http = inject(HttpService);
  private readonly _auth = inject(AuthStore);

  save(
    categoryType: CategoryType,
    categoryTypeId: number,
    fields: Field[]
  ): Promise<void> {
    return this._http.put(
      `${this._getUrl(categoryType)}/${categoryTypeId}`,
      fields
    );
  }

  async fetchAllFields(
    categoryType: CategoryType
  ): Promise<Record<number, Field[]>> {
    const fields = await this._http.get<Record<number, FieldResponse>>(
      this._getUrl(categoryType)
    );

    return Object.keys(fields).reduce(
      (acc, key) => ({
        ...acc,
        [key]: fields[parseInt(key)].fields,
      }),
      {}
    );
  }

  fetchFields(
    categoryType: CategoryType,
    categoryTypeId: number
  ): Promise<FieldResponse> {
    return this._http.get<FieldResponse>(
      `${this._getUrl(categoryType)}/${categoryTypeId}`
    );
  }

  async fetchFieldPermissions(
    categoryType: CategoryType
  ): Promise<FieldPermissions[]> {
    const roleId = this._auth.roleId() as number;

    return this._http.get<FieldPermissions[]>(
      `/types-fields-permissions/${categoryType}?roleId=${roleId}`
    );
  }

  private _getUrl(type: CategoryType): string {
    switch (type) {
      case 'locations':
        return '/location-types-fields';
      case 'assets':
        return '/asset-types-fields';
      case 'users':
        return '/user-types-fields';
      case 'customers':
        return '/customer-types-fields';
      case 'contractors':
        return '/contractor-types-fields';
    }
  }
}
