import { SectionProperties } from './section-properties';
import { SectionLabelInfoProperties } from './simple-section-properties';

export interface DatepickerSectionCommonProperties {
  showTimepicker: boolean;
}

export type DatepickerSectionProperties = SectionProperties<
  SectionLabelInfoProperties,
  DatepickerSectionCommonProperties
>;
