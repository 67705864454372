import { ExceptionTargetType } from '@fieldos/business';
import { DomainEntityBase } from './entity-base';
import { FileModel } from './file';

export interface AssignedException {
  comments: string;
  taxonomyId: number;
  taxonomyLabel: string;
  media: FileModel[];
  targetId: number;
}

export interface ReasonCode extends DomainEntityBase {
  properties: ReasonCodeProperties;
}

interface ReasonCodeProperties {
  code: string;
  requireValidation: boolean;
  action: ReasonCodeAction;
  actionValue?: {
    statusGuid: string;
  };
  type: ExceptionTargetType;
}

export type ReasonCodeAction =
  | 'changedestinationlocationinwo'
  | 'changeseal'
  | 'changestatusofwo'
  | 'changeDPUandUnlockDPUSection';
