import { CdkScrollable, CdkScrollableModule } from '@angular/cdk/scrolling';
import { DatePipe, NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogService } from '@fieldos/core';
import { ChatMessage, FileModel } from '@fieldos/models';
import { AuthStore } from '@fieldos/store/index';
import { textColorClasses } from '@fieldos/utils';
import { TranslocoModule } from '@ngneat/transloco';
import { ChatMessageItem, SendChatMessageModel } from '../chat-window.models';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  standalone: true,
  imports: [
    NgClass,
    DatePipe,
    TranslocoModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatIconModule,
    CdkScrollableModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class ChatMessagesComponent implements AfterViewInit {
  constructor() {
    effect(
      () => {
        const userId = this.userId();
        const messages = this.chatMessages().map((message) => ({
          ...message,
          isMine: message.userId === userId,
        }));

        this.messages.set(messages);
      },
      { allowSignalWrites: true }
    );
  }

  public readonly chatMessages = input.required<ChatMessage[]>();
  public readonly loading = input<boolean>(false);
  public readonly canAddFiles = input<boolean>(true);

  public readonly sendMessage = output<SendChatMessageModel>();
  public readonly fileAdded = output<File>();

  protected readonly userId = inject(AuthStore).userId;

  protected readonly messages = signal<ChatMessageItem[]>([]);

  protected readonly newMessageCtrl = new FormControl('', {
    nonNullable: true,
  });

  protected get userColor(): Record<number, string> {
    const users: number[] = [];

    this.messages().forEach((message) => {
      if (users.includes(message.userId)) {
        return;
      }

      users.push(message.userId);
    });

    return users.reduce(
      (acc, userId, index) => ({
        ...acc,
        [userId]: textColorClasses[index % textColorClasses.length],
      }),
      {}
    );
  }

  private readonly _dialog = inject(DialogService);
  private readonly _scroll = viewChild(CdkScrollable);

  ngAfterViewInit(): void {
    if (this._scroll) {
      this._scroll()?.scrollTo({ bottom: 0 });
    }
  }

  protected onFileClick(file: FileModel): void {
    this._dialog.openFileGallery([file], 0, { removeDisabled: true });
  }

  protected onSendMessage(): void {
    if (!this.newMessageCtrl.value) {
      return;
    }

    this.sendMessage.emit({
      files: [],
      message: this.newMessageCtrl.value,
    });

    this.newMessageCtrl.setValue('');
  }
}
