<div class="flex flex-col h-full overflow-hidden">
  <table
    mat-table
    class="mat-elevation-z0 overflow-auto flex-grow"
    [dataSource]="currentPageData()">
    @for (column of columns(); track $index) {
      <ng-container [matColumnDef]="column.propertyName">
        <th *matHeaderCellDef mat-header-cell>
          {{ column.headerLabel }}
        </th>
        <td
          *matCellDef="let element"
          class="text-wrap"
          [style]="element[column.propertyName].style">
          @if (column.customRenderer) {
            <ng-container
              *ngComponentOutlet="
                column.customRenderer;
                inputs: { value: element[column.propertyName] }
              " />
          } @else {
            {{ element[column.propertyName] }}
          }
        </td>
      </ng-container>
    }

    <tr *matHeaderRowDef="displayedColumns(); sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns()" mat-row></tr>
  </table>

  @if (usePaginator()) {
    <mat-paginator
      [length]="data().length"
      [pageIndex]="currentPageIndex()"
      [pageSize]="pageSize()"
      (page)="onPageChange($event)" />
  }
</div>
