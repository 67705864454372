import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { passwordValidator } from '@fieldos/utils';

export const createPasswordForm = () =>
  new FormGroup(
    {
      password: new FormControl('', {
        nonNullable: true,
        validators: [Validators.minLength(10), passwordFormValidator],
      }),
      confirmPassword: new FormControl('', { nonNullable: true }),
    },
    { validators: passwordValidator('password', 'confirmPassword') }
  );

const PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const passwordFormValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (control.value && !PASSWORD_REGEX.test(control.value)) {
    return { password: true };
  }

  return null;
};
