@if (dpu(); as dpu) {
  <div class="flex flex-col">
    <div class="flex justify-between mb-2">
      <div class="flex gap-4 items-center">
        <div class="p-2 border-primary border-2 text-primary">
          {{ dpuStatus() }}
        </div>

        @if (dpu.dpuReasonCodeLabel) {
          <div>
            {{ 'common.blocked' | transloco }}:<span class="font-bold">
              {{ dpu.dpuReasonCodeLabel }}
            </span>
          </div>
        }
      </div>

      <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        @if (canEdit()) {
          <button mat-menu-item (click)="remove.emit()">
            {{ 'work_orders.sections.dpu.remove_from_work_order' | transloco }}
          </button>
        }

        <button
          *appHasPermission="['workorder.dpusection.reason.code.add']"
          mat-menu-item
          (click)="openAddReasonCodeDialog()">
          {{ 'work_orders.routes.assign_reason_code' | transloco }}
        </button>
      </mat-menu>
    </div>

    <div class="text-lg font-bold">
      {{ 'common.short_number' | transloco }} {{ dpu.number }}
    </div>
    <div>
      {{ 'work_orders.dpus.weight' | transloco }} {{ dpu.weight || 30 }}
    </div>
    <div>
      {{ 'work_orders.dpus.packaging' | transloco }} {{ dpuPackagingName() }}
    </div>
  </div>
}
