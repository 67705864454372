import { UserProfile } from '@fieldos/models';
import { environment } from 'environments/environment';
import { io } from 'socket.io-client';

export const createSocketConnection = (user: UserProfile) =>
  io(environment.socketUrl, {
    path: '/socket.io',
    query: {
      userid: user.userId,
      tid: user.tid,
    },
  }).connect();
