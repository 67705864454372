<div #menuContainer>
  <div
    class="relative flex flex-col py-2 px-4 bg-white shadow-lg border rounded-lg prose">
    <h6 class="m-0 font-medium text-center">{{ pointTitle }}</h6>
    @if (!loading) {
      <div class="flex justify-center w-full gap-2">
        @if (isEditing) {
          <button
            mat-icon-button
            (click)="remove.emit()"
            appClickStopPropagation
            color="warn">
            <mat-icon>delete</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="reposition.emit($event)"
            appClickStopPropagation
            color="primary">
            <mat-icon>open_with</mat-icon>
          </button>
        } @else {
          <button
            mat-icon-button
            [class.opacity-0]="loading"
            [disabled]="loading"
            (click)="cancel.emit()"
            appClickStopPropagation
            color="warn">
            <mat-icon>clear</mat-icon>
          </button>
          <button
            mat-icon-button
            [class.opacity-0]="loading"
            [disabled]="loading"
            (click)="confirm.emit()"
            appClickStopPropagation
            color="success">
            <mat-icon>check</mat-icon>
          </button>
        }
      </div>
    } @else {
      <div class="flex items-center justify-center">
        <mat-spinner color="primary" diameter="24" />
      </div>
    }
  </div>
</div>
