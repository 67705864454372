<app-header-content-layout>
  <ng-container toolbar>
    <div class="flex-grow"></div>

    <ng-container toolbar>
      <div class="flex-grow"></div>
      <span class="text-base font-medium">
        {{ 'common.type' | transloco }}
      </span>
      <app-select-field
        class="w-64"
        [options]="categoryTypes()"
        [value]="store.selectedCategoryType()"
        (valueSelected)="store.setSelectedType($event)"
        placeholder="{{
          'fields_designer.' + categoryType() + '.select_label' | transloco
        }}" />
    </ng-container>

    <ng-content select="[extraButtons]" />

    @if (hasAddPermission()) {
      <button mat-flat-button (click)="onAddClick()" color="primary">
        {{ newEntityLabel() }}
      </button>
    }
  </ng-container>
  <div class="m-4 prose flex-grow max-w-full bg-card" fuseScrollbar="">
    @if (store.selectedCategoryType()) {
      <app-data-grid
        gridClass="h-full"
        class="block w-full h-full"
        [columns]="store.columns()"
        [data]="store.currentEntities()"
        [loading]="store.loading()"
        defaultSizeToFit />
    } @else {
      <div class="flex justify-center items-center h-full">
        {{
          'fields_designer.' + categoryType() + '.select_category_type'
            | transloco
        }}
      </div>
    }
  </div>
</app-header-content-layout>
