@if (loading()) {
  <div class="flex justify-center items-center h-full">
    <mat-spinner color="primary" diameter="32" />
  </div>
} @else {
  <ag-grid-angular
    (gridReady)="onGridReady($event)"
    [gridOptions]="gridOptions()"
    class="data-grid ag-theme-alpine {{ gridClass() }}"
    [allowContextMenuWithControlKey]="true"
    [animateRows]="false"
    [columnDefs]="columnDefs()"
    [components]="components()"
    [context]="context()"
    [debounceVerticalScrollbar]="true"
    [defaultColDef]="defaultColDef()"
    [enableRangeSelection]="enableRangeSelection()"
    [getContextMenuItems]="getContextMenuItems"
    [isRowSelectable]="isRowSelectable"
    [pagination]="usePagination()"
    [rowBuffer]="30"
    [rowBuffer]="100"
    [rowData]="data()"
    [rowSelection]="rowSelection()"
    [suppressContextMenu]="false"
    [suppressRowClickSelection]="true"
    [suppressScrollOnNewData]="true"
    (cellValueChanged)="cellValueChanged.emit($event)"
    (firstDataRendered)="onFirstDataRendered()"
    (rangeSelectionChanged)="onRangeSelectionChange($event)"
    (rowClicked)="rowClick.emit($event)"
    (rowDataUpdated)="onRowDataUpdated()"
    (rowDoubleClicked)="onRowDoubleClick($event)"
    (selectionChanged)="onSelectionChange($event)"
    suppressCellFocus />
}
