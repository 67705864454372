import { Route } from '@angular/router';

export const locationRoutes: Route[] = [
  {
    path: 'locations',
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./locations/locations.component').then(
            (m) => m.LocationsComponent
          ),
        data: {
          title: 'locations.list',
        },
      },
      {
        path: 'new',
        pathMatch: 'full',
        loadComponent: () =>
          import('./location-form/location-form.component').then(
            (m) => m.LocationFormComponent
          ),
        data: {
          title: 'locations.add_new',
        },
      },
      {
        path: 'new/:typeId',
        pathMatch: 'full',
        loadComponent: () =>
          import('./location-form/location-form.component').then(
            (m) => m.LocationFormComponent
          ),
        data: {
          title: 'locations.add_new',
        },
      },
      {
        path: ':locationId/edit',
        pathMatch: 'full',
        loadComponent: () =>
          import('./location-form/location-form.component').then(
            (m) => m.LocationFormComponent
          ),
        data: {
          title: 'locations.edit',
        },
      },
    ],
  },
];
