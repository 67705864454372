import { Component, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { SendChatMessageModel } from '@fieldos/components';
import { AuthStore } from '@fieldos/store/index';
import { TranslocoModule } from '@ngneat/transloco';
import { ChatMessagesComponent } from 'app/@components/chat-window/chat-messages/chat-messages.component';
import { ChatMessagesDialogData } from './chat-messages-dialog.models';

@Component({
  selector: 'app-chat-messages-dialog',
  templateUrl: './chat-messages-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ChatMessagesComponent,
    TranslocoModule,
  ],
})
export class ChatMessagesDialogComponent {
  constructor() {
    this.messages.set(this.data.messages);
  }

  protected readonly messages = signal<ChatMessagesDialogData['messages']>([]);

  protected readonly data = inject<ChatMessagesDialogData>(MAT_DIALOG_DATA);

  private readonly _user = inject(AuthStore).profile;

  protected onSendMessage(message: SendChatMessageModel): void {
    this.messages.set([
      ...this.messages(),
      {
        id: 0,
        date: new Date().toLocaleDateString(),
        dateDiff: '0s',
        message: message.message,
        files: message.files,
        userAvatar: this._user()?.avatar || '',
        userId: this._user()?.userId || 0,
        userName: this._user()?.name || '',
      },
    ]);
  }
}
