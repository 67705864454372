import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DatepickerSection } from '@fieldos/models';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SectionBasedEntityListAGGridContext } from '../../section-columns.models';

@Component({
  selector: 'app-date-cell-renderer',
  templateUrl: './date-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DatePipe],
})
export class DateCellRendererComponent implements ICellRendererAngularComp {
  protected value?: Date;
  protected showTime = false;

  agInit(
    params: ICellRendererParams<
      string,
      string,
      SectionBasedEntityListAGGridContext
    >
  ): void {
    this._setValue(params);
  }

  refresh(
    params: ICellRendererParams<
      string,
      string,
      SectionBasedEntityListAGGridContext
    >
  ): boolean {
    this._setValue(params);
    return true;
  }

  private _setValue(
    params: ICellRendererParams<
      string,
      string,
      SectionBasedEntityListAGGridContext
    >
  ): void {
    if (params.value) {
      this.value = new Date(params.value as string);

      const section = params.context.sections[
        params.colDef?.field as string
      ] as DatepickerSection;

      if (section) {
        this.showTime = section.properties?.showTimepicker || false;
      }
    }
  }
}
