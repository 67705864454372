import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TableEvent } from '../table.models';

@Injectable()
export class TableService {
  private readonly _events$ = new Subject<TableEvent>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly events$ = this._events$.asObservable();

  emitEvent<T = unknown>(event: string, data: T) {
    this._events$.next({ id: event, data });
  }
}
