import { ThemePalette } from '@angular/material/core';
import { DomainEntityBase } from '@fieldos/models';
import { ICellRendererParams } from 'ag-grid-community';

export interface GridActionButton<T = DomainEntityBase> {
  id: string;
  icon: string | ((data: T) => string);
  color?: ThemePalette | ((data: T) => ThemePalette);
  tooltip?: string;
}

export interface ActionsCellRendererParams<T = DomainEntityBase>
  extends ICellRendererParams<T> {
  actions: GridActionButton<T>[];
  visibleActions?: (data: T) => string[];
}
