import { inject } from '@angular/core';
import { SectionsDataService } from '@fieldos/data-services';
import { Section, SectionsResponse } from '@fieldos/models';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { WorkspaceStore } from './workspace.store';

interface SectionStoreState {
  sectionConfigs: Record<number, SectionsResponse>;
}

export const SectionsStore = signalStore(
  { providedIn: 'root' },
  withState<SectionStoreState>({
    sectionConfigs: {},
  }),
  withMethods(
    (
      store,
      dataService = inject(SectionsDataService),
      workspaceStore = inject(WorkspaceStore)
    ) => ({
      async fetchForScope(scopeId: number, force = false): Promise<void> {
        if (!scopeId) {
          return;
        }

        if (!force && store.sectionConfigs()[scopeId]) {
          return;
        }

        const response = await dataService.fetchSections(scopeId, 'workorders');

        patchState(store, {
          sectionConfigs: {
            ...store.sectionConfigs(),
            [scopeId]: response,
          },
        });
      },
      selectForScope(scopeId: number): Section[] | undefined {
        return store.sectionConfigs()[scopeId]?.sections;
      },
      selectForCurrentScope(): Section[] | undefined {
        return store.sectionConfigs()[workspaceStore.selectedWorkspaceScopeId()]
          ?.sections;
      },
      removeAll(): void {
        patchState(store, {
          sectionConfigs: {},
        });
      },
    })
  )
);
