import { Injectable } from '@angular/core';
import { DomainEntityBase } from '@fieldos/models';
import { createGetRequestFn } from '@fieldos/utils';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Observable, switchMap, tap } from 'rxjs';

export type EntityAutocompleteEntityType =
  | 'maps'
  | 'locations'
  | 'assets'
  | 'roles';

interface EntityAutocompleteState<T> {
  entities: T[];
  loading: boolean;
  entityType?: EntityAutocompleteEntityType;
}

@Injectable()
export class EntityAutocompleteSelectStore<
  T extends DomainEntityBase,
> extends ComponentStore<EntityAutocompleteState<T>> {
  constructor() {
    super({
      entities: [],
      loading: false,
    });
  }

  public readonly entities$ = this.select((state) => state.entities);
  public readonly loading$ = this.select((state) => state.loading);

  public readonly fetchEntities = this.effect(
    (type$: Observable<EntityAutocompleteEntityType>) =>
      type$.pipe(
        tap((entityType) =>
          this.patchState({
            entityType,
            loading: true,
          })
        ),
        switchMap((type) =>
          this._fetchEntities<T[]>(type).pipe(
            tapResponse(
              (entities) =>
                this.patchState({
                  entities,
                  loading: false,
                }),
              () =>
                this.patchState({
                  loading: false,
                })
            )
          )
        )
      )
  );

  private _fetchEntities = createGetRequestFn();
}
