import { DomainEntityBase } from '../entity-base';

interface FieldValue<T = unknown> {
  id: string;
  value: unknown;
}

export interface FieldBasedEntity<T = unknown> extends DomainEntityBase {
  value: FieldValue<T>[];
  typeId: number;
}
