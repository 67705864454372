import { NgComponentOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Signal,
  Type,
  computed,
  inject,
  input,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { LabelWithInfoComponent } from '@fieldos/components';
import { Field, FieldSubType } from '@fieldos/models';
import { createFieldInfo, createFieldLabel } from '@fieldos/utils';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FieldFormBaseComponent } from '../field-form-base.component';
import { FieldsFormStore } from '../fields-form.store';
import {
  getComponentInputs,
  loadComponentForFieldSubType,
} from './field-form.helper';

@Component({
  selector: 'app-field-form',
  templateUrl: './field-form.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgComponentOutlet,
    MatCardModule,
    LabelWithInfoComponent,
    TranslocoModule,
  ],
})
export class FieldFormComponent implements OnInit {
  public readonly fieldId = input.required<string>();
  public readonly fieldSubType = input.required<FieldSubType>();

  protected component = signal<Type<FieldFormBaseComponent<Field>> | undefined>(
    undefined
  );

  protected readonly store = inject(FieldsFormStore);

  protected readonly inputs = signal<Record<string, any>>({});

  protected readonly label = computed(() =>
    createFieldLabel(
      this.store.selectFieldById(this.fieldId())(),
      this._activeLang()
    )
  );

  protected readonly info = computed(() =>
    createFieldInfo(
      this.store.selectFieldById(this.fieldId())(),
      this._activeLang()
    )
  );

  private readonly _activeLang = toSignal(
    inject(TranslocoService).langChanges$
  ) as Signal<string>;

  async ngOnInit(): Promise<void> {
    const component = await loadComponentForFieldSubType(this.fieldSubType());
    this.inputs.set(getComponentInputs(this.fieldSubType(), this.fieldId()));

    this.component.set(component);
  }
}
