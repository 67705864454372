import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  Type,
  computed,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { Section } from '@fieldos/models';

import { NgComponentOutlet } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { createFieldInfo, createFieldLabel } from '@fieldos/utils';
import { TranslocoService } from '@ngneat/transloco';
import { LabelWithInfoComponent } from '../../../../@components/label-with-info';
import { SectionsFormStore } from '../sections-form.store';
import { SECTION_COMPONENT_MAP } from './section-component-map.provider';
import { SectionsFormContainerSectionComponent } from './sections-form-container-section/sections-form-container-section.component';
import { SectionsFormSectionBaseComponent } from './sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-section',
  templateUrl: './sections-form-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LabelWithInfoComponent, MatCardModule, NgComponentOutlet],
})
export class SectionsFormSectionComponent<V> {
  constructor() {
    effect(
      () => {
        const section = this._store.sectionsMap()[this.sectionId()];
        if (section) {
          this.component.set(this._getComponent(section));
        }
      },
      { allowSignalWrites: true }
    );
  }

  public readonly sectionId = input.required<string>();

  protected readonly label = computed(() =>
    createFieldLabel(
      this._store.sectionsMap()[this.sectionId()],
      this._activeLang()
    )
  );

  protected readonly info = computed(() =>
    createFieldInfo(
      this._store.sectionsMap()[this.sectionId()],
      this._activeLang()
    )
  );

  protected readonly rights = computed(() => {
    const rights = this._store.rights();

    if (rights && rights[this.sectionId()]) {
      return rights[this.sectionId()];
    }

    return {
      show: false,
      mandatory: false,
      canEdit: false,
    };
  });

  protected readonly component = signal<Type<
    SectionsFormSectionBaseComponent | SectionsFormContainerSectionComponent
  > | null>(null);

  private readonly _store = inject(SectionsFormStore);

  private readonly _activeLang = toSignal(
    inject(TranslocoService).langChanges$
  ) as Signal<string>;

  private readonly _sectionsMap = inject(SECTION_COMPONENT_MAP);
  private _transloco = inject(TranslocoService);

  private _getComponent(
    section: Section
  ): Type<
    SectionsFormSectionBaseComponent | SectionsFormContainerSectionComponent
  > {
    return this._sectionsMap[section.subtype];
  }

  protected get commonProperties(): Section['properties'] {
    const section = this._store.sectionsMap()[this.sectionId()];
    return section.properties;
  }
}
