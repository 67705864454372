<mat-drawer
  class="w-full sm:w-[640px] dark:bg-gray-900"
  [opened]="chatOpen"
  mode="over"
  position="end">
  @if (store.entity()) {
    <app-chat-window
      class="block w-full h-full"
      [chatMessages]="store.messages()"
      [loading]="store.sendingMessage()"
      (closeClick)="chatCloseClick.emit(); chatOpen = false; closeClick.emit()"
      (drawerClose)="chatCloseClick.emit(); chatOpen = false"
      (sendMessage)="store.sendMessage($event)" />
  }
</mat-drawer>

<div
  [class.hidden]="chatOpen"
  class="bg-card border-l flex flex-col w-full h-full">
  <div
    class="h-14 flex-shrink-0 w-full flex justify-between items-center border-b px-4">
    <h3 class="text-on-base font-bold">
      {{
        store.entity()
          ? ('section_based_entity.' + store.entityType() + '.edit' | transloco)
          : ('section_based_entity.' + store.entityType() + '.create'
            | transloco)
      }}
    </h3>

    <div class="flex items-center gap-1">
      @if (!store.isFormValid()) {
        <button mat-icon-button class="pointer-events-none">
          <mat-icon class="text-orange-500" svgIcon="mat_solid:warning" />
        </button>
      }

      @if (store.entityType() === 'workorders' && store.entity()) {
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon svgIcon="mat_solid:more_vert" />
        </button>

        <mat-menu #menu="matMenu">
          <button
            *appHasPermission="['workorder.reason.code.add']"
            mat-menu-item
            (click)="openAddReasonCodeDialog()">
            {{ 'work_orders.routes.assign_reason_code' | transloco }}
          </button>
          <button
            *appHasPermission="['workorder.download.dpu.code']"
            mat-menu-item
            (click)="downloadDPUCodes()">
            {{ 'work_orders.download_dpu_codes' | transloco }}
          </button>

          <button
            *appHasPermission="['workorder.email.to.location.dpu.code']"
            mat-menu-item
            (click)="sendDPUCodesEmail()">
            {{ 'work_orders.send_email_with_dpus' | transloco }}
          </button>
        </mat-menu>
      }

      @if (canPin) {
        <button mat-icon-button (click)="pinClick.emit()">
          <mat-icon
            [class.rotate-45]="mode === 'side'"
            svgIcon="mat_solid:push_pin" />
        </button>
      }

      @if (store.entity()) {
        <button mat-icon-button (click)="chatOpen = true">
          <mat-icon svgIcon="mat_solid:forum" />
        </button>
      }

      <button
        mat-icon-button
        matTooltip="{{ 'common.escape_to_close' | transloco }}"
        (click)="closeClick.emit()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>

  <div
    class="flex-grow min-h-0 flex-shrink overflow-auto bg-slate-100"
    cdkScrollable>
    <app-sections-form />
  </div>

  <div class="flex justify-end p-4 border-t">
    <button mat-button (click)="closeClick.emit()">
      {{ 'common.close' | transloco }}
    </button>

    @if (!store.entity()) {
      <button
        mat-flat-button
        class="relative"
        [disabled]="!store.isFormValid()"
        (click)="saveClick.emit()"
        color="primary">
        <div class="w-full flex justify-center absolute -top-[4px]">
          <mat-spinner [class.opacity-0]="!store.isCreating()" diameter="24" />
        </div>
        <span [class.opacity-0]="store.isCreating()">
          {{
            'section_based_entity.' + store.entityType() + '.create' | transloco
          }}
        </span>
      </button>
    }
  </div>
</div>
