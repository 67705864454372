import {
  ChangeDetectorRef,
  Directive,
  TemplateRef,
  ViewContainerRef,
  effect,
  inject,
  input,
} from '@angular/core';
import { Claim } from '@fieldos/models';
import { ClaimsStore } from '../@store';

@Directive({
  selector: '[appHasPermission]',
  standalone: true,
})
export class HasPermissionDirective {
  constructor() {
    effect(() => {
      const claims = this._claimsStore.claims();
      const requiredPermissions = this.appHasPermission();

      this._viewContainerRef.clear();

      const shouldShow =
        (requiredPermissions &&
          requiredPermissions.some((e) => claims.includes(e))) ||
        !requiredPermissions;

      if (shouldShow) {
        this._viewContainerRef.createEmbeddedView(this._templateRef);
        this._detector.detectChanges();
      }
    });
  }

  public readonly appHasPermission = input.required<Claim[] | undefined>();

  public readonly _claimsStore = inject(ClaimsStore);

  private readonly _templateRef = inject(TemplateRef<void>);
  private readonly _viewContainerRef = inject(ViewContainerRef);
  private readonly _detector = inject(ChangeDetectorRef);
}
