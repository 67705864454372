import { effect, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';
import { patchState, signalStore, withHooks, withMethods } from '@ngrx/signals';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import { AuthStore } from '..';
import { withDataSourceState } from './data-source-feature.store';
import { StandardDataSourceDataService } from './standard-data-source.service';

export const CustomDataSourceStore = signalStore(
  { providedIn: 'root' },
  withDataSourceState<number>(),
  withMethods(
    (store, _dataSourceService = inject(StandardDataSourceDataService)) => ({
      async fetchDataSource(
        dataSourceId: number,
        params?: Params
      ): Promise<void> {
        try {
          const data = await _dataSourceService.fetch(dataSourceId, params);
          patchState(store, (state) => ({
            ...state,
            [dataSourceId]: {
              loaded: true,
              isStale: false,
              data,
            },
          }));
        } catch (error) {
          console.error(error);
        }
      },
      async fetchAllDataSources(): Promise<void> {
        try {
          const dataSources =
            await _dataSourceService.fetchCustomDataSourcesWithOptions();
          patchState(store, (state) => ({
            ...state,
            dataSources,
            cache: dataSources.reduce(
              (acc, curr, index) => ({
                ...acc,
                [dataSources[index].id]: {
                  loaded: true,
                  isStale: false,
                  data: (curr.data || []).reduce(
                    (acc, current) => ({
                      ...acc,
                      [current.id]: current,
                    }),
                    {}
                  ),
                },
              }),
              {}
            ),
          }));
        } catch (error) {
          console.error(error);
        }
      },
    })
  ),
  withHooks({
    onInit(store, authStore = inject(AuthStore)) {
      effect(
        () => {
          const language = authStore.language();

          if (language) {
            store.clearCache();
            store.fetchAllDataSources();
          }
        },
        { allowSignalWrites: true }
      );

      toObservable(authStore.isAuthenticated)
        .pipe(
          distinctUntilChanged(),
          filter((isAuthenticated) => isAuthenticated === true),
          tap(() => store.fetchAllDataSources()),
          takeUntilDestroyed()
        )
        .subscribe();
    },
  })
);
