import { FileModel } from '../file';
import { RightsStatusType } from '../sections';
import { ChecklistTaskType } from './checklist-task-type';

export type ChecklistTaskProperties =
  | ChecklistTaskPropertiesBase
  | ChecklistTaskOptionsProperties;

export interface ChecklistTaskPropertiesBase {
  mandatory: boolean;
  defaultValue: ChecklistTaskDefaultValue;
}

export interface ChecklistTaskOptionsProperties {
  options: string[];
}

export type ChecklistTaskDefaultValue = number | boolean | string;

export interface ChecklistTask<
  T extends ChecklistTaskPropertiesBase = ChecklistTaskPropertiesBase,
> {
  id: string;
  name: string;
  description: string;
  type: ChecklistTaskType;
  mandatory: boolean;
  defaultValue: ChecklistTaskDefaultValue;
  imagesRights: RightsStatusType;
  filesRights: RightsStatusType;
  commentsRights: RightsStatusType;
  files: FileModel[];
  link: string;
  notes: string;
  properties?: T;
}

export interface ChecklistSection {
  id: string;
  name: string;
  description: string;
  entries: ChecklistTask[];
}
