import { SectionBasedEntity } from '../sections';

export type WorkOrder = SectionBasedEntity;

export interface WorkOrderDuration {
  activityType: WorkOrderActivityType;
  duration: number;
}

export enum WorkOrderActivityType {
  General = 'General Activity',
  Inspection = 'Inspection Activity',
  Labor = 'Labor Activity',
  Other = 'Other Activity',
  Travel = 'Travel Activity',
  Vendor = 'Vendor Activity',
}
