import { FormControl } from '@angular/forms';
import { DomainEntityId } from './entity-base';

export interface DPU extends DomainEntityId<number> {
  number: string | null;
  statusId: number;
  weight: number;
  packagingId: number;
  woId: number;

  quantity: string;
  dpuReasonCodeLabel: string; // null sau string in limba userului. Daca e null inseamna ca DPU nu e blocat
  dpuExceptionId: number; // number. taxonomy_id luat din tabela dpu
  processed: null;
  position: number; // number.
}

export type DPUCreateModel = Pick<DPU, 'number' | 'weight'>;

export type CreateDPUFormType = {
  number: FormControl<string | null>;
  weight: FormControl<number>;
};
