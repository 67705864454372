import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideAlertStore, provideAppRouterStore } from './stores';

export const provideAppStore = (): Array<Provider | EnvironmentProviders> => [
  provideEffects(),
  provideStore(),

  provideAlertStore(),
  provideAppRouterStore(),
  provideStoreDevtools({
    maxAge: 25,
    logOnly: false,
    autoPause: true,
    trace: true,
    features: {
      pause: false,
      lock: true,
      persist: true,
    },
    connectInZone: true,
  }),
];
