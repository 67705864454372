import { Injectable, computed, inject, signal } from '@angular/core';
import { LocationsDataService } from '@fieldos/data-services';
import { LocationModel } from '@fieldos/models';

@Injectable({
  providedIn: 'root',
})
export class LocationsFacade {
  public readonly locations = computed(() => this._locations());
  public readonly loading = computed(() => this._loading());

  private readonly _locations = signal<LocationModel[]>([]);
  private readonly _loading = signal(false);
  private readonly _service = inject(LocationsDataService);

  async fetch(query?: Record<string, string>): Promise<void> {
    this._loading.set(true);

    try {
      const locations = await this._service.fetchAll(query);
      this._locations.set(locations);
    } catch (error) {
      this._loading.set(false);
    }
  }
}
