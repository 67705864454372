import { Pipe, PipeTransform } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { isFunction } from 'lodash';
import { GridActionButton } from './actions-cell-renderer.models';

@Pipe({
  name: 'actionsCellRendererColor',
  standalone: true,
})
export class ActionsCellRendererColorPipe<T> implements PipeTransform {
  transform(value: GridActionButton<T>, data: T): ThemePalette {
    if (!value.color) {
      return 'primary';
    }

    if (isFunction(value.color)) {
      return value.color(data);
    }

    return value.color;
  }
}
