<div class="p-4 flex flex-col gap-2 w-full" [formGroup]="form">
  <app-datetime-picker
    formControlName="min"
    class="w-full ag-custom-component-popup"
    [useTime]="false"
    label="{{ 'common.min_date' | transloco }}" />
  <app-datetime-picker
    formControlName="max"
    class="w-full ag-custom-component-popup"
    [useTime]="false"
    label="{{ 'common.max_date' | transloco }}" />
</div>
