import { Field, Section, SectionLabelInfoProperties } from '@fieldos/models';

export const createFieldLabel = (
  field: Field | Section | undefined,
  language: string
) => {
  if (!field) {
    return '';
  }

  if (field.translatableProperties[language]) {
    return field.translatableProperties[language].label || '';
  }

  const foundLanguage = Object.keys(field.translatableProperties)[0];

  if (!foundLanguage) {
    return '';
  }

  return field.translatableProperties[foundLanguage].label;
};

export const getTranslatableProperties = (
  field: Field | Section,
  language: string
): any => {
  if (field.translatableProperties[language]) {
    return field.translatableProperties[language];
  }

  const foundLanguage = Object.keys(field.translatableProperties)[0];

  if (!foundLanguage) {
    return '';
  }

  return field.translatableProperties[foundLanguage];
};

export const createFieldInfo = (
  field: Field | Section | undefined,
  language: string
) => {
  if (!field) {
    return '';
  }

  if (field.translatableProperties[language]) {
    return field.translatableProperties[language].info || '';
  }

  const foundLanguage = Object.keys(field.translatableProperties)[0];

  if (!foundLanguage) {
    return '';
  }

  return field.translatableProperties[foundLanguage].info || '';
};

export const getTranslatablePropertyName = (
  translatableProperties: Record<string, SectionLabelInfoProperties>,
  language: string
): string => {
  if (translatableProperties[language]) {
    return translatableProperties[language].label;
  }

  const foundLanguage = Object.keys(translatableProperties)[0];

  if (!foundLanguage) {
    return '';
  }

  return translatableProperties[foundLanguage].label;
};
