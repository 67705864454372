@if (store.isEditing() && section().data) {
  <mat-checkbox
    class="pointer-events-none"
    [checked]="!!form.value"
    color="primary">
    {{ section().data.name }}
  </mat-checkbox>
}

@if (!store.entity()?.id) {
  {{
    'work_orders.sections.confirmlocation.create_work_order.placeholder'
      | transloco
  }}
}

@if (!section().data && store.entity()?.id) {
  {{ 'work_orders.sections.confirmlocation.not_available' | transloco }}
}
