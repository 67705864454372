// the tslints are disabled because we enforce arrow functions in favor of classic functions.
// however, in this case we need the this context to be preserved to the class
// the decorator is used on.

/* eslint-disable */
export function AutoUnsubscribe(blackList: string[] = []) {
  // tslint:disable-next-line
  return function (constructor: any) {
    const original = constructor.prototype.ngOnDestroy;

    // tslint:disable-next-line
    constructor.prototype.ngOnDestroy = function () {
      for (const prop of Object.keys(this)) {
        const property = this[prop];
        if (!blackList.includes(prop)) {
          if (property && typeof property.unsubscribe === 'function') {
            property.unsubscribe();
          }
        }
      }
      original && typeof original === 'function' && original.apply(this, arguments);
    };
  };
}
/* eslint-enable */
