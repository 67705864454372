import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FileModel } from '@fieldos/models';
import { FileSizePipe } from '@fieldos/pipes';
import { saveAs } from 'file-saver';
import { FileUploaderIsImagePipe } from '../file-uploader/file-uploader-is-image.pipe';
import { FileGalleryDialogData } from './file-gallery.models';

@Component({
  selector: 'app-file-gallery',
  templateUrl: './file-gallery.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    FileSizePipe,
    FileUploaderIsImagePipe,
    NgTemplateOutlet,
  ],
  styles: [
    `
      :host {
        display: block;
        height: 100vh;
        width: 100vw;
      }
    `,
  ],
})
export class FileGalleryComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected readonly data: FileGalleryDialogData,
    private readonly _dialogRef: MatDialogRef<void>
  ) {
    this.previewFileIndex = data.selectedIndex;
  }

  protected previewFileIndex = 0;

  protected get currentPreviewFile(): FileModel | undefined {
    return this.data.files[this.previewFileIndex];
  }

  protected onNextClick(): void {
    const nextIndex = this.previewFileIndex + 1;
    if (nextIndex === this.data.files.length) {
      this.previewFileIndex = 0;
    } else {
      this.previewFileIndex = nextIndex;
    }
  }

  protected onPreviousClick(): void {
    const nextIndex = this.previewFileIndex - 1;

    if (nextIndex === -1) {
      this.previewFileIndex = this.data.files.length - 1;
    } else {
      this.previewFileIndex = nextIndex;
    }
  }

  protected downloadCurrentFile(): void {
    if (this.currentPreviewFile) {
      saveAs(this.currentPreviewFile.url, this.currentPreviewFile.name);
    }
  }

  protected onDelete(): void {
    this.data.onDelete(this.previewFileIndex);

    this.data.files.splice(this.previewFileIndex, 1);

    if (this.data.files.length) {
      this.onPreviousClick();
    } else {
      this._dialogRef.close();
    }
  }
}
