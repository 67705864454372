import { ReportColumnConfig } from './report-filter';
import { ReportType } from './report-type';

export interface ReportColumnsResponse {
  reportType: ReportType;
  columns: ReportColumnConfig[];
}

export interface ReportColumnTranslatableProperties {
  name: string;
  description?: string;
}
