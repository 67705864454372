<div class="flex flex-col">
  <ng-content />
  @if (errors(); as errors) {
    @for (error of errors; track error) {
      <div class="text-sm mt-1 text-red-500 italic leading-4">
        {{ error.message | transloco: error.props }}
      </div>
    }
  }
</div>
