import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FieldError } from './field-wrapper.models';

@Component({
  selector: 'app-field-wrapper',
  templateUrl: './field-wrapper.component.html',
  standalone: true,
  imports: [TranslocoModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldWrapperComponent {
  public readonly errors = input<FieldError[]>();
}
