<div class="flex flex-col items-center text-base">
  <span class="font-bold"> {{ 'exceptions.blocked' | transloco }} </span>

  @if (exceptions().length) {
    <div class="flex w-full">
      <span class="flex-1 font-bold">
        {{ 'exceptions.title' | transloco }}
      </span>

      <span class="flex-1 font-bold">
        {{ 'exceptions.resolve_title' | transloco }}
      </span>
      <span class="flex-1 font-bold text-center">
        {{ 'exceptions.blocking' | transloco }}
      </span>

      <span class="flex-1 font-bold text-center">
        {{ 'common.actions' | transloco }}
      </span>
    </div>
  }

  @for (exception of exceptions(); track $index) {
    <div class="flex w-full py-1 border-b">
      <span class="flex-1 flex items-center leading-4 text-md">
        <button mat-icon-button (click)="onInfoClick(exception)">
          <mat-icon>info_outline</mat-icon>
        </button>

        <div class="text-base">
          {{ exception.label }}
        </div>
      </span>

      <span class="flex-1 flex items-center text-md">
        {{ exception.labelConfirmed }}
      </span>
      <span class="flex-1 flex justify-center items-center text-md">
        {{
          (exception.blocksWorkOrder ? 'common.yes' : 'common.no') | transloco
        }}
      </span>

      <span class="flex-1 flex justify-center items-center">
        @if (exception.blocksWorkOrder) {
          <button mat-icon-button (click)="onActionClick(exception)">
            <mat-icon>published_with_changes</mat-icon>
          </button>
        }
      </span>
    </div>
  }
</div>
