import { Route } from '@angular/router';

export const userTeamRoutes: Route[] = [
  {
    path: 'workforce',
    loadComponent: () =>
      import('./workforce.component').then((m) => m.WorkforceComponent),
    data: {
      title: 'menu.workforce',
    },
    children: [
      {
        path: 'users',
        loadComponent: () =>
          import('./user/users/users.component').then(
            (m) => m.UserListComponent
          ),
      },
      {
        path: 'teams',
        loadComponent: () =>
          import('./team/teams-list/team-list.component').then(
            (m) => m.TeamListComponent
          ),
      },
      { path: '', redirectTo: 'users', pathMatch: 'full' },
    ],
  },
  {
    path: 'workforce/users/:userId/edit',
    loadComponent: () =>
      import('./user/user-form/user-form.component').then(
        (m) => m.UserFormComponent
      ),
    data: {
      title: 'users.edit_user',
    },
  },

  {
    path: 'workforce/users/new/:typeId',
    loadComponent: () =>
      import('./user/user-form/user-form.component').then(
        (m) => m.UserFormComponent
      ),
    data: {
      title: 'users.create_new_user',
    },
  },
  {
    path: 'workforce/users/new',
    loadComponent: () =>
      import('./user/user-form/user-form.component').then(
        (m) => m.UserFormComponent
      ),
    data: {
      title: 'users.create_new_user',
    },
  },
  {
    path: 'workforce/teams/:teamId/edit',
    loadComponent: () =>
      import('./team/team-form/team-form.component').then(
        (m) => m.TeamFormComponent
      ),
    data: {
      title: 'team.edit_team',
    },
  },
];
