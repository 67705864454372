import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Renderer2,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { DurationInputService } from './duration.service';

@Directive({
  selector: '[durationInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DurationInputDirective),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DurationInputDirective),
      multi: true,
    },
  ],
  standalone: true,
})
export class DurationInputDirective implements ControlValueAccessor, Validator {
  constructor(
    private _durationService: DurationInputService,
    private _renderer: Renderer2,
    private _elementRef: ElementRef
  ) {}

  @HostListener('blur', ['$event.target.value'])
  protected blur(value: string): void {
    this.updateValue(value);
    this.onTouchedCallback();
  }

  @HostListener('keyup.enter', ['$event.target.value'])
  protected enter(value: string): void {
    this.updateValue(value);
  }

  validate(_: AbstractControl): ValidationErrors | null {
    const inputValue: string = this._elementRef.nativeElement.value.trim();
    const isParsable = this._durationService.isParseable(inputValue);

    // if has notnull input and its not parsable
    if (!!inputValue && !isParsable) {
      return { pattern: true };
    } else {
      return null;
    }
  }

  /** Writes the display value into the input. ControlValueAccessor */
  writeValue(inputValue: string | number): void {
    const parsedValue = this._durationService.parseDurationString(inputValue);

    let displayValue = '';
    if (inputValue === '0' && parsedValue !== null && !isNaN(parsedValue)) {
      displayValue = this._durationService.getDurationString(parsedValue);
    } else {
      displayValue = !inputValue ? '' : (inputValue + '').trim();
    }

    this._renderer.setProperty(
      this._elementRef.nativeElement,
      'value',
      displayValue
    );
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    return;
  }

  protected onChangeCallback: (_: number) => void = (_: number) => undefined;
  protected onTouchedCallback: () => void = () => undefined;

  protected updateValue(value: string): void {
    // update display value
    this.writeValue(value);

    // updates ngModel value based on input
    const duration = this._durationService.parseDurationString(value);
    if (duration) {
      this.onChangeCallback(duration);
    }
  }
}
