import { Component, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-mat-icon-cell-renderer',
  template: `<div class="flex justify-center items-center h-full">
    <mat-icon [class.text-green-500]="value()">{{
      value() ? 'check' : 'close'
    }}</mat-icon>
  </div>`,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  standalone: true,
  imports: [MatIconModule],
})
export class BooleanValueCellRendererComponent
  implements ICellRendererAngularComp
{
  public params: boolean;

  protected readonly value = signal(false);

  agInit(params: ICellRendererParams<unknown, boolean, unknown>): void {
    this.value.set(params.value as boolean);
  }
  refresh(params: ICellRendererParams<unknown, boolean, unknown>): boolean {
    this.value.set(params.value as boolean);

    return false;
  }
}
