import { Injectable, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  private readonly _title = signal('');

  public readonly title = this._title.asReadonly();

  setTitle(title: string) {
    this._title.set(title);
  }
}
