<div>
  <div
    class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper"
    ref="eWrapper"
    role="presentation">
    <input
      id="ag-132-input"
      class="ag-input-field-input ag-text-field-input"
      [formControl]="searchFormCtrl"
      placeholder="Search..."
      ref="eInput"
      type="text" />
  </div>

  <div class="flex flex-col">
    <mat-checkbox
      [checked]="isSelectAllChecked"
      [indeterminate]="isSelectAllIntermediate"
      (change)="onSelectAllChange($event)"
      color="primary">
      {{ 'common.select_all' | transloco }}
    </mat-checkbox>

    @for (option of options$ | async; track option.id) {
      <mat-checkbox
        [checked]="selection.isSelected(option.id)"
        (change)="onOptionSelected(option)"
        color="primary">
        {{ option.name }}
      </mat-checkbox>
    }
  </div>
</div>
