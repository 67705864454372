import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Section } from '@fieldos/models';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-show-text-section',
  templateUrl: './sections-form-show-text-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionsFormShowTextSectionComponent
  extends SectionsFormSectionBaseComponent<Section<string>>
  implements OnInit
{
  override ngOnInit(): void {
    return;
  }
}
