import { JsonPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SimpleFormGroup } from '@browsepedia/ng-forms';
import {
  AutocompleteSelectComponent,
  NumberFieldComponent,
} from '@fieldos/components';
import {
  PackagingLoadedSectionValueEntry,
  PackagingSection,
} from '@fieldos/models';
import {
  DataSourceStore,
  StandardDataSourceId,
} from '@fieldos/store/data-source-cache';
import { minLengthArrayValidator } from '@fieldos/utils';
import { TranslocoModule } from '@ngneat/transloco';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-packaging-section',
  templateUrl: './sections-form-packaging-section.component.html',
  standalone: true,
  imports: [
    TranslocoModule,
    AutocompleteSelectComponent,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    NumberFieldComponent,
    JsonPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionsFormPackagingSectionComponent
  extends SectionsFormSectionBaseComponent<PackagingSection>
  implements OnInit
{
  protected readonly options = inject(DataSourceStore).selectDataSource(
    StandardDataSourceId.Packaging
  );

  override ngOnInit(): void {
    this.initializeForm();

    if (this.rights()?.mandatory) {
      this.setValidation();
      this.form.updateValueAndValidity();
    }

    const sectionValue = this.sectionValue();

    if (sectionValue) {
      this.form.clear();
      sectionValue.forEach((value) =>
        this.form.push(this.createFormGroup(value))
      );
      this.detector.detectChanges();
    }

    if (!this.rights()?.canEdit) {
      this.setDisabled();
    }

    this.store.updateSelectedSectionStatus(this.sectionId(), {
      dirty: this.form.dirty,
      valid: this.rights()?.mandatory ? !this.form.invalid : true,
    });
  }

  protected override initializeForm(): void {
    this.form = new FormArray<
      SimpleFormGroup<PackagingLoadedSectionValueEntry>
    >([this.createFormGroup()]);
  }

  protected onAddClick(): void {
    this.form.push(this.createFormGroup());
  }

  protected override setDisabled(): void {
    this.form.disable();
  }

  protected override setValidation(): void {
    this.form.setValidators(minLengthArrayValidator(1));
    this.form.updateValueAndValidity();
  }

  protected onSave(): void {
    this.store.updateSectionValue(this.sectionId(), this.form.getRawValue());

    this.store.updateSelectedSectionStatus(this.sectionId(), {
      dirty: this.form.dirty,
      valid: !this.form.invalid,
    });

    this.form.markAsPristine();
  }

  protected removeRow(index: number): void {
    this.form.removeAt(index);
    this.form.markAsDirty();
  }

  private createFormGroup(
    value?: PackagingLoadedSectionValueEntry
  ): SimpleFormGroup<PackagingLoadedSectionValueEntry> {
    return new SimpleFormGroup<PackagingLoadedSectionValueEntry>({
      packagingId: new FormControl(value?.packagingId || 0, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(1)],
      }),
      quantity: new FormControl(value?.quantity || 0, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(1)],
      }),
    });
  }
}
