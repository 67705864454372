import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  effect,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import {
  CustomerContractSection,
  DataSourceSection,
  SectionSubType,
} from '@fieldos/models';
import { StandardDataSourceId } from '@fieldos/store/data-source-cache';
import { AutoUnsubscribe } from '@fieldos/utils';
import { Subscription } from 'rxjs';
import { AutocompleteSelectComponent } from '../../../../../@components/fields/autocomplete-select';
import { SectionsFormDataSourceSectionBaseComponent } from '../sections-form-data-source-section.base';

@Component({
  selector: 'app-sections-form-customer-contract-section',
  templateUrl: './sections-form-customer-contract-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, AutocompleteSelectComponent, ReactiveFormsModule],
})
@AutoUnsubscribe()
export class SectionsFormCustomerContractSectionComponent
  extends SectionsFormDataSourceSectionBaseComponent<CustomerContractSection>
  implements OnInit
{
  constructor() {
    super();

    effect(
      () => {
        const sections = Object.values(this.store.sectionsMap());

        const customerSection = sections?.find(
          (section) => section.subtype === SectionSubType.Customer
        ) as DataSourceSection;

        if (
          customerSection &&
          this.section().properties?.showForSelectedCustomer &&
          customerSection.value
        ) {
          this.queryEntities({
            parent: customerSection.value as number,
          });
        }
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      const sections = Object.values(this.store.sectionsMap());
      const section = sections.find(
        (section) => section.subtype === SectionSubType.Customer
      ) as DataSourceSection;

      if (section.value && this.section().properties?.showForSelectedCustomer) {
        this.queryEntities({
          parent: section.value,
        });
      }
    });
  }

  protected override dataSourceId = StandardDataSourceId.CustomerContracts;

  private _customer$!: Subscription;

  override ngOnInit(): void {
    super.ngOnInit();
  }

  protected override setValidation(): void {
    this.form.setValidators(Validators.required);
    this.form.updateValueAndValidity();
  }
}
