@if (vm$ | async; as vm) {
  <div class="flex flex-col gap-2" [formGroup]="form">
    <app-autocomplete-select
      formControlName="locationId"
      [loading]="vm.loading"
      [options]="vm.locations"
      label="{{ locationLabel ?? 'common.location' | transloco }}"
      labelProperty="name"
      valueProperty="id" />

    @if (!hideSublocation) {
      <app-autocomplete-select
        formControlName="sublocationId"
        [loading]="vm.loading"
        [options]="vm.subLocations"
        label="{{ sublocationLabel ?? 'common.sublocation' | transloco }}"
        labelProperty="name"
        valueProperty="id" />
    }
  </div>
}
