<div
  class="flex-grow min-h-0 flex-shrink overflow-auto bg-slate-100"
  cdkScrollable>
  <div class="flex overflow-y-auto flex-col-reverse h-full">
    <div class="flex flex-col flex-auto shrink p-6 bg-card dark:bg-transparent">
      @for (message of messages(); track message.id) {
        <!-- Start of the day -->
        @if (
          $first ||
          (messages()[$index - 1].date | date: 'd') !==
            (message.date | date: 'd')
        ) {
          <div class="flex items-center justify-center my-3 -mx-6">
            <div class="flex-auto border-b"></div>
            <div
              class="flex-0 mx-4 text-sm font-medium leading-5 text-secondary">
              {{ message.date | date: 'longDate' }}
            </div>
            <div class="flex-auto border-b"></div>
          </div>
        }

        <div
          [ngClass]="{
            'items-end': message.isMine,
            'items-start': !message.isMine,
            'mt-0.5':
              $index > 0 && messages()[$index - 1].isMine === message.isMine,
            'mt-3':
              $index > 0 && messages()[$index - 1].isMine !== message.isMine
          }"
          class="flex flex-col">
          <!-- Bubble -->
          <div
            [ngClass]="{
              'bg-blue-500 text-blue-50': message.isMine,
              'bg-gray-500 text-gray-50': !message.isMine
            }"
            class="relative max-w-3/4 px-3 py-2 rounded-lg">
            @if (
              messages()[$index].files && messages()[$index].files.length > 0
            ) {
              <div class="flex justify-end h-32">
                <img
                  class="rounded-lg mb-1 object-contain cursor-pointer"
                  [src]="messages()[$index].files[0].url"
                  (click)="onFileClick(messages()[$index].files[0])"
                  alt="Image" />
              </div>
            }
            <!-- Speech bubble tail -->
            @if ($last || messages()[$index + 1].isMine !== message.isMine) {
              <div
                [ngClass]="{
                  'text-blue-500 -right-1 -mr-px mb-px': message.isMine,
                  'text-gray-500 -left-1 -ml-px mb-px -scale-x-1':
                    !message.isMine
                }"
                class="absolute bottom-0 w-3">
                <ng-container *ngTemplateOutlet="speechBubbleExtension" />
              </div>
            }

            @if (!message.isMine) {
              <div
                class="min-w-4 leading-5 text-green-400 font-bold {{
                  userColor[message.userId]
                }}">
                {{ message.userName }}
              </div>
            }

            <!-- Message -->
            <div class="min-w-4 leading-5" [innerHTML]="message.message"></div>
          </div>
          <!-- Time -->
          @if (
            $first ||
            $last ||
            messages()[$index + 1].isMine !== message.isMine ||
            messages()[$index + 1].date !== message.date
          ) {
            <div
              [ngClass]="{
                'mr-3': message.isMine,
                'ml-3': !message.isMine
              }"
              class="my-0.5 text-sm font-medium text-secondary">
              {{ message.date | date: 'HH:mm' }}
            </div>
          }
        </div>
      } @empty {
        <div class="text-center italic text-base">
          {{ 'chat_window.no_messages' | transloco }}
        </div>
      }

      @if (loading()) {
        <div
          class="relative max-w-3/4 px-3 py-2 rounded-lg bg-blue-500 text-blue-50 ml-auto p-4">
          <!-- Speech bubble tail -->
          <div
            class="absolute bottom-0 w-3 text-blue-500 -right-1 -mr-px mb-px">
            <ng-container *ngTemplateOutlet="speechBubbleExtension" />
          </div>

          <!-- Message -->
          <div class="min-w-4 leading-5">
            <mat-spinner color="white" diameter="16" />
          </div>
        </div>
      }
    </div>

    <!-- Speech bubble tail SVG -->
    <!-- @formatter:off -->
    <ng-template #speechBubbleExtension>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg">
        <g
          id="Page-1"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          stroke="none">
          <path
            d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z"
            fill="currentColor"
            fill-rule="nonzero"></path>
        </g>
      </svg>
    </ng-template>
    <!-- @formatter:on -->
  </div>
</div>
<div class="flex justify-end p-4 border-t">
  <mat-form-field
    class="fuse-mat-dense fuse-mat-rounded fuse-mat-bold w-full ml-4"
    subscriptSizing="dynamic">
    <textarea
      matInput
      [disabled]="!newMessageCtrl.value.length"
      [formControl]="newMessageCtrl"
      (keydown.enter)="onSendMessage(); $event.preventDefault()"
      cdkTextareaAutosize
      placeholder="{{
        'work_orders.form.chat.input_placeholder' | transloco
      }}"></textarea>
  </mat-form-field>
  <div class="flex items-center h-11 my-px ml-4">
    @if (canAddFiles()) {
      <button mat-icon-button (click)="photoInput.click()" color="primary">
        <mat-icon [svgIcon]="'heroicons_outline:camera'" />
      </button>
      <button mat-icon-button (click)="documentInput.click()" color="primary">
        <mat-icon [svgIcon]="'heroicons_outline:paper-clip'" />
      </button>
    }
    <button mat-icon-button (click)="onSendMessage()" color="primary">
      <mat-icon [svgIcon]="'heroicons_outline:paper-airplane'" />
    </button>

    <input
      #photoInput
      hidden
      (change)="fileAdded.emit($any($event.target).files)"
      accept="image/*"
      type="file" />

    <input
      #documentInput
      hidden
      (change)="fileAdded.emit($any($event.target).files)"
      accept="*"
      type="file" />
  </div>
</div>
