<div class="flex flex-col gap-4">
  @if (form) {
    <mat-form-field class="fuse-mat-dense w-full" subscriptSizing="dynamic">
      <mat-select [formControl]="form">
        @for (option of statusNodes(); track option.id) {
          <mat-option [value]="option.id">
            {{ option.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  @if (!formValid()) {
    <fuse-alert appearance="fill" type="warning">
      {{ 'work_orders.form.fill_required_fields' | transloco }}
      <mat-icon fuseAlertIcon>warning</mat-icon>
    </fuse-alert>
  }
</div>
