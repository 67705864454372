import {
  DestroyRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, filter, fromEvent, tap } from 'rxjs';

@Directive({
  selector: '[appBottomScrollDetector]',
  standalone: true,
})
export class BottomScrollDetectorDirective implements OnInit {
  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _destroyRef: DestroyRef
  ) {}

  @Input() public useHostElementScroll = false;
  @Input() public bottomScrollDetectOffset = 0;
  @Input() public bottomScrollDetectDisabled = false;

  @Output() public readonly appBottomScrollDetector = new EventEmitter<void>();

  ngOnInit(): void {
    fromEvent<MouseEvent>(
      this.useHostElementScroll ? this._elementRef.nativeElement : window,
      'scroll'
    )
      .pipe(
        filter(() => {
          if (this.useHostElementScroll) {
            return (
              !this.bottomScrollDetectDisabled &&
              this._elementRef.nativeElement.clientHeight +
                this._elementRef.nativeElement.scrollTop >=
                this._elementRef.nativeElement.scrollHeight -
                  this.bottomScrollDetectOffset &&
              this._elementRef.nativeElement.scrollTop !== 0
            );
          }
          return (
            !this.bottomScrollDetectDisabled &&
            window.innerHeight + window.scrollY >=
              document.body.offsetHeight - this.bottomScrollDetectOffset
          );
        }),
        debounceTime(200),
        tap(() => this.appBottomScrollDetector.emit()),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }
}
