<h1 mat-dialog-title>Signature</h1>
<div mat-dialog-content class="flex gap-4 flex-col pt-1">
  <mat-form-field class="fuse-mat-dense" subscriptSizing="dynamic">
    <mat-label>Signee name</mat-label>
    <input matInput [formControl]="nameCtrl" />
  </mat-form-field>

  <app-signature
    class="flex flex-col h-full"
    [height]="120"
    (valueChange)="onSignatureValueChange($event)"
    placeholder="Draw signature" />
</div>

<div mat-dialog-actions align="end">
  <button mat-flat-button [mat-dialog-close]="">
    {{ 'common.cancel' | transloco }}
  </button>
  <button
    mat-flat-button
    [disabled]="form.invalid"
    (click)="onSave()"
    color="primary">
    {{ 'common.save' | transloco }}
  </button>
</div>
