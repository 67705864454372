import { MimeType, SectionSubType } from '@fieldos/models';

export const allowedMimeTypes = (): Record<
  SectionSubType.Documents | SectionSubType.Media,
  MimeType[]
> => ({
  [SectionSubType.Documents]: [
    'application/pdf',

    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.ms-excel',

    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/vnd.adobe.photoshop',
    'drawing/x-dwf',
    'application/vnd.ms-powerpoint',

    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
  [SectionSubType.Media]: [
    'image/jpeg',
    'image/png',
    'image/tiff',
    'image/bmp',
    'image/gif',
  ],
});
