import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  SectionBaseEntityAction,
  SectionBasedEntity,
  SectionBasedEntityType,
} from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { RowClickedEvent } from 'ag-grid-community';
import {
  DataGridActionsService,
  DataGridComponent,
} from 'app/@components/data-grid';
import { tap } from 'rxjs';
import {
  DEFAULT_SECTION_COLUMN,
  SectionBasedEntityListModel,
  sectionBasedListGridComponents,
} from '../section-columns';
import { SectionsEntityListStore } from './sections-entity-list.store';

@Component({
  selector: 'app-sections-entity-list',
  templateUrl: './sections-entity-list.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatProgressSpinnerModule, DataGridComponent, TranslocoModule],
  providers: [DataGridActionsService],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class SectionsEntityListComponent {
  constructor() {
    this._actionService.buttonClick$
      .pipe(
        takeUntilDestroyed(),
        tap(({ buttonId, data }) => {
          if (buttonId === 'delete') {
            this.store.delete(data['id'] as number);
          }
        })
      )
      .subscribe();
  }
  @Input() public set entityType(entityType: SectionBasedEntityType) {
    this.store.setEntityType(entityType);
  }

  @Output() public readonly rowClick = new EventEmitter<SectionBasedEntity>();

  protected readonly components = [...sectionBasedListGridComponents];
  protected readonly defaultColDef = inject(DEFAULT_SECTION_COLUMN);

  protected readonly store = inject(SectionsEntityListStore);

  private readonly _actionService: DataGridActionsService<
    SectionBasedEntityListModel,
    SectionBaseEntityAction
  > = inject(DataGridActionsService);

  protected async onRowClick(
    row: RowClickedEvent<SectionBasedEntityListModel>
  ) {
    const rowData = row.data as SectionBasedEntityListModel;

    const entity = await this.store.fetchById(rowData['id'] as number);

    this.rowClick.emit(entity);
  }
}
