import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[clickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  constructor(private _elementRef: ElementRef<HTMLElement>) {}

  @Input() public clickOutsideDisabled = false;

  @Output() public readonly clickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event'])
  private _onClick(event: MouseEvent): void {
    if (this.clickOutsideDisabled) {
      return;
    }

    const target = event.target as HTMLElement;
    const clickInside = this._elementRef.nativeElement.contains(target);

    if (!clickInside) {
      this.clickOutside.emit(event);
    }
  }
}
