import { inject } from '@angular/core';
import { SectionBasedEntityType, StatusGraphNode } from '@fieldos/models';
import { signalStore, withMethods } from '@ngrx/signals';
import { withStatusGraphStore } from './status-graph-feature.store';

export const WorkOrderStatusGraphStore = signalStore(
  { providedIn: 'root' },
  withStatusGraphStore('workorders')
);

export const ServiceRequestStatusGraphStore = signalStore(
  { providedIn: 'root' },
  withStatusGraphStore('servicerequests')
);

export const StatusGraphStore = signalStore(
  { providedIn: 'root' },
  withMethods(
    (
      store,
      workOrderStore = inject(WorkOrderStatusGraphStore),
      serviceRequestStore = inject(ServiceRequestStatusGraphStore)
    ) => ({
      fetchForScopeId(scopeId: number): void {
        workOrderStore.fetchForScopeId(scopeId);
        serviceRequestStore.fetchForScopeId(scopeId);
      },

      selectCurrentStatusGraph(
        entityType: SectionBasedEntityType
      ): StatusGraphNode[] {
        if (entityType === 'workorders') {
          return workOrderStore.currentScopeStatusGraph();
        } else {
          return serviceRequestStore.currentScopeStatusGraph();
        }
      },
      selectCurrentStatusGraphSignal(entityType: SectionBasedEntityType) {
        if (entityType === 'workorders') {
          return workOrderStore.currentScopeStatusGraph;
        } else {
          return serviceRequestStore.currentScopeStatusGraph;
        }
      },
      clearCache(): void {
        workOrderStore.clearCache();
        serviceRequestStore.clearCache();
      },
    })
  )
);
