import { inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { DomainEntityBase } from '@fieldos/models';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';

export const CustomInformationStore = signalStore(
  {
    providedIn: 'root',
  },
  withEntities<DomainEntityBase>(),
  withState<{ loading: boolean }>({
    loading: false,
  }),
  withMethods((store, http = inject(HttpService)) => ({
    async fetchAll(): Promise<void> {
      try {
        patchState(store, {
          loading: true,
        });
        const data = await http.get<DomainEntityBase[]>('/information-custom');
        patchState(store, { loading: false });
        patchState(store, setAllEntities(data));
      } catch (error) {
        console.error(error);
      }
    },
  }))
);
