import { SectionProperties } from './section-properties';
import { SectionLabelInfoProperties } from './simple-section-properties';

export interface AssetSectionCommonProperties {
  showAsset: boolean;
  showSubasset: boolean;
}

export interface AssetSectionTranslatableProperties
  extends SectionLabelInfoProperties {
  assetLabel: string;
  subassetLabel: string;
}

export type AssetSectionProperties = SectionProperties<
  AssetSectionTranslatableProperties,
  AssetSectionCommonProperties
>;
