import { computed, effect, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';
import { DomainEntityBase } from '@fieldos/models';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import { AuthStore } from '../auth';
import { withDataSourceState } from './data-source-feature.store';
import { StandardDataSourceId } from './data-source.models';
import { StandardDataSourceDataService } from './standard-data-source.service';

export const DataSourceStore = signalStore(
  { providedIn: 'root' },
  withDataSourceState<StandardDataSourceId>(),
  withComputed((store) => ({
    dataSourceOptions: computed<DomainEntityBase[]>(() => {
      const dataSources = store.dataSources() || [];
      return dataSources
        .filter((option) => option.public)
        .map((dataSource) => ({
          id: dataSource.id,
          name: `data_sources.${dataSource.name}`,
        }));
    }),
  })),
  withMethods(
    (store, _dataSourceService = inject(StandardDataSourceDataService)) => ({
      fetchDataSource(dataSourceId: StandardDataSourceId, params?: Params) {
        try {
          const data = _dataSourceService.fetch(dataSourceId, params);
          patchState(store, (state) => ({
            ...state,
            [dataSourceId]: {
              loaded: true,
              isStale: false,
              data,
            },
          }));
        } catch (error) {
          console.error(error);
        }
      },
      async fetchWithoutCache(
        dataSourceId: StandardDataSourceId,
        params?: Params
      ): Promise<DomainEntityBase[]> {
        try {
          return await _dataSourceService.fetch(dataSourceId, params);
        } catch (error) {
          console.error(error);
          return [];
        }
      },
      async fetchAllDataSources(): Promise<void> {
        try {
          const dataSources =
            await _dataSourceService.fetchDataSourcesWithData();

          patchState(store, (state) => ({
            ...state,
            dataSources,
            cache: dataSources.reduce(
              (acc, curr, index) => ({
                ...acc,
                [dataSources[index].id]: {
                  loaded: true,
                  isStale: false,
                  data: curr.data?.reduce(
                    (acc, current) => ({
                      ...acc,
                      [current.id]: current,
                    }),
                    {}
                  ),
                },
              }),
              {}
            ),
          }));
        } catch (error) {
          console.error(error);
        }
      },

      clearCache: () => patchState(store, {}),
    })
  ),
  withHooks({
    onInit: async (store, authStore = inject(AuthStore)) => {
      effect(
        () => {
          const language = authStore.language();

          if (language) {
            store.clearCache();
            store.fetchAllDataSources();
          }
        },
        { allowSignalWrites: true }
      );

      toObservable(authStore.isAuthenticated)
        .pipe(
          distinctUntilChanged(),
          filter((isAuthenticated) => isAuthenticated === true),
          tap(() => store.fetchAllDataSources()),
          takeUntilDestroyed()
        )
        .subscribe();
    },
  })
);
