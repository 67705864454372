<div
  *ngIf="!dismissible || (dismissible && !dismissed)"
  class="fuse-alert-container"
  [@fadeIn]="!dismissed"
  [@fadeOut]="!dismissed">
  <!-- Border -->
  <div *ngIf="appearance === 'border'" class="fuse-alert-border"></div>

  <!-- Icon -->
  <div *ngIf="showIcon" class="fuse-alert-icon">
    <!-- Custom icon -->
    <div class="fuse-alert-custom-icon">
      <ng-content select="[fuseAlertIcon]"></ng-content>
    </div>

    <!-- Default icons -->
    <div class="fuse-alert-default-icon">
      <mat-icon
        *ngIf="type === 'primary'"
        [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>

      <mat-icon
        *ngIf="type === 'accent'"
        [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>

      <mat-icon
        *ngIf="type === 'warn'"
        [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>

      <mat-icon
        *ngIf="type === 'basic'"
        [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>

      <mat-icon
        *ngIf="type === 'info'"
        [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>

      <mat-icon
        *ngIf="type === 'success'"
        [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>

      <mat-icon
        *ngIf="type === 'warning'"
        [svgIcon]="'heroicons_solid:exclamation-triangle'"></mat-icon>

      <mat-icon
        *ngIf="type === 'error'"
        [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
    </div>
  </div>

  <!-- Content -->
  <div class="fuse-alert-content">
    <div class="fuse-alert-title">
      <ng-content select="[fuseAlertTitle]"></ng-content>
    </div>

    <div class="fuse-alert-message">
      <ng-content></ng-content>
    </div>
  </div>

  <!-- Dismiss button -->
  <button mat-icon-button class="fuse-alert-dismiss-button" (click)="dismiss()">
    <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
  </button>
</div>
