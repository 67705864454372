export interface ErrorResponse {
  error: {
    message: string;
  };
}

export const isErrorResponse = <T = any>(
  value: ErrorResponse | T
): value is ErrorResponse =>
  value &&
  typeof (value as ErrorResponse).error === 'object' &&
  typeof (value as ErrorResponse).error.message === 'string';
