import { Component, inject, OnInit, signal } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Params } from '@angular/router';
import {
  CustomerContractSection,
  DataSourceSection,
  DomainEntityBase,
} from '@fieldos/models';
import {
  DATA_SOURCE_ENTITY_SELECT_TYPE_MAP,
  DATA_SOURCE_SECTION_SUBTYPE_MAP,
} from '@fieldos/providers';
import { DataSourceStore, StandardDataSourceId } from '@fieldos/store';
import { AutoUnsubscribe, minLengthArrayValidator } from '@fieldos/utils';
import { SectionsFormSectionBaseComponent } from './sections-form-section-base.component';

@Component({
  selector: '',
  template: '',
})
@AutoUnsubscribe()
export class SectionsFormDataSourceSectionBaseComponent<
    TSection extends
      | DataSourceSection
      | CustomerContractSection = DataSourceSection,
  >
  extends SectionsFormSectionBaseComponent<TSection>
  implements OnInit
{
  protected readonly _workOrderSubtypeDataSourceMap = inject(
    DATA_SOURCE_SECTION_SUBTYPE_MAP
  );

  protected readonly _selectTypeMap = inject(
    DATA_SOURCE_ENTITY_SELECT_TYPE_MAP
  );

  protected get isMultipleSelect(): boolean {
    return this._selectTypeMap[this.section().subtype] === 'multiple';
  }

  protected readonly _dataSourceStore = inject(DataSourceStore);

  protected dataSourceId!: StandardDataSourceId;
  protected entityQuery?: Record<string, number | string>;

  protected readonly items = signal<DomainEntityBase[]>([]);
  protected readonly loading = signal<boolean>(false);

  override ngOnInit(): void {
    if (!this.dataSourceId) {
      throw new Error('Override the `entityType` in the inheriting class');
    }

    const items = this._dataSourceStore.selectDataSource(this.dataSourceId)();
    this.items.set(items);

    super.ngOnInit();
  }

  protected override initializeForm(): void {
    this.form = new FormControl<number | number[]>(
      this.isMultipleSelect ? [] : 0,
      { nonNullable: true }
    ) as any;
  }

  protected override setValidation(): void {
    if (this.isMultipleSelect) {
      this.form.setValidators(minLengthArrayValidator(1));
    } else {
      this.form.setValidators([Validators.required, Validators.min(1)]);
    }

    this.form.updateValueAndValidity();
  }

  protected queryEntities(query?: Params): void {
    this._dataSourceStore.fetchDataSource(this.dataSourceId, query);
  }
}
