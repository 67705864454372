import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { WorkspaceStore } from '@fieldos/store';
import { AutoUnsubscribe } from '@fieldos/utils';
import { TranslocoModule } from '@ngneat/transloco';
import { tap } from 'rxjs';

@Component({
  selector: 'app-workspace-select',
  templateUrl: './workspace-select.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
})
@AutoUnsubscribe()
export class WorkspaceSelectComponent {
  constructor() {
    effect(() => {
      const workspaceId = this.store.selectedWorkspaceId();

      this.workspaceCtrl.setValue(workspaceId, { emitEvent: false });
    });

    this.workspaceCtrl.valueChanges
      .pipe(
        tap((workspaceId) => this.store.selectWorkspace(workspaceId)),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  protected readonly store = inject(WorkspaceStore);

  protected readonly workspaceCtrl = new FormControl();
}
