import { computed, effect, inject } from '@angular/core';
import { StatusGraphDataService } from '@fieldos/data-services';
import { SectionBasedEntityType, StatusGraphNode } from '@fieldos/models';
import {
  patchState,
  signalStoreFeature,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { WorkspaceStore } from '../workspace.store';

export interface StatusGraphStoreState {
  statusGraphs: Record<number, StatusGraphNode[]>;
  loading: boolean;
}

const initialState: StatusGraphStoreState = {
  statusGraphs: {},
  loading: false,
};

export const withStatusGraphStore = (entityType: SectionBasedEntityType) =>
  signalStoreFeature(
    withState(initialState),
    withComputed((store, workspaceStore = inject(WorkspaceStore)) => ({
      currentScopeStatusGraph: computed(
        () => store.statusGraphs()[workspaceStore.selectedWorkspaceId() || 0]
      ),
    })),
    withMethods((store, dataService = inject(StatusGraphDataService)) => ({
      async fetchForScopeId(scopeId: number): Promise<void> {
        patchState(store, { loading: true });

        const statusGraph = await dataService.fetchForScopeId(
          scopeId,
          entityType
        );

        patchState(store, {
          loading: false,
          statusGraphs: {
            ...store.statusGraphs(),
            [scopeId]: statusGraph,
          },
        });
      },
      clearCache(): void {
        patchState(store, { statusGraphs: {} });
      },
    })),
    withHooks({
      onInit: (
        store,
        statusGraphService = inject(StatusGraphDataService),
        workspaceStore = inject(WorkspaceStore)
      ) => {
        effect(
          async () => {
            patchState(store, { loading: true });
            const scopeId = workspaceStore.selectedWorkspaceScopeId();

            if (scopeId) {
              const statusGraphs = await statusGraphService.fetchForScopeId(
                scopeId,
                entityType
              );
              patchState(store, {
                loading: false,
                statusGraphs: {
                  ...store.statusGraphs(),
                  [scopeId]: statusGraphs,
                },
              });
            }
          },
          { allowSignalWrites: true }
        );
      },
    })
  );
