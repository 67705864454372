import { HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { HttpService } from '@fieldos/core';
import { Workspace, WorkspaceScope } from '@fieldos/models';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceDataService {
  private _http = inject(HttpService);

  fetchWorkspaces(): Promise<Workspace[]> {
    return this._http.get<Workspace[]>(`/workspaces`);
  }

  fetchWorkspaceScopes(): Promise<WorkspaceScope[]> {
    return this._http.get<WorkspaceScope[]>(`/wo-scopes`);
  }

  fetchWorkspaceScopesForWorkspace(
    workspaceId: number
  ): Promise<WorkspaceScope[]> {
    const headers = new HttpHeaders();
    headers.append('workspace-id', `${workspaceId}`);

    return this._http.get<WorkspaceScope[]>(`/wo-scopes`, 'json', {}, headers);
  }

  changeWorkspace(workspaceId: number): Promise<void> {
    return this._http.put<void>(`/user-profile`, { workspaceId });
  }
}
