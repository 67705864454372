import { Route } from '@angular/router';

export const assetRoutes: Route[] = [
  {
    path: 'equipments',
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./assets/assets.component').then((m) => m.AssetsComponent),
        data: {
          title: 'assets.list',
        },
      },
      {
        path: 'new',
        pathMatch: 'full',
        loadComponent: () =>
          import('./asset-form/asset-form.component').then(
            (m) => m.AssetFormComponent
          ),
        data: {
          title: 'assets.add_new',
        },
      },
      {
        path: 'new/:typeId',
        pathMatch: 'full',
        loadComponent: () =>
          import('./asset-form/asset-form.component').then(
            (m) => m.AssetFormComponent
          ),
        data: {
          title: 'assets.add_new',
        },
      },

      {
        path: ':assetId/edit',
        pathMatch: 'full',
        loadComponent: () =>
          import('./asset-form/asset-form.component').then(
            (m) => m.AssetFormComponent
          ),
        data: {
          title: 'assets.edit',
        },
      },
    ],
  },
];
