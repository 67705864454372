import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthStore } from '@fieldos/store/auth';
import { WorkspaceStore } from '@fieldos/store/workspace.store';

import { Observable } from 'rxjs';

export const workspaceScopeHeadersInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const workspaceStore = inject(WorkspaceStore);
  const authStore = inject(AuthStore);

  let headers = req.headers;
  const workspaceId =
    workspaceStore.selectedWorkspaceId() ||
    authStore.profile()?.activeWorkspace.id;
  const workspaceScopeId = workspaceStore.selectedWorkspaceScopeId();
  const roleId = authStore.roleId();

  if (!headers.get('workspace-id')) {
    headers = headers.set('workspace-id', `${workspaceId}`);
  }

  if (workspaceScopeId && !headers.get('wo-scope-id')) {
    headers = headers.set('wo-scope-id', `${workspaceScopeId}`);
  }

  if (roleId && !headers.get('role-id')) {
    headers = headers.set('role-id', `${roleId}`);
  }

  return next(
    req.clone({
      headers,
    })
  );
};
