import { Injectable } from '@angular/core';
import { ErrorResponse, User, UserFieldSubType } from '@fieldos/models';
import { FieldBasedEntitiesServiceBase } from './field-based-entities.service';

@Injectable({
  providedIn: 'root',
})
export class UsersDataService extends FieldBasedEntitiesServiceBase<User> {
  constructor() {
    super('users', UserFieldSubType.UserType);
  }

  updatePassword(userId: number, password: string): Promise<void> {
    return this._http.put(`/change-password/${userId}`, { password });
  }

  setTemporaryPassword(userId: number, password: string): Promise<void> {
    return this._http.put(`/set-temporary-password/${userId}`, { password });
  }

  resetPassword(
    token: string,
    password: string,
    forUser = false
  ): Promise<void> {
    const body = { password } as any;
    if (forUser) {
      body.source = 'requestReset';
    }

    return this._http.put(`/password-reset-link-change/${token}`, body);
  }

  resetTemporaryPassword(token: string, password: string): Promise<void> {
    return this._http.put(`/change-temporary-password/${token}`, { password });
  }

  sendResetPasswordLink(userId: number): Promise<void> {
    return this._http.put(`/password-reset-link/${userId}`, {});
  }

  validateResetPasswordLink(
    token: string,
    forUser = false
  ): Promise<ErrorResponse | void> {
    const body = {} as any;
    if (forUser) {
      body.source = 'requestReset';
    }
    return this._http.put(`/password-reset-link-validate/${token}`, body);
  }
}
