@if (store.loading()) {
  <div class="flex items-center justify-center w-full h-full">
    <mat-spinner color="primary" diameter="32" />
  </div>
} @else {
  @if (store.loaded() && store.data().length > 0) {
    <app-data-grid
      gridClass="flex-grow"
      class="flex-grow flex flex-col transition-all duration-300 ease-in-out"
      [columns]="store.columnDefs() || []"
      [components]="components"
      [context]="{
        sections: store.sectionsMap(),
        entityType: store.entityType()
      }"
      [data]="store.data() || []"
      [defaultColDef]="defaultColDef"
      (rowClick)="onRowClick($event)"
      (selectionChange)="store.setSelection($event)"
      rowSelection="multiple"
      showSelectionColumn />
  }

  @if (store.loaded() && store.data().length === 0) {
    <div class="flex items-center justify-center w-full h-full">
      {{
        'section_based_entity.' + store.entityType() + '.none_found' | transloco
      }}
    </div>
  }
}
