import { Injectable, inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import {
  PaginatedResponse,
  WorkOrderAssignee,
  WorkOrderAssigneeLocation,
  WorkOrderRouteWorkOrdersShowType,
} from '@fieldos/models';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderAssigneesDataService {
  private readonly _http = inject(HttpService);

  async query(
    term: string,
    page: number,
    sort: string,
    sortDir: 'asc' | 'desc'
  ): Promise<PaginatedResponse<WorkOrderAssignee>> {
    const data = await this._http.get<PaginatedResponse<WorkOrderAssignee>>(
      `/assignees-with-routes`,
      'json',
      {
        sortDir,
        sort,
        page: page.toString(),
        term,
      }
    );

    return data;
  }
  queryRoutes(
    responsibles: number[],
    filters: WorkOrderRouteWorkOrdersShowType[]
  ): Promise<WorkOrderAssigneeLocation[]> {
    return this._http.post(`/routes-wo-responsibles`, {
      responsibles,
      filters,
      sort: [
        {
          woCount: 'desc',
        },
      ],
    });
  }

  reorderRouteWorkOrders(
    routeId: number,
    workOrders: number[],
    movedToIndex: number
  ): Promise<void> {
    const workOrdersWithPosition = workOrders.map((id, index) => ({
      woid: id,
      position: index,
    })) as any[];

    workOrdersWithPosition[movedToIndex].reordered = true;

    return this._http.patch(`/workorder-update-position/${routeId}`, {
      workOrders: workOrdersWithPosition,
    });
  }
}
