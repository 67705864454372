import { ElementRef, Injectable, NgZone } from '@angular/core';
import { Point } from 'ngx-panzoom/lib/types/point';
import { fromEvent, Subscription } from 'rxjs';

@Injectable()
export class MouseCursorElementService {
  constructor(private _ngZone: NgZone) {}
  public data: any;

  public isActive = false;

  private _dropzone: HTMLElement | null = null;

  private _placeholder: HTMLElement | null = null;
  private _mouseElement: HTMLElement | null = null;
  private _mouseMove$!: Subscription;

  start<T>(event: MouseEvent | Point, data: T): void {
    this.end();
    this.data = data;
    this.isActive = true;
    if (this._placeholder) {
      const node = this._placeholder.cloneNode(true) as HTMLElement;
      node.style.display = 'block';
      node.style.position = 'fixed';
      node.style.pointerEvents = 'none';
      node.style.zIndex = '9999';
      node.id = 'bp-mouse-cursor-element';
      document.body.append(node);
      this._mouseElement = node;
      this._onMouseMove(event);
    }

    this._ngZone.runOutsideAngular(() => {
      this._mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove').subscribe(
        (event: MouseEvent) => this._onMouseMove(event)
      );
    });
  }

  end(): void {
    this.isActive = false;
    if (this._mouseElement) {
      document.body.removeChild(this._mouseElement);
    }

    this._mouseMove$ && this._mouseMove$.unsubscribe();
    this._mouseElement = null;
    this.data = undefined;
  }

  setPlaceholder(elementRef: ElementRef<HTMLElement>): void {
    elementRef.nativeElement.style.display = 'none';
    this._placeholder = elementRef.nativeElement;
  }

  registerDropzone(element: HTMLElement): void {
    this._dropzone = element;
  }

  private _onMouseMove(event: MouseEvent | Point): void {
    const x = event instanceof MouseEvent ? event.pageX : event.x;
    const y = event instanceof MouseEvent ? event.pageY : event.y;

    if (!this._mouseElement) {
      return;
    }

    if (this._mouseElement) {
      this._mouseElement.style.left = `${
        x - this._mouseElement.clientWidth / 2
      }px`;
      this._mouseElement.style.top = `${y - 28}px`;
    }
  }
}
