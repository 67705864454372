import { FieldSimpleTranslatableProperties } from './simple-field-properties';

export interface FieldProperties<
  TTranslatableProperties = FieldSimpleTranslatableProperties,
  TCommonProperties extends
    FieldCommonPropertiesBase = FieldCommonPropertiesBase,
> {
  translatableProperties: Record<string, TTranslatableProperties>;
  properties: TCommonProperties;
}

export interface FieldCommonPropertiesBase {
  containerId?: string;
  arePropertiesReadonly?: boolean
}
