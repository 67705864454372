import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  computed,
  inject,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import {
  DataGridActionsService,
  DataGridComponent,
  HeaderContentLayoutComponent,
  SelectFieldComponent,
} from '@fieldos/components';
import { HasPermissionDirective } from '@fieldos/directives';
import { Claim, TaxonomyType } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { of, switchMap } from 'rxjs';
import { TAXONOMY_LIST_STORE } from '../taxonomy.store';

@Component({
  selector: 'app-taxonomy-list',
  templateUrl: './taxonomy-list.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DataGridComponent,
    TranslocoModule,
    SelectFieldComponent,
    HeaderContentLayoutComponent,
    RouterModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    HasPermissionDirective,
  ],
  providers: [DataGridActionsService],
})
export class TaxonomyListComponent {
  constructor() {
    inject(DataGridActionsService)
      .buttonClick$.pipe(
        switchMap((event) => {
          if (event.buttonId === 'delete') {
            this.store.delete(event.data.id);
          }

          if (event.buttonId === 'edit') {
            this.editTaxonomy.emit(event.data);
          }

          return of('');
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  @Output() public readonly newTaxonomy = new EventEmitter<void>();
  @Output() public readonly editTaxonomy = new EventEmitter();

  public readonly taxonomyType = input.required<TaxonomyType>();

  protected readonly taxonomyPermission = computed<Claim>(
    () => `administration.${this.taxonomyType()}.add`
  );

  protected readonly store = inject(TAXONOMY_LIST_STORE);

  protected onNewTaxonomy() {
    this.newTaxonomy.emit();
  }
}
