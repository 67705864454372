import { FieldSubType } from './field-type.enum';
import {
  FieldCommonPropertiesBase,
  FieldProperties,
  FieldSimpleTranslatableProperties,
} from './properties';

export interface FieldBase<
  TTranslatableProperties = FieldSimpleTranslatableProperties,
  TCommonProperties extends
    FieldCommonPropertiesBase = FieldCommonPropertiesBase,
> extends FieldProperties<TTranslatableProperties, TCommonProperties> {
  id: string;
  subtype: FieldSubType;
}

export type Field<
  T = any,
  TTranslatableProperties = FieldSimpleTranslatableProperties,
  TCommonProperties extends
    FieldCommonPropertiesBase = FieldCommonPropertiesBase,
> = FieldBase<TTranslatableProperties, TCommonProperties> & {
  value?: T;
  defaultValue?: T;
};

export type FieldEntityBody = Pick<Field, 'id' | 'value'>[];

export interface FieldEntry<T = unknown> {
  id: string;
  value: T;
}
