<mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent">
  <mat-drawer-content class="flex flex-col">
    <ng-content />
  </mat-drawer-content>
  <mat-drawer
    class="w-full sm:w-[640px] dark:bg-gray-900"
    [autoFocus]="false"
    [mode]="mode"
    [opened]="opened"
    (closed)="close()"
    position="end">
    <app-sections-form-drawer-content
      [canPin]="canPin"
      [mode]="mode"
      (closeClick)="close()"
      (pinClick)="onPinChange()"
      (saveClick)="onCreate()" />
  </mat-drawer>
</mat-drawer-container>
