import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SectionBasedEntityListAGGridContext } from '@fieldos/business';
import { StarRatingFieldComponent } from '@fieldos/components';
import { RepeatTimesDirective } from '@fieldos/directives';
import { RatingSection, RatingSectionValue } from '@fieldos/models';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-rating-cell-renderer',
  templateUrl: './rating-cell-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    StarRatingFieldComponent,
    FormsModule,
    RepeatTimesDirective,
    MatIconModule,
  ],
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
})
export class RatingCellRendererComponent implements ICellRendererAngularComp {
  @ViewChild('cellContainer')
  private readonly _cellContainer!: ElementRef<HTMLDivElement>;

  protected value: number = 0;
  protected showStars = true;

  protected get showHalfStar(): boolean {
    return !Number.isInteger(this.value) && this.value !== 0;
  }

  protected get starCount(): number {
    return Math.floor(this.value || 0);
  }

  agInit(
    params: ICellRendererParams<
      string,
      RatingSectionValue,
      SectionBasedEntityListAGGridContext
    >
  ): void {
    this.value = params.value?.rating || 0;
    const fieldName = params.colDef?.field || '';
    const section = params.context.sections[fieldName] as RatingSection;

    this.showStars = section.properties?.showStars || true;
  }

  refresh(
    params: ICellRendererParams<unknown, RatingSectionValue, void>
  ): boolean {
    this.value = params.value?.rating || 0;
    return true;
  }
}
