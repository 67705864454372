import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  effect,
  inject,
} from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  SectionsFormDrawerContentComponent,
  SectionsFormStore,
} from '@fieldos/business';
import { LayoutDrawerFacade } from '@fieldos/facades';

@Component({
  selector: 'app-layout-drawer',
  templateUrl: './layout-drawer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatSidenavModule, SectionsFormDrawerContentComponent],
  providers: [SectionsFormStore],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class LayoutDrawerComponent {
  constructor() {
    effect(
      () => {
        const entityType = this.store.entityType();
        const entity = this.store.entity();

        this._formStore.setEntityType(entityType);
        if (entity) {
          this._formStore.initialize(entity);
        } else {
          this._formStore.hardReset();
        }
      },
      { allowSignalWrites: true }
    );
  }

  protected readonly store = inject(LayoutDrawerFacade);
  private readonly _formStore = inject(SectionsFormStore);

  @HostListener('window:keyup.escape')
  private _onEscape(): void {
    this.store.close();
  }
}
