import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  output,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DialogService } from '@fieldos/core';
import { DPUStore } from '@fieldos/store/dpu.store';
import { TranslocoModule } from '@ngneat/transloco';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-assign-exception-select-dpus-action',
  templateUrl: './assign-exception-select-dpus-action.component.html',
  standalone: true,
  imports: [MatButtonModule, TranslocoModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignExceptionSelectDpusActionComponent {
  public readonly valueChange = output<number[]>();

  protected readonly selectedDPUsNames = computed(() =>
    this.selection()
      .map((e) => this._dpuStore.entityMap()[e].number)
      .join(', ')
  );

  protected readonly selection = signal<number[]>([]);

  private readonly _dialog = inject(DialogService);
  private readonly _dpuStore = inject(DPUStore);

  protected async onAddDPUs(): Promise<void> {
    const component = await import(
      '@fieldos/business/dpus/select-dpus-dialog/select-dpus-dialog.component'
    ).then((m) => m.SelectDpusDialogComponent);

    const selection = await lastValueFrom(
      this._dialog.openDialog<number[], number[] | undefined>(component, {
        width: '800px',
        minHeight: '800px',
        data: this.selection(),
      })
    );

    if (selection) {
      this.valueChange.emit(selection);
      this.selection.set(selection);
    }
  }
}
