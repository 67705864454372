import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  DataSourceSection,
  DomainEntityBase,
  LocationDataSourceModel,
} from '@fieldos/models';
import { StandardDataSourceId } from '@fieldos/store/data-source-cache';
import {
  AutocompleteSelectComponent,
  MultipleSelectComponent,
} from '../../../../../@components/fields';
import { SectionsFormDataSourceSectionBaseComponent } from '../sections-form-data-source-section.base';

@Component({
  selector: 'app-sections-form-data-source-section',
  templateUrl: './sections-form-data-source-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    AutocompleteSelectComponent,
    MultipleSelectComponent,
    ReactiveFormsModule,
  ],
})
export class SectionsFormDataSourceSectionComponent
  extends SectionsFormDataSourceSectionBaseComponent<DataSourceSection>
  implements OnInit
{
  protected readonly searchProperties = signal<
    (keyof DomainEntityBase)[] | (keyof LocationDataSourceModel)[]
  >(['name']);

  override ngOnInit(): void {
    this.dataSourceId = this._workOrderSubtypeDataSourceMap[
      this.section().subtype
    ] as number;

    if (this.dataSourceId === StandardDataSourceId.Locations) {
      this.searchProperties.set(['name', 'locationCode']);
    }

    super.ngOnInit();
  }
}
