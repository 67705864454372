import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DescriptionSection } from '@fieldos/models';
import { HtmlEditorComponent } from '../../../../../@components/fields/html-editor';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-description-section',
  templateUrl: './sections-form-description-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, HtmlEditorComponent],
})
export class SectionsFormDescriptionSectionComponent extends SectionsFormSectionBaseComponent<DescriptionSection> {
  protected override initializeForm(): void {
    this.form = new FormControl<string>('', { nonNullable: true });
  }

  protected override setValidation(): void {
    this.form.setValidators([Validators.minLength(3), Validators.required]);
    this.form.updateValueAndValidity();
  }
}
