<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full">
  <!-- Content -->
  <div class="flex flex-col flex-auto">
    @if (true) {
      <router-outlet />
    }
  </div>

  <app-toasts />
</div>
