<app-floating-filter-layout
  [isActive]="isParentFilterActive()"
  (clear)="onClear()">
  <div
    class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper"
    ref="eWrapper"
    role="presentation">
    <input
      id="ag-132-input"
      class="ag-input-field-input ag-text-field-input"
      [disabled]="true"
      placeholder="{{ 'common.search' | transloco }}"
      ref="eInput"
      type="text"
      value="{{ searchFormCtrl.value | date: 'd/MM/yyyy' }}" />
  </div>
</app-floating-filter-layout>
