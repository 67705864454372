import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SimpleFormGroup } from '@browsepedia/ng-forms';
import { RatingSection } from '@fieldos/models';
import { TranslocoModule } from '@ngneat/transloco';
import { StarRatingFieldComponent } from '../../../../../@components/fields/star-rating-field';
import { SectionsFormSectionBaseComponent } from '../sections-form-section-base.component';

@Component({
  selector: 'app-sections-form-rating-section',
  templateUrl: './sections-form-rating-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    StarRatingFieldComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslocoModule,
  ],
})
export class SectionsFormRatingSectionComponent extends SectionsFormSectionBaseComponent<RatingSection> {
  protected override initializeForm(): void {
    this.form = new SimpleFormGroup({
      comments: new FormControl('', { nonNullable: true }),
      rating: new FormControl(0, { nonNullable: true }),
    });
  }

  protected override setValidation(): void {
    this.form.controls.rating.setValidators([
      Validators.required,
      Validators.max(5),
      Validators.min(0),
    ]);
    this.form.controls.rating.updateValueAndValidity();
  }

  protected override setDisabled(): void {
    this.form.controls.rating.disable();
    this.form.controls.comments.disable();
  }
}
