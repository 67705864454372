<h1 mat-dialog-title>
  {{ data.title | transloco }}
</h1>
<mat-dialog-content class="flex flex-col">
  <app-file-uploader [files]="files()" (filesAdded)="files.set($event)" />
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button [mat-dialog-close]="files()" color="primary">
    {{ 'common.close' | transloco }}
  </button>
</mat-dialog-actions>
