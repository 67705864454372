import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DataGridActionsService } from '@fieldos/components';
import { ClickStopPropagationDirective } from '@fieldos/directives';
import { SectionBaseEntityAction } from '@fieldos/models';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SectionBasedEntityListModel } from '../../section-columns.models';

@Component({
  selector: 'app-actions-column',
  templateUrl: './actions-column.component.html',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, ClickStopPropagationDirective],
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
    `,
  ],
})
export class ActionsColumnComponent implements ICellRendererAngularComp {
  constructor(
    private _dataGridService: DataGridActionsService<
      SectionBasedEntityListModel,
      SectionBaseEntityAction
    >
  ) {}

  protected actions: SectionBaseEntityAction[] = [];

  private _data!: SectionBasedEntityListModel;

  agInit(
    params: ICellRendererParams<
      SectionBasedEntityListModel,
      Record<SectionBaseEntityAction, boolean>
    >
  ): void {
    const value = params.value as Record<SectionBaseEntityAction, boolean>;
    this.actions = Object.keys(value).filter(
      (e) => value[e as SectionBaseEntityAction]
    ) as SectionBaseEntityAction[];

    this._data = params.data as SectionBasedEntityListModel;
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    const value = params.value as Record<SectionBaseEntityAction, boolean>;
    this.actions = Object.keys(value).filter(
      (e) => value[e as SectionBaseEntityAction]
    ) as SectionBaseEntityAction[];

    return false;
  }

  protected onActionClick(buttonId: SectionBaseEntityAction): void {
    this._dataGridService.onButtonClick(buttonId, this._data);
  }
}
