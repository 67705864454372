import { Component, output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormArray,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TextFieldComponent } from '@fieldos/components';
import { TranslocoModule } from '@ngneat/transloco';
import { filter, tap } from 'rxjs';

@Component({
  selector: 'app-assign-exception-add-seals-action',
  templateUrl: './assign-exception-add-seals-action.component.html',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    TranslocoModule,
    ReactiveFormsModule,
    TextFieldComponent,
  ],
})
export class AssignExceptionAddSealsActionComponent {
  constructor() {
    this.addSealCtrl();

    this.sealsFormArray.valueChanges
      .pipe(
        takeUntilDestroyed(),
        filter(() => this.sealsFormArray.valid),
        tap(() => this.sealsChange.emit(this.sealsFormArray.value as string[]))
      )
      .subscribe();
  }

  public readonly sealsChange = output<string[]>();

  protected readonly sealsFormArray = new FormArray<FormControl<string | null>>(
    []
  );

  protected removeSeal(index: number): void {
    this.sealsFormArray.removeAt(index);
  }

  protected addSealCtrl(): void {
    this.sealsFormArray.push(
      new FormControl<string | null>(null, { validators: Validators.required })
    );
  }
}
