import { Observable } from 'rxjs';

export const filterEquals =
  <T>(compareValue: T) =>
  (source: Observable<T>): Observable<T> =>
    new Observable((subscriber) =>
      source.subscribe({
        next: (value) => {
          if (value === compareValue) {
            subscriber.next(value);
          }
        },
        error: (error) => subscriber.error(error),
        complete: () => subscriber.complete(),
      })
    );
