<div>
  <app-file-uploader
    imageClass="object-scale-down border"
    [disabled]="form.disabled"
    [files]="files"
    [progressReport]="progress"
    [useExternalAdd]="true"
    (addClick)="openSignature()"
    (fileClick)="onFileClick($event)"
    (fileRemoved)="onRemoveFile($event)"
    placeholder="Click to add signature" />
</div>
