<div
  [class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
  [ngClass]="item.classes?.wrapper"
  class="fuse-vertical-navigation-item-wrapper">
  <div
    [ngClass]="{
      'fuse-vertical-navigation-item-active': active,
      'fuse-vertical-navigation-item-disabled': item.disabled,
      'fuse-vertical-navigation-item-active-forced': item.active
    }"
    class="fuse-vertical-navigation-item"
    [matTooltip]="item.tooltip || ''">
    <!-- Icon -->
    <ng-container *ngIf="item.icon">
      <mat-icon
        [ngClass]="item.classes?.icon"
        class="fuse-vertical-navigation-item-icon"
        [svgIcon]="item.icon"></mat-icon>
    </ng-container>

    <!-- Title & Subtitle -->
    <div class="fuse-vertical-navigation-item-title-wrapper">
      <div class="fuse-vertical-navigation-item-title">
        <span [ngClass]="item.classes?.title">
          {{ item.title | transloco }}
        </span>
      </div>
      <ng-container *ngIf="item.subtitle">
        <div class="fuse-vertical-navigation-item-subtitle">
          <span [ngClass]="item.classes?.subtitle">
            {{ item.subtitle }}
          </span>
        </div>
      </ng-container>
    </div>

    <!-- Badge -->
    <ng-container *ngIf="item.badge">
      <div class="fuse-vertical-navigation-item-badge">
        <div
          [ngClass]="item.badge.classes"
          class="fuse-vertical-navigation-item-badge-content">
          {{ item.badge.title }}
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="!skipChildren">
  <div class="p-2 w-full">
    <mat-form-field class="w-full fuse-mat-dense" subscriptSizing="dynamic">
      <input
        matInput
        (input)="searchTerm.set($any($event.target).value)"
        placeholder="{{ 'common.search' | transloco }}"
        type="text" />
    </mat-form-field>
  </div>

  <div class="fuse-vertical-navigation-item-children">
    <ng-container *ngFor="let item of filteredChildren(); trackBy: trackByFn">
      <!-- Skip the hidden items -->
      <!-- Skip the hidden items -->
      <ng-container *appHasPermissionStartingWith="item.permissionStartsWith">
        <ng-container *appHasPermission="item.permissions">
          <ng-container
            *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
            <!-- Basic -->
            <ng-container *ngIf="item.type === 'basic'">
              <fuse-vertical-navigation-basic-item
                [name]="name"
                [item]="item"></fuse-vertical-navigation-basic-item>
            </ng-container>

            <!-- Collapsable -->
            <ng-container *ngIf="item.type === 'collapsable'">
              <fuse-vertical-navigation-collapsable-item
                [name]="name"
                [autoCollapse]="autoCollapse"
                [item]="item"></fuse-vertical-navigation-collapsable-item>
            </ng-container>

            <!-- Divider -->
            <ng-container *ngIf="item.type === 'divider'">
              <fuse-vertical-navigation-divider-item
                [name]="name"
                [item]="item"></fuse-vertical-navigation-divider-item>
            </ng-container>

            <!-- Group -->
            <ng-container *ngIf="item.type === 'group'">
              <fuse-vertical-navigation-group-item
                [name]="name"
                [item]="item"></fuse-vertical-navigation-group-item>
            </ng-container>

            <!-- Spacer -->
            <ng-container *ngIf="item.type === 'spacer'">
              <fuse-vertical-navigation-spacer-item
                [name]="name"
                [item]="item"></fuse-vertical-navigation-spacer-item>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
