import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { DomainEntityBase } from '@fieldos/models';
import { DataSourceStore } from '@fieldos/store';
import { tap } from 'rxjs';
import { MultipleSelectComponent } from '../multiple-select/multiple-select.component';

@Component({
  selector: 'app-data-source-multi-select',
  templateUrl: './data-source-multi-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MultipleSelectComponent, ReactiveFormsModule, AsyncPipe],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataSourceMultiSelectComponent),
      multi: true,
    },
  ],
})
export class DataSourceMultiSelectComponent implements ControlValueAccessor {
  constructor() {
    this.formCtrl.valueChanges
      .pipe(
        tap((entityIds: number[]) => this._onChange(entityIds)),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  @Input() public label = '';
  @Input({ required: true }) public set dataSourceId(dataSourceId: number) {
    const options = this._dataSourceStore.selectDataSource(dataSourceId)();
    this.options.set(options);
  }

  protected readonly formCtrl = new FormControl<number[]>([], {
    nonNullable: true,
  });
  protected options = signal<DomainEntityBase[]>([]);

  private _onChange!: (value: number[]) => void;
  private _onTouch!: () => void;
  private readonly _dataSourceStore = inject(DataSourceStore);

  writeValue(entityIds: number[]): void {
    this.formCtrl.setValue(entityIds, { emitEvent: false });
  }

  registerOnChange(fn: (value: number[]) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }
}
