<app-floating-filter-layout
  [isActive]="isParentFilterActive() || !!searchFormCtrl.value"
  (clear)="onClear()">
  <div
    class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper"
    ref="eWrapper"
    role="presentation">
    <input
      class="ag-input-field-input ag-text-field-input"
      [formControl]="searchFormCtrl"
      placeholder="{{ 'common.search' | transloco }}"
      ref="eInput"
      type="text" />
  </div>
</app-floating-filter-layout>
