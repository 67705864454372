<app-header-content-layout>
  <ng-container toolbar>
    <ng-content select="[toolbar]" />
  </ng-container>

  <div class="flex flex-grow min-h-0">
    <div class="w-60 bg-card dark:bg-transparent flex-shrink-0" fuseScrollbar>
      <ng-content select="[sidebar]" />
    </div>

    <ng-content />
  </div>
</app-header-content-layout>
