import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ClickStopPropagationDirective } from '@fieldos/directives';
import { Point } from 'ngx-panzoom/lib/types/point';

@Component({
  selector: 'app-point-menu',
  templateUrl: './point-menu.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ClickStopPropagationDirective,
  ],
})
export class PointMenuComponent {
  @Input() public loading = false;
  @Input() public isEditing = false;
  @Input() public pointTitle = '';

  @Output() public readonly confirm = new EventEmitter<void>();
  @Output() public readonly cancel = new EventEmitter<void>();
  @Output() public readonly reposition = new EventEmitter<MouseEvent>();
  @Output() public readonly remove = new EventEmitter<void>();

  @ViewChild('menuContainer') private _container!: ElementRef<HTMLDivElement>;
  show(event: MouseEvent | Point): void {
    const element = this._container.nativeElement;
    element.style.position = 'fixed';
    element.style.zIndex = '999';
    element.style.display = 'block';

    let left = '0';
    let top = '0';

    if (event instanceof MouseEvent) {
      const target = event.target as HTMLElement;
      if (target.tagName === 'MAT-ICON') {
        const box = target.getBoundingClientRect();
        top = `${box.top + box.height}px`;
        left = `${box.left - element.clientWidth / 2 + box.width / 2}px`;
      } else {
        top = `${event.pageY}px`;
        left = `${event.pageX - element.clientWidth / 2}px`;
      }
    } else {
      left = `${event.x - element.clientWidth / 2}px`;
      top = `${event.y}px`;
    }

    element.style.top = top;
    element.style.left = left;
  }

  hide(): void {
    const element = this._container.nativeElement;
    element.style.display = 'none';
  }
}
