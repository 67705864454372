import { inject } from '@angular/core';
import { HttpService } from '@fieldos/core';
import {
  CategoryType,
  Field,
  FieldBasedEntity,
  FieldSubType,
} from '@fieldos/models';

export class FieldBasedEntitiesServiceBase<
  T extends FieldBasedEntity = FieldBasedEntity,
> {
  constructor(
    private readonly _categoryType: CategoryType,
    private readonly _type: FieldSubType
  ) {}

  protected readonly _http = inject(HttpService);

  fetchAll(query?: Record<string, string>): Promise<T[]> {
    if (query) {
      const queryParams = Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join('&');
      return this._http.get<T[]>(`/${this._categoryType}?${queryParams}`);
    }

    return this._http.get<T[]>(`/${this._categoryType}`);
  }
  fetchOne(entityId: number): Promise<T> {
    return this._http.get<T>(`/${this._categoryType}/${entityId}`);
  }

  create(fields: Field[]): Promise<T> {
    const typeId = fields.find((field) => field.subtype === this._type)
      ?.value as number;

    return this._http.post<T>(`/${this._categoryType}`, {
      typeId,
      value: fields.map((field) => ({
        id: field.id,
        value: field.value,
      })),
    });
  }

  update(entityId: number, fields: Field[]): Promise<T> {
    const requestBody = fields.map((field) => ({
      id: field.id,
      value: field.value,
    }));

    return this._http.put<T>(`/${this._categoryType}/${entityId}`, requestBody);
  }

  delete(entityId: number): Promise<void> {
    return this._http.delete(`/${this._categoryType}/${entityId}`);
  }
}
