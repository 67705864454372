<div class="flex flex-wrap gap-4">
  @for (option of availableOptions; track option.value) {
    @if (form.value === option.value) {
      <button
        mat-flat-button
        [disabled]="form.disabled"
        (click)="onSelectPriority(option.value)"
        color="{{ option.value | workOrderFormPriorityBtnClass }}">
        {{ option.label | transloco }}
      </button>
    } @else {
      <button
        mat-stroked-button
        [disabled]="form.disabled"
        (click)="onSelectPriority(option.value)"
        color="{{ option.value | workOrderFormPriorityBtnClass }}">
        {{ option.label | transloco }}
      </button>
    }
  }
</div>
