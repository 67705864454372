<div #cellContainer>
  @if (showStars) {
    <mat-icon *repeatTimes="starCount">star</mat-icon>
    @if (showHalfStar) {
      <mat-icon>star_half</mat-icon>
    }
  } @else {
    <div class="flex justify-center">
      <span class="text-base font-medium text-left"> {{ value }}/5 </span>
    </div>
  }
</div>
